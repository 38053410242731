import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  smallText: {
    fontSize: '0.8rem'
  }
}));

const CopyrightInfo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.smallText}>
      Powered by <strong>PragLife</strong>. Pragmatrix Technology LLC. &copy; 2024
    </Box>
  );
}

export default CopyrightInfo;
