import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import { Link } from "react-router-dom";
import React from "react";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import useUserSession from '../../hooks/use-user-session';

const useStyles = makeStyles(theme => ({
  card: {
    overflow: "visible"
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  background: {
    backgroundColor: 'rgb(63,81,181,0.6)'
    // backgroundColor: theme.palette.primary.main
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center"
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto"
  },
  fullWidth: {
    width: "100%"
  },
  logo: {
    display: "flex",
    flexDirection: "column"
  }
}));

const Signin = ({ history }) => {
  const firebaseAuth = firebase.auth();
  const session = useUserSession();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (session.user) {
      history.push('/');
    }
  }, [session]);

  const handleAuth = (evt) => {
    if (username && password) {
      firebaseAuth
        .signInWithEmailAndPassword(username, password)
        .then((userCredential) => { 
          // const user = userCredential.user;
          // setErrorMsg('');
          // history.push('/');
          window.location.reload();
        }).catch((error) => {
          setErrorMsg(`[${error.code}]: ${error.message}`);
        });
    } else {
      setErrorMsg('Username & Password is mandatory!');
    }
    evt.preventDefault();
  }
  
  const classes = useStyles();
  return (
    <>
      <div className={classNames(classes.session, classes.background)}>
        <div className={classes.content}>
          <div className={classes.wrapper}>
            <Card>
              <CardContent>
                <form onSubmit={handleAuth}>
                  <div
                    className={classNames(classes.logo, `pb-xs`)}
                  >
                    <img
                      width="200"
                      src={`${process.env.PUBLIC_URL}/static/images/pragmatrix_logo.png`}
                      alt=""
                      className="block"
                    />
                    <Typography variant="h5" style={{ textAlign: 'left' }}>
                      Electronic Batch Record
                    </Typography>
                    {errorMsg && <Alert severity="error">
                      {errorMsg}                      
                    </Alert>}
                  </div>
                  <TextField
                    id="username"
                    label="Username"
                    className={classes.textField}
                    fullWidth
                    required
                    margin="normal"
                    value={username}
                    onChange={(evt) => { setUsername(evt.target.value) }}
                  />
                  <TextField
                    id="password"
                    label="Password"
                    className={classes.textField}
                    type="password"
                    fullWidth
                    required
                    margin="normal"
                    value={password}
                    onChange={(evt) => { setPassword(evt.target.value) }}
                  /><br/><br/>
                  {/* <FormControlLabel
                    control={<Checkbox value="checkedA" />}
                    label="Stayed logged in"
                    className={classes.fullWidth}
                  /> */}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Login
                  </Button>
                  {/* <div className="pt-1 text-md-center">
                    <Link to="/forgot">
                      <Button>Forgot password?</Button>
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="/signup">
                      <Button>Create new account.</Button>
                    </Link>
                  </div> */}
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
