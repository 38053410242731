import { useRef } from 'react';
import dayjs from 'dayjs';
import { FormGenerator } from "formik-generator-materialui";
import Button from '@material-ui/core/Button';
import useBatchFormData from '../../hooks/use-batch-form-data';
import { BATCH_FORM_NAMES as FORM_NAMES } from '../../hooks/constants';

const formFieldsSchema = [
    {
        title: 'Room #',
        typeField: 'text',
        path: 'roomNum',
    },
    {
        title: 'Record the tooling number, feed tray number, perf blade and calibration due date from the machine',
        typeField: 'group',
        path: 'toolingNum',
        subfields: [
            {
                title: 'Feed Tray Num',
                typeField: 'text',
                path: 'toolingNum/feedTrayNum',
                col: 3
            },
            {
                title: 'Perf Blade Num',
                typeField: 'text',
                path: 'toolingNum/perfBladeNum',
                col: 3
            },
            {
                title: 'Tooling Num',
                typeField: 'text',
                path: 'toolingNum/toolingNum',
                col: 3
            },
            {
                title: 'Calibration',
                typeField: 'date',
                path: 'toolingNum/calibrationDate',
                simple: true,
                col: 3
            },
            {
                title: 'Verified',
                typeField: 'switch',
                path: 'toolingNum/verified',
                col: 2
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'toolingNum/verifiedBy',
                col: 3
            },
            {
                title: 'Verified On',
                typeField: 'date',
                simple: true,
                path: 'toolingNum/verifiedOn',
                col: 3
            }
        ]
    },
    {
        title: 'Set the temperature and speed settings as specified in the BOM.',
        hint: 'Confirm the actual values are within specified ranges.',
        typeField: 'switch',
        path: 'tempAndSpeedRecordedVerified'
    },
    {
        title: 'Adjust the Form temperature to produce completely formed cavities.',
        typeField: 'switch',
        path: 'adjustTemperatureVerified'
    },
    {
        title: 'Adjust Seal Pressure to achieve acceptable seal integrity.',
        typeField: 'switch',
        path: 'adjustSealPressureVerified'
    },
    {
        title: 'Adjust Perforation Pressure to achieve acceptable perforation.',
        typeField: 'switch',
        path: 'adjustPerforationPressureVerified'
    },
    {
        title: 'Print Plate (artwork) installed.',
        typeField: 'switch',
        path: 'printPlateInstalledVerified'
    },
    {
        title: 'Make a print of the artwork on an artwork approval form.',
        hint: 'Verify the Lot number/expiration date match the Master Blister Record. Include an initialed/dated copy of printed lidding in the packaging record.',
        typeField: 'switch',
        path: 'printArtworkVerified'
    },
    {
        title: 'Inspect a blister card',
        typeField: 'group',
        subfields: [
            {
                title: 'Print legibly, completeness and position',
                typeField: 'group',
                subfields: [
                    {
                        title: 'Ok',
                        typeField: 'switch',
                        path: 'blisterInspection/print/ok',
                        col: 2
                    },
                    {
                        title: 'Technician',
                        typeField: 'text',
                        path: 'blisterInspection/print/technician',
                        col: 3
                    },
                    {
                        title: 'Verified By',
                        typeField: 'text',
                        path: 'blisterInspection/print/verifiedBy',
                        col: 3
                    },
                    {
                        title: 'Verified On',
                        typeField: 'date',
                        path: 'blisterInspection/print/verifiedOn',
                        simple: true,
                        col: 3
                    }
                ]
            },
            {
                title: 'Perforation functionality',
                typeField: 'group',
                subfields: [
                    {
                        title: 'Ok',
                        typeField: 'switch',
                        path: 'perforation/print/ok',
                        col: 2
                    },
                    {
                        title: 'Technician',
                        typeField: 'text',
                        path: 'perforation/print/technician',
                        col: 3
                    },
                    {
                        title: 'Verified By',
                        typeField: 'text',
                        path: 'perforation/print/verifiedBy',
                        col: 3
                    },
                    {
                        title: 'Verified On',
                        typeField: 'date',
                        path: 'perforation/print/verifiedOn',
                        simple: true,
                        col: 3
                    }
                ]
            },
            {
                title: 'Child resistance functionality',
                typeField: 'group',
                subfields: [
                    {
                        title: 'Ok',
                        typeField: 'switch',
                        path: 'childResistance/print/ok',
                        col: 2
                    },
                    {
                        title: 'Technician',
                        typeField: 'text',
                        path: 'childResistance/print/technician',
                        col: 3
                    },
                    {
                        title: 'Verified By',
                        typeField: 'text',
                        path: 'childResistance/print/verifiedBy',
                        col: 3
                    },
                    {
                        title: 'Verified On',
                        typeField: 'date',
                        path: 'childResistance/print/verifiedOn',
                        col: 3
                    }
                ]
            }
        ]
    }
];

const prepareDataToEdit = (values) => {
    const data = Object.assign({}, values);
    if (data['toolingNum/verifiedOn'] && data['toolingNum/verifiedOn'].seconds) {
        data['toolingNum/verifiedOn'] = dayjs.unix(data['toolingNum/verifiedOn'].seconds).toDate();
    }
    if (data['toolingNum/calibrationDate'] && data['toolingNum/calibrationDate'].seconds) {
        data['toolingNum/calibrationDate'] = dayjs.unix(data['toolingNum/calibrationDate'].seconds).toDate();
    }
    if (data['blisterInspection/print/verifiedOn'] && data['blisterInspection/print/verifiedOn'].seconds) {
        data['blisterInspection/print/verifiedOn'] = dayjs.unix(data['blisterInspection/print/verifiedOn'].seconds).toDate();
    }
    if (data['childResistance/print/verifiedOn'] && data['childResistance/print/verifiedOn'].seconds) {
        data['childResistance/print/verifiedOn'] = dayjs.unix(data['childResistance/print/verifiedOn'].seconds).toDate();
    }
    if (data['perforation/print/verifiedOn'] && data['perforation/print/verifiedOn'].seconds) {
        data['perforation/print/verifiedOn'] = dayjs.unix(data['perforation/print/verifiedOn'].seconds).toDate();
    }
    return data;
}

function LineSetupForm({
    orderId,
    lotId,
    onSave = null,
    onCancel = null,
    isReadOnly = false,
    isValidateOnlyOnSubmit = true,
    isPreview = false
}) {
    const { batchFormData, saveBatchForm, error } = useBatchFormData(orderId, lotId, FORM_NAMES.lineSetup);
    let initialValues = {};
    if (batchFormData) {
        initialValues = prepareDataToEdit(batchFormData);
    }

    const saveFormData = (formData) => {
        saveBatchForm(formData).then(() => {
            onSave && onSave();
        });
    };

    const formRef = useRef(null);
    return (
        <>
            <FormGenerator
              onSubmit={(values) => {
                saveFormData(values);
              }}
              fields={formFieldsSchema}
              formRef={formRef}
              initialValues={initialValues}
              readOnly={isReadOnly}
              isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
            />
            {!isPreview && <>
                <Button color="secondary" style={{ marginRight: '2em' }}
                    onClick={() => {
                        formRef.current.resetForm();
                        onCancel && onCancel();
                    }}>Cancel</Button>
                <Button variant="contained" color="primary"
                    onClick={() => formRef.current.submitForm()}>Save</Button>
            </>}
        </>
    );
}

export default LineSetupForm;