export const BATCH_FORM_SLUGS = {
    productionSetup: 'production-setup',
    packaging: 'packaging',
    lineSetup: 'line-setup',
    lineStartup: 'line-startup',
    dailyOperations: 'daily-operations',
    dailyRecord: 'daily-record',
    rejects: 'rejects',
    qaInspection: 'qa-inspection',
    inspectionComments: 'inspection-comments',
    qaAccess: 'qa-access',
    additionalProductionSetup: 'additional-production-setup',
    additionalPackaging: 'additional-packaging',
    additionalLineSetup: 'additional-line-setup',
    additionalLineStartup: 'additional-line-startup',
    additionalDailyOperations: 'additional-daily-operations',
    additionalDailyRecord: 'additional-daily-record',
    additionalRejects: 'additional-rejects',
    additionalQaInspection: 'additional-qa-inspection',
    additionalInspectionComments: 'additional-inspection-comments',
    manualProcessOne: 'manual-process-one',
    manualProcessTwo: 'manual-process-two'
};

// This is to make the code backwards compatible. 
// TODO: remove usage of *BATCH_FORM_NAMES*
export const BATCH_FORM_NAMES = BATCH_FORM_SLUGS;

export const BATCH_FORM_STATUS = {
    COMPLETED: "COMPLETED",
    REVIEWED: 'REVIEWED',
    VERIFIED: "VERIFIED",
    QA_VERIFIED: "QA_VERIFIED",
    REOPENED: "REOPENED",
};

export const USER_ROLE = {
    ADMIN: 'admin',
    SUPERVISOR: 'supervisor',
    QA: 'qa',
    DOCTOR: 'doctor',
    REVIEWER: 'reviewer',
    VERIFIER: 'verifier',
    COMPLETER: 'completer',
    REOPENER: 'reopener',
    SITE_USER: 'site-user', // For Scheduler App
    LAB_USER: 'lab-user' // For Scheduler App
};

export const USER_ROLE_MASTER = USER_ROLE;
export const USER_ROLE_MASTER_MAP = {
    [USER_ROLE_MASTER.SITE_USER]: 'Site User',
    [USER_ROLE_MASTER.LAB_USER]: 'Lab User'
};

export const NoteStatus = {
    pending: 'pending',
    done: 'done',
    signaturePending: 'signaturePending'
};

export const PERMISSION_CODES = {
    BATCH_FORM_STATUS_COMPLETED: 'BATCH_FORM_STATUS_COMPLETED',
    BATCH_FORM_STATUS_REVIEWED: 'BATCH_FORM_STATUS_REVIEWED',
    BATCH_FORM_STATUS_VERIFIED: 'BATCH_FORM_STATUS_VERIFIED',
    BATCH_FORM_STATUS_QA_VERIFIED: 'BATCH_FORM_STATUS_QA_VERIFIED',
    BATCH_FORM_STATUS_REOPENED: 'BATCH_FORM_STATUS_REOPENED',
    BATCH_FORM_QA_ACCESS: 'BATCH_FORM_QA_ACCESS',
    BATCH_FORM_ACCESS_LOGS: 'BATCH_FORM_ACCESS_LOGS'
};

export const FORM_STATUS_PERMISSION_CODE_MAP = {
    [BATCH_FORM_STATUS.COMPLETED]: PERMISSION_CODES.BATCH_FORM_STATUS_COMPLETED,
    [BATCH_FORM_STATUS.REVIEWED]: PERMISSION_CODES.BATCH_FORM_STATUS_REVIEWED,
    [BATCH_FORM_STATUS.VERIFIED]: PERMISSION_CODES.BATCH_FORM_STATUS_VERIFIED,
    [BATCH_FORM_STATUS.QA_VERIFIED]: PERMISSION_CODES.BATCH_FORM_STATUS_QA_VERIFIED,
    [BATCH_FORM_STATUS.REOPENED]: PERMISSION_CODES.BATCH_FORM_STATUS_REOPENED,
};

export const FORM_TYPES = {
    manualProcess: 'manual-process'
};

export const PERMISSIONS_MASTER = [
    {
        title: 'Update Form Status to Completed',
        code: PERMISSION_CODES.BATCH_FORM_STATUS_COMPLETED
    },
    {
        title: 'Update Form Status to Reviewed',
        code: PERMISSION_CODES.BATCH_FORM_STATUS_REVIEWED
    },
    {
        title: 'Update Form Status to Verified',
        code: PERMISSION_CODES.BATCH_FORM_STATUS_VERIFIED
    },
    {
        title: 'Update Form Status to QA Verified',
        code: PERMISSION_CODES.BATCH_FORM_STATUS_QA_VERIFIED
    },
    {
        title: 'Update Form Status to Re-opened',
        code: PERMISSION_CODES.BATCH_FORM_STATUS_REOPENED
    },
    {
        title: 'Update Form QA Access records',
        code: PERMISSION_CODES.BATCH_FORM_QA_ACCESS
    },
    {
        title: 'View Form Access Logs',
        code: PERMISSION_CODES.BATCH_FORM_ACCESS_LOGS
    }
];

export const APP_NAMES = {
    electronicBatchRecord: 'electronic-batch-record',
    scheduler: 'scheduler'
};

export const RESOURCE_TYPE = {
    cleanRoom: 'cleanRoom',
    equipment: 'equipment',
    person: 'person',
    template: 'template', // Cell Data Type
    day: 'day', // Cell Data Type
    process: 'process', // Scheduler Entity 
    client: 'client', // Scheduler Entity
    event: 'event', // Scheduler Entity
    schedule: 'schedule',
    cleanRoomCapacityView: 'cleanRoomCapacityView',
    equipmentCapacityView: 'equipmentCapacityView',
    personCapacityView: 'personCapacityView',
    singleCleanRoomCapacityView: 'singleCleanRoomCapacityView',
    singleEquipmentCapacityView: 'singleEquipmentCapacityView',
    singlePersonCapacityView: 'singlePersonCapacityView',
    singleScheduleCapacityView: 'singleScheduleCapacityView'
};

export const RESOURCE_SUB_TYPE = {
    equipment: {
        ventillator: 'ventillator',
        o2_incubator: 'o2-incubator',
        co2_incubator: 'co2-incubator'
    } 
};

export const RESOURCE_TYPE_NAME_MAP = {
    cleanRoom: 'Clean Rooms',
    equipment: 'Equipment',
    person: 'People',
    process: 'Processes',
    client: 'Customers'
};

export const CLIENT_NAMES = {
    ca01: { name: 'CA-01', code: 'ca-01' },
    ca02: { name: 'CA-02', code: 'ca-02' }
};

export const PROCESS_NAMES = {
    p01: { name: 'P-01', code: 'p-01' },
    p02: { name: 'P-02', code: 'p-02' }
};

export const FIRESTORE_COLLECTIONS = {
    schedulerEvents: 'schedulerEvents',
    schedulerResources: 'schedulerResources',
    schedulerTemplates: 'schedulerTemplates',
    schedulerBlockedDays: 'schedulerBlockedDays',
    schedulerOrders: 'schedulerOrders',
    schedulerAutoAllocation: 'schedulerAutoAllocation'
};

export const SCHEDULE_TYPE = {
    PLANNER: 'planner',
    SITE: 'site'
};

export const SCHEDULE_ORDER_DATE_TYPE = {
    BIOPSY: 'biopsy',
    INJECTION_1: 'injection1',
    INJECTION_2: 'injection2'
};

export const SCHEDULER = {
    FIRESTORE_COLLECTIONS,
    PROCESS_NAMES,
    CLIENT_NAMES,
    RESOURCE_TYPE_NAME_MAP,
    RESOURCE_TYPE,
    SCHEDULE_TYPE,
    SCHEDULE_ORDER_DATE_TYPE,
    PEOPLE_SKILLS: {
        beginner: 'Beginner',
        intermediate: 'Intermediate',
        expert: 'Expert'
    },
    EQUIPMENT_TYPES: [
    //     {
    //     code: RESOURCE_SUB_TYPE.equipment.ventillator,
    //     name: 'Ventillator'
    // }, 
    {
        code: RESOURCE_SUB_TYPE.equipment.o2_incubator,
        name: 'O2 Incubator'
    }, {
        code: RESOURCE_SUB_TYPE.equipment.co2_incubator,
        name: 'CO2 Incubator'
    }],
    EQUIPMENT_TYPE_OPTIONS: [
        // RESOURCE_SUB_TYPE.equipment.ventillator, 
        RESOURCE_SUB_TYPE.equipment.o2_incubator, RESOURCE_SUB_TYPE.equipment.co2_incubator]
};

export const PROCESS_CODE_BIOPSY = 'pr-bio';

export const DAY_24HRS_MINUTES = 24*60;
export const ALL_DAY_DURATION = 0;