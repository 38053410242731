import { useFormikContext, Field } from 'formik';
import { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

function ResourceIntegrationForm({ onChange }) {
  const { values, handleChange, touched, errors } = useFormikContext();

  useEffect(() => {
    onChange?.(values);
  }, [values, touched, errors]);

  return (
    <Box>
      <TextField
          fullWidth
          id="cliniTrackId"
          name="cliniTrackId"
          label="CliniTrack ID"
          value={values.cliniTrackId}
          onChange={handleChange}
          error={touched.cliniTrackId && Boolean(errors.cliniTrackId)}
          helperText={touched.cliniTrackId && errors.cliniTrackId}
        />
      <TextField
          fullWidth
          id="customIntegrationId1"
          name="customIntegrationId1"
          label="Custom Integration ID 1"
          value={values.customIntegrationId1}
          onChange={handleChange}
          error={touched.customIntegrationId1 && Boolean(errors.customIntegrationId1)}
          helperText={touched.customIntegrationId1 && errors.customIntegrationId1}
        />
      <TextField
          fullWidth
          id="customIntegrationId2"
          name="customIntegrationId2"
          label="Custom Integration ID 2"
          value={values.customIntegrationId2}
          onChange={handleChange}
          error={touched.customIntegrationId2 && Boolean(errors.customIntegrationId2)}
          helperText={touched.customIntegrationId2 && errors.customIntegrationId2}
        />
    </Box>
  );
}

export default ResourceIntegrationForm;
