import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
// import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useFormsMaster from '../../hooks/use-form-master-list';
import ManualProcessOneForm from '../batch-forms/manual-process-one-form';
import ManualProcessTwoForm from '../batch-forms/manual-process-two-form';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

export default function WorkflowCheckpointFormModal({ isVisible, onClose, onSave }) {  
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [checkpointDescription, setCheckpointDescription] = useState('');
  const [manualProcessFormTemplate, setManualProcessFormTemplate] = useState({ slug: '' });
  const [isSelectDropDownOpen, setIsSelectDropDownOpen] = useState(false);
  // const [isActive, setIsActive] = useState(true);

  const { manualProcessFormsMasterList, manualProcessFormsMasterMap } = useFormsMaster();

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  return (
    <>
      <Dialog      
        open={isOpen}
        onClose={() => {
          onClose?.();
        }}
        aria-labelledby="workflow-checkpoint-form-modal-title">
        <DialogTitle id="workflow-forms-modal-title">Configure Manual Process</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="workflow-forms-modal-description">
            Choose the forms for this Batch
          </DialogContentText> */}
          
          <TextField
            required
            autoFocus
            tabIndex="0"
            value={checkpointDescription}
            onChange={(e) => {
              setCheckpointDescription(e.target.value);
            }}
            margin="dense"
            id="process-description"
            label="Process Description"
            fullWidth
          />
          {/* <Checkbox checked={isActive} /> <Typography style={{ display: 'inline-block' }}>Is Active?</Typography> */}

          <FormControl className={classes.formControl} required>
            <InputLabel htmlFor="manual-process-form-template-select">Select Form Template</InputLabel>
            <Select
              open={isSelectDropDownOpen}
              onClose={() => {
                setIsSelectDropDownOpen(false);
              }}
              onOpen={() => {
                setIsSelectDropDownOpen(true);
              }}
              value={manualProcessFormTemplate?.slug}
              onChange={(event) => {
                if (event.target.value === '') {
                  setManualProcessFormTemplate({ slug: '' });
                } else {
                  setManualProcessFormTemplate(manualProcessFormsMasterMap[event.target.value]);
                }
              }}
              inputProps={{
                name: 'manualProcessFormTemplate',
                id: 'manual-process-form-template-select',
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {manualProcessFormsMasterList.map((form) => (<MenuItem key={`manual-process-select-option-${form.slug}`} value={form.slug}>{form.name}</MenuItem>))}
            </Select>
          </FormControl>

          {manualProcessFormTemplate.slug != '' && <Box p={1} mt={3} style={{ backgroundColor: '#ffffe0' }}>
            <Typography style={{ textTransform: 'uppercase', color: 'red' }}>Process Form Preview</Typography>
            {manualProcessFormTemplate?.slug === 'manual-process-one' && <ManualProcessOneForm isPreview={true} onSave={() => {}} orderId={null} lotId={null} />}
            {manualProcessFormTemplate?.slug === 'manual-process-two' && <ManualProcessTwoForm isPreview={true} onSave={() => {}} orderId={null} lotId={null} />}
          </Box>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { onClose?.() }}>
            Cancel
          </Button>
          <Button onClick={() => { onSave?.(checkpointDescription, manualProcessFormTemplate) }} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}