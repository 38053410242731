import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pageTitle: {
    fontSize: '1.4rem',
    fontWeight: 300,
    height: '44px'
  }
}));

export default function PageTitle({ title, children }) {
  const classes = useStyles();

  return (
    <Box className={classes.pageHeader}>
      <Typography variant="h5" className={classes.pageTitle}>{title}</Typography>
      {children && 
        <Box>
          {children}
        </Box>
      }
    </Box>
  )
}