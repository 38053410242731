import { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Wrapper from "../../components/Wrapper/Wrapper";
import Breadcrumb from '../../design-system/breadcrumb';
import PageTitle from "../../design-system/page-title";
import TabPanel from "../../design-system/tab-content-panel";
import SimpleSnackbar from "../../design-system/simple-snackbar";
import TemplateDataGrid from "../../components/scheduler-admin/template-datagrid";
import TemplateFormModal from "../../components/scheduler-admin/template-form-modal";
import PublishSlotAvailability from '../../components/scheduler-admin/publish-slot-availability';
import useSchedulerAutoAllocationConfig from '../../hooks/scheduler/use-scheduler-auto-allocation-config';
import AutoSchedulerConfigForm from '../../components/scheduler-admin/auto-scheduler-config-form';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  floatingButton: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(-10)
  }
}));

const ManageTemplatesPage = () => {
  const classes = useStyles();
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Successfully saved auto-scheduler config');

  const {data} = useSchedulerAutoAllocationConfig();

  return (
    <Wrapper>        
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      <PageTitle title="Manage Templates" />

      <Tabs value={activeTabIndex} onChange={(evt, newTabIndex) => {
        setActiveTabIndex(newTabIndex);
      }}>
        <Tab label="Manual Templates" />
        <Tab label="Smart Template" />
        <Tab label="Slots" />
      </Tabs>

      <TabPanel index={0} value={activeTabIndex} key="manual-templates">
        <Box sx={{ position: 'relative'}}>
          <TemplateDataGrid />

          <Fab color="primary" aria-label="create new template" className={classes.floatingButton} onClick={() => {
            setIsCreateTemplate(true);
          }}>
            <AddIcon />
          </Fab>
        </Box>
      </TabPanel>
      <TabPanel index={1} value={activeTabIndex} key="smart-templates">
        <AutoSchedulerConfigForm onSave={() => {
          setIsSnackbarVisible(true);
          setSnackbarMessage('Sucessfully saved configuration');
        }} />
      </TabPanel>
      <TabPanel index={2} value={activeTabIndex} key="smart-templates-slot-availability">
        <Alert variant="outlined" color="warning">
          # Slots are calculated as per ProKidney Template. Generate slots for upto 3 months.
        </Alert>
        <PublishSlotAvailability autoAllocationConfig={data} 
          onPublish={() => {
            setIsSnackbarVisible(true);
            setSnackbarMessage('Sucessfully published slot count');
          }} 
        />
      </TabPanel>

      {isCreateTemplate && 
        <TemplateFormModal 
          isVisible={isCreateTemplate}
          onClose={() => {
            setIsCreateTemplate(false);
          }}
          onSave={(values) => {
            console.log('onSave: ', values);
          }}
      />}

      <SimpleSnackbar
        isVisible={isSnackbarVisible}
        message={snackbarMessage}
        onClose={() => {
          setIsSnackbarVisible(false);
        }}
      />
    </Wrapper>
  );
}

export default ManageTemplatesPage;