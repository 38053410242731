import { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
// import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { BATCH_FORM_STATUS } from '../../hooks/constants';

export default function ConfigureFormModal({ modalTitle, isVisible, formMasterData, onClose, onSave }) {
  const [isOpen, setIsOpen] = useState(false);
  const [sopDate, setSopDate] = useState(formMasterData?.sopDate || '');
  const [selectedFormStatuses, setSelectedFormStatuses] = useState(formMasterData?.formStatuses || []);

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose?.();
      }}
      aria-labelledby="configure-forms-modal-title"
      aria-describedby="configure-forms-modal-description">
      <DialogTitle id="configure-forms-modal-title">{modalTitle || `Configure Form: ${formMasterData?.name || ''}`}</DialogTitle>
      <DialogContent>        

        <TextField
          autoFocus
          tabIndex="0"
          value={sopDate}
          type="date"
          onChange={(e) => {
            setSopDate(e.target.value);
          }}
          margin="dense"
          id="sop-date"
          label="SOP Date"
          fullWidth
        />
        <br/><br/>
        <DialogContentText id="configure-forms-modal-description">
          Choose statuses for this form.
        </DialogContentText>

        {Object.values(BATCH_FORM_STATUS).map((statusVal) => (
          <div key={`${statusVal}-checkbox`}>
            <Checkbox value={statusVal} checked={selectedFormStatuses.indexOf(statusVal) !== -1} onClick={(evt) => {              
              let newStatuses = [];
              if (evt.target.checked) {
                newStatuses = Object.values(BATCH_FORM_STATUS).filter((stVal) => {
                  return (stVal == evt.target.value || (selectedFormStatuses.indexOf(stVal) !== -1));
                });
              } else {
                const foundIndex = selectedFormStatuses.indexOf(evt.target.value);
                selectedFormStatuses.splice(foundIndex, 1);
                newStatuses = [].concat(selectedFormStatuses);
              }
              setSelectedFormStatuses(newStatuses);
            }} />
            <Typography style={{ display: 'inline-block' }}> {statusVal.replaceAll('_', ' ')} </Typography>
          </div>
        ))}

        
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { onClose?.() }}>
          Cancel
        </Button>
        <Button onClick={() => {          
          onSave?.(sopDate, selectedFormStatuses);
        }} 
        color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}