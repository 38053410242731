import { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    close: {
      width: theme.spacing(1) * 4,
      height: theme.spacing(1) * 4,
    },
}));
  
function SimpleSnackbar({ isVisible, message, onClose = null }) {
    const [isOpen, setIsOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setIsOpen(isVisible);
    }, [isVisible]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setIsOpen(false);
        onClose && onClose();
    };

    return (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={isOpen}
          autoHideDuration={6000}
          TransitionComponent={Fade}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
    )
}

export default SimpleSnackbar;