// import { Bar, Bubble } from "react-chartjs-2";
import dayjs from 'dayjs';
import { makeStyles } from "@material-ui/core/styles";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import CardHeader from "@material-ui/core/CardHeader";
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import ResourceUtilizationCharts from './resource-utilization-charts';
// import Chip from '@material-ui/core/Chip';
// import { resourceCapacityMockData } from "../../utils/mock/dashboard";

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: 0,
    maxWidth: '50vw',
    minWidth: '50vw',
  },
  sectionTitle: {
    fontWeight: 300,
    textAlign: 'center',
    marginBottom: theme.spacing(-0.5),
    fontSize: '1.2rem'
  },
  subTitle: {
    fontWeight: 500,
    textAlign: 'center',
    fontSize: '0.9rem',
    marginBottom: theme.spacing(2),
  },
}));

/**
 * 
 * @param {object} param0 
 * { 
 *   isVisible - boolean
 *   resource - object
 *   dateRangeStart - dayjs
 *   dateRangeEnd - dayjs
 *   totalAvailability - number 
 *   totalUtilization - number
 *   plannerEvents - object[] 
 * }
 * @returns 
 */
const PlannerGridCapacityCharts = ({ isVisible, resource, dateRangeStart, dateRangeEnd, totalAvailability, totalUtilization, plannerEvents }) => {
  const classes = useStyles();
  // console.log('resource: ', isVisible, resource, totalAvailability, totalUtilization);
  
  return (
    <>
      {isVisible && <Slide direction="left" in={isVisible}>
        <Box p={1} ml={1} className={classes.root}>
          <Typography variant="h6" className={classes.sectionTitle}>{resource.name} - Utilization</Typography>
          <Typography variant="h6" className={classes.subTitle}>{dateRangeStart.format('MM/DD/YYYY')} - {dateRangeEnd.format('MM/DD/YYYY')}</Typography>
          <ResourceUtilizationCharts 
            resource={resource} 
            plannerEvents={plannerEvents}
            totalAvailability={totalAvailability}
            totalUtilization={totalUtilization}
          />
          {/* <Grid container spacing={2}>
            {resourceCapacityMockData.map((chart, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Card>
                  <CardHeader
                    subheader={chart.title}
                  />
                  <CardContent>
                    {chart.type === "bar" && (
                      <Bar
                        data={chart.data}
                        height={chart.height}
                        options={chart.options}
                      />
                    )}
                    {chart.type === "bubble" && (
                      <Bubble
                        data={chart.data}
                        height={chart.height}
                        options={chart.options}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid> */}
        </Box>
      </Slide>}
    </>
  )
}

export default PlannerGridCapacityCharts;