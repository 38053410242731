import { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
import htmlReactParser from 'html-react-parser';

const useStyles = makeStyles(theme => ({
  waCellRoot: {
    backgroundColor: '#FAFBFC',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    minWidth: '96px',
    maxWidth: '96px',
    minHeight: '30px',
    maxHeight: '30px',
    overflow: 'hidden',
    // padding: '2px',
    // margin: '1px',
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'left',
    // display: 'flex',
    // flexWrap: 'wrap',
    '&:hover': {
      backgroundColor: '#E6EFFC'
    }
  },
  cellId: {
    color: 'rgb(0,0,0,0.2)',
    fontSize: '0.6rem',
    position: 'absolute',
    right: 0
  },
  highlightDrop: {
    border: '1px dashed red'
  }
}));

const zoomStyles = {
  '-5': {
    minWidth: '48px'
  },
  '-4': {
    minWidth: '56px'
  },
  '-3': {
    minWidth: '64px'
  },
  '-3': {
    minWidth: '72px'
  },
  '-2': {
    minWidth: '80px'
  },
  '-1': {
    minWidth: '88px'
  },
  0: {},
  1: {
    minWidth: '104px'
  },
  2: {
    minWidth: '112px'
  },
  3: {
    minWidth: '120px'
  },
  4: {
    minWidth: '128px'
  },
  5: {
    minWidth: '136px'
  }
};

// dragenter, dragover, dragleave
const WaGridCell = ({ id, hideCellId, onResourceDrop, onClick, onRightClick, onCellTooltipPreRender, onDelete, children, zoomLevel = 0, customStyles= {} }) => {
  const classes = useStyles();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState('...loading');
  const [isAnimateComplete, setIsAnimateComplete] = useState(true);
  const [isHighlightResourceDrop, setIsHighlightResourceDrop] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsAnimateComplete(true);
  //   }, 100);
  // }, []);
  
  return (
    <Fade in={isAnimateComplete}>
      <Tooltip open={isTooltipVisible} title={tooltipText} arrow>
        <div 
          className={`${classes.waCellRoot} ${isHighlightResourceDrop ? classes.highlightDrop : ''}`}
          style={Object.assign({}, zoomStyles[zoomLevel], customStyles)}
          data-id={id}
          onMouseEnter={(evt) => {
            if (children) {              
              const cellId = evt.target?.dataset?.['id'];
              if (cellId) {
                setIsTooltipVisible(true);
                const text = onCellTooltipPreRender?.(cellId);
                setTooltipText(htmlReactParser(text));
              }
            } else {
              setIsTooltipVisible(false);  
            }
          }}
          onMouseLeave={() => {
            setIsTooltipVisible(false);
          }}
          onDragEnter={(evt) => {
            // console.log('Cell: onDragEnter: ', evt);
            evt.stopPropagation();
            evt.preventDefault();
            setIsHighlightResourceDrop(true);
          }}
          onDragOver={(evt) => {
            // console.log('Cell: onDragOver: ', evt);
            evt.stopPropagation();
            evt.preventDefault();
            setIsHighlightResourceDrop(true);
          }}
          onDragLeave={(evt) => {
            // console.log('Cell: onDragLeave: ', evt);
            evt.stopPropagation();
            setIsHighlightResourceDrop(false);
          }}
          onDrop={(evt) => {
            const resource = JSON.parse(evt.dataTransfer.getData('text/javascript'));
            const targetId = evt.target?.dataset?.['id'];
            // console.log('Cell: onDrop: ', evt, resource, targetId);
            setIsHighlightResourceDrop(false);
            onResourceDrop?.(resource, targetId);
          }}
          onClick={(evt) => {
            // console.log('onClick: evt: ', evt, evt.defaultPrevented);
            onClick?.(evt.target?.dataset?.['id']);
          }}
          onContextMenu={(evt) => {
            console.log('onRightClick: evt: ', evt);
            onRightClick?.(evt.target?.dataset?.['id']);
          }}
        >
          {!hideCellId && <span className={classes.cellId}>{id}</span>}
          {children}
        </div>
      </Tooltip>
    </Fade>
  );
}

export default WaGridCell;