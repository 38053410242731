import { useEffect, useState } from 'react';
import { firestoreInstance } from '../../config/firebase-init';

const useTemplates = (showAll = false) => {
  const templatesRef = showAll ? firestoreInstance.collection('schedulerTemplates') : 
    firestoreInstance.collection('schedulerTemplates').where('active', '==', true);

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribeTemplatesRef = templatesRef.onSnapshot((res) => {
      if (!res.empty) {
        const dataRecords = res.docs.map((doc) => {
          return {...doc.data(), NO_ID_FIELD: doc.id};
        });
        setData(dataRecords);
      } else {
        setData([]);
      }
      setError(null);
    }, (err) => { setError(err); });
    
    return () => {
      unsubscribeTemplatesRef();
    };
  }, []);

  return {
    templates: data
  };
}

export default useTemplates;