import {useState} from 'react';
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import useSchedulerAutoAllocationPublishStatus from '../../hooks/scheduler/use-scheduler-auto-allocation-publish-status';
import useSchedulerBlockedDays from '../../hooks/scheduler/use-scheduler-blocked-days';
import useResources from '../../hooks/scheduler/use-resources';
import { generateSlotAvailability } from '../../helpers-scheduler';
import useSchedulerEvents from '../../hooks/scheduler/use-scheduler-events';
import { SCHEDULE_TYPE } from '../../hooks/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #cfcfcf'
  }
}));

function PublishSlotAvailability({ autoAllocationConfig, onPublish }) {
  const classes = useStyles();
  const today = dayjs();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const { fetchEventsByDateRange } = useSchedulerEvents();
  const { blockedDaysMap } = useSchedulerBlockedDays();
  const {data, error, getSortedDatesAndSlotCount, publishSlotAvailability} = useSchedulerAutoAllocationPublishStatus();
  const { 
    cleanRooms,
    equipment,
    people
  } = useResources();

  const handlePublish = () => {
    const datesArray = [];
    let tempDate = fromDate.clone();
    while (toDate.diff(tempDate, 'day') >= 0) {
      datesArray.push(new Date(tempDate.year(), tempDate.month(), tempDate.date()));
      tempDate = tempDate.add(1, 'day');
    }

    fetchEventsByDateRange(
      fromDate.subtract(1, 'day').toDate(),
      toDate.add(24, 'day').toDate(),
      SCHEDULE_TYPE.PLANNER
    ).then((events) => {
      const availableSlotsList = generateSlotAvailability(
        datesArray, autoAllocationConfig, Object.keys(blockedDaysMap), [].concat(cleanRooms, equipment, people), events);

      const payload = {
        fromDate: fromDate.toISOString(), 
        toDate: toDate.toISOString(), 
        slots: {} 
      };

      availableSlotsList.map(slot => {
        payload.slots[slot.date] = slot.numSlots;
      });

      publishSlotAvailability(payload).then(() => {
        onPublish?.();
      });
    });
  };

  return (
    <Box p={2} className={classes.root}>
      {data && <Alert variant="outlined" color="success">
        Published on {dayjs(data.publishedOn).format('MM/DD/YYYY H:mm:ss A')}
      </Alert>}
      {!data && <Alert variant="outlined" color="warning">
        Not Published
      </Alert>}
      
      <Box mt={2} display="flex" alignItems="flex-end">
        <KeyboardDatePicker 
          label="From Date" value={fromDate} onChange={setFromDate}
          minDate={today}
          format="MM/DD/YYYY" />
        <KeyboardDatePicker 
          label="To Date" value={toDate} onChange={setToDate}
          minDate={today.add(1,'day')}
          format="MM/DD/YYYY" />
        <Box ml={2}>
          <Button variant="contained" color="primary" onClick={handlePublish}>Publish</Button>
        </Box>
      </Box>

      {data && <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="Available Slots">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell># Slots</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSortedDatesAndSlotCount().map(({dateISOStr, count}) => (
              <TableRow key={`table-row-${dateISOStr}`}>
                <TableCell>{dayjs(dateISOStr).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </Box>
  );
}

export default PublishSlotAvailability;
