import React, { useState } from 'react';
import useLocalStorage from "use-local-storage";
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import SidebarItem from './SidebarItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { drawerWidth } from '../../styleVariables';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import { APP_NAMES } from '../../hooks/constants';
import useUserSession from '../../hooks/use-user-session';

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 56px)'
  },
  logoContainer: {
    textAlign: 'left',
    padding: '20px 12px 12px 12px'
  },
  logo: {
    // maxWidth: '150px',
    width: '100%',
    textAlign: 'center'
  },
  textCenter: {
    textAlign: 'center'
  },
  fullHeight: {
    // height: 'calc(100vh - 56px)'
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    maxWidth: drawerWidth,
    height: '100%',
    zIndex: theme.zIndex.drawer + 99,
    backgroundColor: '#FAFBFC'
  },
  modal: {
    [theme.breakpoints.down('sm')]: {
      top: '56px!important'
    },
    [theme.breakpoints.up('sm')]: {
      top: '56px!important'
    },
    zIndex: '1000!important'
  },
  backdrop: {
    [theme.breakpoints.down('sm')]: {
      top: '56px'
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px'
    }
  },
  navigation: {
    margin: theme.spacing(1.5)
  }
}));

const Sidebar = ({ opened, toggleDrawer, routes, location }) => {
  const [selectedApp, setSelectedApp] = useLocalStorage("selectedApp", APP_NAMES.electronicBatchRecord);
  const { userProfile } = useUserSession();
  const classes = useStyles();
  const [activeRoute, setActiveRoute] = useState(undefined);
  const toggleMenu = index =>
    setActiveRoute(activeRoute === index ? undefined : index);

  const menu = (
    <Box className={classes.root}>
      <div className={`${classes.logoContainer} ${classes.textCenter}`}>
      <img src="/praglife-logo.svg" className={classes.logo} />
        {/* <img src="https://pragmatrix.com/wp-content/uploads/2018/08/pragmatrix_logo1.png" className={classes.logo} /> */}
        {/* <img src="/static/images/logo/logo-prokidney.png" className={classes.logo} /> */}
        {/* <img src="/static/images/logo/ny_blood_center.png" className={classes.logo} /> */}
      </div>
      <List component="div" className={classes.navigation}>
        {routes.filter((route) => {
          return !route.isHiddenInNav && route.appName === selectedApp;
        }).filter((route) => {
          if (userProfile) {
            if (route.allowedRoles) {
              return route.allowedRoles.indexOf(userProfile.role) !== -1;
            }
            return true;
          }
          return false;
        }).map((route, index) => {
          const isCurrentPath =
            location.pathname.indexOf(route.path) > -1 ? true : false;
          return (
            <SidebarItem
              key={index}
              index={index}
              route={route}
              activeRoute={activeRoute}
              toggleMenu={toggleMenu}
              currentPath={isCurrentPath}
            />
          );
        })}
      </List>
    </Box>
  );

  return (
    <>
      <Hidden smDown>
        <Drawer
          variant="persistent"
          classes={{
            paper: classes.drawerPaper
          }}
          open={opened}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop
            },
            onBackdropClick: toggleDrawer
          }}
        >
          <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.fullHeight}>
            {menu}
            {/* <div className={`${classes.logoContainer} ${classes.textCenter}`}>
              <img src="/static/images/logo/pragmatrix_logo1.png" className={classes.logo} />
            </div> */}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          classes={{
            paper: classes.drawerPaper
          }}
          open={opened}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          disableBackdropTransition={!iOS}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop
            },
            onBackdropClick: toggleDrawer
          }}
        >
          {menu}
        </SwipeableDrawer>
      </Hidden>
    </>
  );
};

Sidebar.prototypes = {
  opened: PropTypes.func,
  toggleDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  openDrawer: PropTypes.func,
  routes: PropTypes.object
};

const SidebarWithRouter = withRouter(Sidebar);

export default withWidth()(SidebarWithRouter);
