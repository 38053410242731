import { useMemo, useEffect, useState } from 'react';
import { formStatusInitialState } from './use-batch-form-data';
import { firestoreInstance } from '../config/firebase-init';

export default function useBatchFormsStatus(orderId, lotId) {
    const batchFormRef = firestoreInstance
        .collection('orders').doc(orderId)
        .collection('lots').doc(lotId)
        .collection('batchForms');
    const [ formsStatusMap, setFormsStatusMap ] = useState({});

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribeBatchFormRef = batchFormRef.onSnapshot((res) => {
            if (!res.empty) {
                const dataRecords = res.docs.map((doc) => {
                    return {...doc.data(), NO_ID_FIELD: doc.id};
                });
                setData(dataRecords);
            } else {
                setData([]);
            }
            setError(null);
        }, (err) => { setError(err); });
        
        return () => {
            unsubscribeBatchFormRef();
        };
    }, []);
    
    useMemo(() => {
        if (data) {
            const tempFormStatusMap = {};
            data.forEach((row) => {
                tempFormStatusMap[row.NO_ID_FIELD] = row.formStatus || formStatusInitialState
            });
            setFormsStatusMap(tempFormStatusMap);
        }
    }, [data]);

    return {
        batchFormsStatus: formsStatusMap,
        error,
    }
}