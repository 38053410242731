import { useEffect, useState } from 'react';
import { firestoreInstance } from '../config/firebase-init';

export default function useOrderData(orderId) {
  const orderRef = firestoreInstance.collection('orders').doc(orderId);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
      const unsubscribeOrderRef = orderRef.onSnapshot((doc) => {
          setData(doc.data());
          setError(null);
      }, (err) => { setError(err); });

      return () => {
        unsubscribeOrderRef();
      };
  }, []);

  return {
    orderData: data
  }
}