import { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ResourceFormModal from './resource-form-modal';
import useResources from '../../hooks/scheduler/use-resources';
import { RESOURCE_TYPE } from '../../hooks/constants';
import { Box } from '@material-ui/core';
import { debounce } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import dayjs from 'dayjs';
import htmlReactParser from 'html-react-parser';

const useStyles = makeStyles({
  root: {
    '& .MuiTableCell-body': {
      padding: 0,
      paddingLeft: '8px'
    },
    // "&.dataRow": {
    //   "&:hover": {
    //     backgroundColor: "#efefefef",
    //   },
    // },
    // "&.dataRow--last": {
    //   "& > *": {
    //     borderBottom: "unset",
    //   },
    // },
    // "&.selected": {
    //   "& > *": {
    //     backgroundColor: "rgb(63,81,181,0.1)",
    //   },
    // },
  },
  searchInput:{
    padding:"5px"
  }
});

const ResourceDataGrid = ({ resourceType }) => {
  const classes = useStyles();
  const [isEditResource, setIsEditResource] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [searchString, setSearchString] = useState('');

  const { cleanRooms, equipment, people, processes, clients, filter } = useResources(true, resourceType);
 
  let data = [];
  switch(resourceType) {
    case RESOURCE_TYPE.process:
      data = processes;
      break;
    case RESOURCE_TYPE.cleanRoom:
      data = cleanRooms;
      break;
    case RESOURCE_TYPE.equipment:
      data = equipment;
      break;
    case RESOURCE_TYPE.person:
      data = people;
      break;
    case RESOURCE_TYPE.client:
      data = clients;
      break;
  }

 // data = data.map(r => Object.assign({downtime:[]}, r));
  
  const searchResource = debounce((e) => {
   const value = e.target.value;
   setSearchString(value);
   filter(value);
  }, 500);

  const downtimePopinTex = (arr) => {
    arr || []
    const futureDownTime = arr.filter((d) => dayjs(d).isAfter(dayjs()));
      return {
        tooltipText: futureDownTime.map((d) => dayjs(d).format('MM/DD/YYYY ,  ddd') + ' <br/>').join(''),
        downtimeCount: (futureDownTime.length > 1) ? futureDownTime.length + ' Days' : futureDownTime.length + ' Day'
      }
  }
  
  return (
    <>
      <Box display="flex" justifyContent="space-between">
       <Chip label={`Total: ${data?.length}`} color="secondary" />
       <input
            className={classes.searchInput}
            type="search"
            placeholder="Search here..."
            onChange={searchResource}
          />
      </Box>
      <TableContainer component={Paper} className={classes.root}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Short Name</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Downtime</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIdx) => (
              <TableRow
                key={`template-row-${rowIdx}`}
                className={`${
                  rowIdx === data.length - 1
                    ? "dataRow dataRow--last"
                    : `dataRow dataRow--${rowIdx}`
                }`}
              >
                <TableCell scope="row">{row.name}</TableCell>
                <TableCell scope="row">{row.shortName}</TableCell>
                <TableCell scope="row">{row.active ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  {row.code}
                </TableCell>
                 { 
                  (row?.downtime?.length != 0) ? <Tooltip placement="top-start" title={htmlReactParser(downtimePopinTex(row?.downtime || []).tooltipText)}>
                  <TableCell>
                   {downtimePopinTex(row?.downtime || []).downtimeCount}
                     </TableCell>
                  </Tooltip>
                  : <TableCell>
                   {downtimePopinTex(row?.downtime || []).downtimeCount}
                     </TableCell> 
                  }
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setIsEditResource(true);
                      setSelectedResource(row);
                    }}
                  >
                    <BorderColorIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEditResource && 
        <ResourceFormModal 
          resourceType={resourceType}
          resourceId={selectedResource.NO_ID_FIELD}          
          onClose={() => {
            setIsEditResource(false);
          }}
          onSave={() => {
            setIsEditResource(false);
          }}
      />}
    </>
  );
}

export default ResourceDataGrid;