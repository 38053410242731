import { useRef,useState, useMemo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
// import Avatar from "@material-ui/core/Avatar";
// import { deepOrange } from '@material-ui/core/colors';
import WaGridCell from './wa-grid-cell';
import WaGridCellColumnHead from './wa-grid-cell-column-head';
import WaGridCellData from './wa-grid-cell-data';
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    maxHeight: 'calc(100vh - 255px)',
    overflowX: 'hidden',
    overflowY: 'hidden',
    display: 'grid',
    gridTemplateColumns: '45px 1fr',
    gridTemplateRows: '35px 1fr',
    gridTemplateAreas: `
      '. gridHeadTop'
      'gridHeadLeft gridHeadMain'
    `
  },
  gridHeadTop: {
    gridArea: 'gridHeadTop',
    display: 'grid',
    columnGap: '2px',
    overflowX: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  gridHeadLeft: {
    // marginTop: '2px',
    gridArea: 'gridHeadLeft',
    display: 'grid',
    gridTemplateColumns: 'auto',
    // gridTemplateRows: '45px',
    rowGap: '2px',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  gridMain: {
    gridArea: 'gridHeadMain',
    display: 'grid',
    columnGap: '2px',
    rowGap: '2px',    
    overflowX: 'auto',
    overflowY: 'auto',
  },
  colLabel: {
    backgroundColor: '#303f9f',
    color: '#fff',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.2),
    textAlign: 'center',
    margin: theme.spacing(1),
    marginTop: 0,
    // boxShadow: '0 1px 6px 0 rgb(0 0 0 / 6%), 0 2px 32px 0 rgb(0 0 0 / 16%)'
    boxShadow: '3px 5px 3px rgb(0 0 0 / 16%)'
  },
  rowLabel: {
    fontSize: '0.8rem',
    lineHeight: '0.95rem',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'right',
    minHeight: '30px',
    maxHeight: '30px',
    border: '1px solid transparent',
    borderBottom: '1px dashed #999',
    '& > *': {
      paddingRight: '2px',
      textAlign: 'center'
    }
  },
  rowLabelHighlighted:{
    backgroundColor: '#cfcfcf',
  }
  // rowLabel__circle: {
  //   backgroundColor: deepOrange[500],
  //   display: 'flex',
  //   flexDirection: 'column',
  //   '& span': { 
  //     fontSize: '11px', 
  //     display: 'block'
  //   }
  // }
}));

const WaGrid = ({ 
  numCols, 
  numRows, 
  colLabels, 
  rowLabels, 
  cellData, 
  cellDataType, 
  onCellTooltipPreRender,
  onCellStylesPreRender,
  onCellDataPreRender, 
  onResourceDrop, 
  onCellColumnHeadClick, 
  onCellClick, 
  zoomLevel = 0,
  onLoadMore
}) => {
  const classes = useStyles();
  const [gridMainStyles, setGridMainStyles] = useState({});
  const [gridHeadTopStyles, setGridHeadTopStyles] = useState({});
  const gridHeadTopRef = useRef();
  const gridHeadLeftRef = useRef();
  const gridMainRef = useRef();
  const gridHeadLeftCell_scrollIntoViewRef = useRef();

  useEffect(() => {
    const main_gridTemplateCols_style = `repeat(${numCols}, auto)`;
    setGridMainStyles({
      gridTemplateColumns: main_gridTemplateCols_style
    });
    setGridHeadTopStyles({
      gridTemplateColumns: main_gridTemplateCols_style,
    });
  }, [numCols]);

  const handleGridMainScroll = (evt) => {
    // console.log('handleGridMainScroll: ', evt.target.scrollLeft, evt.target.scrollTop);
    gridHeadTopRef.current.scrollTo(evt.target.scrollLeft, 0);
    gridHeadLeftRef.current.scrollTo(0, evt.target.scrollTop);
    
    if(evt.target.scrollTop + evt.target.clientHeight >= evt.target.scrollHeight) {
      onLoadMore?.(evt);
    }
  };
  
  const renderGridCells = () => {
    const elements = [], topElements = [], leftElements = [];
    if (Object.keys(colLabels).length > 0) {
      for(let i = 0;i <= numCols;i++) {
        if (i === 0) {
          // topElements.push(
          //   <div key="colLabel-0"></div>
          // );
        } else {
          topElements.push(
            <WaGridCellColumnHead 
              key={`colLabel-${i}`} 
              domId={`colLabel-${i}`} 
              id={i} 
              label={colLabels?.[i-1]?.label || ''}
              isHighlighted={colLabels?.[i-1]?.isHighlighted}
              icon={cellDataType}
              additionalData={colLabels?.[i-1]?.additionalData || {}}
              zoomLevel={zoomLevel} 
              onClick={onCellColumnHeadClick}
            />
          );
        }        
      }
    }

    for(let i = 0;i < numCols*numRows;i++) {
      const rowL = rowLabels?.[i/numCols];
      if (i%numCols == 0) {
        leftElements.push(
          <div 
            ref={rowL.scrollIntoView ? gridHeadLeftCell_scrollIntoViewRef : null} 
            id={`${rowL.scrollIntoView ? 'scroll-into-view' : `row-label-${i}`}`} 
            key={`tooltip-${i}`} 
            className={`${classes.rowLabel} ${rowL?.isHighlighted ? classes.rowLabelHighlighted : ''}`}
          >
            {/* <Avatar className={classes.rowLabel__circle}>
              <span>{rowL?.subLabel || ''}</span>
              {rowL?.label || ''}
            </Avatar> */}
            <div style={{ fontWeight: 'bold' }}>
              <span>{rowL?.subLabel || ''}</span>&nbsp;
              {rowL?.label || ''}
            </div>
            <span>{rowL?.tooltip}</span>
          </div>
        );        
      }

      let label = '';
      if (cellData?.[i]) {
        if (typeof cellData?.[i] == 'string') {
          label = cellData?.[i];
        } else {
          label = onCellDataPreRender ? onCellDataPreRender(cellData?.[i]) : cellData?.[i]?.title;
        }      
      }
     
      elements.push(        
        <WaGridCell 
          zoomLevel={zoomLevel} 
          key={`wa-cell-${i}`} 
          id={i}
          customStyles={onCellStylesPreRender?.(i) || {}}
          onCellTooltipPreRender={(cellId) => {
            return onCellTooltipPreRender?.(cellData?.[cellId]);
          }}
          onClick={onCellClick}
          onResourceDrop={(resource, cellId) => {
            onResourceDrop?.(resource, cellId);
          }}
        >
          {cellData?.[i] && <WaGridCellData
            zoomLevel={zoomLevel}
            id={i} 
            label={label}
            icon={cellDataType}
            type={cellData?.[i]?.type}
            data={cellData?.[i]} />}
        </WaGridCell>
      );
    }
    return {elements, topElements, leftElements};
  }

  const {elements, topElements, leftElements} = renderGridCells();
  
  useEffect(() => {
    if (gridHeadLeftCell_scrollIntoViewRef.current) {      
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          const { offsetTop } = gridHeadLeftCell_scrollIntoViewRef.current;
          const { offsetTop: gridMainOffsetTop } = gridMainRef.current;
          gridMainRef.current.scrollTo(0, offsetTop-gridMainOffsetTop);
        }, 0);
      });
    }
  }, [gridHeadLeftCell_scrollIntoViewRef.current]);

  return (
    <div className={classes.gridContainer}>
      <div className={classes.gridHeadTop} ref={gridHeadTopRef} style={gridHeadTopStyles}>
        {topElements}
      </div>
      <div className={classes.gridHeadLeft} ref={gridHeadLeftRef}>
        {leftElements}
      </div>
      <div className={`${classes.gridMain}`} ref={gridMainRef} style={gridMainStyles} onScroll={handleGridMainScroll}>
        {elements}
      </div>
    </div>
  );
}

export default WaGrid;