import { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import Wrapper from "../../components/Wrapper/Wrapper";
import Breadcrumb from '../../design-system/breadcrumb';
import PageTitle from "../../design-system/page-title";
import useSchedulerEvents from "../../hooks/scheduler/use-scheduler-events";

const DebugPage = () => {
  const [startDateISOString, setStartDateISOString] = useState('2022-10-04T04:00:01.000Z');
  const [endDateISOString, setEndDateISOString] = useState('2022-10-05T03:59:59.000Z');
  const [resourceCodes, setResourceCodes] = useState(['kevin-kwan','clean-room-02','L-O2-incubator-13','CO2-incubator-13','suma-jennings']);
  const { testISODateQuery } = useSchedulerEvents();

  const handleFetchEvents = () => {
    testISODateQuery(startDateISOString, endDateISOString, resourceCodes).then(data => {
      console.log('data: ', data);
    });
  };

  return (
    <Wrapper>        
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      <PageTitle title="Debug Tool" />
      
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <TextField fullWidth label="Start Date ISO String" value={startDateISOString} onChange={(evt) => {
          setStartDateISOString(evt.target.value);
        }} />
        
        <TextField fullWidth label="End Date ISO String" value={endDateISOString} onChange={(evt) => {
          setEndDateISOString(evt.target.value);
        }} />

        <TextField fullWidth label="Resource Code" value={resourceCodes.join(',')} onChange={(evt) => {
          setResourceCodes(evt.target.value.split(','));
        }} />

        <Box mt={2}>
          <Button variant="outlined" onClick={handleFetchEvents}>Fetch Planner Events</Button>
        </Box>
      </Box>

      <Box>
        
      </Box>
    </Wrapper>
  );
};

export default DebugPage;