import { useState } from "react";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import dayjs from 'dayjs';
import { SCHEDULE_TYPE, SCHEDULE_ORDER_DATE_TYPE } from '../../hooks/constants';

const styles = {
  root: { 
    cursor: 'pointer',
    // position: 'relative'
  },
  content: { display: 'flex', alignItems: 'center' },
  p: { fontSize: '11px' },
  tooltip: {
    padding: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    marginTop: '8px',
    backgroundColor: '#fff',
    color: '#555',
    borderRadius: '5px',
    textTransform: 'capitalize',
    position: 'fixed',
    zIndex: 5
  }
};

function CalendarEventCard(props) {
  const { event } = props;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handlePopoverOpen = (domEvt) => {
    if (event.scheduleType === SCHEDULE_TYPE.SITE || (event.scheduleType === SCHEDULE_TYPE.PLANNER && event?.meta_subjectId)) {
      setIsTooltipVisible(true);
    }
  };

  const handlePopoverClose = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div style={styles.root}>
      <div style={styles.content} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {event.scheduleType === SCHEDULE_TYPE.SITE && 
          <>
              {event.meta_eventType === SCHEDULE_ORDER_DATE_TYPE.BIOPSY && <PersonIcon fontSize="small" color="error" />} 
              {event.meta_eventType === SCHEDULE_ORDER_DATE_TYPE.INJECTION_1 && <DoneIcon fontSize="small" color="error" />} 
              {event.meta_eventType === SCHEDULE_ORDER_DATE_TYPE.INJECTION_2 && <DoneAllIcon fontSize="small" color="error" />} 
              &nbsp;
              <Typography style={styles.p}>{event.meta_subjectId}:&nbsp;{event.clientCode}</Typography>
          </>
        }
        {event.scheduleType === SCHEDULE_TYPE.PLANNER && <Typography style={styles.p}>{event.title}</Typography>}
      </div>
      {isTooltipVisible && <div style={styles.tooltip}>
        <ul style={{paddingLeft: 0, paddingTop: 0, margin: 0, listStyle: 'none' }}>
          {event.scheduleType === SCHEDULE_TYPE.SITE && <>
            {event?.clientCode}
            {event?.meta_biopsyDate && <li>Biopsy: {dayjs(event.meta_biopsyDate).format('MM/DD HH:mm A')}</li>}
            {event?.meta_injection1Date && <li>Injection 1: {dayjs(event.meta_injection1Date).format('MM/DD HH:mm A')}</li>}
            {event?.meta_injection2Date && <li>Injection 2: {dayjs(event.meta_injection2Date).format('MM/DD HH:mm A')}</li>}
          </>}
          {event.scheduleType === SCHEDULE_TYPE.PLANNER && <>
            {event?.meta_subjectId && <li><Typography style={styles.p}>Subject ID: {event.meta_subjectId}</Typography></li>}
          </>}
        </ul>
      </div>}
    </div>
  );
}

export default CalendarEventCard;
