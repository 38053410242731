import { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router";
import Box from '@material-ui/core/Box';
import BatchFormWizard from '../../components/batch-form-wizard';
import PageTitle from "../../design-system/page-title";
import Breadcrumb from '../../design-system/breadcrumb';

function PrintOrderLotBatchPage(props) {
    const { match } = props;

    const history = useHistory();

    useEffect(() => {
      setTimeout(() => {
        handlePrintBatch();
        history.goBack();
      }, 100);
    }, []);

    const componentRef = useRef();
    const handlePrintBatch = useReactToPrint({
      content: () => componentRef.current
    });
    
    return (
      <Box>
        <Breadcrumb items={[{ text: 'Home', path: '/' }, { text: 'Manage Orders', path: '/orders' }]} />
        <PageTitle title={`Edit Batch Record`} />

        <div ref={componentRef}>
          <BatchFormWizard
              orderId={match.params.orderId}
              lotId={match.params.lotId}
              formName={match.params.formName}
              onSave={() => {
                  setIsSnackbarVisible(true);
                  setSnackbarMessage('Saved form!');
              }}
              onNoteAdded={() => {
                  setIsSnackbarVisible(true);
                  setSnackbarMessage('Saved note!');
              }}
          />
        </div>
      </Box>
    );
}

export default PrintOrderLotBatchPage;