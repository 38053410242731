import { useRef } from 'react';
import dayjs from 'dayjs';
import { FormGenerator } from "formik-generator-materialui";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import useBatchFormData from '../../hooks/use-batch-form-data';
import { BATCH_FORM_NAMES as FORM_NAMES } from '../../hooks/constants';

const useStyles = makeStyles((theme) => ({
    paragraph: {
        marginBottom: theme.spacing(2),
        fontSize: '0.8rem'
    },
  }));

const formFieldsSchema = [
    {
        title: 'Room #',
        typeField: 'text',
        path: 'roomNum'
    },
    {
        title: 'Before filling product tray, count the cycles for 1 minute to determine machine speed.',
        typeField: 'group',
        path: 'lineStartup',
        subfields: [
            {
                title: 'Speed',
                typeField: 'text',
                path: 'lineStartup/speed',
                col: 2
            },
            {
                title: 'Verified',
                typeField: 'switch',
                path: 'lineStartup/verified',
                col: 2
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'lineStartup/verifiedBy',
                col: 2
            },
            {
                title: 'Verified On',
                typeField: 'date',
                path: 'lineStartup/verifiedOn',
                col: 3
            },
        ]
    },
    {
        title: 'Confirm machine temperature/speed are within valiidated ranges',
        typeField: 'group',
        subfields: [
            {
                title: 'Verified',
                typeField: 'switch',
                path: 'machineTemperature/ok',
                col: 2
            },
            {
                title: 'Verified On',
                typeField: 'date',
                path: 'machineTemperature/verifiedOn',
                col: 3
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'machineTemperature/verifiedBy',
                col: 3
            }
        ]
    },
    {
        title: 'Product meets the description',
        typeField: 'group',
        subfields: [
            {
                title: 'Verified',
                typeField: 'switch',
                path: 'product/ok',
                col: 2
            },
            {
                title: 'Verified On',
                typeField: 'date',
                path: 'product/verifiedOn',
                col: 3
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'product/verifiedBy',
                col: 3
            }
        ]
    },
    {
        title: 'Wipe down the feed tray and utensiles with 70% isopropanol',
        typeField: 'group',
        subfields: [
            {
                title: 'Verified',
                typeField: 'switch',
                path: 'wipeTray/ok',
                col: 2
            },
            {
                title: 'Verified On',
                typeField: 'date',
                path: 'wipeTray/verifiedOn',
                col: 3
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'wipeTray/verifiedBy',
                col: 3
            }
        ]
    },
    {
        title: 'Fill the feed tray and begin the blistering run (Production)',
        typeField: 'group',
        subfields: [
            {
                title: 'Verified',
                typeField: 'switch',
                path: 'beginBlisteringRun/ok',
                col: 2
            },
            {
                title: 'Verified On',
                typeField: 'date',
                path: 'beginBlisteringRun/verifiedOn',
                col: 3
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'beginBlisteringRun/verifiedBy',
                col: 3
            }
        ]
    },
    {
        title: '',
        typeField: 'group',
        subfields: [
            {
                title: 'Blister Strips/Shipper',
                typeField: 'text',
                path: 'blisterStripsShipper',
                col: 6
            },
            {
                title: 'Comments',
                typeField: 'text',
                path: 'blisterStripsComments',
                col: 6
            }
        ]
    }
];

const prepareDataToEdit = (values) => {
    const data = Object.assign({}, values);
    if (data['beginBlisteringRun/verifiedOn'] && data['beginBlisteringRun/verifiedOn'].seconds) {
        data['beginBlisteringRun/verifiedOn'] = dayjs.unix(data['beginBlisteringRun/verifiedOn'].seconds).toDate();
    }
    if (data['lineStartup/verifiedOn'] && data['lineStartup/verifiedOn'].seconds) {
        data['lineStartup/verifiedOn'] = dayjs.unix(data['lineStartup/verifiedOn'].seconds).toDate();
    }
    if (data['machineTemperature/verifiedOn'] && data['machineTemperature/verifiedOn'].seconds) {
        data['machineTemperature/verifiedOn'] = dayjs.unix(data['machineTemperature/verifiedOn'].seconds).toDate();
    }
    if (data['product/verifiedOn'] && data['product/verifiedOn'].seconds) {
        data['product/verifiedOn'] = dayjs.unix(data['product/verifiedOn'].seconds).toDate();
    }
    if (data['wipeTray/verifiedOn'] && data['wipeTray/verifiedOn'].seconds) {
        data['wipeTray/verifiedOn'] = dayjs.unix(data['wipeTray/verifiedOn'].seconds).toDate();
    }
    return data;
}

function LineStartupForm({
    orderId,
    lotId,
    onSave = null,
    onCancel = null,
    isReadOnly = false,
    isValidateOnlyOnSubmit = true,
    isPreview = false
}) {
    const classes = useStyles();
    const { batchFormData, saveBatchForm, error } = useBatchFormData(orderId, lotId, FORM_NAMES.lineStartup);
    let initialValues = {};
    if (batchFormData) {
        initialValues = prepareDataToEdit(batchFormData);
    }

    const saveFormData = (formData) => {
        saveBatchForm(formData).then(() => {
            onSave && onSave();
        });
    };

    const formRef = useRef(null);
    return (
        <>
            <Typography className={classes.paragraph}>
                The first blister strips filled are inspected/tested by QA. If the blister strips are unacceptable, the mechanic will make necessary adjustments to achieve acceptable blisters. Following adjustments, QA will again inspect the first blister strips packaged for acceptance. All inspection and test results are recorded on the “QA Testing and Inspection” Form.
            </Typography>
            <Typography className={classes.paragraph}>
                Products using the vision system: At the start of each tablet, at start-up each day, after a lunch period, and after break, pass at least three blister cards with at least one dosage form missing from a blister cavity past the vision camera. Assure the marking system marks each blister card and honks for each strip with an empty cavity. Record the results on the “QA Test & Inspection” page.
            </Typography>
            <Typography className={classes.paragraph}>
                Determine the number of blister strips that will be contained in one shipper. Shipper labels are prepared using that information. Verify the shipper labels contain the correct information.
            </Typography>
            <Typography className={classes.paragraph}>
                Dusty Tablets: Uncoated tablets may be dusty requiring dust removal prior to blister packaging. The methods for performing this are:
            </Typography>
            <ul>
                <li>Gently shaking the tablets in a colander using a vacuum to collect the dust</li>                
                <li>Vacuuming the tablets. A fine clean mesh is put on the vacuum hose to keep the tablet from being sucked up. Additionally, the film with filled blister cavities may be wiped with a clean lint free prior to sealing the strips with lidding.</li>
            </ul>
            <FormGenerator
              onSubmit={(values) => {
                saveFormData(values);
              }}
              fields={formFieldsSchema}
              formRef={formRef}
              initialValues={initialValues}
              readOnly={isReadOnly}
              isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
            />
            {!isPreview && <>
                <Button color="secondary" style={{ marginRight: '2em' }}
                    onClick={() => {
                        formRef.current.resetForm();
                        onCancel && onCancel();
                    }}>Cancel</Button>
                <Button variant="contained" color="primary"
                    onClick={() => formRef.current.submitForm()}>Save</Button>
            </>}
        </>
    );
}

export default LineStartupForm;