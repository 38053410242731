import { Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import ArticleIcon from "@material-ui/icons/NoteOutlined";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import Box from "@material-ui/core/Box";
import FormStatusIcon from "../batch-form-wizard/form-status-icon";
import { FORM_TYPES } from "../../hooks/constants";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    backgroundColor: '#fff',
    boxShadow: `rgba(0, 0, 0, 0.16) 0px 5px 10px`,
    cursor: 'pointer',
    borderRadius: theme.spacing(0.5)
  },
  title: {
    display: 'flex',
    alignItems: 'top',
    '& > svg': {
      marginRight: theme.spacing(0.5),
      width: '0.9em',
      height: '0.9em'
    },
    '& .text': {
      fontSize: '0.9rem',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    }
  },
  card_tall: {
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& span': {
      flexBasis: 'min-content'
    }
  },
  text_sop: {
    fontSize: '0.8rem',
    height: '1rem',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  manualProcessCard: {
    backgroundColor: '#ffffe0'
  }
}));

export default function FormCard({ formMetadata, index, workflowStateSlug }) {
  const classes = useStyles();
  const isInParkedState = (workflowStateSlug === 'parked-forms');

  return (
    <Draggable draggableId={formMetadata.slug} index={index}>
      {(provided) => (
        <div 
          className={`${classes.root} ${isInParkedState ? '' : classes.card_tall} ${formMetadata?.type === FORM_TYPES.manualProcess ? classes.manualProcessCard : ''}`} 
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box className={classes.title}>
            {formMetadata?.type == FORM_TYPES.manualProcess ? <WorkOutlineIcon color="primary" /> : <ArticleIcon color="primary" />}
            <Box className="text">
              <span>{formMetadata?.name}</span>
              {!isInParkedState && formMetadata?.sopDate && <label className={classes.text_sop}><strong>SOP</strong> {formMetadata.sopDate}</label>}
            </Box>
          </Box>
          {!isInParkedState && formMetadata?.formStatuses && <div>
            {formMetadata?.formStatuses.map((fStatus) => (
              <FormStatusIcon key={`${formMetadata?.slug}-${fStatus}`} status={fStatus} variant="chip" />
            ))}
          </div>}
        </div>
      )}
    </Draggable>
  );
}