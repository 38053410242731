import { BATCH_FORM_SLUGS } from "../../hooks/constants";
import ProductionSetupForm from "./production-setup-form";
import PackagingForm from "./packaging-form";
import LineSetupForm from "./line-setup-form";
import LineStartupForm from "./line-startup-form";
import DailyOperationsForm from "./daily-operations-form";
import DailyRecordForm from "./daily-record-form";
import RejectsForm from "./rejects-form";
import QAInspectionForm from "./qa-inspection-form";
import QaAccessForm from "./qa-access-form";
import InspectionCommentsForm from "./inspection-comments-form";

import AdditionalProductionSetupForm from "./additional-production-setup-form";
import AdditionalPackagingForm from "./additional-packaging-form";
import AdditionalLineSetupForm from "./additional-line-setup-form";
import AdditionalLineStartupForm from "./additional-line-startup-form";
import AdditionalDailyOperationsForm from "./additional-daily-operations-form";
import AdditionalDailyRecordForm from "./additional-daily-record-form";
import AdditionalRejectsForm from "./additional-rejects-form";
import AdditionalQAInspectionForm from "./additional-qa-inspection-form";
import AdditionalInspectionCommentsForm from "./additional-inspection-comments-form";
import ManualProcessOneForm from '../batch-forms/manual-process-one-form';
import ManualProcessTwoForm from '../batch-forms/manual-process-two-form';

export default function FormSlugComponentMapper(formSlug) {
  switch(formSlug) {
    case BATCH_FORM_SLUGS.additionalDailyOperations:
      return AdditionalDailyOperationsForm;
    case BATCH_FORM_SLUGS.additionalDailyRecord:
      return AdditionalDailyRecordForm;
    case BATCH_FORM_SLUGS.additionalInspectionComments:
      return AdditionalInspectionCommentsForm;
    case BATCH_FORM_SLUGS.additionalLineSetup:
      return AdditionalLineSetupForm;
    case BATCH_FORM_SLUGS.additionalLineStartup:
      return AdditionalLineStartupForm;
    case BATCH_FORM_SLUGS.additionalPackaging:
      return AdditionalPackagingForm;
    case BATCH_FORM_SLUGS.additionalProductionSetup:
      return AdditionalProductionSetupForm;
    case BATCH_FORM_SLUGS.additionalQaInspection:
      return AdditionalQAInspectionForm;
    case BATCH_FORM_SLUGS.additionalRejects:
      return AdditionalRejectsForm;
    case BATCH_FORM_SLUGS.dailyOperations:
      return DailyOperationsForm;
    case BATCH_FORM_SLUGS.dailyRecord:
      return DailyRecordForm;
    case BATCH_FORM_SLUGS.inspectionComments:
      return InspectionCommentsForm;
    case BATCH_FORM_SLUGS.lineSetup:
      return LineSetupForm;
    case BATCH_FORM_SLUGS.lineStartup:
      return LineStartupForm;
    case BATCH_FORM_SLUGS.packaging:
      return PackagingForm;
    case BATCH_FORM_SLUGS.productionSetup:
      return ProductionSetupForm;
    case BATCH_FORM_SLUGS.qaAccess:
      return QaAccessForm;
    case BATCH_FORM_SLUGS.qaInspection:
      return QAInspectionForm;
    case BATCH_FORM_SLUGS.rejects:
      return RejectsForm;
    case BATCH_FORM_SLUGS.manualProcessOne:
      return ManualProcessOneForm;
    case BATCH_FORM_SLUGS.manualProcessTwo:
      return ManualProcessTwoForm;
    default:
      return '';
  }
}