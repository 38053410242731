import { useRef } from 'react';
import { FormGenerator } from "formik-generator-materialui";
import Button from '@material-ui/core/Button';
import useBatchFormData from '../../hooks/use-batch-form-data';
import { BATCH_FORM_NAMES as FORM_NAMES } from '../../hooks/constants';

const formFieldsSchema = [
    {
        title: 'Packaging Materials',
        typeField: 'arrayObject',
        path: "packagingMaterials",
        emptyAddText: 'Add Row',
        subfields: [
            {
                title: 'Type',
                typeField: 'select',
                name: 'type',
                choices: ['Film', 'Foil'],
                col: 3
            },
            {
                title: 'Supplier Name',
                typeField: 'text',
                name: 'supplierName',
                col: 3
            },
            {
                title: 'Lot #',
                typeField: 'text',
                name: 'lotNum',
                col: 3
            },
            {
                title: 'Width',
                typeField: 'text',
                name: 'width',
                col: 3
            },
            {
                title: 'Amount',
                typeField: 'text',
                name: 'amount',
                col: 3
            },
            {
                title: 'Checked',
                typeField: 'switch',
                name: 'checked',
                col: 3
            },
            {
                title: 'Verified',
                typeField: 'switch',
                name: 'verified',
                col: 2
            }
        ]
    }
];

const prepareDataToEdit = (values) => {
    const data = Object.assign({}, values);
    return data;
}

function PackagingForm({
    orderId,
    lotId,
    onSave = null,
    onCancel = null,
    isReadOnly = false,
    isValidateOnlyOnSubmit = true
}) {
    const { batchFormData, saveBatchForm, error } = useBatchFormData(orderId, lotId, FORM_NAMES.packaging);
    let initialValues = {};
    if (batchFormData) {
        initialValues = prepareDataToEdit(batchFormData);
    }

    const saveFormData = (formData) => {
        saveBatchForm(formData).then(() => {
            onSave && onSave();
        });
    };

    const formRef = useRef(null);
    return (
        <>
            <FormGenerator
              onSubmit={(values) => {
                saveFormData(values);
              }}
              fields={formFieldsSchema}
              formRef={formRef}
              initialValues={initialValues}
              readOnly={isReadOnly}
              isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
            />
            <Button color="secondary" style={{ marginRight: '2em' }}
                onClick={() => {
                    formRef.current.resetForm();
                    onCancel && onCancel();
                }}>Cancel</Button>
            <Button variant="contained" color="primary"
                onClick={() => formRef.current.submitForm()}>Save</Button>
        </>
    );
}

export default PackagingForm;