import { useMemo, useEffect, useState } from 'react';
import { firestoreInstance } from '../config/firebase-init';

export default function useBatchesMaster() {
  const batchesMasterRef = firestoreInstance.collection('batchesMaster');

  const [batchesMasterListData, setData] = useState([]);
  const [batchesMasterListError, setError] = useState(null);

  useEffect(() => {
      const unsubscribeBatchesMasterRef = batchesMasterRef.onSnapshot((res) => {
          if (!res.empty) {
              const dataRecords = res.docs.map((doc) => {
                  return {...doc.data(), NO_ID_FIELD: doc.id};
              });
              setData(dataRecords);
          } else {
              setData([]);
          }
          setError(null);
      }, (err) => { setError(err); });
      
      return () => {
        unsubscribeBatchesMasterRef();
      };
  }, []);

  const batchesMasterAvailableFormSlugs = useMemo(() => {
    const myMap = {};
    if (batchesMasterListData) {      
      batchesMasterListData.forEach((batchMasterRec) => {
        myMap[batchMasterRec.batchSlug] = {
          availableFormSlugs: batchMasterRec.availableForms.map((form) => form.slug)
        };
      });
    }
    return myMap;
  }, [batchesMasterListData]);

  return {
    batchesMasterList: batchesMasterListData,
    batchesMasterAvailableFormSlugs,
    createNewRecord: (batchName, batchSlug, availableForms) => {
      const payload = {
        batchName,
        batchSlug,
        availableForms,
        workflowStates: {
          'state-0': { 
            slug: 'state-0', 
            name: 'Sequence 1', 
            formSlugs: [],
            activationRules: []
          },
          'state-1': { slug: 'state-1', name: 'Sequence 2', formSlugs: [], activationRules: [] },
          'state-2': { slug: 'state-2', name: 'Sequence 3', formSlugs: [], activationRules: [] },
          'state-3': { slug: 'state-3', name: 'Sequence 4', formSlugs: [], activationRules: [] }
        },
        workflowStatesSequence: ['state-0', 'state-1', 'state-2', 'state-3']
      };

      return formsMasterRef
        .add(payload)
        .then(async (docRef) => {
          return docRef.id;
        })
        .catch((e) => {
          console.error("Error creating new batch record: ", e);
        });
    }
  };
}