import React, { useEffect, useState, useRef, useMemo } from "react";
import dayjs from 'dayjs';
import moment from 'moment';
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer
} from 'react-big-calendar';

import { makeStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { IconButton } from "@material-ui/core";
import useSchedulerAutoAllocationPublishStatus from "../../hooks/scheduler/use-scheduler-auto-allocation-publish-status";
import useSchedulerAutoAllocationConfig from '../../hooks/scheduler/use-scheduler-auto-allocation-config';
import useSchedulerEvents from "../../hooks/scheduler/use-scheduler-events";
import useSchedulerOrders from "../../hooks/scheduler/use-scheduler-orders";
import { doesResourceHaveConflicts, _getResourceAvailabilityInMinutes } from '../../helpers-scheduler';
import { DatePicker } from "@material-ui/pickers";


const useStyles = makeStyles(theme => ({
  infoBox: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1)
    },
    marginBottom: theme.spacing(2),
    justifyContent: 'right'
  },
 
}));

const mLocalizer = momentLocalizer(moment);

const CapacityForecastModal = ({isVisible,initData
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const componentRef = useRef();
  

  useEffect(() => {
    setShow(isVisible);
  }, [isVisible]);


  return (
    <>
      <Dialog
        open={show}
        className={classes.root}
        maxWidth="lg"
        fullWidth
        onClose={() => {
          
        }}
        aria-labelledby="Capacity Forecasting"
        aria-describedby="Capacity Forecasting">
        <DialogTitle id="Capacity-Forecasting" className={classes.title}>
          <Box display="flex" justifyContent="space-between">
            <div>Capacity Forecasting</div>
           <Box className={classes.infoBox}>
             
              <Chip label={`Customer: ${initData.split(' ').length > 2 ? initData.split(' ')[1]:initData.split(' ')[0]}`} />
              <Chip label={`Process: ${initData.split(' ').length > 2 ? initData.split(' ')[2]:initData.split(' ')[1]}`} />
              <IconButton onClick={() => setShow(false)}>
                    <CloseIcon size="small" />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent ref={componentRef}>
          
          <DatePicker variant="static" initialFocusedDate={dayjs().date()} onChange={()=>{
          }} onMonthChange={(date) => {
          }}  />
        </DialogContent>
        <DialogActions disableSpacing>      
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CapacityForecastModal;