import { useEffect, useState, useMemo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Box from '@material-ui/core/Box';
// import AddIcon from '@material-ui/icons/Add';
// import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { TextField } from '@material-ui/core';
// import { InputAdornment } from '@material-ui/core';
// import SearchIcon from "@material-ui/icons/Search";
import SplitButtonWithOptionsAdvanced from '../../design-system/split-button-with-options-advanced';
import { RESOURCE_TYPE } from '../../hooks/constants';
import SplitButtonWithOptions from '../../design-system/split-button-with-options';
import useResources from '../../hooks/scheduler/use-resources';
import { isString } from '../../helpers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonGroup_splitButton: {
    '& .MuiButtonGroup-root': {
      borderRadius: 0,
      '& button': {
        borderRadius: 0,
        borderLeft: 0
      },
      '&:nth-child(2)': {
        '& button:nth-child(1)': {
          borderLeft: '1px solid'
        }
      }
    },
    '& .MuiButtonGroup-root:last-child': {
      '& button:last-child': {
        borderRadius: '4px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }
    }
  },
  autocompleteInput: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: theme.spacing(0.5),
      marginLeft:"4px",
    },
    '& label': {
      fontWeight: 400,
      fontSize: '0.8rem',
      lineHeight: '0.65rem'
    },
    '& input': {
      fontSize: '0.8rem',
      
    }
  },
  wrapper : {
    display:'inline-flex',
    '& .MuiAutocomplete-root': {
      width:'100%',
    },
  },
  dataFilterWrapper: {
    display: 'flex'
  }
}));

const PlannerGridToolbar = ({ cleanRooms, people, equipment, isNoneSelected, onZoomLevelChange, onViewChange, events }) => {
  const classes = useStyles();
  const [zoomLevel, setZoomLevel] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCleanRoomView, setSelectedCleanRoomView] = useState({ view: '', resource: null });
  const [selectedEquipmentView, setSelectedEquipmentView] = useState({ view: '', resource: null });
  const [selectedPeopleView, setSelectedPeopleView] = useState({ view: '', resource: null });
  const [view, setView] = useState(RESOURCE_TYPE.day);
  const [selectedClient, setSelectedClient] = useState(null);
  const { clients } = useResources(false, RESOURCE_TYPE.client);
  const [selectedSubID, setSelectedSubID] = useState(['']);

  useEffect(() => {
    if (isNoneSelected) {
      setSelectedIndex(-1);
    }
  }, [isNoneSelected]);


  const handleChangeView = (value, resourceCode) => {
    setView(value);
    if(selectedClient) {
      onViewChange?.(value, resourceCode, {customer: selectedClient});
    }
    else{
      onViewChange?.(value, resourceCode); 
    }  
  };

  // const handleChangeZoomLevel = (value) => {
  //   setZoomLevel(value);
  //   onZoomLevelChange?.(value);
  // };

  const subjectIdOptions = useMemo(() => {
    const subIdArr =  events.map(c => c.meta_subjectId).filter(r => r != "" || r != null);
    return [...new Set(subIdArr)];
  }, [events]);

  return (
    <div className={classes.root}>
      {/* <ButtonGroup variant="outlined" aria-label="outlined primary button group" color="secondary" size="small">
         <Button><Switch size="small" checked={showTooltip} onClick={(evt) => {
          setShowTooltip(evt.target.checked);
        }} /> Tooltips</Button> 
        <Button onClick={() => {
          if (zoomLevel > -5) {
            handleChangeZoomLevel(zoomLevel-1);
          }
        }}><RemoveIcon /></Button>
        <Button>{zoomLevel}</Button>            
        <Button onClick={() => {
          if (zoomLevel < 5) {
            handleChangeZoomLevel(zoomLevel+1);
          }
        }}><AddIcon /></Button>
      </ButtonGroup> */}
      <Box className={classes.dataFilterWrapper}>
        <SplitButtonWithOptions options={['All Customers'].concat(clients.map(c => c.shortName))} onSelect={(clientCode) => {
          const clientResource = clientCode != 'All Customers' ? clients.find(c => c.shortName == clientCode) : null;
          setView(RESOURCE_TYPE.day);
          setSelectedIndex(0);
          setSelectedClient(clientResource)
          onViewChange?.(view, null, {customer: clientResource, subjectId: null});
          setSelectedSubID([]);
        }} />

        <Box sx={{ width: 150 }} className={classes.wrapper}>
          <Autocomplete sx={{ width: 150 }}
            size="small"
            value={selectedSubID}
            getOptionLabel={(option) => {
              return isString(option) ? option : '';
            }}
            onChange={(event, subjectId) => {
              const filterParam = subjectId ? { subjectId } : { customer: selectedClient }
              onViewChange?.(view, null, filterParam);
              setView(RESOURCE_TYPE.day);
              setSelectedIndex(0);
            }}
            freeSolo
            options={subjectIdOptions}
            renderInput={(params) => (
              <TextField {...params} label="Search Subject Id" 
                margin="none" size="small" variant="outlined"
                className={classes.autocompleteInput}
              />
            )}
          />
        </Box>
      </Box>

      <Box>
         &nbsp;&nbsp;
        <ButtonGroup 
          variant="outlined" aria-label="outlined primary button group" color="primary" size="small" 
          className={classes.buttonGroup_splitButton}>
          <Button 
            variant={selectedIndex === 0 ? 'contained' : ''}
            onClick={() => { 
              handleChangeView(RESOURCE_TYPE.day);
              setSelectedIndex(0);
            }}
          >
            {/* <CalendarTodayIcon fontSize="small" />  */}
            All Schedules
          </Button>
          <SplitButtonWithOptionsAdvanced 
            options={[{code: 'all', label: 'All Rooms'}, {code: 'all-rooms-capacity', label: 'All Rooms - Capacity'}].concat(cleanRooms?.map((c) => ({code: c.code, label: `${c.name} - Capacity`, ...c})))}
            variant={selectedIndex === 1 ? 'contained' : ''} 
            onSelect={(val) => {            
              if (val.code === 'all') {
                handleChangeView(RESOURCE_TYPE.cleanRoom);
                setSelectedCleanRoomView({ view: RESOURCE_TYPE.cleanRoom, resource: null });
              } else if (val.code === 'all-rooms-capacity') {
                handleChangeView(RESOURCE_TYPE.cleanRoomCapacityView);
                setSelectedCleanRoomView({ view: RESOURCE_TYPE.cleanRoomCapacityView, resource: null });
              } else {
                handleChangeView(RESOURCE_TYPE.singleCleanRoomCapacityView, val);
                setSelectedCleanRoomView({ view: RESOURCE_TYPE.singleCleanRoomCapacityView, resource: val });
              }
              setSelectedIndex(1);
            }}
            onClick={() => {
              if (selectedCleanRoomView.view) {
                handleChangeView(selectedCleanRoomView.view, selectedCleanRoomView.resource);
              } else {
                handleChangeView(RESOURCE_TYPE.cleanRoom);
              }
              setSelectedIndex(1);
            }} />
          <SplitButtonWithOptionsAdvanced 
            options={[{code: 'all', label: 'All People'}, {code: 'all-people-capacity', label: 'All People - Capacity'}].concat(people?.map((p) => ({code: p.code, label: `${p.name} - Capacity`, ...p})))}
            variant={selectedIndex === 2 ? 'contained' : ''} 
            onSelect={(val) => {
              if (val.code === 'all') {
                handleChangeView(RESOURCE_TYPE.person);
                setSelectedPeopleView({ view: RESOURCE_TYPE.person, resource: null });
              } else if (val.code === 'all-people-capacity') {
                handleChangeView(RESOURCE_TYPE.personCapacityView);
                setSelectedPeopleView({ view: RESOURCE_TYPE.personCapacityView, resource: null });
              } else {
                handleChangeView(RESOURCE_TYPE.singlePersonCapacityView, val);
                setSelectedPeopleView({ view: RESOURCE_TYPE.singlePersonCapacityView, resource: val });
              }
              setSelectedIndex(2);
            }}
            onClick={() => {
              if (selectedPeopleView.view) {
                handleChangeView(selectedPeopleView.view, selectedPeopleView.resource);
              } else {
                handleChangeView(RESOURCE_TYPE.person);
              }
              setSelectedIndex(2);
            }} />
          <SplitButtonWithOptionsAdvanced 
            options={[{code: 'all', label: 'All Equipment'}, {code: 'all-equipment-capacity', label: 'All Equipment - Capacity'}].concat(equipment?.map((e) => ({code: e.code, label: `${e.name} - Capacity`, ...e})))}
            variant={selectedIndex === 3 ? 'contained' : ''} 
            onSelect={(val) => {
              if (val.code === 'all') {
                handleChangeView(RESOURCE_TYPE.equipment);
                setSelectedEquipmentView({ view: RESOURCE_TYPE.equipment, resource: null });
              } else if (val.code === 'all-equipment-capacity') {
                handleChangeView(RESOURCE_TYPE.equipmentCapacityView);
                setSelectedEquipmentView({ view: RESOURCE_TYPE.equipmentCapacityView, resource: null });
              } else {
                handleChangeView(RESOURCE_TYPE.singleEquipmentCapacityView, val);
                setSelectedEquipmentView({ view: RESOURCE_TYPE.singleEquipmentCapacityView, resource: val });
              }
              setSelectedIndex(3);
            }}
            onClick={() => { 
              if (selectedEquipmentView.view) {
                handleChangeView(selectedEquipmentView.view, selectedEquipmentView.resource);
              } else {
                handleChangeView(RESOURCE_TYPE.equipment);
              }
              setSelectedIndex(3);
            }} />
        </ButtonGroup>
      </Box>
    </div>
  )
}

export default PlannerGridToolbar;