// Pages
import {
  // AppBar,
  // Autocomplete,
  // Avatars,
  // BackendError,
  // Badges,
  // Blank,
  // ButtonNavigation,
  // Buttons,
  Calendar,
  // Cards,
  // Charts,
  // Chat,
  // Chips,
  // Detail,
  // Dialogs,
  // Dividers,
  // Drawers,
  // Editor,
  // ExpansionPanels,
  // Google,
  // GridList,
  Home,
  // Invoice,
  // Leaflet, // this component has error
  // Lists,
  // Lockscreen,
  OrderLotBatchPage,
  Media,
  // Menus,
  Messages,
  // NotFound,
  OrdersPage,
  // Paper,
  // PasswordReset,
  // Pickers,
  // PricingPage,
  // Products,
  // Progress,
  // SelectionControls,
  // Selects,
  // Signin,
  // Signup,
  // Snackbars,
  Social,
  // Steppers,
  // Tables,
  // Tabs,
  // Taskboard,
  // TextFields,
  // TimelinePage,
  // Tooltips,
  // Widgets,
} from './pages';
import PrintOrderLotBatchPage from './pages/Orders/print-order-lot-batch-page';
import SensorMonitoringPage from './pages/Home/sensor-monitoring-page';
import BatchWorkflowConfigurationPage from './pages/Manage/batch-workflow-configuration-page';
import ManageFormsPage from './pages/Manage/manage-forms-page';
import ManagePermissions from './pages/Manage/permissions';
import ManageBatchesPage from './pages/Manage/manage-batches-page';
import ViewSchedulePage from './pages/Scheduler/view-schedule-page';
import MasterPlannerPage from './pages/Scheduler/master-planner-page';
import ManageTemplatesPage from './pages/Scheduler/manage-templates-page';
import ManageResourcesPage from './pages/Scheduler/manage-resources-page';
import DebugToolPage from './pages/Scheduler/debug-tool-page';
// import AutoSchedulerPage from './pages/Scheduler/auto-scheduler-page';
import { RESOURCE_TYPE, USER_ROLE_MASTER } from './hooks/constants';

import AppsIcon from '@material-ui/icons/Apps';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
// Icons
// import ExploreIcon from '@material-ui/icons/Explore';
import BuildIcon from '@material-ui/icons/Build';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import RestoreIcon from '@material-ui/icons/Restore';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ArticleIcon from "@material-ui/icons/NoteOutlined";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";

// import FaceIcon from '@material-ui/icons/Face';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
// import Looks3Icon from '@material-ui/icons/Looks3';
// import Looks4Icon from '@material-ui/icons/Looks4';
// import ChatIcon from '@material-ui/icons/Chat';
import InboxIcon from '@material-ui/icons/Inbox';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

// import NavigationIcon from '@material-ui/icons/Navigation';
import PeopleIcon from '@material-ui/icons/People';
// import PersonIcon from '@material-ui/icons/Person';
// import PhotoIcon from '@material-ui/icons/Photo';
// import ShowChartIcon from '@material-ui/icons/ShowChart';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import ListAltIcon from "@material-ui/icons/ListAlt";
import { APP_NAMES } from './hooks/constants';

export default {
  items: [
    {
      path: '/',
      name: 'Home',
      type: 'link',
      icon: HomeOutlinedIcon,
      component: Home,
      appName: APP_NAMES.electronicBatchRecord
    },
    { // this additional / mapped path is for Scheduler App
      path: '/',
      name: 'View Schedule',
      type: 'link',
      icon: CalendarTodayOutlinedIcon,
      component: ViewSchedulePage,
      appName: APP_NAMES.scheduler
      // visible for all roles
    },
    {
      path: '/orders',
      name: 'Orders',
      type: 'link',
      icon: ListAltIcon,
      component: OrdersPage,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/orders/:orderId/:lotId/batch',
      name: 'Lot Edit',
      component: OrderLotBatchPage,
      isHiddenInNav: true,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/orders/:orderId/:lotId/batch/:formName/note/:noteId',
      name: 'Lot Edit',
      component: OrderLotBatchPage,
      isHiddenInNav: true,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/orders/:orderId/:lotId/print-batch',
      name: 'Lot Print',
      component: PrintOrderLotBatchPage,
      isHiddenInNav: true,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/inbox',
      name: 'Inbox',
      type: 'link',
      icon: InboxIcon,
      component: Messages,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/sensor-monitoring',
      name: 'Monitoring',
      type: 'link',
      icon: LocalLibraryIcon,
      component: SensorMonitoringPage,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/manage/forms',
      name: 'Forms',
      icon: ArticleIcon,
      component: ManageFormsPage,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/manage/forms/manual-processes',
      name: 'Manual Processes',
      icon: WorkOutlineIcon,
      component: ManageFormsPage,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/manage/batches',
      name: 'Batches',
      icon: AppsIcon,
      component: ManageBatchesPage,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/manage/batch-workflow/:batchMasterRecordId',
      name: 'Workflow Configurator',
      icon: InboxIcon,
      component: BatchWorkflowConfigurationPage,
      isHiddenInNav: true,
      appName: APP_NAMES.electronicBatchRecord
    },
    {
      path: '/permissions',
      name: 'Access Controls',
      icon: SettingsIcon,
      component: ManagePermissions,
      appName: APP_NAMES.electronicBatchRecord
    },
    // Scheduler Links
    {
      path: '/schedule/plan',
      name: 'Master Planner',
      icon: GridOnOutlinedIcon,
      component: MasterPlannerPage,
      appName: APP_NAMES.scheduler,
      allowedRoles: [USER_ROLE_MASTER.LAB_USER, USER_ROLE_MASTER.ADMIN]
    },
    // {
    //   path: '/schedule/manage/auto-scheduler',
    //   name: 'Auto Scheduler',
    //   icon: TimerIcon,
    //   component: AutoSchedulerPage,
    //   appName: APP_NAMES.scheduler,
    //   allowedRoles: [USER_ROLE_MASTER.LAB_USER, USER_ROLE_MASTER.ADMIN]
    // },
    {
      path: '/schedule/manage/templates',
      name: 'Templates',
      icon: DashboardOutlinedIcon,
      component: ManageTemplatesPage,
      appName: APP_NAMES.scheduler,
      allowedRoles: [USER_ROLE_MASTER.LAB_USER, USER_ROLE_MASTER.ADMIN]
    },
    {
      path: '/schedule/manage/resources/:resourceType',
      name: 'Clean Rooms',
      icon: MeetingRoomIcon,
      component: ManageResourcesPage,
      isHiddenInNav: true,
      appName: APP_NAMES.scheduler,
      allowedRoles: [USER_ROLE_MASTER.LAB_USER, USER_ROLE_MASTER.ADMIN]
    },
    {
      path: `/schedule/manage/resources/${RESOURCE_TYPE.cleanRoom}`,
      name: 'Clean Rooms',
      icon: MeetingRoomIcon,
      component: ManageResourcesPage,
      appName: APP_NAMES.scheduler,
      allowedRoles: [USER_ROLE_MASTER.LAB_USER, USER_ROLE_MASTER.ADMIN]
    },
    {
      path: `/schedule/manage/resources/${RESOURCE_TYPE.equipment}`,
      name: 'Equipment',
      icon: BuildIcon,
      component: ManageResourcesPage,
      appName: APP_NAMES.scheduler,
      allowedRoles: [USER_ROLE_MASTER.LAB_USER, USER_ROLE_MASTER.ADMIN]
    },
    {
      path: `/schedule/manage/resources/${RESOURCE_TYPE.person}`,
      name: 'People',
      icon: PeopleIcon,
      component: ManageResourcesPage,
      appName: APP_NAMES.scheduler,
      allowedRoles: [USER_ROLE_MASTER.LAB_USER, USER_ROLE_MASTER.ADMIN]
    },
    {
      path: `/schedule/manage/resources/${RESOURCE_TYPE.process}`,
      name: 'Processes',
      icon: AccountTreeIcon,
      component: ManageResourcesPage,
      appName: APP_NAMES.scheduler,
      allowedRoles: [USER_ROLE_MASTER.LAB_USER, USER_ROLE_MASTER.ADMIN]
    },
    {
      path: `/schedule/manage/resources/${RESOURCE_TYPE.client}`,
      name: 'Customers',
      icon: ApartmentIcon,
      component: ManageResourcesPage,
      appName: APP_NAMES.scheduler,
      allowedRoles: [USER_ROLE_MASTER.LAB_USER, USER_ROLE_MASTER.ADMIN]
    },
    {
      path: `/schedule/debug-tool`,
      name: 'Debug Tool',
      icon: OfflineBoltIcon,
      component: DebugToolPage,
      appName: APP_NAMES.scheduler,
      allowedRoles: [USER_ROLE_MASTER.ADMIN]
    }
    // {
    //   path: '/manage',
    //   name: 'Configure',
    //   type: 'submenu',
    //   icon: SettingsIcon,
    //   children: [
    //     // {
    //     //   path: '/forms',
    //     //   name: 'Forms',
    //     //   component: ManageFormsPage
    //     // },
    //     // {
    //     //   path: '/batches',
    //     //   name: 'Batches',
    //     //   component: ManageBatchesPage
    //     // },
    //     // {
    //     //   path: '/batch-workflow/:batchMasterRecordId',
    //     //   name: 'Workflow Configurator',
    //     //   component: BatchWorkflowConfigurationPage,
    //     //   isHiddenInNav: true
    //     // },
    //     // {
    //     //   path: '/users',
    //     //   name: 'Users',
    //     //   component: Social
    //     // },
    //     // {
    //     //   path: '/roles',
    //     //   name: 'Roles',
    //     //   component: Social
    //     // },
        // {
        //   path: '/permissions',
        //   name: 'Access Controls',
        //   component: ManagePermissions
        // },
    //   ]
    // },
    // {
    //   path: '/chat',
    //   name: 'Chat',
    //   type: 'link',
    //   icon: ChatIcon,
    //   component: Chat
    // },
    // {
    //   path: '/apps',
    //   name: 'Apps',
    //   type: 'submenu',
    //   icon: AppsIcon,
    //   badge: {
    //     type: 'primary',
    //     value: '5'
    //   },
    //   children: [
    //     {
    //       path: '/calendar',
    //       name: 'Calendar',
    //       component: Calendar
    //     },
    //     {
    //       path: '/media',
    //       name: 'Media',
    //       component: Media
    //     },
    //     {
    //       path: '/social',
    //       name: 'Social',
    //       component: Social
    //     }
    //   ]
    // },
    // {
    //   path: '/widgets',
    //   name: 'Widgets',
    //   type: 'link',
    //   icon: PhotoIcon,
    //   component: Widgets
    // },
    // {
    //   path: '/material',
    //   name: 'Material',
    //   type: 'submenu',
    //   icon: EqualizerIcon,
    //   badge: {
    //     type: 'error',
    //     value: '10'
    //   },
    //   children: [
    //     {
    //       path: '/appbar',
    //       name: 'App Bar',
    //       component: AppBar
    //     },
    //     {
    //       path: '/autocomplete',
    //       name: 'Auto Complete',
    //       component: Autocomplete
    //     },
    //     {
    //       path: '/avatars',
    //       name: 'Avatars',
    //       component: Avatars
    //     },
    //     {
    //       path: '/badges',
    //       name: 'Badges',
    //       component: Badges
    //     },
    //     {
    //       path: '/button-navigation',
    //       name: 'Button Navigation',
    //       component: ButtonNavigation
    //     },
    //     {
    //       path: '/buttons',
    //       name: 'Buttons',
    //       component: Buttons
    //     },
    //     {
    //       path: '/cards',
    //       name: 'Cards',
    //       component: Cards
    //     },
    //     {
    //       path: '/chips',
    //       name: 'Chips',
    //       component: Chips
    //     },
    //     {
    //       path: '/dialogs',
    //       name: 'Dialogs',
    //       component: Dialogs
    //     },
    //     {
    //       path: '/dividers',
    //       name: 'Dividers',
    //       component: Dividers
    //     },
    //     {
    //       path: '/drawers',
    //       name: 'Drawers',
    //       component: Drawers
    //     },
    //     {
    //       path: '/expansion-panels',
    //       name: 'Expansion Panels',
    //       component: ExpansionPanels
    //     },
    //     {
    //       path: '/grid-list',
    //       name: 'Grid List',
    //       component: GridList
    //     },
    //     {
    //       path: '/lists',
    //       name: 'Lists',
    //       component: Lists
    //     },
    //     {
    //       path: '/menus',
    //       name: 'Menus',
    //       component: Menus
    //     },
    //     {
    //       path: '/paper',
    //       name: 'Paper',
    //       component: Paper
    //     },
    //     {
    //       path: '/pickers',
    //       name: 'Pickers',
    //       component: Pickers
    //     },
    //     {
    //       path: '/progress',
    //       name: 'Progress',
    //       component: Progress
    //     },
    //     {
    //       path: '/selection-controls',
    //       name: 'Selection Controls',
    //       component: SelectionControls
    //     },
    //     {
    //       path: '/selects',
    //       name: 'Selects',
    //       component: Selects
    //     },
    //     {
    //       path: '/snackbars',
    //       name: 'Snackbars',
    //       component: Snackbars
    //     },
    //     {
    //       path: '/steppers',
    //       name: 'Steppers',
    //       component: Steppers
    //     },
    //     {
    //       path: '/tables',
    //       name: 'Tables',
    //       component: Tables
    //     },
    //     {
    //       path: '/tabs',
    //       name: 'Tabs',
    //       component: Tabs
    //     },
    //     {
    //       path: '/text-fields',
    //       name: 'Text Fields',
    //       component: TextFields
    //     },
    //     {
    //       path: '/tooltips',
    //       name: 'Tooltips',
    //       component: Tooltips
    //     }
    //   ]
    // },
    // {
    //   path: '/editor',
    //   name: 'Editor',
    //   type: 'link',
    //   icon: Looks3Icon,
    //   component: Editor
    // },
    // {
    //   path: '/ecommerce',
    //   name: 'Ecommerce',
    //   type: 'submenu',
    //   icon: Looks4Icon,
    //   badge: {
    //     type: 'secondary',
    //     value: 'N'
    //   },
    //   children: [
    //     {
    //       path: '/products',
    //       name: 'Products',
    //       component: Products
    //     },
    //     {
    //       path: '/detail',
    //       name: 'Detail',
    //       component: Detail
    //     }
    //   ]
    // },
    // {
    //   path: '/taskboard',
    //   name: 'Taskboard',
    //   type: 'link',
    //   icon: ViewColumnIcon,
    //   component: Taskboard
    // },
    // {
    //   path: '/charts',
    //   name: 'Charts',
    //   type: 'link',
    //   icon: ShowChartIcon,
    //   component: Charts
    // },
    // {
    //   path: '/maps',
    //   name: 'Maps',
    //   type: 'submenu',
    //   icon: NavigationIcon,
    //   children: [
    //     {
    //       path: '/google',
    //       name: 'Google',
    //       component: Google
    //     },
    //     // {
    //     //   path: '/leaflet',
    //     //   name: 'Leaflet',
    //     //   component: Leaflet
    //     // }
    //   ]
    // },
    // {
    //   path: '/pages',
    //   name: 'Pages',
    //   type: 'submenu',
    //   icon: PagesIcon,
    //   children: [
    //     {
    //       path: '/invoice',
    //       name: 'Invoice',
    //       component: Invoice
    //     },
    //     {
    //       path: '/timeline',
    //       name: 'Timeline',
    //       component: TimelinePage
    //     },
    //     {
    //       path: '/blank',
    //       name: 'Blank',
    //       component: Blank
    //     },
    //     {
    //       path: '/pricing',
    //       name: 'Pricing',
    //       component: PricingPage
    //     }
    //   ]
    // },
    // {
    //   name: 'Authentication',
    //   type: 'submenu',
    //   icon: PersonIcon,
    //   children: [
    //     {
    //       path: '/signin',
    //       name: 'Signin',
    //       component: Signin
    //     },
    //     {
    //       path: '/signup',
    //       name: 'Signup',
    //       component: Signup
    //     },
    //     {
    //       path: '/forgot',
    //       name: 'Forgot',
    //       component: PasswordReset
    //     },
    //     {
    //       path: '/lockscreen',
    //       name: 'Lockscreen',
    //       component: Lockscreen
    //     }
    //   ]
    // },
    // {
    //   name: 'Error',
    //   type: 'submenu',
    //   icon: FaceIcon,
    //   children: [
    //     {
    //       path: '/404',
    //       name: '404',
    //       component: NotFound
    //     },
    //     {
    //       path: '/500',
    //       name: 'Error',
    //       component: BackendError
    //     }
    //   ]
    // },
    // {
    //   path: 'https://iamnyasha.github.io/react-primer-docs/',
    //   name: 'Documentation',
    //   type: 'external',
    //   icon: LocalLibraryIcon
    // },
    // {
    //   path: 'https://primer.fusepx.com',
    //   name: 'Get Angular Version',
    //   type: 'external',
    //   icon: BookmarkIcon
    // }
  ]
};
