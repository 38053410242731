import { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

export default function QrCodeModal({ isVisible, customerName, productName, lotNum, batchNum, onClose }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  const componentRef = useRef();
  const handlePrintBatch = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose?.();
      }}
      aria-labelledby="qr-code-modal-title"
      aria-describedby="qr-code-modal-description">
      <DialogTitle id="qr-code-modal-title">Print QR Code</DialogTitle>
      <DialogContent ref={componentRef}>
        
        <DialogContentText id="qr-code-modal-description">
          <img src="/static/images/qr-code.svg" width="250" />  
        </DialogContentText>

        <Typography>Customer: {customerName}</Typography>
        <Typography>Product: {productName}</Typography>
        <Typography>Lot #: {lotNum}</Typography>
        <Typography>Batch #: {batchNum}</Typography>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => { onClose?.() }}>
          Cancel
        </Button>
        <Button onClick={handlePrintBatch} color="primary">
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
}