import { useEffect, useState, useMemo } from "react";
import { firestoreInstance } from '../config/firebase-init';

export default function useBatchWorkflow(batchWorkflowId) {
  const batchWorkflowRef = firestoreInstance
        .collection('batchesMaster').doc(batchWorkflowId);

  const [isLoading, setIsLoading] = useState(true);
  const [formCards, setFormCards] = useState(null);
  const [workflowStates, setWorkflowStates] = useState(null);
  const [workflowStatesSequence, setWorkflowStatesSequence] = useState([]);

  const [batchMasterRecordData, setData] = useState(null);
  const [batchMasterRecordError, setError] = useState(null);

  useEffect(() => {
      const unsubscribeBatchWorkflowRef = batchWorkflowRef.onSnapshot((doc) => {
          setData(doc.data());
          setError(null);
      }, (err) => { setError(err); });

      return () => {
        unsubscribeBatchWorkflowRef();
      };
  }, []);

  const batchAvailableFormSlugs = useMemo(() => {
    return batchMasterRecordData ? batchMasterRecordData.availableForms.map((form) => form.slug) : [];
  }, [batchMasterRecordData]);

  useEffect(() => {
    if (batchMasterRecordData) {
      const newFormCards = {};      
      const parkedFormSlugs = [];

      batchMasterRecordData.availableForms.forEach((availableForm) => {
        newFormCards[availableForm.slug] = availableForm;

        const isFormAssignedToState = Object.keys(batchMasterRecordData.workflowStates).find((wfStateKey) => {
          return batchMasterRecordData.workflowStates[wfStateKey].formSlugs.indexOf(availableForm.slug) !== -1
        });
        if (!isFormAssignedToState) {
          parkedFormSlugs.push(availableForm.slug);
        }
      });
      
      setFormCards(newFormCards);
      setWorkflowStates(Object.assign({}, batchMasterRecordData.workflowStates, {
        'parked-forms': { 
          slug: 'parked-forms', 
          name: 'Available Forms & Processes',
          formSlugs: parkedFormSlugs
        }
      }));
      setWorkflowStatesSequence(batchMasterRecordData.workflowStatesSequence);
      setIsLoading(false);
    }
  }, [batchMasterRecordData]);

  return {
    isLoading,
    workflowConfig: {
      formCards,
      workflowStates,
      workflowStatesSequence
    },
    batchAvailableFormSlugs,
    saveWorkflowStates: (newStates) => {
      const workflowStatesPayload = Object.assign({}, newStates);
      delete workflowStatesPayload['parked-forms'];
      return batchWorkflowRef.update({workflowStates: workflowStatesPayload}).catch((err) => {
        console.error('err: ', err);
      });
    },
    addNewSequence: () => {
      const newSeqSlug = `state-${workflowStatesSequence.length}`;
      const newSequence = { 
        slug: newSeqSlug, 
        name: `Sequence ${workflowStatesSequence.length + 1}`, 
        formSlugs: [] ,
        activationRules: []
      };

      const newWorkflowStatesSequence = Array.from(workflowStatesSequence);
      newWorkflowStatesSequence.push(newSeqSlug);

      return batchWorkflowRef.update({
        workflowStates: Object.assign({}, batchMasterRecordData.workflowStates, { [newSeqSlug] : newSequence }),
        workflowStatesSequence: newWorkflowStatesSequence
      }).catch((err) => {
        console.error('err: ', err);
      });
    },
    updateAvailableForms: (availableForms) => {
      return batchWorkflowRef
        .update({
          availableForms
        })
        .catch((e) => {
          console.error("Error updating available forms: ", e);
        });
    },
    updateStateActivationRules: (stateSlug, payload) => {
      return batchWorkflowRef
        .set({
          workflowStates: { 
            [stateSlug]: { 
              activationRules: payload 
            } 
          }
        }, { merge: true })
        .catch((e) => {
          console.error("Error updating state activation rules: ", e);
        });
    }
  };
}