import { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { KeyboardTimePicker } from '@material-ui/pickers';
import Timer from '@material-ui/icons/Timer';
import dayjs from 'dayjs';
import { DAY_24HRS_MINUTES } from '../../hooks/constants';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    '& p': {
      width: '80px',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap'
    },
    '& .MuiFormControl-root': {
      width: '120px',
      marginRight: theme.spacing(2)
    },
  },
  minutesInput: {
    '& .MuiInputBase-input': {
      padding: 0
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  }
}));

function ResourceAvailabilityForm({ onChange, initialValues }) {
  const classes = useStyles();
  const [dow, setDow] = useState(['sun', 'mon','tue','wed','thu','fri','sat']);
  const [availability, setAvailability] = useState({
    sun_from: null,
    sun_to: null,
    sun_isWorking: false,
    sun_workingMinutes: 0,

    mon_from: null,
    mon_to: null,
    mon_isWorking: false,
    mon_workingMinutes: 0,

    tue_from: null,
    tue_to: null,
    tue_isWorking: false,
    tue_workingMinutes: 0,

    wed_from: null,
    wed_to: null,
    wed_isWorking: false,
    wed_workingMinutes: 0,

    thu_from: null,
    thu_to: null,
    thu_isWorking: false,
    thu_workingMinutes: 0,

    fri_from: null,
    fri_to: null,
    fri_isWorking: false,
    fri_workingMinutes: 0,

    sat_from: null,
    sat_to: null,
    sat_isWorking: false,
    sat_workingMinutes: 0,
  });

  useEffect(() => {
    if (initialValues) {
      // Transforms String representation of Date to dayjs() objects.
      const transformedAvailability = {};
      Object.keys(availability).forEach((key) => {
        transformedAvailability[key] = (typeof initialValues[key] === 'boolean' || typeof initialValues[key] === 'number') ? initialValues[key] : 
          (initialValues[key] ? dayjs(initialValues[key]) : availability[key]);
      });
      updateInPlace_AvailabilityForAllDays(transformedAvailability);
      setAvailability(Object.assign({}, availability, transformedAvailability));
    }
  }, [initialValues]);

  const calculateAvailabilityForSingleDay = (availability, val) => {
    const isWorking = availability[`${val}_isWorking`];
    const isWorkingFrom = availability[`${val}_from`];
    const isWorkingTo = availability[`${val}_to`];
    const availableMinutes = isWorking ? 
      (isWorkingFrom && isWorkingTo ? isWorkingTo.diff(isWorkingFrom, 'minute') : DAY_24HRS_MINUTES) : 0;
    return availableMinutes;
  };

  const updateInPlace_AvailabilityForAllDays = (availability) => {
    dow.forEach((val) => {
      const availableMinutes = calculateAvailabilityForSingleDay(availability, val);
      availability[`${val}_workingMinutes`] = availableMinutes;
    });
  };

  const notifyChange = (newAvailability) => {
    // console.log('newAvailability: ', newAvailability);
    const transformedAvailability = {};
    Object.keys(newAvailability).forEach((key) => {
      if (typeof newAvailability[key] !== 'boolean' && typeof newAvailability[key] !== 'number') {
        transformedAvailability[key] = newAvailability[key]?.toISOString() || '';
      } else {
        transformedAvailability[key] = newAvailability[key];
      }
    });
    // console.log('transformedAvailability: ', transformedAvailability);
    onChange?.(transformedAvailability);
  };

  const handleDateChange = (date, displayVal, fieldNamePrefix, fromOrToField) => {
    let newAvailability = {};
    if (date) {
      newAvailability = Object.assign({}, availability, {
        [`${fieldNamePrefix}_${fromOrToField}`]: date
      });
    } else {
      // Clear both From & To dates
      newAvailability = Object.assign(newAvailability, availability, {
        [`${fieldNamePrefix}_from`]: null,
        [`${fieldNamePrefix}_to`]: null
      });
    }
    const availableMinutes = calculateAvailabilityForSingleDay(newAvailability, fieldNamePrefix);
    newAvailability[`${fieldNamePrefix}_workingMinutes`] = availableMinutes;

    if (newAvailability) {
      setAvailability(newAvailability);
      notifyChange(newAvailability);
    }
  };

  function handleCheckboxChange(evt, fieldNamePrefix) {
    const temp = {
      [`${fieldNamePrefix}_isWorking`]: evt.target.checked
    };
    if (evt.target.checked) {
      if (availability[`${fieldNamePrefix}_from`] && availability[`${fieldNamePrefix}_to`]) {
        const availableMinutes = calculateAvailabilityForSingleDay(Object.assign({}, availability, temp), fieldNamePrefix);
        temp[`${fieldNamePrefix}_workingMinutes`] = availableMinutes;
      } else {
        temp[`${fieldNamePrefix}_workingMinutes`] = DAY_24HRS_MINUTES;  
      }
    } else {
      temp[`${fieldNamePrefix}_workingMinutes`] = 0;
    }
    const newAvailability = Object.assign({}, availability, temp);
    setAvailability(newAvailability);
    notifyChange(newAvailability);
  };

  const handleApplyToAllDays = () => {
    const temp = {
      sun_from: availability.sun_from,
      sun_to: availability.sun_to,
      sun_isWorking: availability.sun_isWorking,
      sun_workingMinutes: availability.sun_workingMinutes,
    };
    for(let i = 1;i < dow.length;i++) {
      const val = dow[i];
      temp[`${val}_from`] = availability.sun_from ? availability.sun_from.clone() : null;
      temp[`${val}_to`] = availability.sun_to ? availability.sun_to.clone() : null;
      temp[`${val}_isWorking`] = availability.sun_isWorking;
      temp[`${val}_workingMinutes`] = availability.sun_workingMinutes;
    }
    setAvailability(temp);
    notifyChange(temp);
  };

  const renderFieldGroup = (fieldNamePrefix) => {
    return (
      <Box className={classes.row}>
        <Typography>{fieldNamePrefix}</Typography>
        <KeyboardTimePicker 
          name={`${fieldNamePrefix}_from`} 
          keyboardIcon={<Timer fontSize="small" />} 
          label="From" 
          value={availability[`${fieldNamePrefix}_from`]} 
          onChange={(date, displayVal) => {
            handleDateChange(date, displayVal, fieldNamePrefix, 'from');
          }} 
        />
        <KeyboardTimePicker 
          name={`${fieldNamePrefix}_to`} 
          keyboardIcon={<Timer fontSize="small" />} 
          label="To" 
          value={availability[`${fieldNamePrefix}_to`]} 
          onChange={(date, displayVal) => {
            handleDateChange(date, displayVal, fieldNamePrefix, 'to');
          }} 
        />
        <TextField placeholder="Hours" type="number" 
          className={classes.minutesInput}
          style={{ width: '40px' }}
          readOnly
          value={availability[`${fieldNamePrefix}_workingMinutes`]/60} />
        <label className={classes.label}>
          <Checkbox 
            checked={availability[`${fieldNamePrefix}_isWorking`]} 
            onChange={(evt) => {
              handleCheckboxChange(evt, fieldNamePrefix);
            }} 
          /> 
          <Typography>Is Working?</Typography> 
        </label>
        {fieldNamePrefix === 'sun' && <Button variant="outlined" size="small" onClick={handleApplyToAllDays}>Copy to all</Button>}
      </Box>
    );
  };

  const weeklyWorkingHours = useMemo(() => dow.reduce((sum,dow) => (sum+(+availability[`${dow}_workingMinutes`])), 0)/60, [availability]);

  return (
    <Box>
      <Alert variant="outlined" color="info">
        <Box>The <strong>Is Working</strong> checkbox indicates Resource is working that day.</Box>
        <Box>If <strong>From & To Time</strong> is EMPTY, Resource is available for 24 hours.</Box>
        <Box>If either <strong>From or To Time</strong> is EMPTY for a day, both are ignored.</Box>
        <Box>Weekly Availability = {weeklyWorkingHours} Hours</Box>
      </Alert>
      {renderFieldGroup('sun')}
      {renderFieldGroup('mon')}
      {renderFieldGroup('tue')}
      {renderFieldGroup('wed')}
      {renderFieldGroup('thu')}
      {renderFieldGroup('fri')}
      {renderFieldGroup('sat')}
    </Box>
  );
}

export default ResourceAvailabilityForm;
