import { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { FormGenerator } from "formik-generator-materialui";
import { 
  Formik
  // Form, Field, ErrorMessage, useFormikContext 
} from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useResourceData from "../../hooks/scheduler/use-resource-data";
import { RESOURCE_TYPE, SCHEDULER, USER_ROLE_MASTER } from "../../hooks/constants";
import TabPanel from "../../design-system/tab-content-panel";
import ResourceIntegrationForm from './resource-form-integration.partial';
import ResourceAvailabilityForm from './resource-form-availability.partial';
import ResourceDowntimeForm from './resource-form-downtime.partial';
import ResourceSkillsForm from './resource-form-skills.partial';
import useSchedulerEvents from "../../hooks/scheduler/use-scheduler-events";
import useSchedulerAutoAllocationPublishStatus from "../../hooks/scheduler/use-scheduler-auto-allocation-publish-status";
import dayjs from "dayjs";
import { ListItem, Typography } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/CloseRounded';
import useUserSession from "../../hooks/use-user-session";
import useResourceSchedule from "../../hooks/scheduler/use-resource-schedule";
import { Alert } from "@material-ui/lab";

const formFieldsSchema = [
  {
    title: 'Name',
    typeField: 'text',
    path: "name",
    required: true,
  },
  {
    title: 'Code (System Use)',
    typeField: 'text',
    path: "code",
    required: true
  },
  {
    title: 'Short Name (For Display)',
    typeField: 'text',
    path: "shortName",
    required: true,
  },
  {
    title: 'Type',
    typeField: 'hidden',
    path: "type",
    choices: [RESOURCE_TYPE.cleanRoom, RESOURCE_TYPE.equipment, RESOURCE_TYPE.person, RESOURCE_TYPE.process, RESOURCE_TYPE.client],
    required: true,
  },
  {
    title: 'Sub Type',
    typeField: 'select',
    path: "subType",
    choices: [],
    required: true,
  },
  {
    title: 'Resource ID', // (SKU / EmpID)
    typeField: 'text',
    path: "resourceId",
    required: false,
  },
  {
    title: 'Active',
    typeField: 'switch',
    path: 'active'
  }
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  code: Yup.string().required(),
  shortName: Yup.string().required(),
  type: Yup.string().required()
});

const useStyles = makeStyles(theme => ({
  tabContentWrapper: {
    border: '1px solid #cfcfcf',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    maxWidth: '660px'
  },
  scrollableUl:{
    height:'200px',
    overflowY:'auto',
    paddingLeft:'10px',
    '& li':{
      paddingLeft:'0px',
      borderBottom:'1px solid #ece6e6'
    }
  },
  root : {
  '& .MuiDialog-paper' : {
    minHeight:'calc(100% - 100px) !important;',
    maxHeight:'calc(100% - 100px) !important;',
    maxWidth:'1100px !important;',
  }
  
 }
}));

const ResourceFormModal = ({ resourceType = null, resourceId = null, onClose = null, onSave = null}) => {
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({});
  const [selectedIntegrations, setSelectedIntegrations] = useState({
    cliniTrackId: '',
    customIntegrationId1: '',
    customIntegrationId2: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [selectedDowntime, setSelectedDowntime] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isDowntimeFormDirty, setIsDowntimeFormDirty] = useState(false);
  // const [isAvailabilityFormDirty, setIsAvailabilityFormDirty] = useState(false);
  const [showDownTimeList, setShowDownTimeList] = useState(false);
  const { userProfile } = useUserSession();

  const { detectConflictsAndSaveStatusForResourceDowtime } = useSchedulerEvents();
  const { recalculateAndPublishSlotAvailability } = useSchedulerAutoAllocationPublishStatus();
  const { resource, add, update } = useResourceData(resourceId);
  const formRef = useRef(null);
  const resourceEvents = useResourceSchedule(resource?.code);

  useEffect(() => {
    if (resourceType) {
      if (resourceType == RESOURCE_TYPE.equipment) {
        formFieldsSchema[4].typeField = 'select';
        formFieldsSchema[4].choices = SCHEDULER.EQUIPMENT_TYPE_OPTIONS;
      } else {
        formFieldsSchema[4].typeField = 'hidden';
      }
    }
  }, [resourceType]);

  useEffect(() => {
    if (resourceId) {
      if (resource) {
        formFieldsSchema[1].readOnly = true;
        setInitialValues({
          type: resourceType,
          active: resource.active,
          subType: resource.subType,
          shortName: resource.shortName,
          code: resource.code,
          name: resource.name,
          subType: resource.subType || '',
          resourceId: resource.resourceId || ''
        });
      }
    } else if (!resourceId && resourceType) {
      formFieldsSchema[1].readOnly = false;
      setInitialValues({ 
        type: resourceType,
        subType: '',
        active: true
      });
    }
  }, [resourceId, resourceType, resource]);

  const handleSave = (values) => {
    validationSchema.isValid(values).then((valid) => {
      if (valid) {
        const payload = {
          ...values, 
          integrations: selectedIntegrations,
          availability: selectedAvailability || resource?.availability || {},
          downtime: selectedDowntime || resource?.downtime || [],
          skills: selectedSkills || resource?.skills || {}
        };

        setIsLoading(true);
        if (resourceId) {
          delete values['NO_ID_FIELD'];
          update(payload).then(() => {
            if (isDowntimeFormDirty) {
              return recalculateAndPublishSlotAvailability();
            }
          }).then(() => {
            if (isDowntimeFormDirty) {
              return detectConflictsAndSaveStatusForResourceDowtime(resource.code, payload.downtime);
            }
          }).then(() => {
            setIsLoading(false);
            formRef.current.resetForm();
            onSave?.();
          });
        } else {
          add(payload).then(() => {
            // More slots could've been added by adding a resource.
            if (isDowntimeFormDirty) {
              return recalculateAndPublishSlotAvailability();
            }
          }).then(() => {
            setIsLoading(false);
            formRef.current.resetForm();
            onSave?.();
          });
        }
      }
    });
  }

  const handleCancel = () => {
    formRef.current.resetForm();
    onClose?.();
  }

  const ListDownTime = ({downtime}) => {
    return (
      <div>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" gutterBottom >Downtime Days</Typography>
          <IconButton onClick={() => { setShowDownTimeList(false) }}>
            <CloseIcon></CloseIcon>
          </IconButton>
        </Box>
      {
       downtime?.length > 0 ? <ul className={classes.scrollableUl}>
          {downtime.map(date => (
            <ListItem >
              <ListItemText primary={dayjs(date).format('MM/DD/YYYY,  ddd')} />
            </ListItem>
          ))}
        </ul> :  <Alert severity="info">This resource has no downtime</Alert>
     }
      </div>

    );
  }
  const isAvailabilityAndDowntimeVisible = [RESOURCE_TYPE.cleanRoom, RESOURCE_TYPE.equipment, RESOURCE_TYPE.person].indexOf(resourceType) !== -1;

  function setIntegrationIndex () {
    if(resourceType === RESOURCE_TYPE.process || resourceType === RESOURCE_TYPE.client) {
      return 0
    }
    if(isAvailabilityAndDowntimeVisible && resourceType === RESOURCE_TYPE.person) {
      return 3
    }
    else {
      return 2
    }
  }

  return (
    <>
      <Dialog className={classes.root}
        maxWidth="lg"
        fullWidth
        open={true}
        onClose={() => {
          onClose?.();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {resourceId ? `Edit Resource: ${resource?.name}` : 'New Resource'}
          {resource && userProfile.role === USER_ROLE_MASTER.ADMIN && <Chip label={resource.id} />}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item style={{marginTop:'38px'}}>
              <FormGenerator
                onSubmit={handleSave}
                fields={formFieldsSchema}
                formRef={formRef}
                initialValues={initialValues}
                readOnly={false}
                isValidateOnlyOnSubmit={true}
              />
            </Grid>
            <Grid item style={{flexGrow: 1}}>
              <Tabs value={activeTabIndex} onChange={(evt, newTabIndex) => {
                // console.log('tab change: ', evt, newTabIndex);
                setActiveTabIndex(newTabIndex);
              }}>
               
                {isAvailabilityAndDowntimeVisible && <Tab label="Availability" />}
                {isAvailabilityAndDowntimeVisible && <Tab label="Downtime" />}
                {resourceType === RESOURCE_TYPE.person && <Tab label="Skills" />}
                 <Tab label="Integrations" />
              </Tabs>
              <Box className={classes.tabContentWrapper}>
                <TabPanel index={setIntegrationIndex()} value={activeTabIndex} key="integrations">
                  {((resourceId && resource) || !resourceId) && <Formik
                    initialValues={resource?.integrations || selectedIntegrations}
                  >
                    {({ isSubmitting }) => (
                      <ResourceIntegrationForm onChange={(values) => {
                        setSelectedIntegrations(values);
                      }} />
                    )}
                  </Formik>}
                </TabPanel>
                {isAvailabilityAndDowntimeVisible && <TabPanel index={0} value={activeTabIndex} key="availability">
                  <ResourceAvailabilityForm initialValues={resource?.availability || null} onChange={(values) => {
                    setSelectedAvailability(values);
                  }} />
                </TabPanel>}
                {isAvailabilityAndDowntimeVisible && <TabPanel index={1} value={activeTabIndex} key="downtime"> 
                {!showDownTimeList ? <ResourceDowntimeForm initialValues={resource?.downtime}  resourseSchedule={resourceEvents}
                click={(res)=> {
                   setShowDownTimeList(true) 
                  }} onChange={(values) => {
                    setSelectedDowntime(values);
                    setIsDowntimeFormDirty(true);
                  }} /> : <ListDownTime downtime={resource?.downtime}/>
                }
                </TabPanel>}
                {resourceType === RESOURCE_TYPE.person && <TabPanel index={2} value={activeTabIndex} key="skills">
                  <ResourceSkillsForm initialValues={resource?.skills || null} onChange={(values) => {
                    setSelectedSkills(values);
                  }} />
                </TabPanel>}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>          
          <Button onClick={() => formRef.current.submitForm()} color="primary" variant="outlined" disabled={isLoading}>Save</Button>
          {isLoading && <CircularProgress size={30} color="secondary" />}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ResourceFormModal;