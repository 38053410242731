import { useRef } from 'react';
import { FormGenerator } from "formik-generator-materialui";
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';
import useBatchFormData from '../../hooks/use-batch-form-data';
import { BATCH_FORM_NAMES as FORM_NAMES } from '../../hooks/constants';

const formFieldsSchema = [
    {
        title: 'Line Setup',
        typeField: 'group',
        path: 'lineSetup',
        subfields: [
            {
                title: 'Assure the Line clearance checklist is complete and posted in the room.',
                typeField: 'group',
                path: 'lineClearanceChecklist',
                subfields: [
                    {
                        title: 'Verified',
                        typeField: 'switch',
                        path: 'lineClearanceChecklist/verified'
                    },
                    {
                        title: 'Verified By',
                        typeField: 'text',
                        path: 'lineClearanceChecklist/verifiedBy'
                    },
                    {
                        title: 'Verified On',
                        typeField: 'date',
                        simple: true,
                        path: 'lineClearanceChecklist/verifiedOn'
                    }
                ],
                col: 4
            },
            {
                title: 'Room Identifier Sign is complete and posted for the current run.',
                typeField: 'group',
                path: 'roomIdentifierSign',
                subfields: [
                    {
                        title: 'Verified',
                        typeField: 'switch',
                        path: 'roomIdentifierSign/verified'
                    },
                    {
                        title: 'Verified By',
                        typeField: 'text',
                        path: 'roomIdentifierSign/verifiedBy'
                    },
                    {
                        title: 'Verified On',
                        typeField: 'date',
                        simple: true,
                        path: 'roomIdentifierSign/verifiedOn'
                    }
                ],
                col: 4
            },
            {
                title: 'Current run is documented in the Room Usage Log.',
                typeField: 'group',
                path: 'currentRun',
                subfields: [
                    {
                        title: 'Verified',
                        typeField: 'switch',
                        path: 'currentRun/verified'
                    },
                    {
                        title: 'Verified By',
                        typeField: 'text',
                        path: 'currentRun/verifiedBy'
                    },
                    {
                        title: 'Verified On',
                        typeField: 'date',
                        simple: true,
                        path: 'currentRun/verifiedOn'
                    }
                ],
                col: 4
            },
        ]
    },
    {
        title: 'Receipt and Verification of Bulk Drug Product',
        typeField: 'arrayObject',
        path: "verification",
        emptyAddText: 'Add Row',
        subfields: [
            {
                title: 'Date',
                typeField: 'date',
                name: 'date',
                simple: true,
                col: 3
            },
            {
                title: 'Drum No.',
                typeField: 'text',
                name: 'drumNum',
                col: 3
            },
            {
                title: 'Quantity/Weight',
                typeField: 'text',
                name: 'weight',
                col: 3
            },
            {
                title: 'Bulk Lot #',
                typeField: 'text',
                name: 'bulkLotNum',
                col: 3
            },
            {
                title: 'Checked',
                typeField: 'switch',
                name: 'checked',
                col: 3
            },
            {
                title: 'Checked By',
                typeField: 'text',
                name: 'checkedBy',
                col: 3
            },
            {
                title: 'Checked',
                typeField: 'date',
                simple: true,
                name: 'checkedOn',
                col: 4
            },
            {
                title: 'Verified',
                typeField: 'switch',
                name: 'verified',
                col: 3
            },
            {
                title: 'Verified By',
                typeField: 'text',
                name: 'verifiedBy',
                col: 3
            },
            {
                title: 'Verified',
                typeField: 'date',
                simple: true,
                name: 'verifiedOn',
                col: 4
            },
        ]
    }
];

const prepareDataToEdit = (values) => {
    const data = Object.assign({}, values);
    // console.log(data);
    if (data['lineClearanceChecklist/verifiedOn'] && data['lineClearanceChecklist/verifiedOn'].seconds) {
        data['lineClearanceChecklist/verifiedOn'] = dayjs.unix(data['lineClearanceChecklist/verifiedOn'].seconds).toDate();
    }
    if (data['roomIdentifierSign/verifiedOn'] && data['roomIdentifierSign/verifiedOn'].seconds) {
        data['roomIdentifierSign/verifiedOn'] = dayjs.unix(data['roomIdentifierSign/verifiedOn'].seconds).toDate();
    }
    if (data['currentRun/verifiedOn'] && data['currentRun/verifiedOn'].seconds) {
        data['currentRun/verifiedOn'] = dayjs.unix(data['currentRun/verifiedOn'].seconds).toDate();
    }
    
    if (data['verification']) {
        const data_verification = [].concat(data['verification']);
        data_verification.forEach((row) => {
            // console.log(row.date);
            if (row.date && row.date.seconds) {
                row.date = dayjs.unix(row.date.seconds).toDate();
            }
            if (row.checkedOn && row.checkedOn.seconds) {
                row.checkedOn = dayjs.unix(row.checkedOn.seconds).toDate();
            }
        });
        data['verification'] = data_verification;
    }

    return data;
}

function ProductionSetupForm({
    orderId,
    lotId,
    onSave = null,
    onCancel = null,
    isReadOnly = false,
    isValidateOnlyOnSubmit = true
}) {
    const { batchFormData, saveBatchForm, error } = useBatchFormData(orderId, lotId, FORM_NAMES.productionSetup);
    let initialValues = {};
    if (batchFormData) {
        // TODO: Issue - when switching from one TAB to another, the date field in the arrayObject returns Invalid Date.
        initialValues = prepareDataToEdit(batchFormData);
    }

    const saveFormData = (formData) => {
        console.log(formData);
        saveBatchForm(formData).then(() => {
            onSave && onSave();
        });
    };

    const formRef = useRef(null);
    return (
        <>
            <FormGenerator
              onSubmit={(values) => {
                saveFormData(values);
              }}
              fields={formFieldsSchema}
              formRef={formRef}
              initialValues={initialValues}
              readOnly={isReadOnly}
              isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
            />
            <Button color="secondary" style={{ marginRight: '2em' }}
                onClick={() => {
                    formRef.current.resetForm();
                    onCancel && onCancel()
                }}>Cancel</Button>
            <Button variant="contained" color="primary"
                onClick={() => formRef.current.submitForm()}>Save</Button>
        </>
    );
}

export default ProductionSetupForm;