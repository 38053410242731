import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Slide from "@material-ui/core/Slide";
import WaGridCellData from './wa-grid-cell-data';
import { RESOURCE_TYPE } from '../../hooks/constants';
import useTemplates from '../../hooks/scheduler/use-templates';
import useResources from '../../hooks/scheduler/use-resources';

const useStyles = makeStyles(theme => ({
  sidePanel: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: 0,
    maxWidth: '250px',
    minWidth: '250px',
    minHeight: '77.5vh',
    maxHeight: '77.5vh',
    overflowY: 'auto',
    '& .title': {
      textTransform: 'uppercase'
    }
  },
  section: {
    '& > *': {
      display: 'flex',
      '& svg': {
        marginRight: '4px'
      },
      borderBottom: '1px solid #efefef'
    }
  },
  sectionFlex2: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      display: 'flex',
      '& svg': {
        marginRight: '4px'
      },
      borderBottom: '1px solid #efefef',
      width: '46%'
    }
  },
  sectionFlex3: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      display: 'flex',
      '& svg': {
        marginRight: '4px'
      },
      borderBottom: '1px solid #efefef',
      width: '30%'
    }
  },
  sectionTitle: {
    fontWeight: 300,
    marginBottom: theme.spacing(1),
    fontSize: '0.9em',
    textTransform: 'uppercase'
  }
}));

const PlannerGridSidePanel = ({ isVisible, contentType }) => {
  const classes = useStyles();
  const { templates } = useTemplates();
  const { cleanRooms, equipment, people } = useResources();

  return (
    <>
      {isVisible && <Slide direction="left" in={isVisible}>
        <Box p={1} ml={1} className={classes.sidePanel}>
          {contentType === 'templates' && templates && <Box> 
            <Typography variant="h6" className={classes.sectionTitle}>Templates</Typography>
            <Box className={classes.section}>
              {templates.map((t) => (
                <WaGridCellData 
                  key={`template-${t.NO_ID_FIELD}`} 
                  id={t.NO_ID_FIELD} 
                  icon={RESOURCE_TYPE.template}
                  type={RESOURCE_TYPE.template}
                  label={t.name} 
                  data={t} />
              ))} 
            </Box>
          </Box>}

          
          {contentType === 'resources' && <Box>
            <Typography variant="h6" className={classes.sectionTitle}>Clean Rooms</Typography>
            <Box mb={2} className={classes.sectionFlex2}>
              {cleanRooms?.map((r) => (
                <WaGridCellData 
                  key={`resource-cleanRoom-${r.NO_ID_FIELD}`} 
                  id={r.NO_ID_FIELD} 
                  icon={RESOURCE_TYPE.cleanRoom}
                  type={RESOURCE_TYPE.cleanRoom}
                  label={r.name} 
                  data={r} />
              ))}
            </Box>

            <Typography variant="h6" className={classes.sectionTitle}>Equipment</Typography>
            <Box mb={2} className={classes.sectionFlex3}>
              {equipment?.map((r) => (
                <WaGridCellData 
                  key={`resource-equipment-${r.NO_ID_FIELD}`} 
                  id={r.NO_ID_FIELD} 
                  icon={RESOURCE_TYPE.equipment}
                  type={RESOURCE_TYPE.equipment}
                  label={r.shortName} 
                  data={r} />
              ))}
            </Box>

            <Typography variant="h6" className={classes.sectionTitle}>People</Typography>
            <Box className={classes.sectionFlex2}>
              {people?.map((r) => (
                <WaGridCellData 
                  key={`resource-person-${r.NO_ID_FIELD}`} 
                  id={r.NO_ID_FIELD} 
                  icon={RESOURCE_TYPE.person}
                  type={RESOURCE_TYPE.person}
                  label={r.name} 
                  data={r} />
              ))}
            </Box>
          </Box>}
        </Box>
      </Slide>}
    </>
  );
}

export default PlannerGridSidePanel;