import { useEffect, useState } from 'react';
import { FIRESTORE_COLLECTIONS } from '../constants';
import { firestoreInstance } from '../../config/firebase-init';

function useSchedulerAutoAllocationConfig() {
  const autoAllocationConfigRef = firestoreInstance.collection(FIRESTORE_COLLECTIONS.schedulerAutoAllocation).doc('config');

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribeAutoAllocationConfigRef = autoAllocationConfigRef.onSnapshot((doc) => {
      setData(doc.data());
      setError(null);
    }, (err) => { setError(err); });
    
    return () => {
      unsubscribeAutoAllocationConfigRef();
    };
  }, []);

  return {
    data,
    error,
    saveConfig: (payload) => {
      return autoAllocationConfigRef.set(payload);
    }
  };
}

export default useSchedulerAutoAllocationConfig;
