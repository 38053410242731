import React, { useEffect, useState, useRef, useMemo } from "react";
import dayjs from 'dayjs';
import moment from 'moment';
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer
} from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

// import * as dates from '../../utils/big-calendar-date-utils';
import { makeStyles } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from "@material-ui/lab";
import { IconButton, TextField } from "@material-ui/core";
import { KeyboardTimePicker } from '@material-ui/pickers';
import Timer from '@material-ui/icons/Timer';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from "@material-ui/core/CircularProgress";
import TimeDurationInput from './time-duration-input';
import { ALL_DAY_DURATION, PROCESS_CODE_BIOPSY, RESOURCE_TYPE, USER_ROLE_MASTER, DAY_24HRS_MINUTES } from "../../hooks/constants";
import useUserSession from "../../hooks/use-user-session";
import useSchedulerAutoAllocationPublishStatus from "../../hooks/scheduler/use-scheduler-auto-allocation-publish-status";
import useSchedulerAutoAllocationConfig from '../../hooks/scheduler/use-scheduler-auto-allocation-config';
import useSchedulerEvents from "../../hooks/scheduler/use-scheduler-events";
import useSchedulerOrders from "../../hooks/scheduler/use-scheduler-orders";
import { doesResourceHaveConflicts, calculateResourceUtilizationInMinutes, _getResourceAvailabilityInMinutes } from '../../helpers-scheduler';
import { isEmpty, isString } from '../../helpers';
import useResourcesV2 from "../../hooks/scheduler/use-resources-v2";
import ResourceUtilizationCharts from './resource-utilization-charts';

const RIGHT_PANEL_VIEW = {
  calendar: 'calendar',
  conflicts: 'conflicts'
};

const useStyles = makeStyles(theme => ({
  root: {
    '& p': {
      marginRight: theme.spacing(2)
    },
    '& .rbc-addons-dnd-resizable': {
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  title: {
    paddingBottom: 0
  },
  // CHeckboxGroup Styles
  infoBox: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1)
    },
    marginBottom: theme.spacing(2),
    justifyContent: 'right'
  },
  checkboxLabel: {
    cursor: 'pointer',
    '& .MuiCheckbox-root': {
      padding: 0,
      paddingRight: theme.spacing(0.5)
    },
    '& p': {
      display: 'inline',
      fontSize: '0.8rem'
    }
  },
  timeInput: {
    maxWidth: '140px',
    '& label': {
      fontSize: '0.8rem'
    },
    '& input': {
      fontSize: '0.8rem'
    }
  },
  durationField: {
    border: 0,
    borderBottom: '1px solid #1f1f1f',
    margin: '0 8px',
    minWidth: '75px',
    maxWidth: '75px'
  },
  sectionTitle: {
    fontWeight: '500'
  },
  autocompleteInput: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: theme.spacing(0.5)
    },
    '& label': {
      fontWeight: 400,
      fontSize: '0.8rem',
      lineHeight: '0.65rem'
    },
    '& input': {
      fontSize: '0.8rem',
    }
  },
  separator: {
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    borderBottom: '2px dashed #cfcfcf'
  },
  timeAndDurationFieldsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const mLocalizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  });


const CheckboxGroup = ({ 
  isScheduleEventAllDayEdit, 
  conflictIndicatorByResource,
  scheduleEvent, 
  resourceType, 
  selectedResources, 
  selectedResourcesAndTimeMap,
  onChange,
  onShowConflicts
}) => {
  const classes = useStyles();
 
  return (
    <>
      {selectedResources.map((resourceCode, index) => {
        const r = selectedResourcesAndTimeMap[resourceCode];
        return (
          <Box key={`assign-${resourceType}-${index}`} display="flex" justifyContent="space-between" alignItems="end">
            <label className={classes.checkboxLabel}>
              <Checkbox
                value={r.code}
                checked={true}              
                onChange={(evt) => {
                  if (!evt.target.checked) {
                    const temp = {};
                    selectedResources.forEach(rCode => {
                      if (rCode !== evt.target.value) {
                        temp[rCode] = selectedResourcesAndTimeMap[rCode];
                      }
                    });
                    onChange?.(temp);
                  }
                }}
              />
              <Typography>{r.name}</Typography>
            </label>
            <Box className={classes.timeAndDurationFieldsWrapper}>
              {(conflictIndicatorByResource[resourceCode]?.hasConflicts || (scheduleEvent.hasResourceDowntime?.resourceCodes || [])?.indexOf(resourceCode) !== -1) ? 
                <Tooltip title="Conflict / Resource Downtime Found. Click for details.">
                  <Button 
                    size="small" 
                    color="inherit" 
                    startIcon={<WarningIcon color="error" />}
                    onClick={() => {
                      onShowConflicts(resourceCode);
                    }}
                  >Show</Button>
                </Tooltip> : 
                <Tooltip title="No conflict">
                  <CheckIcon fontSize="small" style={{ marginRight: '8px' }} htmlColor="green" onClick={() => {
                    onShowConflicts(resourceCode);
                  }} />
                </Tooltip>
              }

              {isScheduleEventAllDayEdit && <>
                <KeyboardTimePicker className={classes.timeInput} keyboardIcon={<Timer fontSize="small" />} label="Start Time" value={r.start}  
                  onChange={(date, displayVal) => {
                    if (r && date) {
                      const temp = {};
                      selectedResources.forEach(rCode => {
                        if (rCode !== r.code) {
                          temp[rCode] = selectedResourcesAndTimeMap[rCode];
                        } else {
                          temp[rCode] = Object.assign({}, r, { ...r, start: date?.toDate(), duration: r.duration });
                        }
                      });
                      onChange?.(temp);
                    }
                  }} />
                <TimeDurationInput val={r.duration} onChange={() => {

                }} />
              </>}
              {/* <select
                className={classes.durationField}
                defaultValue={r.duration}
                onChange={(evt) => {
                  if (r) {
                    const temp = {};
                    selectedResources.forEach(rCode => {
                      if (rCode !== r.code) {
                        temp[rCode] = selectedResourcesAndTimeMap[rCode];
                      } else {
                        temp[rCode] = Object.assign({}, r, { ...r, start: r.start, duration: evt.target.value });
                      }
                    });
                    onChange?.(temp);
                  }
                }}> 
                <option value="15">15 mins</option>
                <option value="30">30 mins</option>
                <option value="45">45 mins</option>
                <option value="60">60 mins</option>
                <option value="75">75 mins</option>
                <option value="90">90 mins</option>
                <option value="120">120 mins</option>
                <option value="150">150 mins</option>
                <option value="180">180 mins</option>
                <option value="1438">1438 mins</option>
              </select>*/}
            </Box>
          </Box>
        );
      })}
    </>
  );
}

const ResourceAssignmentModal = ({ 
  isVisible, 
  scheduleEvent, 
  processes, 
  clients, 
  cleanRooms, 
  equipment, 
  people, 
  onSave, 
  onClose, 
  onDelete 
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedDayOfWeek, setFormattedDayOfWeek] = useState('');
  const [selectedCleanRooms, setSelectedCleanRooms] = useState([]); // Selected Clean Room Codes
  const [selectedEquipment, setSelectedEquipment] = useState([]); // Selected Equipment Codes
  const [selectedPeople, setSelectedPeople] = useState([]); // Selected People Codes
  const [calendarEvents, setCalendarEvents] = useState([]); // events show on the day view of the react-big-calendar
  const [selectedResourcesAndTimeMap, setSelectedResourcesAndTimeMap] = useState({});
  const [scheduleEventStart, setScheduleEventStart] = useState(null); // dayjs
  const [scheduleEventEnd, setScheduleEventEnd] = useState(null); // dayjs
  const [isScheduleEventAllDayEdit, setIsScheduleEventAllDayEdit] = useState(false); // used to display Start/End time fields, so that the start/end time can be edited.
  const [isSavingData, setIsSavingData] = useState(false);
  const [conflictingEvents, setConflictingEvents] = useState([]);
  const [conflictIndicatorByResource, setConflictIndicatorByResource] = useState({});
  const [rightPanelView, setRightPanelView] = useState(RIGHT_PANEL_VIEW.calendar);
  const [selectedResourceToViewConflicts, setSelectedResourceToViewConflicts] = useState();
  const [selectedResourceToViewConflicts_stats, setSelectedResourceToViewConflicts_stats] = useState({
    utilization: 0,
    availability: 0
  });
  const componentRef = useRef();

  const { fetchResource: fetchResourceV2 } = useResourcesV2(); // This is being used because of some weird issue that causes the "equipment" type to be returned as "person"
  const { data: autoAllocationConfig } = useSchedulerAutoAllocationConfig();
  const { userProfile } = useUserSession();
  const { recalculateAndPublishSlotAvailability } = useSchedulerAutoAllocationPublishStatus();
  const { 
    deleteEventsByScheduleId, 
    saveEventResources, 
    fetchConflictingPlannerEvents, 
    bulkUpdateConflictStatus, 
    attempToResolveConflictsInOtherEvents,
    fetchPlannerEventsByResourceForADay
  } = useSchedulerEvents();
  const { deleteOrder } = useSchedulerOrders();
  const [addedResource, setAddedResource] = useState(['']);

  // console.log('conflictIndicatorByResource: ', conflictIndicatorByResource);
  // console.log('scheduleEvent: ', scheduleEvent);

  useEffect(() => {
    if (scheduleEvent) {
      const startDate = dayjs(scheduleEvent.start);
      const endDate = dayjs(scheduleEvent.end);
      setFormattedStartDate(startDate.format('MM/DD/YYYY'));
      setFormattedDayOfWeek(startDate.format('ddd'));
      
      const c = [], e= [], p = [], tempSelectedresourcesAndTimeMap = {};
      scheduleEvent?.resources?.forEach((r) => {
        if (r.type === RESOURCE_TYPE.cleanRoom) {
          c.push(r.code);
        } else if (r.type === RESOURCE_TYPE.equipment) {
          e.push(r.code);
        } else if (r.type === RESOURCE_TYPE.person) {
          p.push(r.code);
        }
        if (isEmpty(r.start)) {
          r.start = startDate.clone().toDate();
          r.duration = endDate.diff(startDate, 'minute');
        } else if (isString(r.start)) {
          r.start = dayjs(r.start).toDate();
        }
        r.allDay = !isEmpty(r.allDay) ? r.allDay : scheduleEvent.allDay;
        tempSelectedresourcesAndTimeMap[r.code] = r;
      });
      // console.log('tempSelectedresourcesAndTimeMap: ', tempSelectedresourcesAndTimeMap);
      
      setSelectedCleanRooms(c);
      setSelectedEquipment(e);
      setSelectedPeople(p);
      setSelectedResourcesAndTimeMap(tempSelectedresourcesAndTimeMap);

      setIsScheduleEventAllDayEdit(!scheduleEvent.allDay);
      if (!scheduleEvent.allDay) {
        setScheduleEventStart(dayjs(scheduleEvent.start));
        setScheduleEventEnd(dayjs(scheduleEvent.end));
      }
    }
  }, [scheduleEvent]);

  useEffect(() => {
    if (selectedResourcesAndTimeMap) {
      const tempCalendarEvents = [];
      Object.values(selectedResourcesAndTimeMap).forEach((r) => {
        tempCalendarEvents.push({
          resourceId: r.code,
          title: r.name,
          code: r.code,
          type: r.type,
          duration: r.duration,
          start: r.start,
          allDay: r.allDay,
          end: dayjs(r.start).add(r.duration, 'minute').toDate(),
        });
      });
      setCalendarEvents(tempCalendarEvents);
    }
  }, [selectedResourcesAndTimeMap]);

  useEffect(() => {
    setShow(isVisible);
  }, [isVisible]);

  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: scheduleEvent.start,
      // max: scheduleEventEnd.toDate(),
      views: Object.keys(Views).filter((v) => (v === 'DAY')).map((k) => Views[k]),
    }),
    [scheduleEvent]
  );

  useEffect(() => {
    const eventResourceCodes = Object.keys(selectedResourcesAndTimeMap);
    if (scheduleEvent?.id && eventResourceCodes.length > 0 && autoAllocationConfig) {
      // console.log('boo: selectedResourcesAndTimeMap: ', selectedResourcesAndTimeMap, scheduleEvent);
      fetchConflictingPlannerEvents(
        scheduleEvent.scheduleId, 
        scheduleEvent.id, 
        eventResourceCodes, 
        scheduleEvent.start,
        scheduleEvent.end
      ).then(data => {
        if (data.length > 0) {
          // Conflicting events found. Need to further investigate if the resourceType=person are conflicting.
          // TODO: Determine if any performance optimization can be achieved knowing that the event is an allDay event.
          // #Manual Template Event
          // - Default Duration is Full Day for Person & Resources
          // #ProKidney Template (Event may or may not be configured for the entire day)
          // - Default Duration is saved in configuration
          // Note: ProKidney uses the Biopsy Process

          const tempConflictIndicator = {};
          const defaultDurationForEvent = (scheduleEvent.processCode === PROCESS_CODE_BIOPSY ? 
              autoAllocationConfig.eventDuration[scheduleEvent.dayNum]?.duration : ALL_DAY_DURATION) || ALL_DAY_DURATION;
          const defaultDurationForPerson = (scheduleEvent.processCode === PROCESS_CODE_BIOPSY ? 
              autoAllocationConfig.peopleAllocation[scheduleEvent.dayNum]?.duration : ALL_DAY_DURATION) || ALL_DAY_DURATION;
          eventResourceCodes.map(rCode => fetchResourceV2(rCode)).forEach((masterResource) => {
            const {hasConflicts, eventsHavingResourceConflict} = doesResourceHaveConflicts(
              dayjs(scheduleEvent.start), 
              masterResource, 
              data, 
              scheduleEvent.processCode === PROCESS_CODE_BIOPSY ? 
                (masterResource.type === RESOURCE_TYPE.person ? defaultDurationForPerson : defaultDurationForEvent) : ALL_DAY_DURATION
            );
            tempConflictIndicator[masterResource.code] = {hasConflicts, eventsHavingResourceConflict};
          });
          setConflictIndicatorByResource(tempConflictIndicator);
        } else {
          // No Conflicts
          setConflictIndicatorByResource({});
        }
        setConflictingEvents(data);
      });
    } else if (scheduleEvent?.id && eventResourceCodes.length === 0 && autoAllocationConfig) {
      // No Resources are assigned to the event. Hence, no conflicts
      setConflictIndicatorByResource({});
    }
  }, [scheduleEvent, selectedResourcesAndTimeMap, autoAllocationConfig]);

  useEffect(() => {
    if (selectedResourceToViewConflicts) {
      // console.log('scheduleEvent.dayNum: ', scheduleEvent.dayNum, autoAllocationConfig?.peopleAllocation);
      
      fetchPlannerEventsByResourceForADay(selectedResourceToViewConflicts.code, scheduleEvent.startYMD).then((eventsResourceIsWorkingOn) => {
        const mins = _getResourceAvailabilityInMinutes(dayjs(scheduleEvent.start), selectedResourceToViewConflicts);
        const utilizationMins = calculateResourceUtilizationInMinutes(
          selectedResourceToViewConflicts, 
          eventsResourceIsWorkingOn, 
          autoAllocationConfig
        );
        // console.log('utilizationMins: ', utilizationMins);

        setSelectedResourceToViewConflicts_stats({
          availability: mins,
          utilization: utilizationMins
        });
      });
    } else {
      setSelectedResourceToViewConflicts_stats({
        availability: 0,
        utilization: 0
      });
    }
  }, [selectedResourceToViewConflicts?.code]);

  const {conflictFound, conflictFoundEvents} = useMemo(() => {
    let flag = false; 
    const conflictFoundEvents = [];
    Object.values(conflictIndicatorByResource).forEach(({hasConflicts, eventsHavingResourceConflict}) => {
      if (hasConflicts) {
        flag = hasConflicts;
        conflictFoundEvents.push(...eventsHavingResourceConflict);
      }
    });
    return {conflictFound: flag, conflictFoundEvents};
  }, [conflictIndicatorByResource]);

  const populateResourceWithTimeAndDuration = (r) => {
    if (scheduleEvent.allDay) {
      r.allDay = true;
    } else {
      r.allDay = false;
    }
    if (isEmpty(r.start)) {
      const scheduleEventStartDate = dayjs(scheduleEvent.start);
      const scheduleEventEndDate = dayjs(scheduleEvent.end);
      r.start = scheduleEventStartDate.toDate();
      r.duration = scheduleEventEndDate.diff(scheduleEventStartDate, 'minute');
    }

    const rData = {
      type: r.type,
      name: r.name,
      shortName: r.shortName,
      code: r.code,
      subType: r.subType || '',
      resourceId: r.resourceId || ''
    };
    if (rData.type === RESOURCE_TYPE.person) {
      rData.duration = r.duration;
      rData.allDay = r.allDay;
      rData.start = r.start;
    }
    return rData;
  };

  const handleResourceAdditionFromAutocomplete = (event, value, resourceType) => {
    let selectedResource = null;
    if (resourceType === RESOURCE_TYPE.cleanRoom) {
      selectedResource = cleanRooms.find((rec)=> rec.name == value);
      setSelectedCleanRooms(selectedCleanRooms.concat([selectedResource.code]));
    } else if (resourceType === RESOURCE_TYPE.equipment) {
      selectedResource = equipment.find((rec)=> rec.name == value);
      setSelectedEquipment(selectedEquipment.concat([selectedResource.code]));
    } else if (resourceType === RESOURCE_TYPE.person) {
      selectedResource = people.find((rec)=> rec.name == value);
      setSelectedPeople(selectedPeople.concat([selectedResource.code]));
    }

    if (selectedResource) {      
      const selectedResourceWithTime = populateResourceWithTimeAndDuration(Object.assign({}, selectedResource));
      const newSelectedResourcesMap = Object.assign({}, selectedResourcesAndTimeMap, {
        [selectedResourceWithTime.code]: selectedResourceWithTime
      });
      setSelectedResourcesAndTimeMap(newSelectedResourcesMap);
    }
  };

  const handleResourceModificationInCheckboxGroup = (modifiedSelectedResourcesAndTimeMap, resourceType) => {
    const newSelectedResourcesAndTimeMap = {};
    const modifiedSelectedResources = Object.keys(modifiedSelectedResourcesAndTimeMap);
    modifiedSelectedResources.forEach((code) => {
      newSelectedResourcesAndTimeMap[code] = modifiedSelectedResourcesAndTimeMap[code];
    });
    Object.keys(selectedResourcesAndTimeMap).forEach((code) => {
      if (selectedResourcesAndTimeMap[code].type !== resourceType) {
        newSelectedResourcesAndTimeMap[code] = selectedResourcesAndTimeMap[code];
      }
    });

    if (resourceType === RESOURCE_TYPE.cleanRoom) {
      setSelectedCleanRooms(modifiedSelectedResources);
    } else if (resourceType === RESOURCE_TYPE.equipment) {
      setSelectedEquipment(modifiedSelectedResources);
    } else if (resourceType === RESOURCE_TYPE.person) {
      setSelectedPeople(modifiedSelectedResources);
    }
    setSelectedResourcesAndTimeMap(newSelectedResourcesAndTimeMap);
  };

  const handleSave = async () => {
    setIsSavingData(true);
    // console.log('handleSave: ', scheduleEvent, selectedResourcesAndTimeMap);
    // Saving the updated Resources and the conflict status of the Event being edited
    saveEventResources(scheduleEvent.NO_ID_FIELD, Object.values(selectedResourcesAndTimeMap), conflictFound)?.then(() => {
      return recalculateAndPublishSlotAvailability();
    }).then(() => {
      // Updating the status of the events with conflicts
      if (conflictFoundEvents.length > 0) {
        return bulkUpdateConflictStatus(conflictFoundEvents, true);
      } else {
        return attempToResolveConflictsInOtherEvents(scheduleEvent);
      }
    }).then(() => {
      setIsSavingData(false);
      onSave?.();
    });
  };

  const handleDelete = async () => {
    setIsSavingData(true);
    let promise;    
    if (scheduleEvent.meta_orderId) {
      promise = deleteEventsByScheduleId(scheduleEvent.scheduleId).then(() => {
        // This planner event was created via Site Order. Hence, delete the corresponding Order as well.
        return deleteOrder(scheduleEvent.meta_orderId);
      });
    } else {
      promise = deleteEventsByScheduleId(scheduleEvent.scheduleId);
    }
 
    return promise.then(() => {
      return recalculateAndPublishSlotAvailability();
    }).then(() => {
      return attempToResolveConflictsInOtherEvents();
    }).then(() => {
      setIsSavingData(false);
      onDelete?.();
    });
  };

  /**
   * 
   * @param {dayjs} startDate 
   * @param {dayjs} endDate 
   */
  const handleCopyTimeToAllResources = () => {
    if (scheduleEventStart && scheduleEventEnd) {
      const duration = scheduleEventEnd?.diff(scheduleEventStart, 'minute');
      if (duration > 0) {
        const newSelectedResourcesAndTimeMap = {};
        Object.keys(selectedResourcesAndTimeMap).forEach((code) => {
          newSelectedResourcesAndTimeMap[code] = Object.assign({}, selectedResourcesAndTimeMap[code], {duration, allDay: false});
        });
        setSelectedResourcesAndTimeMap(newSelectedResourcesAndTimeMap);
        setFormErrors([]);
      } else {
        setFormErrors(['Event Start Time MUST be before End Time']);
      }
    } else {
      setFormErrors(['Nothing to copy. Event Start Time & Event End Time are required.']);
    }
  }

  const handleShowConflictsForResource = (resourceCode) => {
    const masterResource = fetchResourceV2(resourceCode);
    setSelectedResourceToViewConflicts(masterResource);
    setRightPanelView(RIGHT_PANEL_VIEW.conflicts);
  };

  return (
    <>
      <Dialog
        open={show}
        className={classes.root}
        maxWidth="lg"
        fullWidth
        onClose={() => {
          onClose?.();
        }}
        aria-labelledby="assign-resources-modal-title"
        aria-describedby="assign-resources-modal-description">
        <DialogTitle id="assign-resources-modal-title" className={classes.title}>
          <Box display="flex" justifyContent="space-between">
            <div>Assign Resources</div>
            {scheduleEvent?.id && <Box className={classes.infoBox}>
              {userProfile.role === USER_ROLE_MASTER.ADMIN && <>
                <Chip label={`Event #: ${scheduleEvent.id}`} />
              </>}
              {scheduleEvent?.meta_subjectId && <Chip label={`Subject ID: ${scheduleEvent?.meta_subjectId} `} />}
              <Chip label={`Process: ${processes?.find((p) => (p.code === scheduleEvent.processCode))?.name || scheduleEvent.processCode}`} />
              <Chip label={`Customer: ${clients?.find((c) => (c.code === scheduleEvent.clientCode))?.name || scheduleEvent.processCode}`} />
              <Chip label={`Day #: ${scheduleEvent.dayNum}`} />
              <Chip label={`Date: ${formattedStartDate}, ${formattedDayOfWeek}`} />
             {/* // <Chip label={`Day Of Week: ${formattedDayOfWeek}`} /> */}
            </Box>}
          </Box>
        </DialogTitle>
        <DialogContent ref={componentRef}>
          <Box display="flex">
            <Box width="40%" height="500px" overflow="auto">
              {formErrors.length > 0 && <Alert variant="outlined" color="error"> 
                {formErrors.map((err, errIdx) => <Box key={`error-${errIdx}`}>{err}</Box>)}
              </Alert>}

              {scheduleEvent?.id && <>
                {userProfile.role === USER_ROLE_MASTER.ADMIN && <Box mb={1}>
                  <Chip label={`S: ${dayjs(scheduleEvent.start).toISOString()}`}/>
                  <Chip label={`E: ${dayjs(scheduleEvent.end).toISOString()}`}/>
                </Box>}

                {isScheduleEventAllDayEdit && <Box display="flex" justifyContent="space-between">
                  <Box>
                    <KeyboardTimePicker 
                      className={classes.timeInput} 
                      keyboardIcon={<Timer fontSize="small" />} 
                      label="Event Start Time" 
                      value={scheduleEventStart}  
                      onChange={(date, displayVal) => {
                        setScheduleEventStart(date);
                      }} />
                    <KeyboardTimePicker 
                      className={classes.timeInput} 
                      keyboardIcon={<Timer fontSize="small" />} 
                      label="Event End Time" 
                      value={scheduleEventEnd}  
                      onChange={(date, displayVal) => {
                        setScheduleEventEnd(date);
                      }} />
                  </Box>
                  <Box>
                    <Button size="small" variant="outlined" color="primary" onClick={handleCopyTimeToAllResources}>Copy to all</Button>
                  </Box>
                </Box>}
                {!isScheduleEventAllDayEdit && <Alert variant="outlined" color="warning" icon={<CalendarTodayOutlinedIcon />} 
                  action={<Button onClick={() => {
                    setIsScheduleEventAllDayEdit(true);
                  }}>Modify</Button>}
                  style={{ 
                    paddingTop: '2px',
                    paddingBottom: '2px'
                  }}
                >This is an <strong>All Day</strong> Event.</Alert>}
              </>}
              <Box className={classes.separator}>
                <Box mb={1} display="flex" justifyContent="space-between">
                  <Typography className={classes.sectionTitle}>Clean Rooms</Typography>
                  <Box sx={{ width: 150 }}>
                    <Autocomplete
                      size={"small"}
                      value={addedResource}
                      disableClearable
                      onChange={(event, value) => {
                        handleResourceAdditionFromAutocomplete(event, value, RESOURCE_TYPE.cleanRoom);
                        setAddedResource([]);
                      }}
                      freeSolo
                      options={cleanRooms.filter((option) => {
                        return selectedCleanRooms.indexOf(option.code) === -1;
                      }).map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField {...params} label="Add"
                          margin="none" size="small" variant="outlined"
                          className={classes.autocompleteInput} />
                      )}
                    />
                  </Box>
                </Box>
                <CheckboxGroup 
                  isScheduleEventAllDayEdit={isScheduleEventAllDayEdit}
                  conflictIndicatorByResource={conflictIndicatorByResource}
                  resourceType={RESOURCE_TYPE.cleanRoom}
                  scheduleEvent={scheduleEvent} 
                  resources={cleanRooms}
                  selectedResources={selectedCleanRooms} 
                  selectedResourcesAndTimeMap={selectedResourcesAndTimeMap}
                  onShowConflicts={handleShowConflictsForResource}
                  onChange={(data) => {
                    handleResourceModificationInCheckboxGroup(data, RESOURCE_TYPE.cleanRoom);
                  }} />
              </Box>

              <Box className={classes.separator}>
                <Box mb={1} display="flex" justifyContent="space-between">
                  <Typography className={classes.sectionTitle}>Equipment</Typography>
                  <Box sx={{ width: 150 }} >
                    <Autocomplete
                      disableClearable
                      value={addedResource}
                      onChange={(event, value) => {
                        handleResourceAdditionFromAutocomplete(event, value, RESOURCE_TYPE.equipment);
                        setAddedResource([]);
                      }}
                      freeSolo
                      options={equipment.filter((option) => {
                        return selectedEquipment.indexOf(option.code) === -1;
                      }).map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField {...params} label="Add"
                          margin="none" size="small" variant="outlined"
                          className={classes.autocompleteInput} />
                      )}
                    />
                  </Box>
                </Box>
                <CheckboxGroup 
                  isScheduleEventAllDayEdit={isScheduleEventAllDayEdit}
                  conflictIndicatorByResource={conflictIndicatorByResource}
                  resourceType={RESOURCE_TYPE.equipment}
                  scheduleEvent={scheduleEvent} 
                  resources={equipment} 
                  selectedResources={selectedEquipment} 
                  selectedResourcesAndTimeMap={selectedResourcesAndTimeMap}
                  onShowConflicts={handleShowConflictsForResource}
                  onChange={(data) => {
                    handleResourceModificationInCheckboxGroup(data, RESOURCE_TYPE.equipment);
                  }} />
              </Box>

              <Box className={classes.separator}>
                <Box mb={1} display="flex" justifyContent="space-between">
                  <Typography className={classes.sectionTitle}>People</Typography>
                  <Box sx={{ width: 150 }}>
                    <Autocomplete
                      disableClearable
                      value={addedResource}
                      onChange={(event, value) => {
                        handleResourceAdditionFromAutocomplete(event, value, RESOURCE_TYPE.person);
                        setAddedResource([]);
                      }}
                      freeSolo
                      options={people.filter((option) => {
                        return selectedPeople.indexOf(option.code) === -1;
                      }).map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField {...params} label="Add"
                          margin="none" size="small" variant="outlined" 
                          className={classes.autocompleteInput}/>
                      )}
                    />
                  </Box>
                </Box>
                <CheckboxGroup 
                  isScheduleEventAllDayEdit={isScheduleEventAllDayEdit}
                  conflictIndicatorByResource={conflictIndicatorByResource}
                  resourceType={RESOURCE_TYPE.person}
                  scheduleEvent={scheduleEvent} 
                  resources={people} 
                  selectedResources={selectedPeople}
                  selectedResourcesAndTimeMap={selectedResourcesAndTimeMap}
                  onShowConflicts={handleShowConflictsForResource}
                  onChange={(data) => {
                    handleResourceModificationInCheckboxGroup(data, RESOURCE_TYPE.person);
                  }} />
              </Box>
            </Box>

            {/* Calendar */}
            <Box flexGrow="1">
              {rightPanelView === RIGHT_PANEL_VIEW.calendar && scheduleEvent && <DragAndDropCalendar
                style={{ height: '60vh', width: '100%' }}
                selectable
                resizable 
                onEventResize={(resizeType, { event, start, end }) => {
                  console.log('onEventResize: ', resizeType, event, start, end);
                  // TODO: Update selectedResourcesAndTimeMap
                }}
                onEventDrop={(event) => {
                  const resourceCode = event?.event?.code;
                  const newStartDate = event.start;
                  const draggedEvent = selectedResourcesAndTimeMap[resourceCode];
                  setSelectedResourcesAndTimeMap(Object.assign({}, selectedResourcesAndTimeMap, {
                    [resourceCode]: Object.assign({}, draggedEvent, { start: newStartDate })
                  }));
                }}
                components={components}
                defaultDate={defaultDate}
                events={calendarEvents}
                localizer={mLocalizer}
                toolbar={false}
                max={max}
                showMultiDayTimes
                step={60}
                views={views}
                view='day'
                onView={() => {
                  console.log('calendar view change');
                }}
              />}
              {rightPanelView === RIGHT_PANEL_VIEW.conflicts && <Box p={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>
                    <i>{selectedResourceToViewConflicts.name}</i> - 
                    {conflictIndicatorByResource[selectedResourceToViewConflicts.code]?.hasConflicts ? 
                      ' Utilization & Conflicts' : ' Utilization'}
                  </Typography>
                  <IconButton onClick={() => setRightPanelView(RIGHT_PANEL_VIEW.calendar)}>
                    <CloseIcon size="small" />
                  </IconButton>
                </Box>
                <ResourceUtilizationCharts 
                  resource={selectedResourceToViewConflicts} 
                  totalAvailability={selectedResourceToViewConflicts_stats.availability} 
                  totalUtilization={selectedResourceToViewConflicts_stats.utilization}
                  eventsTabTitle="Event Conflicts"
                  plannerEvents={conflictIndicatorByResource[selectedResourceToViewConflicts.code]?.eventsHavingResourceConflict} 
                />
              </Box>}
            </Box>
          </Box>

        </DialogContent>
        <DialogActions disableSpacing>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box>
              {userProfile.role === USER_ROLE_MASTER.ADMIN && <Button onClick={handleDelete} disabled={isSavingData} variant="outlined" color="secondary">
                Delete Schedule
              </Button>}
            </Box>
            <Box display="flex" alignItems="center">
              <Button onClick={() => { onClose?.() }}>
                Cancel
              </Button>
              <Button variant="outlined" onClick={handleSave} color="primary" disabled={isSavingData}>
                {conflictFound ? 'Save with Conflict(s)' : 'Save'}
              </Button>
              {isSavingData && <CircularProgress size={30} color="secondary" />}
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ResourceAssignmentModal;