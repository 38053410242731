import { useMemo, useState } from 'react';
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import dayjs from "dayjs";
import TabPanel from "../../design-system/tab-content-panel";
import { RESOURCE_TYPE, USER_ROLE_MASTER } from "../../hooks/constants";
import useUserSession from '../../hooks/use-user-session';

const ResourceUtilizationCharts = ({ resource, totalAvailability, totalUtilization, plannerEvents, eventsTabTitle }) => {
  const { userProfile } = useUserSession();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const {utilizationPercent} = useMemo(() => {
    return {
      utilizationPercent: ((totalUtilization*100)/totalAvailability).toFixed(2)
    };
  }, [totalUtilization, totalAvailability]); 

  return (
    <>
      {resource && 
      (resource.type === RESOURCE_TYPE.person || resource.type === RESOURCE_TYPE.cleanRoom || resource.type === RESOURCE_TYPE.equipment) && 
        <Box>
          <Tabs value={activeTabIndex} onChange={(evt, newTabIndex) => {
            setActiveTabIndex(newTabIndex);
          }}>
            <Tab label="Statistics" />
            <Tab label={`${eventsTabTitle || 'Events'} [${plannerEvents?.length || 0}]`} />
            <Tab label={`Downtime [${resource.downtime?.length || 0}]`} />
          </Tabs>
          <TabPanel index={0} value={activeTabIndex} key="statistics">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Card variant="outlined">
                  <CardHeader
                    subheader="Utilization"
                  />
                  <CardContent>
                    <Chip color="secondary" label={`${utilizationPercent}%`} />
                    <ul>
                      <li>Availability = {(totalAvailability/60)?.toFixed(2)} hrs</li>
                      <li>Utilization = {(totalUtilization/60)?.toFixed(2)} hrs</li>
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Card variant="outlined">
                  <CardHeader
                    subheader="Downtime"
                  />
                  <CardContent>
                    <Chip color="secondary" label={`${resource?.downtime?.length || 0} DAY(S)`} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel index={1} value={activeTabIndex} key="events">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Process</TableCell>
                    <TableCell>Client</TableCell>
                    <TableCell>Resources</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {plannerEvents?.map((row, rowIdx) => {
                    const startD = dayjs(row.start);
                    const endD = dayjs(row.end);
                    return (
                      <TableRow
                        key={`resource-utilization-row-${rowIdx}`}
                        className={`${
                          rowIdx === plannerEvents.length - 1
                            ? "dataRow dataRow--last"
                            : `dataRow dataRow--${rowIdx}`
                        }`}
                      >
                        <TableCell scope="row">
                          {startD.format('MM/DD/YYYY')}
                          {userProfile.role === USER_ROLE_MASTER.ADMIN && <Box>
                            <Chip label={`Event #: ${row.id}`} />
                            <Chip label={`S: ${row.start}`} />
                            <Chip label={`E: ${row.end}`} />
                          </Box>}
                        </TableCell>
                        <TableCell scope="row">
                          {row.allDay ? <Chip label="All Day" color="secondary" size="small" /> : 
                            <Chip label={`${startD.format('HH:mm A')} - ${endD.format('HH:mm A')}`} size="small" />}
                        </TableCell>
                        <TableCell scope="row">{row.processCode}</TableCell>
                        <TableCell scope="row">{row.clientCode}</TableCell>
                        <TableCell scope="row">{row.resources.map(r => r.name).join(', ')}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>

          <TabPanel index={2} value={activeTabIndex} key="downtime">
          <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resource.downtime?.map((dateISOString, rowIdx) => {
                    return (
                      <TableRow
                        key={`resource-downtime-row-${rowIdx}`}
                        className={`${
                          rowIdx === resource.downtime.length - 1
                            ? "dataRow dataRow--last"
                            : `dataRow dataRow--${rowIdx}`
                        }`}
                      >
                        <TableCell scope="row">
                          {dayjs(dateISOString).format('MM/DD/YYYY')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Box>
      }
    </>
  );
};

export default ResourceUtilizationCharts;
