import { useRef } from 'react';
import { FormGenerator } from "formik-generator-materialui";
import Button from '@material-ui/core/Button';
import * as Yup from "yup";
import { firestoreInstance } from '../../config/firebase-init';

const formFieldsSchema = [
    {
        title: '',
        typeField: 'group',
        subfields: [
            {
                title: 'Customer',
                path: 'customer',
                typeField: 'select',
                required: true,
                choices: ['Accord Healthcare', 'LGM Pharma', 'Major Pharm', 'Reckitt Benckiser', 'Antimicrobe', 'Baxter', 'Teva', 'API Distributor', 'Perrigo'],
                col: 3
            },
            {
                title: "Product Name",
                path: "product",
                typeField: "text",
                required: true,
                col: 3
            },
            {
                title: "Part #",
                path: "part",
                typeField: "text",
                required: true,
                col: 3
            },
            {
                title: "Description",
                path: "description",
                typeField: "text",
                col: 3
            }
        ]
    },
    {
        title: '',
        typeField: 'group',
        subfields: [
            {
                title: "Artwork",
                path: "artwork",
                typeField: "text",
                col: 3
            },
            {
                title: "Die Size",
                path: "dieSize",
                typeField: "text",
                col: 3
            },
            {
                title: "Tooling #",
                path: "toolingNum",
                typeField: "text",
                col: 3
            },
            {
                title: "Perf Blade",
                path: "perfBlade",
                typeField: "text",
                col: 3
            },
            {
                title: "Machine Speed Range",
                path: "machineSpeedRange",
                typeField: "text",
                col: 3
            },
            {
                title: "Seal Temp Range",
                path: "sealTempRange",
                typeField: "text",
                col: 3
            },
            {
                title: "Room Temp Range",
                path: "roomTempRange",
                typeField: "text",
                col: 3
            }
        ]
    },
    {
        title: 'Components',
        typeField: 'group',
        subfields: [
            {
                title: "Film",
                path: "components/film",
                typeField: "text",
                col: 4
            },
            {
                title: "Recommended Width",
                path: "components/film/width",
                typeField: "text",
                col: 4
            },
            {
                title: "Vendor",
                path: "components/film/vendor",
                typeField: "text",
                col: 4
            },
            {
                title: "Lidding",
                path: "components/lidding",
                typeField: "text",
                col: 4
            },
            {
                title: "Recommended Width",
                path: "components/lidding/width",
                typeField: "text",
                col: 4
            },
            {
                title: "Vendor",
                path: "components/lidding/vendor",
                typeField: "text",
                col: 4
            },
            {
                title: "Shipper",
                path: "components/shipper",
                typeField: "text",
                col: 4
            },
            {
                title: "Shipper Label",
                path: "components/shipperLabel",
                typeField: "text",
                col: 4
            }
        ]
    },
    {
        title: '',
        typeField: 'group',
        subfields: [
            {
                title: "Process Instructions",
                path: "processInstructions",
                typeField: "richTextEditor",
                col: 12
            }
        ]
    }
];

function OrderForm({
    onSave = null,
    onCancel = null,
    initialValues = {},
    isReadOnly = false,
    isValidateOnlyOnSubmit = true
}) {
    const ordersRef = firestoreInstance.collection('orders');
    
    const saveOrder = (data) => {
        if (!initialValues.NO_ID_FIELD) {
            data.orderDate = new Date();        
            ordersRef.add(data).then(() => {
                onSave && onSave(initialValues);
            }).catch((e) => {
                console.error('Error saving data: ', e);
            });
        } else {
            ordersRef.doc(initialValues.NO_ID_FIELD).set(data).then(() => {
                onSave && onSave(initialValues);
            }).catch((e) => {
                console.error('Error saving data: ', e);
            });
        }
    }

    const formRef = useRef(null);
    return (
          <>
            <FormGenerator
              onSubmit={(values) => {
                saveOrder(values);
              }}
              fields={formFieldsSchema}
              formRef={formRef}
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                  customer: Yup.string().required(),
                  product: Yup.string().required(),
                  part: Yup.string().required(),
              })}
              readOnly={isReadOnly}
              isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
            />
            <Button color="secondary" style={{ marginRight: '2em' }}
                onClick={() => { onCancel && onCancel(initialValues) }}>Cancel</Button>
            <Button variant="contained" color="primary"
                onClick={() => formRef.current.submitForm()}>Save</Button>
        </>
    )
}

export default OrderForm;