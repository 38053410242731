import { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import PersonIcon from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AppsIcon from '@material-ui/icons/Apps';

const useStyles = makeStyles(theme => ({
  waCellResource: {
    display: 'inline-flex',
    cursor: 'move',
    // borderLeft: '3px dotted #303f9f',
    paddingLeft: '3px',
    marginLeft: '3px',
    fontSize: '0.9rem',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    margin: '5px 0',
    '&:hover': {
      backgroundColor: 'inherit', // '#efefef',
      boxShadow: `rgba(0, 0, 0, 0.16) 0px 0px 5px`
    }
  },
  hide: {
    display: 'none'
  }
}));

const zoomStyles = {
  '-5': {
    fontSize: '0.6rem'
  },
  '-4': {
    fontSize: '0.6rem'
  },
  '-3': {
    fontSize: '0.6rem'
  },
  '-3': {
    fontSize: '0.6rem'
  },
  '-2': {
    fontSize: '0.7rem'
  },
  '-1': {
    fontSize: '0.8rem'
  },
  0: {},
  1: {
    fontSize: '0.9rem'
  },
  2: {
    fontSize: '0.9rem'
  },
  3: {
    fontSize: '0.9rem'
  },
  4: {
    fontSize: '0.9rem'
  },
  5: {
    fontSize: '1rem'
  }
};

// https://www.javascripttutorial.net/web-apis/javascript-drag-and-drop/#:~:text=Introduction%20to%20JavaScript%20Drag%20and%20Drop%20API&text=To%20drag%20an%20image%2C%20you,you%20would%20drag%20an%20image
// dragstart, drag, dragend
const WaGridCellResource = ({ id, type, label, icon, data, zoomLevel = 0 }) => {
  const classes = useStyles();
  const [isDragging, setIsDragging] = useState(false);

  return (
    <span 
      data-id={id}
      draggable
      className={classes.waCellResource}
      style={zoomStyles[zoomLevel]}
      onDragStart={(evt) => {
        // console.log('Resource: onDragStart: ', evt, evt.target.dataset['id'], data);
        evt.stopPropagation();
        const resourceId = evt.target.dataset['id'];
        evt.dataTransfer.setData('text/javascript', JSON.stringify({ resourceId, type, data, label }));
        setIsDragging(true);
      }}
      onDragEnd={(evt) => {
        // console.log('Resource: onDragEnd: ', evt, evt.target.dataset['id']);
        evt.stopPropagation();
        setIsDragging(false);
      }}
      onClick={(evt) => {
        // console.log('Resource: onClick: ', evt);
        // evt.stopPropagation();
      }}
      // onMouseDown={(evt) => {
      //   // console.log('Resource: onMouseDown: ', evt);
      //   evt.stopPropagation();
      // }}
      // onMouseUp={(evt) => {
      //   evt.stopPropagation();
      // }}
      onContextMenu={(evt) => {
        evt.stopPropagation();
      }}>
        {icon && icon === 'day' && <CalendarTodayOutlinedIcon fontSize="small" /> }
        {icon && icon === 'cleanRoom' && <MeetingRoomIcon fontSize="small" /> }
        {icon && icon === 'person' && <PersonIcon fontSize="small" /> }
        {icon && icon === 'template' && <AppsIcon fontSize="small" /> }
        {icon && icon === 'equipment' && <BuildIcon fontSize="small" /> }
        {icon && (icon === 'singleScheduleCapacityView' || icon === 'singlePersonCapacityView' || 
          icon === 'singleEquipmentCapacityView' || icon === 'singleCleanRoomCapacityView' || 
          icon === 'personCapacityView' || icon === 'equipmentCapacityView' || icon === 'cleanRoomCapacityView') 
          && <AccountTreeIcon htmlColor="#afafaf" fontSize="small" /> }        
        {label}
      </span>
  )
}

export default WaGridCellResource;