import { useState, useEffect } from "react";
import LockIcon from "@material-ui/icons/Lock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import NoteEditor from "../batch-form-notes/note-editor";
import useBatchFormNotes from "../../../hooks/use-batch-form-notes";
import useBatchFormData from "../../../hooks/use-batch-form-data";
import { FORM_STATUS_PERMISSION_CODE_MAP, NoteStatus, USER_ROLE_MASTER } from "../../../hooks/constants";
import useUsersSearch from "../../../hooks/use-users-search";
import usePermissions from "../../../hooks/use-permissions";
import useFormMasterList from "../../../hooks/use-form-master-list";

const useStyles = makeStyles((theme) => ({
  title: {
    "& h2": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  content: {
    minHeight: "400px",
  },
}));

function FormStatusChangeModal({
  isVisible,
  orderId,
  lotId,
  formName,
  newFormStatus,
  onClose,
  onSave,
}) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [messageHtml, setMessageHtml] = useState('');
  const [messageTokens, setMessageTokens] = useState('');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [stepNum, setStepNum] = useState(0);

  const { formsMasterMap } = useFormMasterList();
  const { searchUsersByRole } = useUsersSearch();
  const { permissionsMap } = usePermissions();
  const { saveNote } = useBatchFormNotes(orderId, lotId, formName);
  const {
    saveBatchFormStatus,
  } = useBatchFormData(orderId, lotId, formName);
  const [triggerValueChange, setTriggerValueChange] = useState(false);

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  const handleClose = () => {
    setEmail("");
    setPassword("");
    setStepNum(0);
    setTriggerValueChange(false);
    onClose && onClose();
  };

  const handleSave = async () => {
    const rolesToBeNotified = [];
    const usersToBeNotifiedMessageTokens = [];

    // Note: Computing the user emails to which the Dual Signature message is to be sent, from Permissions assigned to Roles.
    const newFormStatusIndex = formsMasterMap[formName]?.formStatuses?.indexOf(newFormStatus);
    if (newFormStatusIndex !== -1 && 
      (newFormStatusIndex < (formsMasterMap[formName]?.formStatuses?.length - 1))) {      
      const nextFormStatus = formsMasterMap[formName]?.formStatuses[newFormStatusIndex+1];
      const permissionCode = FORM_STATUS_PERMISSION_CODE_MAP[nextFormStatus];
      Object.values(USER_ROLE_MASTER).map(async (role) => {
        if (permissionsMap[permissionCode][role]) {
          rolesToBeNotified.push(role);
        }
      });

      if (rolesToBeNotified) {      
        const usersToBeNotified = await searchUsersByRole(rolesToBeNotified);
        usersToBeNotified.forEach((user) => {
          usersToBeNotifiedMessageTokens.push({
            insert: {
              mention: {
                denotationChar: '@',
                id: user.email,
                value: `${user.name} [${user.email}]`
              }
            }
          });
        });
      }
    }

    saveNote(messageHtml, usersToBeNotifiedMessageTokens.concat(messageTokens), NoteStatus.signaturePending)
      .then(() => {
        return saveBatchFormStatus(newFormStatus);
      })
      .then(() => {
        setEmail("");
        setPassword("");
        setStepNum(0);
        setTriggerValueChange(false);
        onSave && onSave();
      });
  };

  return (
    <>
      <Dialog
        variant="large"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.title}>
          <LockIcon /> Authentication Required
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            This process requires authentication to be completed. <br />
            Enter your notes below and proceed to the NEXT step.
          </DialogContentText>

          {stepNum === 0 && (
            <NoteEditor
              formName={formName}
              triggerValueChange={triggerValueChange}
              onValueChange={(html, tokens) => {
                setMessageHtml(html);
                setMessageTokens(tokens);
                setStepNum(1);
              }}
            />
          )}

          {stepNum === 1 && (
            <>
              <TextField
                autoFocus
                tabIndex="0"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                tabIndex="1"
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {stepNum === 0 && (
            <Button onClick={() => {
              setTriggerValueChange(true);
            }} variant="contained" color="primary">
              Next
            </Button>
          )}
          {stepNum === 1 && (
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FormStatusChangeModal;
