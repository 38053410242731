import create from 'zustand';

const useStore = create((set, get) => ({
    uiState: {
        selectedOrderRowIdx: null
    },
    setSelectedOrderRowIdx: (idx) => {
        set({
            uiState: Object.assign(get().uiState, { selectedOrderRowIdx: idx })
        });
    }
}));

export default useStore;