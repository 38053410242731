import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from "@material-ui/lab/Alert";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Timer from '@material-ui/icons/Timer';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { ALL_DAY_DURATION, SCHEDULER } from '../../hooks/constants';
import useSchedulerAutoAllocationConfig from '../../hooks/scheduler/use-scheduler-auto-allocation-config';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 'calc(100vw - 320px)'
  },
  table: {},
  cell: {
    textAlign: 'center',
    cursor: 'pointer'
  },
  input: {
    width: '20px',
    outline: 0,
    textAlign: 'center',
    border: 0,
    borderBottom: '1px solid #cfcfcf'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  skillWrapper: {
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: theme.spacing(2)
  },
  timeField: {
    width: '82px',
    '& label': {
      fontSize: '0.8rem'
    },
    '& input': {
      fontSize: '0.8rem'
    },
    '& button': {
      padding: 0
    }
  }
}));

const FieldDuration = ({ value, onChange }) => {
  return (
    <select 
      value={value}
      onChange={(evt) => {
        onChange?.(evt);
      }}>
      <option value="30">30 mins</option>
      <option value="60">60 mins</option>
      <option value="90">90 mins</option>
      <option value="120">120 mins</option>
      <option value="150">150 mins</option>
      <option value="180">180 mins</option>
      <option value="210">210 mins</option>
      <option value="240">240 mins</option>
      <option value="270">270 mins</option>
      <option value="300">300 mins</option>
      <option value="0">All Day</option>
    </select>
  );
};

// const ALL_DAY_MINS = '1440';

const AutoSchedulerConfigForm = ({ onSave }) => {
  const classes = useStyles();

  const [peopleSkillsAnchorEl, setPeopleSkillsAnchorEl] = useState(null);
  const [peopleSkills, setPeopleSkills] = useState([
    SCHEDULER.PEOPLE_SKILLS.beginner,
    SCHEDULER.PEOPLE_SKILLS.intermediate,
    SCHEDULER.PEOPLE_SKILLS.expert,
  ]); // TODO: Fetch People Skills dynamically from DB
  const [days, setDays] = useState([]);
  const [cleanRoomAllocation, setCleanRoomAllocation] = useState({});
  const [peopleAllocation, setPeopleAllocation] = useState({});
  const [equipmentTypeAllocation, setEquipmentTypeAllocation] = useState({});
  const [eventStartTime, setEventStartTime] = useState({});
  const [eventDuration, setEventDuration] = useState({});
  const [formErrors, setFormErrors] = useState([]);

  const {data, saveConfig} = useSchedulerAutoAllocationConfig();

  const isPeopleSkillsPopoverOpen = !!peopleSkillsAnchorEl;
  const peopleSkillsPopoverId = isPeopleSkillsPopoverOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    const tempDays = [], 
      tempCleanRoomAllocation = {}, 
      tempPeopleAllocation = {}, 
      tempEquipmentAllocation = {},
      tempEventStartTime = {},
      tempEventDuration = {};

    SCHEDULER.EQUIPMENT_TYPES.forEach(({code}) => {
      tempEquipmentAllocation[code] = {};
      for(let i = 0;i < 21;i++) {
        tempEquipmentAllocation[code][i] = {count: '0'};
      }
    });
    setEquipmentTypeAllocation(tempEquipmentAllocation);

    for(let i = 0;i < 21;i++) {
      tempDays.push(i);
      tempCleanRoomAllocation[i] = {count: '0'};
      tempPeopleAllocation[i] = {
        count: '0',
        duration: '120',
        skills: peopleSkills.map(sk => ({ skillCode: sk, flag: false }))
      };
      tempEventStartTime[i] = {time: null};
      tempEventDuration[i] = {duration: 0}; // All Day
    }
    setDays(tempDays);
    setCleanRoomAllocation(tempCleanRoomAllocation);
    setPeopleAllocation(tempPeopleAllocation);
    setEventStartTime(tempEventStartTime);
    setEventDuration(tempEventDuration);
  }, []);

  useEffect(() => {
    if (data) {
      setPeopleAllocation(data.peopleAllocation);
      setCleanRoomAllocation(data.cleanRoomAllocation);
      setEquipmentTypeAllocation(data.equipmentTypeAllocation);
      setEventStartTime(data.eventStartTime);
      setEventDuration(data.eventDuration);
    }
  }, [data]);

  const handleSaveConfig = () => {
    const errors = [];
    // Ensure the Start Time is specified if Duration isn't All Day
    days.forEach(d => {
      if ((eventDuration[d].duration != ALL_DAY_DURATION) && !eventStartTime[d].time) {
        errors.push(`Start Time is required for Day #${d+1}`);
      }
      if (+eventDuration[d].duration !== ALL_DAY_DURATION) {
        if (+peopleAllocation[d].duration > +eventDuration[d].duration) {
          errors.push(`Duration for People should be less than or equal to Duration for Day #${d+1}`);
        }
      }
    });

    setFormErrors(errors);
    if (errors.length == 0) {
      const modifiedStartTimePayload = {};
      for(let i = 0;i < 21;i++) {
        modifiedStartTimePayload[i] = eventStartTime[i].time && eventStartTime[i].time?.$d ? {time: eventStartTime[i].time.toISOString()} : eventStartTime[i];
      }

      const payload = {
        cleanRoomAllocation,
        equipmentTypeAllocation,
        peopleAllocation,
        eventStartTime: modifiedStartTimePayload,
        eventDuration
      };
      saveConfig(payload).then(() => {
        onSave?.();
      });
    }
  };

  return (
    <Box className={classes.root}>
      {formErrors.length > 0 && <Alert variant="outlined" color="error">
        {formErrors.map((error, idx) => (<div key={`error-msg-${idx}`}>{error}</div>))}
      </Alert>}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="Auto Scheduler Rules">
          <TableHead>
            <TableRow>
              <TableCell>Day #</TableCell>
              {days.map(d => (<TableCell key={`dayNum-${d}`} className={classes.cell}>{d+1}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell><strong>Duration</strong></TableCell>
              {days.map(d => (<TableCell key={`event-duration-${d}`} className={classes.cell}>
                <FieldDuration value={eventDuration[d].duration} onChange={(evt) => {
                  const val = evt.target.value;
                  const temp = Object.assign({}, eventDuration, { 
                    [d]: {duration: val}
                  });
                  setEventDuration(temp);
                }} />
              </TableCell>))}
            </TableRow>

            <TableRow>
              <TableCell><strong>Start Time</strong></TableCell>
              {days.map(d => (<TableCell key={`event-start-time-${d}`} className={classes.cell}>
                <KeyboardTimePicker 
                  label="Start" 
                  className={classes.timeField}
                  disabled={eventDuration[d].duration == '0'}
                  name={`event_start_time_${d}`} 
                  keyboardIcon={<Timer fontSize="small" />} 
                  value={eventStartTime[d].time}
                  onChange={(date, displayVal) => {
                    setEventStartTime(Object.assign({}, eventStartTime, {[d]: {time: date}}));
                  }} />
              </TableCell>))}
            </TableRow>

            <TableRow>
              <TableCell>Clean Rooms</TableCell>
              {days.map(d => (<TableCell key={`cleanRoom-count-${d}`} className={classes.cell}>
                <input type="text" 
                  value={cleanRoomAllocation[d].count}
                  maxLength="2" className={classes.input}
                  onChange={(evt) => {
                    setCleanRoomAllocation(Object.assign({}, cleanRoomAllocation, {[d]: {count: evt.target.value}}));
                  }} 
                />
              </TableCell>))}
            </TableRow>

            <TableRow>
              <TableCell>People</TableCell>
              {days.map(d => (<TableCell key={`people-count-${d}`} className={classes.cell}>
                <Box>
                  <input type="text" value={peopleAllocation[d].count} maxLength="2" className={classes.input}
                    onChange={(evt) => {
                      setPeopleAllocation(Object.assign({}, peopleAllocation, {[d]: {count: evt.target.value, duration: peopleAllocation[d].duration}}));
                    }}
                  />

                  <Box>
                    <FieldDuration value={peopleAllocation[d].duration} onChange={(evt) => {
                      const temp = Object.assign({}, peopleAllocation, { 
                        [d]: {count: peopleAllocation[d].count, duration: evt.target.value}
                      });
                      setPeopleAllocation(temp);
                    }} />
                    <i>(each)</i>
                  </Box>

                  {/* <InfoIcon id={`people-skills-icon-${d}`} fontSize="small" color="primary" onClick={(evt) => {
                    const eleId = evt.target.id || evt.target.parentNode.id;
                    if (peopleSkillsAnchorEl?.id === eleId) {
                      setPeopleSkillsAnchorEl(null);
                    } else {
                      setPeopleSkillsAnchorEl(evt.currentTarget);
                    }
                  }} /> */}
                </Box>
              </TableCell>))}
            </TableRow>

            <TableRow>
              <TableCell colSpan="22">
                <strong>Equipment</strong>
              </TableCell>
            </TableRow>            
            {SCHEDULER.EQUIPMENT_TYPES.map(({code, name}) => (<TableRow key={`${code}-row`}>
              <TableCell>{name}</TableCell>
              {days.map(d => (<TableCell key={`${code}-count-${d}`} className={classes.cell}>
                <input type="text" maxLength="2" className={classes.input}
                  value={equipmentTypeAllocation[code][d].count}
                  onChange={(evt) => {
                    const temp = Object.assign({}, equipmentTypeAllocation[code]);
                    temp[d].count = evt.target.value;
                    setEquipmentTypeAllocation(Object.assign({}, equipmentTypeAllocation, {[code]: temp}));
                  }}
                />
              </TableCell>))}
            </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <Box p={2}>
        <Button variant="contained" color="primary" onClick={handleSaveConfig}>Save</Button>
      </Box>

      <Popper
        id={peopleSkillsPopoverId}
        open={isPeopleSkillsPopoverOpen}
        anchorEl={peopleSkillsAnchorEl}
        disablePortal={false}
        onClose={() => {
          setPeopleSkillsAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2} className={classes.skillWrapper}>
          <label className={classes.label}>
            <Checkbox />
            <Typography>Beginner</Typography>
          </label>
          <label className={classes.label}>
            <Checkbox />
            <Typography>Intermediate</Typography>
          </label>
          <label className={classes.label}>
            <Checkbox />
            <Typography>Expert</Typography>
          </label>
        </Box>
      </Popper>
    </Box>
  );
}

export default AutoSchedulerConfigForm;