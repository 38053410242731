import { useEffect, useMemo, useState } from 'react';
import {SCHEDULE_TYPE, FIRESTORE_COLLECTIONS } from "../constants";
import { firestoreInstance } from '../../config/firebase-init';
import { formatDateToISOYMD, isEmpty, isString } from '../../helpers';

const useResourceSchedule = (resourceCode, startDate = null, endDate = null) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const eventsRef = firestoreInstance.collection(FIRESTORE_COLLECTIONS.schedulerEvents);
  
  useEffect(() => {
    if (resourceCode) {
      let queryRef = eventsRef
        .where('active', '==', true)
        .where('scheduleType', '==', SCHEDULE_TYPE.PLANNER)
        .where("resourceCodes", "array-contains", resourceCode);

      if (startDate && endDate) {

        const startDateISOString = isString(startDate) ? startDate : startDate.toISOString();
        const endDateISOString = isString(endDate) ? endDate : endDate.toISOString();
        queryRef = queryRef.where('start', '>=', startDateISOString).where('start', '<=', endDateISOString).get();
        queryRef = queryRef.where('end', '>=', startDateISOString).where('end', '<=', endDateISOString).get();

        // Get MMDDYYYY representation of the ISO Date
        queryRef.where('allDay', '==', true).where('startYMD', '==', formatDateToISOYMD(startDateISOString)).get();
      }

      const unsubscribeEventsRef = queryRef.onSnapshot((res) => {
        if (!res.empty) {
          const dataRecords = res.docs.map((doc) => {
            return { ...doc.data(), NO_ID_FIELD: doc.id };
          });
          setData(dataRecords);
        } else {
          setData([]);
        }
        setError(null);
      }, (err) => { setError(err); });

      return () => {
        unsubscribeEventsRef();
      };
    }
  }, [resourceCode, startDate, endDate]);

  return [...new Set(data?.map(r => r.startYMD))]
}

export default useResourceSchedule;