import { useRef } from 'react';
import dayjs from 'dayjs';
import { FormGenerator } from "formik-generator-materialui";
import Button from '@material-ui/core/Button';
import useBatchFormData from '../../hooks/use-batch-form-data';
import { BATCH_FORM_NAMES as FORM_NAMES } from '../../hooks/constants';

const formFieldsSchema = [
    {
        title: '',
        typeField: 'group',
        subfields: [
            {
                title: 'Process Done?',
                typeField: 'switch',
                path: 'isVerified',
                col: 6
            },
            {
                title: 'Date',
                typeField: 'date',
                path: 'date',
                simple: true,
                col: 6
            }
        ]
    }
];

const prepareDataToEdit = (values) => {
    const data = Object.assign({}, values);
    if (data['date'] && data['date'].seconds) {
        data['date'] = dayjs.unix(data['date'].seconds).toDate();
    }
    return data;
}

function ManualProcessOneForm({
    orderId,
    lotId,
    onSave = null,
    onCancel = null,
    isReadOnly = false,
    isValidateOnlyOnSubmit = true,
    isPreview = false
}) {
    const { batchFormData, saveBatchForm, error } = isPreview ? { batchFormData: null, saveBatchForm: null, error: null  } : useBatchFormData(orderId, lotId, FORM_NAMES.manualProcessOne);
    let initialValues = {};
    if (batchFormData) {
        initialValues = prepareDataToEdit(batchFormData);
    }

    const saveFormData = (formData) => {
        saveBatchForm(formData).then(() => {
            onSave && onSave();
        });
    };

    const formRef = useRef(null);
    return (
        <>
            <FormGenerator
              onSubmit={(values) => {
                saveFormData(values);
              }}
              fields={formFieldsSchema}
              formRef={formRef}
              initialValues={initialValues}
              readOnly={isReadOnly}
              isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
            />
            {!isPreview && <>
                <Button color="secondary" style={{ marginRight: '2em' }}
                    onClick={() => {
                        formRef.current.resetForm();
                        onCancel && onCancel();
                    }}>Cancel</Button>
                <Button variant="contained" color="primary"
                    onClick={() => formRef.current.submitForm()}>Save</Button>
            </>}
        </>
    );
}

export default ManualProcessOneForm;