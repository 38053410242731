import { useEffect, useState } from 'react';
import { firestoreInstance } from '../config/firebase-init';

export default function useBatchFormNoteData(orderId, lotId, formName, noteId) {
    const batchFormNoteRef = noteId ? firestoreInstance
        .collection('orders').doc(orderId)
        .collection('lots').doc(lotId)
        .collection('batchForms').doc(formName)
        .collection('notes').doc(noteId) : null;
    const streamNotesRef = firestoreInstance.collection('streamNotes');

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (noteId) {
            const unsubscribeBatchFormNoteRef = batchFormNoteRef.onSnapshot((doc) => {
                setData(doc.data());
                setError(null);
            }, (err) => { setError(err); });
            
            return () => {
                unsubscribeBatchFormNoteRef();
            };
        }
    }, [noteId]);

    return {
        noteData: data,
        updateNoteStatus: (status) => {
            return batchFormNoteRef.update({ status: status }).then(() => {
                return true;
            }).then(() => {
                return streamNotesRef.where('originalNoteId', '==', noteId).get().then((res) => {
                    if (res.docs.length > 0) {
                        return res.docs[0].ref.update({
                            status: status
                        });
                    }
                });
            }).catch((error) => {
                console.error(`Error saving ${formName} notes! `, error);
                return Promise.reject(error);
            });
        },
        error,
    }
}