import { useState, useEffect } from "react";
import { FormDialogue } from "formik-generator-materialui";
import * as Yup from "yup";
import useOrderLotList from '../../hooks/use-order-lot-list';
import useBatchMasterList from "../../hooks/use-batch-master-list";

const formFieldsSchema = [
  {
    title: "Lot #",
    path: "lotNum",
    typeField: "text",
    required: true,
  },
  {
    title: "Batch #",
    path: "batchNum",
    typeField: "text",
    required: true,
  },
  {
    title: "Expiration Date",
    path: "expDate",
    typeField: "date",
    required: true,
    simple: true,
  },
  {
    title: 'EBR Template',
    path: 'ebrTemplate',
    typeField: 'select',
    choices: []
  }
];

function LotFormModal({
  isVisible,
  initialValues = {},
  orderData = {},
  onClose = null,
  onSave = null,
}) {
  const { createLot, updateLot } = useOrderLotList(orderData.NO_ID_FIELD);
  const { batchesMasterList } = useBatchMasterList();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (batchesMasterList) {
      formFieldsSchema[3].choices = batchesMasterList.map((batchMasterRec) => {
        return batchMasterRec.batchSlug;
      });
    }
  }, [batchesMasterList]);

  const saveLot = (data) => {
    if (!initialValues.NO_ID_FIELD) {
      createLot(data).then(() => {
        onSave && onSave(initialValues);
      });
    } else {
      updateLot(initialValues.NO_ID_FIELD, data).then(() => {
        onSave && onSave(initialValues);
      });
    }
  };

  return (
    <>
      {batchesMasterList && <FormDialogue
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
          onClose & onClose();
        }}
        okText="Save"
        onOk={(values) => {
          saveLot(values);
        }}
        title={`${initialValues.NO_ID_FIELD ? 'Edit Lot' : 'New Lot'}`}
        text={`Product: ${orderData.product}`}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          lotNum: Yup.string().required(),
          batchNum: Yup.string().required(),
          expDate: Yup.date().required(),
        })}
        fields={formFieldsSchema}
      />}
    </>
  );
}

export default LotFormModal;
