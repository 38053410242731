import dayjs from 'dayjs';
import produce from 'immer';
import { RESOURCE_TYPE, DAY_24HRS_MINUTES, ALL_DAY_DURATION, PROCESS_CODE_BIOPSY } from './hooks/constants';

/**
 * Returns a filtered list of Resources that are eligible to be assigned to Events.
 * - Resource Type MUST BE of type cleanRoom, Equipment or Person
 * - Resource MUST BE active
 * - Resource MUST BE working atleast one day in a week
 * 
 * @param {array[{active: boolean, availability: {}, }]} resources 
 * @returns 
 */
function _parseResourcesInput(resources) {
  const daysOfWeek = ['sun','mon','tue','wed','thu','fri','sat'];
  const relevantResourceTypes = [RESOURCE_TYPE.cleanRoom, RESOURCE_TYPE.equipment, RESOURCE_TYPE.person];

  let isValid = (resources.length > 0);
  if (isValid) {
    const filteredResources = resources.filter(resource => {
      return resource.active && resource.availability && (relevantResourceTypes.indexOf(resource.type) !== -1);
    });
    isValid = filteredResources.length > 0; // There is atleast 1 active resource with defined availability

    if (isValid) {
      const workingResources = filteredResources.filter(resource => {
        return daysOfWeek.some(dow => {
          return (resource.availability[`${dow}_isWorking`]); // && resource.availability[`${dow}_from`] && resource.availability[`${dow}_to`]);
        });
      });
      isValid = workingResources.length > 0; // There is atleast 1 working resource
      return { isValid, workingResources };
    }
  }
  return {isValid, workingResources: null};
}

/**
 * 
 * @param {dayjs} date 
 * @param {object} resources 
 * @param {number} dayNumIdx 
 * @param {object} autoAllocationConfig 
 * @returns 
 */
function _filterAssignableResources(date, resources, dayNumIdx, autoAllocationConfig) {
  return resources.filter(resource => {
    return _isResourceAvailable(date, resource) && // Resource is working and does not have a downtime
      !_doesResourceHaveExistingAssignment(date, resource, dayNumIdx, autoAllocationConfig); // Resource is not already assigned to another event
  });
}

/**
 * A Resource is considered to be availabile if:
 * - Resource is working on the given date
 * - Resource does not have a downtime on the given date
 * 
 * Does not take into account existing assignments for the Resource. 
 * 
 * @param {dayjs} date 
 * @param {*} resource 
 * @returns {boolean} - **true** if the resource is working on the given date and doesn't have a downtime.
 * 
 * @see _filterAssignableResources()
 * @see _doesResourceHaveExistingAssignment()
 */
function _isResourceAvailable(date, resource) {
  const dayOfWeek = (date.format('ddd') || '').toLowerCase();
  const isWorking = resource.availability[`${dayOfWeek}_isWorking`];
  if (isWorking) {
    // const flag_noDowntime = resource.downtime.indexOf(date.format('MMDDYYYY')) === -1; // No downtime
    const dateStrMDY = date.format('MMDDYYYY');
    // Note: Resource Downtime is an array of Dates in ISO format, idnicating if the resource is off on that day.
    const flag_noDowntime = resource.downtime.every(dt => dayjs(dt).format('MMDDYYYY') !== dateStrMDY); // No downtime for the given date
    return flag_noDowntime;
  }
  return false;
}

let isEventsMapByResourceInitialized = false;
let eventsMapByResource = {
  //'date': {'resource-code': [eventObj_1, eventObj_2]}
};

/**
 * Generates a Map stored globally in this file. 
 * @see eventsMapByResource
 * {
 *   'MMDDYYYY': {
 *     'resource-code': [eventObj_1, eventObj_2]
 *   }
 * }
 * @param {*} events 
 */
function _populateEventsMapByResource(events) {
  // console.log('_populateEventsMapByResource: ', events, 'isEventsMapByResourceInitialized: ', isEventsMapByResourceInitialized);
  if (!isEventsMapByResourceInitialized) {
    events.forEach(event => {
      const eventStartDateStrMDY = dayjs(event.start).format('MMDDYYYY');
      if (!eventsMapByResource[eventStartDateStrMDY]) {
        eventsMapByResource[eventStartDateStrMDY] = {};
      }
      event.resources.forEach(resource => {
        if (!eventsMapByResource[eventStartDateStrMDY][resource.code]) {
          eventsMapByResource[eventStartDateStrMDY][resource.code] = [];
        }
        eventsMapByResource[eventStartDateStrMDY][resource.code].push(event);
      });
    });
    isEventsMapByResourceInitialized = true;
  }
}

/**
 * Checks if a Resource has an existing assignment for the given date.
 * @param {dayjs} date
 * @param {object} resource 
 * @param {number} dayNumIdx - Used only for **resourceType=person**
 * @param {object} autoAllocationConfig - Used only for **resourceType=person**
 * @returns {boolean} - **true** if the resource has an existing assignment for the given date.
 */
function _doesResourceHaveExistingAssignment(date, resource, dayNumIdx, autoAllocationConfig) {
  const dateStrMDY = date.format('MMDDYYYY');
  const flag = eventsMapByResource[dateStrMDY]?.[resource.code]?.length > 0; // no existing assignments

  if (flag) {
    // Note: Resource has an assignment on that date. 
    // Note: Auto Allocation Config: Check if the resource is required for a specific duration. Example: ResourceType=person is required for 2 hours
    // - Auto Allocation Config: If Yes, then check if the resource has maxed out availability 
    // -- Schedule Event: Calculate the utilization of the resource for the given date
    // --- Use the StartTime/Duration of the resource if entered in the existing Event (for the given date)
    // --- If StartTime/Date is not entered (for the event on the given date), then use the default Resource Duration from the Auto-Allocation configuration.
    // --- Take into account the StartTime & Duration of the event (dayNum) configured in Auto-Allocation Config
    // -- Resource Availability: If Start/End time availability is not supplied, then Resource is considered working for 24 hours.
    // -- Resource Availability: If Start/End Time availability is supplied, then take that into account
    // - If No, then return that resource has existing assignment
    if (resource.type === RESOURCE_TYPE.person) {
      const defaultRequiredPersonDuration = +autoAllocationConfig?.peopleAllocation[dayNumIdx]?.duration;
      // Note: defaultRequiredPersonDuration=0 indicates resource is required for the entire day
      if (defaultRequiredPersonDuration > 0) {
        const minutesUtilized = _getResourceUtilizationInMinutes(date, resource, defaultRequiredPersonDuration);
        const availableMinutes = _getResourceAvailabilityInMinutes(date, resource);
        if (defaultRequiredPersonDuration + minutesUtilized <= availableMinutes) {
          // Note: defaultRequiredPersonDuration is always less than the Event Duration itself.
          // Indicates that the resource still has availability for another event.
          return false;
        }
      }
    }
  }
  return flag;
}

/**
 * @param {dayjs} date
 * @param {object} resource 
 * @param {object[]} existingEvents - Events array for which the **resources** has assigningments for the given date.
 * @param {number} defaultRequiredPersonDuration - Duration the resource is required for. Used only for **resourceType=person**
 * @returns {boolean} - **true** if resource has availability
 */
export function doesResourceHaveConflicts(date, resource, existingEvents, defaultRequiredPersonDuration) {
  let hasConflicts = existingEvents.length > 0;
  let eventsHavingResourceConflict = [];

  if (hasConflicts) {
    eventsHavingResourceConflict = existingEvents.filter(event => {
      return (event.resourceCodes.indexOf(resource.code) !== -1);
    });

    if (eventsHavingResourceConflict.length > 0) {
      // Note: Resource has an assignment on that date. 
      // Note: Auto Allocation Config: Check if the resource is required for a specific duration. Example: ResourceType=person is required for 2 hours
      // - Auto Allocation Config: If Yes, then check if the resource has maxed out availability 
      // -- Schedule Event: Calculate the utilization of the resource for the given date
      // --- Use the StartTime/Duration of the resource if entered in the existing Event (for the given date)
      // --- If StartTime/Date is not entered (for the event on the given date), then use the default Resource Duration from the Auto-Allocation configuration.
      // --- Take into account the StartTime & Duration of the event (dayNum) configured in Auto-Allocation Config
      // -- Resource Availability: If Start/End time availability is not supplied, then Resource is considered working for 24 hours.
      // -- Resource Availability: If Start/End Time availability is supplied, then take that into account
      // - If No, then return that resource has existing assignment
      if (resource.type === RESOURCE_TYPE.person) {
        if (defaultRequiredPersonDuration > ALL_DAY_DURATION) {
          const minutesUtilized = calculateResourceUtilizationInMinutes_fromEvents(resource, existingEvents, defaultRequiredPersonDuration);
          const availableMinutes = _getResourceAvailabilityInMinutes(date, resource);
          if (defaultRequiredPersonDuration + minutesUtilized <= availableMinutes) {
            // Note: defaultRequiredPersonDuration is always less than the Event Duration itself.
            // Indicates that the resource still has availability for another event.
            hasConflicts = false;
          }
        }
      }
    } else {
      hasConflicts = false;
    }
  }
  return {hasConflicts, eventsHavingResourceConflict};
}

/**
 * This function shouldn't be invoked if ```defaultRequiredPersonDuration=0```. This means resource assignment is for the entire day.
 * @param {*} resource 
 * @param {*} existingEvents 
 * @param {*} defaultRequiredPersonDuration 
 * @returns 
 */
export function calculateResourceUtilizationInMinutes_fromEvents(resource, existingEvents, defaultRequiredPersonDuration) {
  let minutesUtilized = 0;
  existingEvents.forEach(event => {
    const rFound = event.resources.find(r => r.code === resource.code);
    if (rFound?.start && rFound.duration) {
      minutesUtilized += +rFound.duration;
    } else {
      minutesUtilized += defaultRequiredPersonDuration;
    }
  });
  // console.log('_getResourceUtilizationInMinutes: ', resource.name, minutesUtilized);
  return minutesUtilized;
}

/**
 * Returns the availbility of the Resource in minutes for the day
 * @param {dayjs} date 
 * @param {object} resource 
 * @returns 
 */
export function _getResourceAvailabilityInMinutes(date, resource) {
  const dayOfWeek = (date.format('ddd') || '').toLowerCase();
  return resource.availability[`${dayOfWeek}_workingMinutes`] || 0;
};

export function _getResourceUtilizationInMinutes(date, resource, defaultRequiredPersonDuration) {
  const dateStrMDY = date.format('MMDDYYYY');
  let minutesUtilized = 0;
  eventsMapByResource[dateStrMDY]?.[resource.code]?.forEach(event => {
    const rFound = event.resources.find(r => r.code === resource.code);
    if (rFound?.start && rFound.duration) {
      minutesUtilized += +rFound.duration;
    } else {
      minutesUtilized += defaultRequiredPersonDuration === ALL_DAY_DURATION ? DAY_24HRS_MINUTES : defaultRequiredPersonDuration;
    }
  });
  // console.log('_getResourceUtilizationInMinutes: ', resource.name, minutesUtilized);
  return minutesUtilized;
}

/**
 * @param {object} resource
 * @param {object[]} plannerEvents - Events from which the resource utilization is to be calculated.
 * @param {object} autoAllocationConfig 
 */
export function calculateResourceUtilizationInMinutes(resource, plannerEvents, autoAllocationConfig) {
  let resourceTotalUtilization = 0;
  plannerEvents.forEach(event => {
    const resourceFoundInEvent = event.resources.find(r => r.code === resource.code);
    if (resourceFoundInEvent) {
      const defaultRequiredPersonDuration = event.processCode === PROCESS_CODE_BIOPSY ?
          autoAllocationConfig?.peopleAllocation[event.dayNum-1]?.duration : DAY_24HRS_MINUTES;
      const defaultRequiredEventDuration = event.allDay ? 
        DAY_24HRS_MINUTES : dayjs(event.start).diff(dayjs(event.end), 'minute');
      
      // Note: defaultRequiredResourceDuration - 24 HOURS for Manual Template Event. Configured HOURS for ProKidney Template Event.
      const defaultRequiredResourceDuration = resource.type === RESOURCE_TYPE.person ? defaultRequiredPersonDuration : defaultRequiredEventDuration;
      // console.log('resourceFoundInEvent: ', resourceFoundInEvent.start, resourceFoundInEvent.duration, defaultRequiredResourceDuration);
      if (resourceFoundInEvent?.start && resourceFoundInEvent.duration) {
        resourceTotalUtilization += +resourceFoundInEvent.duration;
      } else {
        resourceTotalUtilization += +defaultRequiredResourceDuration;
      }
      // console.log('helper: resourceTotalUtilization: ', resourceTotalUtilization);
    }
  });
  return resourceTotalUtilization;
}

/**
 * Calculates the number of slots available for a given date
 * @param {Date} date - Javascript Date object. Date for which slot availability is to be calculated.
 * @param {object} autoAllocationConfig - {cleanRoomAllocation, equipmentTypeAllocation, peopleAllocation, eventDuration, eventStartTime}
 * @param {object} blockedDates - {'MMDDYYYY': true}
 * @param {*} workingResources 
 * @param {*} assignableCleanRoomsCountByDate 
 * @param {*} assignablePeopleCountCountByDate 
 * @param {*} assignableEquipmentTypesCountByDate 
 * @returns 
 */
function _generateSlotAvailabilityForADate(
  dayNumIdx,
  date, 
  autoAllocationConfig, 
  blockedDates, 
  workingResources, // working resources seggregated by type & sub-type
  assignableCleanRoomsCountByDate, 
  assignablePeopleCountByDate, 
  assignableEquipmentTypesCountByDate
  ) {
  const dStr = date.toISOString();

  const { cleanRoomAllocation, peopleAllocation, equipmentTypeAllocation } = autoAllocationConfig;
  const {
    cleanRooms,
    equipment,
    people,
    equipmentByType
  } = workingResources;

  let isDateBlocked = false, isResourcesUnavailable = false, numSlotsForSchedule = 99;
  for(let i = 0;i < 21;i++) {
    const dateInSchedule = dayjs(dStr).add(i,'day');
    const dateInScheduleStr = dateInSchedule.format('MMDDYYYY');

    // Ensure that the Date is not blocked
    if (blockedDates?.length > 0) {
      if (blockedDates.indexOf(dateInScheduleStr) !== -1) {
        isDateBlocked = true;
        break;
      }
    }

    let numSlotsForTheDay = 9999;

    // Clean Rooms Assignment
    const requiredCleanRoomCount = cleanRoomAllocation[i].count;
    if (!assignableCleanRoomsCountByDate[dateInScheduleStr] && assignableCleanRoomsCountByDate[dateInScheduleStr] !== 0) {
      const assignableCleanRooms = _filterAssignableResources(dateInSchedule, cleanRooms || [], dayNumIdx, autoAllocationConfig);
      // console.log('assignableCleanRooms: ', dateInScheduleStr, assignableCleanRooms);
      assignableCleanRoomsCountByDate[dateInScheduleStr] = assignableCleanRooms.length;
    }
    if (assignableCleanRoomsCountByDate[dateInScheduleStr] < requiredCleanRoomCount) {
      isResourcesUnavailable = true;
      break;
    }
    if (assignableCleanRoomsCountByDate[dateInScheduleStr]/requiredCleanRoomCount < numSlotsForTheDay) {
      numSlotsForTheDay = Math.floor(assignableCleanRoomsCountByDate[dateInScheduleStr]/requiredCleanRoomCount);
    }

    // People Assignment
    const requiredPeopleCount = peopleAllocation[i].count;
    if (!assignablePeopleCountByDate[dateInScheduleStr] && assignablePeopleCountByDate[dateInScheduleStr] !== 0) {
      const assignablePeople = _filterAssignableResources(dateInSchedule, people || [], dayNumIdx, autoAllocationConfig);
      // console.log('assignablePeople: ', assignablePeople.length, 'dayNum: ', i, 'date: ', dateInScheduleStr); // assignablePeople.map(p => p.code));
      assignablePeopleCountByDate[dateInScheduleStr] = assignablePeople.length;
      // Note: This below commented out logic doesn't seem to be required, because:
      // - if 4 persons are required for 2 hours each
      // - we can't really suggest using 1 person for 8 hours
      // assignablePeopleCountByDate[dateInScheduleStr] = 0;
      // const defaultRequiredPersonDuration = autoAllocationConfig?.peopleAllocation[dayNumIdx]?.duration;      
      // assignablePeople.forEach(person => {
      //   const personAvailability = _getResourceAvailabilityInMinutes(dateInSchedule, person);
      //   assignablePeopleCountByDate[dateInScheduleStr] += Math.floor(personAvailability/defaultRequiredPersonDuration);
      // });
    }
    if (assignablePeopleCountByDate[dateInScheduleStr] < requiredPeopleCount) {
      isResourcesUnavailable = true;
      break;
    }
    if (assignablePeopleCountByDate[dateInScheduleStr]/requiredPeopleCount < numSlotsForTheDay) {
      numSlotsForTheDay = Math.floor(assignablePeopleCountByDate[dateInScheduleStr]/requiredPeopleCount);
    }

    // Equipment Assignment
    Object.keys(equipmentTypeAllocation).find(equipmentType => {
      const requiredEquipmentTypeCount = equipmentTypeAllocation[equipmentType][i].count;
      if (!assignableEquipmentTypesCountByDate[dateInScheduleStr]) {
        assignableEquipmentTypesCountByDate[dateInScheduleStr] = {};
      }
      if (!assignableEquipmentTypesCountByDate[dateInScheduleStr][equipmentType] && assignableEquipmentTypesCountByDate[dateInScheduleStr][equipmentType] !== 0) {
        const assignableEquipment = _filterAssignableResources(dateInSchedule, equipmentByType[equipmentType] || [], dayNumIdx, autoAllocationConfig);
        assignableEquipmentTypesCountByDate[dateInScheduleStr][equipmentType] = assignableEquipment.length;
      }
      if (assignableEquipmentTypesCountByDate[dateInScheduleStr][equipmentType] < requiredEquipmentTypeCount) {
        isResourcesUnavailable = true;
        // Stop iterating as a required resource is unavailable
        return true;
      }
      if (assignableEquipmentTypesCountByDate[dateInScheduleStr][equipmentType]/requiredEquipmentTypeCount < numSlotsForTheDay) {
        numSlotsForTheDay = Math.floor(assignableEquipmentTypesCountByDate[dateInScheduleStr][equipmentType]/requiredEquipmentTypeCount);
      }
    });

    if (isResourcesUnavailable) {
      break;
    } else {
      if (numSlotsForTheDay < numSlotsForSchedule) {
        numSlotsForSchedule = numSlotsForTheDay;
      }
    }
  }

  if (isDateBlocked || isResourcesUnavailable ||  numSlotsForSchedule === 9999) {
    return {date: dStr, numSlots: 0};
  } else {
    return {date: dStr, numSlots: numSlotsForSchedule};
  }
}

/**
 * Parses the input array and categories the resources into 
 * - cleanRooms
 * - equipment
 * - people
 * - equipmentByType
 * @param {*} parsedResourceInput - {isValid: boolean, workingResources: []}
 * @returns 
 * 
 * @see _parseResourcesInput()
 */
function _getSeggreatedResourcesByTypeAndSubType(parsedResourceInput) {
  const cleanRooms = [], equipment = [], people = [], equipmentByType = {};
  if (parsedResourceInput.isValid) {
    parsedResourceInput.workingResources.forEach(resource => {
      if (resource.type === RESOURCE_TYPE.cleanRoom) {
        cleanRooms.push(resource);
      } else if (resource.type === RESOURCE_TYPE.equipment) {
        equipment.push(resource);
        if (!equipmentByType[resource.subType]) { 
          equipmentByType[resource.subType] = [];
        }
        equipmentByType[resource.subType].push(resource);
      } else if (resource.type === RESOURCE_TYPE.person) {
        people.push(resource);
      }
    });
  }

  return {
    cleanRooms,
    equipment,
    people,
    equipmentByType
  };
}

/**
 * 
 * @param {Date[]} datesArray - Array of Javascript Date Objects
 * @param {object} autoAllocationConfig 
 * @param {string[]} blockedDates - String = MMDDYYYY
 * @param {object[]} resources 
 * @returns 
 */
export function generateSlotAvailability(datesArray, autoAllocationConfig, blockedDates, resources, events = [], testData = null) {
  const parsedResourceInput = _parseResourcesInput(resources);
  const { cleanRooms, equipment, people, equipmentByType } = _getSeggreatedResourcesByTypeAndSubType(parsedResourceInput);

  const assignableCleanRoomsCountByDate = testData?.assignableCleanRoomsCountByDate || {}, 
    assignablePeopleCountByDate = testData?.assignablePeopleCountByDate || {}, 
    assignableEquipmentTypesCountByDate = testData?.assignableEquipmentTypesCountByDate || {};

  _populateEventsMapByResource(events);
  
  const results = datesArray.map((date, dayNumIdx) => {
    const dStr = date.toISOString();
    if (!parsedResourceInput.isValid) {
      return {date: dStr, numSlots: 0};
    }
    const result = _generateSlotAvailabilityForADate(dayNumIdx, date, autoAllocationConfig, blockedDates, {
      cleanRooms,
      equipment,
      people,
      equipmentByType
    }, assignableCleanRoomsCountByDate, assignablePeopleCountByDate, assignableEquipmentTypesCountByDate);

    return result;
  });
  // console.log('assignablePeopleCountByDate: ', assignablePeopleCountByDate);
  // console.log('eventsMapByResource: ', eventsMapByResource);
  isEventsMapByResourceInitialized = false;
  eventsMapByResource = {};
  return results;
}

/**
 * 
 * @param {number} daySequence 
 * @param {dayjs} date 
 * @param {object} resources 
 * @param {object} autoAllocationConfig 
 * @param {object[]} events 
 * @param {boolean} isAllDayEvent - Is the event for which the resources are being picked, All Day Event?
 * @returns 
 */
export function pickResourcesForSlotAssignment(daySequence, date, resources, autoAllocationConfig, events, isAllDayEvent) {
  _populateEventsMapByResource(events);

  const parsedResourceInput = _parseResourcesInput(resources);
  const nonConflictingWorkingResources = parsedResourceInput.workingResources.filter(resource => {
    return !_doesResourceHaveExistingAssignment(date, resource, daySequence, autoAllocationConfig);
  });

  // Note: Don't pick resources already assigned to schedule
  const { cleanRooms, equipment, people, equipmentByType } = _getSeggreatedResourcesByTypeAndSubType({
    isValid: parsedResourceInput.isValid, 
    workingResources: nonConflictingWorkingResources
  });
  // console.log('investigate: ', cleanRooms, equipment, people);

  const { cleanRoomAllocation, peopleAllocation, equipmentTypeAllocation } = autoAllocationConfig;

  let assignedResources = [];
  const requiredCleanRoomCount = cleanRoomAllocation[daySequence].count;
  if (cleanRooms.length >= requiredCleanRoomCount) {
    assignedResources = assignedResources.concat(cleanRooms.splice(0, requiredCleanRoomCount));
  }

  const requiredPeopleCount = peopleAllocation[daySequence].count;
  if (people.length >= requiredPeopleCount) {
    const defaultRequiredPersonDuration = +autoAllocationConfig?.peopleAllocation[daySequence]?.duration;

    // Sorting person resources in ascending order of utilization
    const sortedPeople = people.map(person => {
      const minsUtilized = _getResourceUtilizationInMinutes(date, person, defaultRequiredPersonDuration);
      return {minsUtilized, person};
    }).sort((a, b) => {
      return a.minsUtilized - b.minsUtilized;
    });
    // console.log('pickResourcesForSlotAssignment: sortedPeople: ', sortedPeople, daySequence);    
    sortedPeople.splice(0, requiredPeopleCount).forEach(peopleWithUtilizationMins => {
      assignedResources.push(Object.assign({}, peopleWithUtilizationMins.person, {
        start: date.toISOString(),
        allDay: isAllDayEvent,
        duration: isAllDayEvent ? 0 : defaultRequiredPersonDuration
      }));
    });
  }

  Object.keys(equipmentTypeAllocation).forEach(equipmentType => {
    const requiredEquipmentTypeCount = equipmentTypeAllocation[equipmentType][daySequence].count;
    if (equipmentByType[equipmentType]?.length >= requiredEquipmentTypeCount) {
      assignedResources = assignedResources.concat(equipmentByType[equipmentType].splice(0, requiredEquipmentTypeCount));
    }
  });

  isEventsMapByResourceInitialized = false;
  eventsMapByResource = {};

  const immutableAssignedResources = produce([], draftState => {
    assignedResources.forEach(r => {
      const rData = {
        type: r.type,
        name: r.name,
        shortName: r.shortName,
        code: r.code,
        subType: r.subType || '',
        resourceId: r.resourceId || ''
      };
      if (rData.type === RESOURCE_TYPE.person) {
        rData.duration = r.duration;
        rData.allDay = r.allDay;
        rData.start = r.start;
      }
      draftState.push(rData);
    });
  });

  return immutableAssignedResources;
}