// import Avatar from "@material-ui/core/Avatar";
// import React from "react";
// import red from "@material-ui/core/colors/red";

export default [
  {
    from: "Ali Connors",
    message: "Received Order from LGM Pharma",
    // avatar: (
    //   <Avatar
    //     alt="Ali Connors"
    //     src={`${process.env.PUBLIC_URL}/static/images/face4.jpg`}
    //   />
    // ),
    subject: "Order Received"
  },
  {
    from: "Trevor Hansen",
    message: "Batch Record for the Order from Accord Healthcare is Approved",
    subject: "Batch Approved"
  },
  {
    from: "Sandra Adams",
    message: "Customer: Teva, Product: Cimetidine ",
    // avatar: (
    //   <Avatar
    //     alt="Sandra Adams"
    //     src={`${process.env.PUBLIC_URL}/static/images/face4.jpg`}
    //   />
    // ),
    subject: "Lot #569837 created"
  },
  {
    from: "Jeff Jones",
    message: "Created Account for qa@pragmatrix.com",
    // avatar: (
    //   <Avatar aria-label="Post" style={{ backgroundColor: red[500] }}>
    //     J
    //   </Avatar>
    // ),
    subject: "New User"
  }
];
