import { useEffect, useState } from 'react';
import { FIRESTORE_COLLECTIONS } from '../constants';
import { firestoreInstance } from '../../config/firebase-init';

const useSchedulerBlockedDays = () => {
  const blockedDaysRef = firestoreInstance.doc(`${FIRESTORE_COLLECTIONS.schedulerBlockedDays}/first-doc`);

  const [blockedDaysData, setBlockedDaysData] = useState(null);
  const [blockedDaysError, setBlockedDaysError] = useState(null);

  useEffect(() => {
    const unsubscribeBlockedDaysRef = blockedDaysRef.onSnapshot((doc) => {
      if (doc.exists) {
        const docData = doc.data();
        setBlockedDaysData(docData);
        setBlockedDaysError(null);
      }
    }, (err) => { setBlockedDaysError(err); });
    
    return () => {
      unsubscribeBlockedDaysRef();
    };
  }, []);

  return {
    blockedDaysMap: blockedDaysData || {},
    saveBlockedDays: (data) => {
      return blockedDaysRef.set(data);
    }
  };
};

export default useSchedulerBlockedDays;
