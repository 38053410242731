import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import DomainIcon from "@material-ui/icons/Domain";
import PrintIcon from "@material-ui/icons/Print";
import CreateIcon from "@material-ui/icons/Create";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Alert from "@material-ui/lab/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TabPanel from "../../design-system/tab-content-panel";
import QaAccessForm from "./qa-access-form";
import useOrderData from "../../hooks/use-order-data";
import useLotData from "../../hooks/use-lot-data";
import useBatchFormsStatus from "../../hooks/use-batch-forms-status";
import useBatchFormNoteData from "../../hooks/use-batch-form-note-data";
import useUserSession from '../../hooks/use-user-session';
import BatchFormNotes from "./batch-form-notes";
import AccessLogsDrawer from './access-logs-drawer';
import FormStatusChangeModal from './form-status-change-modal';
import FormStatusIcon from './form-status-icon';
import {
  BATCH_FORM_NAMES as FORM_NAMES,
  BATCH_FORM_STATUS as FORM_STATUS,
  FORM_TYPES,
  NoteStatus,
  PERMISSION_CODES
} from '../../hooks/constants';
import FormSlugComponentMapper from './form-slug-component-mapper';
import useBatchMasterList from "../../hooks/use-batch-master-list";
import useFormMasterList from "../../hooks/use-form-master-list";
import { getPreviousFormStatus } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    minWidth: 150,
    borderRight: `1px solid ${theme.palette.divider}`,
    "& button": {
      minHeight: "3rem",
      "&.Mui-selected": {
        backgroundColor: 'rgba(245,0,87,0.1)'
      },
      "& span": {
        alignItems: "center",
        textAlign: "left",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      },
    },
  },
  tabContentContainer: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    '& .MuiAlert-root': {
      alignItems: 'center',
      '& .MuiAlert-message p': {
        margin: 0
      }
    }
  },
  manualProcessTab: {
    backgroundColor: '#ffffe0 !important'
  },
  batchToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "32px",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& p": {
      fontSize: "0.9rem",
    },
  },
  formToolbar: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: theme.spacing(1),
    minHeight: "40px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  btn__verify: {
    backgroundColor: 'rgb(173,216,230, 0.6)',
    '&:disabled': {
      backgroundColor: 'rgb(173,216,230, 0.2)',
    }
  },
  btn__complete: {
    backgroundColor: 'rgb(221, 189, 241, 0.6)',
    '&:disabled': {
      backgroundColor: 'rgb(221, 189, 241, 0.2)',
    }
  },
  btn__reopen: {
    backgroundColor: 'rgb(255,182,193,0.6)',
    '&:disabled': {
      backgroundColor: 'rgb(255,182,193,0.2)',
    }
  },
  noteAlert: {
    '& .MuiAlert-message': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    }
  }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function BatchFormStatusIcon({ formStatus }) {
  if (formStatus) {
    if (formStatus[FORM_STATUS.REOPENED]) {
      return <FormStatusIcon status={FORM_STATUS.REOPENED} />
    } else if (formStatus[FORM_STATUS.QA_VERIFIED]) {
      return <FormStatusIcon status={FORM_STATUS.QA_VERIFIED} />
    } else if (formStatus[FORM_STATUS.VERIFIED]) {
      return <FormStatusIcon status={FORM_STATUS.VERIFIED} />
    } else if (formStatus[FORM_STATUS.REVIEWED]) {
      return <FormStatusIcon status={FORM_STATUS.REVIEWED} />
    } else if (formStatus[FORM_STATUS.COMPLETED]) {
      return <FormStatusIcon status={FORM_STATUS.COMPLETED} />
    }
  }
  return <CreateIcon />;
}

function BatchFormWizard({
  orderId,
  lotId,
  onSave,
  formName = null,
  noteId = null,
  onNoteAdded = null,
}) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:900px)");
  const [newBatchFormStatus, setNewBatchFormStatus] = useState(null);
  const [isFormStatusChangeModalVisible, setIsFormStatusChangeModalVisible] = useState(false);
  const [isAccessLogsOpen, setIsAccessLogsOpen] = useState(false);
  const [selectedTabContentView, setSelectedTabContentView] = useState("form");
  const [tabIndex, setTabIndex] = useState(0);
  const [tabsConfig, setTabsConfig] = useState([]);

  const { userPermissions } = useUserSession();

  const { orderData } = useOrderData(orderId);
  const { lotData } = useLotData(orderId, lotId);
  const { batchFormsStatus } = useBatchFormsStatus(orderId, lotId);
  
  const { allFormsMasterMap } = useFormMasterList();
  const { batchesMasterList } = useBatchMasterList();

  const { noteData, updateNoteStatus } = useBatchFormNoteData(
    orderId,
    lotId,
    tabsConfig?.[tabIndex]?.formName,
    noteId
  );

  useEffect(() => {
    if (batchesMasterList && allFormsMasterMap && lotData && batchFormsStatus) {
      const ebrTemplate_batchMasterRec = batchesMasterList?.find((batchMasterRec) => (batchMasterRec.batchSlug === lotData?.ebrTemplate))
      const finalTabsConfig = ebrTemplate_batchMasterRec?.workflowStatesSequence?.reduce((previousTabsConfig, currentStateSlug) => {
        
        let isStateDisabled = true;
        if (!ebrTemplate_batchMasterRec?.workflowStates[currentStateSlug].activationRules || 
          ebrTemplate_batchMasterRec?.workflowStates[currentStateSlug].activationRules?.length === 0) {
          isStateDisabled = false;
        } else {
          let doesMeetRule = true;
          ebrTemplate_batchMasterRec?.workflowStates[currentStateSlug].activationRules.forEach((rule) => {
            doesMeetRule = (doesMeetRule && batchFormsStatus[rule.formSlug]?.[rule.formStatus]);
          });
          isStateDisabled = !doesMeetRule;
        }

        const tempTabsConfig = ebrTemplate_batchMasterRec?.workflowStates[currentStateSlug].formSlugs.map((formSlug) => {
          const availableForm = allFormsMasterMap[formSlug];
          return {
            title: availableForm.name,
            formName: availableForm.slug,
            formType: availableForm.type,
            formComponent: FormSlugComponentMapper(availableForm.slug),
            isDisabled: isStateDisabled
          };
        });
        
        return previousTabsConfig.concat(tempTabsConfig);
      }, []);

      setTabsConfig(finalTabsConfig);
    }
  }, [batchesMasterList, allFormsMasterMap, lotData, batchFormsStatus]);

  useEffect(() => {
    if (tabsConfig && tabsConfig.length > 0) {
      if (formName && noteId) {
        let defaultTabIndex = 0;
        tabsConfig.find((tab, idx) => {
          if (tab.formName === formName) {
            defaultTabIndex = idx;
            return true;
          }
        });
        setTabIndex(defaultTabIndex);        
      }
    }
  }, [tabsConfig, formName, noteId]);

  useEffect(() => {
    if (formName) {
      let selectedTabIndex = null;
      tabsConfig.find((cfg, idx) => {
        if (cfg.formName === formName) {
          selectedTabIndex = idx;
          return true;
        }
      });
      if (selectedTabIndex && selectedTabIndex !== tabIndex) {
        setTabIndex(selectedTabIndex);
      }
    }
  }, [formName]);

  const handleDrawerToggle = () => setIsAccessLogsOpen(!isAccessLogsOpen);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSave = () => {
    onSave && onSave();
  };

  const isFormStatusButtonDisabled = (formName, statusButtonIdentifier, batchFormStatusMap) => {
    const isCurrentFormStatusMarked = batchFormStatusMap[formName]?.[statusButtonIdentifier];
    const prevFormStatus = getPreviousFormStatus(allFormsMasterMap[formName].formStatuses, statusButtonIdentifier);

    // console.log('isFormStatusButtonDisabled: ', formName, statusButtonIdentifier, allFormsMasterMap[formName].formStatuses, batchFormStatusMap[formName], prevFormStatus);

    switch(statusButtonIdentifier) {
      case FORM_STATUS.COMPLETED:
        return isCurrentFormStatusMarked || !userPermissions[PERMISSION_CODES.BATCH_FORM_STATUS_COMPLETED] ||
          (prevFormStatus && !batchFormStatusMap[formName]?.[prevFormStatus]);
      case FORM_STATUS.REVIEWED:
        return isCurrentFormStatusMarked || !userPermissions[PERMISSION_CODES.BATCH_FORM_STATUS_REVIEWED] ||
        (prevFormStatus && !batchFormStatusMap[formName]?.[prevFormStatus]);
      case FORM_STATUS.VERIFIED:
        return isCurrentFormStatusMarked || !userPermissions[PERMISSION_CODES.BATCH_FORM_STATUS_VERIFIED] ||
        (prevFormStatus && !batchFormStatusMap[formName]?.[prevFormStatus]);
      case FORM_STATUS.QA_VERIFIED:
        return isCurrentFormStatusMarked ||  !userPermissions[PERMISSION_CODES.BATCH_FORM_STATUS_QA_VERIFIED] || 
        (prevFormStatus && !batchFormStatusMap[formName]?.[prevFormStatus]); // batchFormStatusMap[formName]?.[FORM_STATUS.VERIFIED];
      case FORM_STATUS.REOPENED:
        return isCurrentFormStatusMarked || !userPermissions[PERMISSION_CODES.BATCH_FORM_STATUS_REOPENED];
      default:
        return false;
    }
  };

  return (
    <>
      {orderData && lotData && (
        <Box className={classes.batchToolbar} boxShadow={4}>
          <Typography style={{ display: "flex" }}>
            <DomainIcon /> &nbsp; Customer: {orderData.customer}
          </Typography>
          <Typography>Product: {orderData.product}</Typography>
          <Typography>Lot #: {lotData.lotNum}</Typography>
          <Typography>Batch #: {lotData.batchNum}</Typography>
        </Box>
      )}
      <div
        className={`${classes.root}`}
        style={{ flexDirection: matches ? "row" : "column" }}
      >
        <Tabs
          orientation={matches ? "vertical" : "horizontal"}
          variant="scrollable"
          value={tabIndex}
          onChange={handleChange}
          aria-label="Batch Forms"
          className={classes.tabs}
        >
          {tabsConfig?.map((tabC, idx) => {
            return (
              <Tab
                className={`${tabC.formType === FORM_TYPES.manualProcess ? classes.manualProcessTab : ''}`}
                disabled={tabC.isDisabled}
                key={`tab-${tabsConfig[idx].formName}`}
                icon={
                  <BatchFormStatusIcon
                    formStatus={batchFormsStatus[tabsConfig[idx].formName]}
                  />
                }
                label={tabC.title}
                {...a11yProps(idx)}
              />
            );
          })}
        </Tabs>
        <div className={classes.tabContentContainer}>
          <Toolbar className={classes.formToolbar}>
            <ButtonGroup
              color="primary"
              size="small"
              aria-label="outlined primary button group"
              style={{ marginBottom: "8px" }}
            >
              {allFormsMasterMap?.[tabsConfig?.[tabIndex]?.formName]?.formStatuses?.map((formStatus) => (
                <Button 
                  key={`formStatusButton-${formStatus}`} 
                  disabled={isFormStatusButtonDisabled(tabsConfig?.[tabIndex]?.formName, formStatus, batchFormsStatus)}
                  onClick={() => {
                    setIsFormStatusChangeModalVisible(true);
                    setNewBatchFormStatus(formStatus);
                  }}
                >
                  <FormStatusIcon status={formStatus} />
                  {formStatus.replaceAll('_', ' ')}
                </Button>
              ))}
            </ButtonGroup>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="flexSpacer" />
            <ButtonGroup
              color="primary"
              size="small"
              aria-label="outlined primary button group"
              style={{ marginBottom: "8px" }}
            >
              <Button
                variant={selectedTabContentView === "form" ? "contained" : ""}
                onClick={() => {
                  setSelectedTabContentView("form");
                }}
              >
                <LineStyleIcon /> Form
              </Button>
              <Button
                variant={selectedTabContentView === "notes" ? "contained" : ""}
                onClick={() => {
                  setSelectedTabContentView("notes");
                }}
              >
                <NoteAddIcon /> Notes
              </Button>
              <Button
                disabled={!userPermissions[PERMISSION_CODES.BATCH_FORM_QA_ACCESS]}
                variant={
                  selectedTabContentView === "qaAccess" ? "contained" : ""
                }
                onClick={() => {
                  setSelectedTabContentView("qaAccess");
                }}
              >
                <AssignmentTurnedInIcon /> QA Access
              </Button>
            </ButtonGroup>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ButtonGroup
              color="primary"
              size="small"
              aria-label="outlined primary button group"
              style={{ marginBottom: "8px" }}
            >
              <Button><PrintIcon /></Button>
            </ButtonGroup>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ButtonGroup
              color="primary"
              size="small"
              aria-label="outlined primary button group"
              style={{ marginBottom: "8px" }}
            >
              <Button
                disabled={!userPermissions[PERMISSION_CODES.BATCH_FORM_ACCESS_LOGS]}
                onClick={handleDrawerToggle}
              >
                Access Logs
              </Button>
            </ButtonGroup>
          </Toolbar>

          {noteData && noteData.html && (
            <Alert
              severity={(noteData.status === NoteStatus.pending || noteData.status === NoteStatus.signaturePending) ? "warning" : "success"}
              className={classes.noteAlert}
            >
              <Typography
                component="div"
                dangerouslySetInnerHTML={{ __html: noteData.html }}
              ></Typography>
              {(noteData.status === NoteStatus.pending || noteData.status === NoteStatus.signaturePending) && 
                <Button variant="outlined" 
                  onClick={() => {
                    updateNoteStatus(NoteStatus.done).then(() => {
                      handleSave && handleSave(); 
                    });
                  }}
                >Mark as Done</Button>
              }
            </Alert>
          )}
          <Box p={2}>
            {tabsConfig?.map((tabC, idx) => {
              const Component = tabC.formComponent;
              return (
                <TabPanel key={tabC.formName} value={tabIndex} index={idx}>
                  {selectedTabContentView === "form" && (
                    <Component
                      orderId={orderId}
                      lotId={lotId}
                      onSave={handleSave}
                    />
                  )}
                </TabPanel>
              );
            })}
            {selectedTabContentView === "notes" && (
              <BatchFormNotes
                orderId={orderId}
                lotId={lotId}
                formName={tabsConfig?.[tabIndex].formName}
                onNoteAdded={onNoteAdded}
              />
            )}
            {selectedTabContentView === "qaAccess" && (
              <QaAccessForm
                orderId={orderId}
                lotId={lotId}
                formName={tabsConfig?.[tabIndex].formName}
                onSave={handleSave}
              />
            )}
          </Box>
        </div>
      </div>
      {isFormStatusChangeModalVisible && <FormStatusChangeModal
        orderId={orderId}
        lotId={lotId}
        formName={tabsConfig?.[tabIndex].formName}
        newFormStatus={newBatchFormStatus}
        isVisible={isFormStatusChangeModalVisible}
        onClose={() => {
          setIsFormStatusChangeModalVisible(false);
        }}
        onSave={() => {
          setIsFormStatusChangeModalVisible(false);
          handleSave();
        }}
      />}
      <AccessLogsDrawer isOpen={isAccessLogsOpen} toogleDrawer={handleDrawerToggle} />
    </>
  );
}

export default BatchFormWizard;
