import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
}));

function ResourceSkillsForm({ initialValues, onChange }) {
  const classes = useStyles();
  const [skills, setSkills] = useState({
    beginner: false,
    intermediate: false,
    expert: false,
  });

  useEffect(() => {
    if (initialValues) {
      const tranformedSkills = {};
      Object.keys(skills).forEach((key) => {
        tranformedSkills[key] = initialValues[key] || skills[key];
      });
      setSkills(tranformedSkills);
    }
  }, [initialValues]);

  const handleChange = (evt) => {
    const newSkills = Object.assign({}, skills, {
      [evt.target.name]: evt.target.checked
    });
    setSkills(newSkills);
    onChange?.(newSkills);
  };

  return (
    <Box>
      <Box>
        <label className={classes.label}>
          <Checkbox name="beginner" onChange={handleChange} checked={skills.beginner} /> 
          <Typography>Beginner</Typography>
        </label>
      </Box>
      <Box>
        <label className={classes.label}>
          <Checkbox name="intermediate" onChange={handleChange} checked={skills.intermediate} /> 
          <Typography>Intermediate</Typography>
        </label>
      </Box>
      <Box>
        <label className={classes.label}>
          <Checkbox name="expert" onChange={handleChange} checked={skills.expert} /> 
          <Typography>Expert</Typography>
        </label>
      </Box>
    </Box>
  );
}

export default ResourceSkillsForm;
