import React, { useState } from 'react';
import useLocalStorage from "use-local-storage";
// import { useHistory } from 'react-router';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
import classNames from "classnames";
import AppBar from '@material-ui/core/AppBar';
import Avatar from "@material-ui/core/Avatar";
// import Badge from '@material-ui/core/Badge';
// import Collapse from '@material-ui/core/Collapse';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Person from '@material-ui/icons/Person';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone';
import NotificationsActiveOutlined from '@material-ui/icons/NotificationsActiveOutlined';
import Hidden from '@material-ui/core/Hidden';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AppsIcon from '@material-ui/icons/Apps';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import Toolbar from '@material-ui/core/Toolbar';
// import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import useUserSession from '../../hooks/use-user-session';
import { Typography } from '@material-ui/core';
import { APP_NAMES, USER_ROLE_MASTER, USER_ROLE_MASTER_MAP } from '../../hooks/constants';

const useStyles = makeStyles(theme => ({
  avatar: {
    display: "inline-block",
    margin: 0,
    cursor: 'pointer',
    maxWidth: '30px',
    maxHeight: '30px',
    margin: '12px'
  },
  appBar: {
    backgroundColor: '#fff',
    boxShadow: '0 1px 3px rgba(0,0,0,.3)',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down('sm')]: {
      position: 'fixed'
    }
  },
  toolBar: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
    minHeight: '56px',
    '& h6': {
      fontSize: '1.15rem',
      fontWeight: 400
    }
  },
  listItemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(0.8)
  },
  branding: {
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: 'auto 0',
    lineHeight: '50px',
    padding: `0 64px 0 0`
  },
  logo: {
    margin: 'auto',
    maxHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80px'
    }
  },
  searchWrapper: {
    flex: '1 1 0%',
    boxSizing: ' border-box'
  },
  searchForm: {
    background: 'white',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1) * 2,
    display: 'block',
    maxWidth: '800px'
  },
  searchInput: {
    fontSize: '1rem',
    padding: theme.spacing(1) * 1.9,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1) * 1.2
    },
    cursor: 'text',
    textIndent: '30px',
    border: 'none',
    background: 'transparent',
    width: '100%',
    outline: '0'
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    left: '0',
    marginTop: '-24px',
    color: 'rgba(0,0,0,.87)'
  },
  appsSubMenu: {
    '& .MuiPaper-root ul': {
      width: '400px',
      display: 'flex',
      flexWrap: 'wrap',
      '& li': {
        width: '200px',
        '& svg': {
          marginRight: theme.spacing(1)
        }
      }
    }
  },
  subMenu: {
    '& .MuiPaper-root': {
      top: '60px !important'
    }
  }
}));

const Header = ({
  logo,
  logoAltText,
  toggleFullscreen,
  toggleDrawer,
  // toogleNotifications
}) => {
  const [selectedApp, setSelectedApp] = useLocalStorage("selectedApp", APP_NAMES.scheduler);

  // const history = useHistory();
  const session = useUserSession();
  const { userProfile, logout } = session;
  
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = useState(null);
  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const [appsAnchorEl, setAppsAnchorEl] = useState(null);
  const [searchExpanded, setSearchExpanded] = useState(false);

  const handleAvatarToggle = event => setAvatarAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAvatarAnchorEl(null);

  const handleAppsToggle = event => setAppsAnchorEl(event.currentTarget);
  const handleAppsCloseMenu = () => setAppsAnchorEl(null);

  const handleSearchExpandToggle = () => setSearchExpanded(!searchExpanded);

  const handleDrawerToggle = () => {
    toggleDrawer();
    if (searchExpanded) handleSearchExpandToggle();
  };

  // const handleNotificationToggle = () => {
  //   toogleNotifications();
  //   if (searchExpanded) handleSearchExpandToggle();
  // };

  return (
    <AppBar position="static" className={classes.appBar} color="default">
      <Toolbar className={classes.toolBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.branding}>
          <Typography variant="h6">
            {selectedApp === APP_NAMES.electronicBatchRecord ? "Electronic Batch Record" : "Scheduler"}
          </Typography>
          {/* <img src={logo} alt={logoAltText} className={classes.logo} /> */}
        </div>

        {/* <Hidden xsDown>
          <div className={classes.searchWrapper}>
            <form className={classes.searchForm}>
              <IconButton aria-label="Search" className={classes.searchIcon}>
                <SearchIcon />
              </IconButton>
              <input
                className={classes.searchInput}
                type="text"
                placeholder="Search"
                autoFocus={true}
              />
            </form>
          </div>
        </Hidden> */}

        {/* <Hidden smUp>
          
        </Hidden> */}
        <span className="flexSpacer" />

        {/* <Hidden smUp>
          <IconButton
            color="inherit"
            onClick={handleSearchExpandToggle}
            aria-expanded={searchExpanded}
            aria-label="Show searchbar"
          >
            <SearchIcon />
          </IconButton>
        </Hidden> */}

        <Hidden xsDown>
          <IconButton color="inherit" onClick={toggleFullscreen}>
            <FullscreenIcon color="primary" />
          </IconButton>
          {/* <IconButton color="inherit" onClick={toggleFullscreen}>
            <SettingsIcon color="primary" />
          </IconButton> */}
          {/* <IconButton color="inherit" onClick={toggleFullscreen}>
            <NotificationsActiveOutlined color="primary" />
          </IconButton> */}
          {/* <IconButton color="inherit" onClick={toggleFullscreen}>
            <HelpOutlineIcon color="primary" />
          </IconButton> */}
        </Hidden>

        {/* <IconButton color="inherit" onClick={handleNotificationToggle}>
          <Badge badgeContent={5} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}

        <IconButton color="inherit" onClick={handleAppsToggle}>
          <AppsIcon color="primary" />
        </IconButton>
        <Menu
          id="apps-menu"
          anchorEl={appsAnchorEl}
          open={Boolean(appsAnchorEl)}
          onClose={handleAppsCloseMenu}
          className={`${classes.subMenu} ${classes.appsSubMenu}`}
        >
          
          <MenuItem disabled={userProfile?.role !== USER_ROLE_MASTER.ADMIN} onClick={() => {}}>
            <ListAltIcon /> 
            <a href="https://app-web-praglife-demo.azurewebsites.net/" target="_blank">
              <ListItemText primary="CliniTrack" />
            </a>
          </MenuItem>
          <MenuItem disabled={userProfile?.role !== USER_ROLE_MASTER.ADMIN} onClick={() => {
            setSelectedApp('electronic-batch-record');
            setTimeout(() => {
              window.location.reload();
            });
          }}>
            <FileCopyTwoToneIcon /> <ListItemText primary="Batch Record" />
          </MenuItem>
          <MenuItem onClick={() => {
            setSelectedApp('scheduler');
            setTimeout(() => {
              window.location.reload();
            });
          }}>
            <CalendarTodayIcon /> <ListItemText primary="Scheduler" />
          </MenuItem>
          <MenuItem disabled={userProfile?.role !== USER_ROLE_MASTER.ADMIN}>
            <AddBoxTwoToneIcon />
            <a href="http://clinitrack.pragmatrix.com/" target="_blank">
              <ListItemText primary="Freezer" />
            </a>
          </MenuItem>
        </Menu>

        <Avatar
          aria-label="User Settings"
          aria-owns={avatarAnchorEl ? 'user-menu' : null}
          aria-haspopup="true"
          alt={userProfile.name}
          src={`${userProfile.profilePicUrl || `${process.env.PUBLIC_URL}/static/images/avatar.jpg`}`}
          className={classNames(classes.avatar)}
          onClick={handleAvatarToggle}
        />

        <Menu
          id="user-menu"
          anchorEl={avatarAnchorEl}
          open={Boolean(avatarAnchorEl)}
          onClose={handleCloseMenu}
          className={classes.subMenu}
        >
          {/* <MenuItem onClick={handleCloseMenu}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <ListItemIcon>
              <NotificationsOffIcon />
            </ListItemIcon>
            <ListItemText primary="Disable notifications" />
          </MenuItem> */}
          <MenuItem>
            <ListItemIcon className={classes.listItemIcon}>
              <Person color="primary" />
            </ListItemIcon>
            <div>
              <ListItemText primary={userProfile.name} />
              <ListItemText primary={userProfile.email} />
              <ListItemText style={{ textTransform: 'capitalize' }} primary={`Role: ${USER_ROLE_MASTER_MAP[userProfile?.role] || userProfile?.role}`} />
              {userProfile.role === USER_ROLE_MASTER.SITE_USER && <ListItemText primary={`Client: ${userProfile.clientCode}`} />}
            </div>
          </MenuItem>
          <MenuItem onClick={() => {
            logout().then(() => {
              handleCloseMenu();
              window.location.reload();
            });
          }}>
            <ListItemIcon className={classes.listItemIcon}>
              <ExitToAppIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </MenuItem>
        </Menu>
      </Toolbar>
      {/* <Hidden smUp>
        <Collapse in={searchExpanded} timeout="auto" unmountOnExit>
          <Toolbar className={classes.toolBar}>
            <div className={classes.searchWrapper}>
              <form className={classNames(classes.searchForm, 'mr-0')}>
                <IconButton aria-label="Search" className={classes.searchIcon}>
                  <SearchIcon />
                </IconButton>
                <input
                  className={classes.searchInput}
                  type="text"
                  placeholder="Search"
                  autoFocus="true"
                />
              </form>
            </div>
          </Toolbar>
        </Collapse>
      </Hidden> */}
    </AppBar>
  );
};

Header.prototypes = {
  logo: PropTypes.string,
  logoAltText: PropTypes.string
};

export default Header;
