import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import { generateSlug } from "../../helpers";

export default function WorkflowFormsModal({ modalTitle, isVisible, isCaptureBatchName, onClose, onSave, formsMaster, batchAvailableFormSlugs }) {  
  const [ batchName, setBatchName ] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedForms, setSelectedForms] = useState([]);

  useEffect(() => {
    if (formsMaster) {
      const newSelectedForms = formsMaster.map((form) => {
        return Object.assign({}, form, 
          { checked: batchAvailableFormSlugs ? batchAvailableFormSlugs.indexOf(form.slug) !== -1 : false }
        )
      });
      setSelectedForms(newSelectedForms);
    }
  }, [formsMaster, batchAvailableFormSlugs]);

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose?.();
        }}
        aria-labelledby="workflow-forms-modal-title"
        aria-describedby="workflow-forms-modal-description">
        <DialogTitle id="workflow-forms-modal-title">{modalTitle || 'Choose Workflow Forms'}</DialogTitle>
        <DialogContent>
          {isCaptureBatchName && 
            <Box mb={3}>
              <TextField
                autoFocus
                tabIndex="0"
                value={batchName}
                required
                onChange={(e) => {
                  setBatchName(e.target.value);
                }}
                margin="dense"
                id="batch-name"
                label="Batch Name"
                fullWidth
              />
            </Box>
          }

          <DialogContentText id="workflow-forms-modal-description">
            Choose forms for this Batch
          </DialogContentText>
          
          <Box>
            {selectedForms.map((form) => (
              <div key={`${form.slug}-checkbox`}>
                <Checkbox checked={form.checked} onClick={() => {
                  const newSelectedForms = selectedForms.map((selForm) => {
                    if (selForm.slug === form.slug) {
                      return Object.assign({}, selForm, { checked: !form.checked });
                    }
                    return Object.assign({}, selForm);
                  });
                  setSelectedForms(newSelectedForms);
                }} />
                <Typography style={{ display: 'inline-block' }}> {form.name} </Typography>
              </div>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { onClose?.() }}>
            Cancel
          </Button>
          <Button onClick={() => {
            const processedSelectedForms = selectedForms.filter((form) => form.checked).map((form) => ({
              slug: form.slug,
              name: form.name
            }));
            if (isCaptureBatchName) {
              onSave?.(processedSelectedForms, batchName, generateSlug(batchName));
            } else {
              onSave?.(processedSelectedForms);
            }            
          }} 
          color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}