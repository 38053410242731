import { useState, useEffect } from 'react';
// import { useUser, useFirestore, useFirestoreDocData } from 'reactfire';
// import firebase from 'firebase/compat/app';
import { firebaseAuth, firestoreInstance } from '../config/firebase-init';

export default function useUserSession() {
    // const firebaseAuth = firebase.auth();
    const uid = firebaseAuth?.currentUser?.uid;
    // const userRef = useUser();
    // const user = JSON.parse(JSON.stringify(userRef)).data;
    
    const userProfileRef = firestoreInstance.collection('users').doc(uid || 'anonymous');
    const [userProfile, setUserProfile] = useState(null);
    const [userProfileError, setUserProfileError] = useState(null);

    const permissionsRef = firestoreInstance.collection('permissions').doc('data');
    const [permissionsMaster, setPermissions] = useState(null);
    const [permissionsError, setPermissionsError] = useState(null);

    useEffect(() => {
        const unsubscribeUserProfileRef = userProfileRef.onSnapshot((doc) => {
            setUserProfile(doc.data());
            setUserProfileError(null);
        }, (err) => { setUserProfileError(err); });

        const unsubscribePermissionsRef = permissionsRef.onSnapshot((doc) => {
            setPermissions(doc.data());
            setPermissionsError(null);
        }, (err) => { setPermissionsError(err); });

        return () => {
            unsubscribeUserProfileRef();
            unsubscribePermissionsRef();
        };
    }, []);
    
    const userPermissions = {};
    if (permissionsMaster && userProfile) {
        permissionsMaster.values && permissionsMaster.values.forEach((perm) => {
            userPermissions[perm.code] = perm[userProfile.role];
        });
    }

    return {
        user: firebaseAuth?.currentUser || null, // ? JSON.parse(JSON.stringify(firebaseAuth?.currentUser)) :
        userProfile: userProfile || {},
        userPermissions,
        logout: () => {
            return firebaseAuth.signOut();
        }
    }
}