import { useEffect, useState } from 'react';
import { firestoreInstance } from '../../config/firebase-init';

const useResourceData = (resourceId) => {
  const resourcesRef = firestoreInstance.collection('schedulerResources');

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (resourceId) {
      const unsubscribeResourcesRef = resourcesRef.doc(resourceId).onSnapshot((doc) => {
        setData({
          id: doc.id,
          ...doc.data()
        });
        setError(null);
      }, (err) => { setError(err); });
      
      return () => {
        unsubscribeResourcesRef();
      };
    }
  }, [resourceId]);

  return {
    resource: resourceId ? data : null,
    add: (values) => {
      return resourcesRef.add(values).then(() => {
        return true;
      }).catch((err) => {
        console.error('Error creating template! ', err);
      });
    },
    update: (values) => {
      return resourcesRef.doc(resourceId).update(values).then(() => {
        return true;
      }).catch((err) => {
        console.error('Error creating template! ', err);
      });
    }
  };
}

export default useResourceData;