import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Button from '@material-ui/core/Button';
import WaGrid from './wa-grid';
import useResources from '../../hooks/scheduler/use-resources';
import useSchedulerProcesses from '../../hooks/scheduler/use-scheduler-processes';
import useSchedulerEvents from '../../hooks/scheduler/use-scheduler-events';
import { RESOURCE_TYPE, SCHEDULE_TYPE, PROCESS_CODE_BIOPSY, DAY_24HRS_MINUTES } from '../../hooks/constants';
import PlannerGridSidePanel from './planner-grid-side-panel';
import PlannerGridToolbar from './planner-grid-toolbar';
import PlannerGridCapacityCharts from './planner-grid-capacity-charts';
import SimpleSnackbar from "../../design-system/simple-snackbar";
import PlannerGridBreadcrumb from './planner-grid-breadcrumb';
import ResourceAssignmentModal from './resource-assignment-modal';
import ConfirmationModal from '../../design-system/confirmation-modal';
import { _getResourceAvailabilityInMinutes } from '../../helpers-scheduler';
import useSchedulerAutoAllocationConfig from '../../hooks/scheduler/use-scheduler-auto-allocation-config';
import useSchedulerAutoAllocationPublishStatus from '../../hooks/scheduler/use-scheduler-auto-allocation-publish-status';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // marginBottom: theme.spacing(4)
  },
  toolbarWrapper: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  floatingSideStripWrapper: {
    position: 'relative'
  },
  floatingSideStrip: {
    position: 'absolute',
    right: '-150px',
    top: '120px',
    transform: 'rotate(-90deg)',
    backgroundColor: '#fff'
  },
  floatingSideStrip__verticalText: {
    textTransform: 'uppercase',
    padding: '0 10px',
    boxShadow: `rgba(0, 0, 0, 0.16) 0px 0px 10px`
  },
  floatingSideStrip__verticalText__selected: {
    '& svg': {
      transform: 'rotate(-90deg)',
    }
  },
  loadingIndicator: {
    position: 'absolute',
    left: theme.spacing(1)
  }
}));

const SCHEDULE_VIEWS = [
  RESOURCE_TYPE.cleanRoom,
  RESOURCE_TYPE.equipment,
  RESOURCE_TYPE.person,
  RESOURCE_TYPE.day,
];

const CAPACITY_VIEWS = [
  RESOURCE_TYPE.cleanRoomCapacityView,
  RESOURCE_TYPE.equipmentCapacityView,
  RESOURCE_TYPE.personCapacityView,
  RESOURCE_TYPE.singleCleanRoomCapacityView,
  RESOURCE_TYPE.singleEquipmentCapacityView,
  RESOURCE_TYPE.singlePersonCapacityView,
  RESOURCE_TYPE.singleScheduleCapacityView
];

const PlannerGrid = ({navigateTo, navInit}) => {
  const classes = useStyles();
  const history = useHistory();
  const [todayD, setTodayD] = useState(dayjs());
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [sidePanelContentToShow, setSidePanelContentToShow] = useState(false);
  const [isSavingMoveScheduleData, setIsSavingMoveScheduleData] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [numMonths, setNumMonths] = useState(0);
  const [view, setView] = useState(RESOURCE_TYPE.day);
  const [viewAdditionalData, setViewAdditionalData] = useState(null);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isDeselectAllToolbarOptions, setIsDeselectAllToolbarOptions] = useState(false);
  const [isScheduleDrillDownActivated, setIsScheduleDrillDownActivated] = useState(false);
  const [scheduleDrillDownBreadcrumb, setScheduleDrillDownBreadcrumb] = useState({ schedule: null, resource: null });
  const [showEventMoveConfirmation, setShowEventMoveConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isResourceAssignmentModalVisible, setIsResourceAssignmentModalVisible] = useState(false);
  const [selectedScheduleEvent, setSelectedScheduleEvent] = useState(null);
  const [movedScheduleEvent, setMovedScheduleEvent] = useState({ event: null, newDate: null });

  const [numScheduleCols, setNumScheduleCols] = useState(20);
  const [numResourceCols, setNumResourceCols] = useState(0);
  const [startMDate, setStartMDate] = useState(null); // The Starting Moment Date of the current Grid
  const [numDays, setNumDays] = useState(0);
  const [colLabels, setColLabels] = useState({});
  const [rowLabels, setRowLabels] = useState({});
  const [cellData, setCellData] = useState({});
  const [selectedCustomerFilter, setSelectedCustomerFilter] = useState(null);
  const [selectedSubjectIdFilter, setSelectedSubjectIdFilter]  = useState(null);
  const [row, setRow] = useState(0);
  // const previousView = useRef();

  const { dateRangeStart, dateRangeEnd } = useMemo(() => {
    const vals = Object.values(rowLabels);
    const dateRangeStart = vals[0]?.dateStringDayjs;
    const dateRangeEnd = vals[vals.length - 1]?.dateStringDayjs;
    return { 
      dateRangeStart,
      dateRangeEnd
    };
  }, [rowLabels]);

  const { cleanRooms, equipment, people, processes, clients, fetchResource } = useResources();
  const {
    events: plannerEvents,
    bulkAdd, 
    saveEventResources,
    deleteEventsByScheduleId,
    prepareEventPayload,
    moveSchedule,
    attempToResolveConflictsInOtherEvents,
    detectConflictAndSaveStatusForSchedule,
    evaluateConflictStatusForEventsAndSave
  } = useSchedulerEvents(SCHEDULE_TYPE.PLANNER, selectedCustomerFilter?.code, selectedSubjectIdFilter);

  const { lookupProcessByCode } = useSchedulerProcesses();
  const { data: autoAllocationConfig } = useSchedulerAutoAllocationConfig();
  const { recalculateAndPublishSlotAvailability } = useSchedulerAutoAllocationPublishStatus();

  // Update Row Labels
  useEffect(() => {
    setIsLoading(true);
    generateAndAppendRowLabelsForMonth(numMonths);
    if (numMonths === 0) {
      setNumMonths(numMonths+1);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [numMonths]);


  useEffect(() => {
    if (navigateTo != null) {
      setIsLoading(true);
      handleScrollTOView()
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [navInit]);

  useEffect(() => {
    if (startMDate) {
      setIsLoading(true);
      if (CAPACITY_VIEWS.indexOf(view) !== -1) {
        if (view === RESOURCE_TYPE.cleanRoomCapacityView) {
          setNumResourceCols(cleanRooms.length);
          const tempColLabels = generateAndSetColLabels(cleanRooms.length, cleanRooms);
          loadEventsByResource(plannerEvents, cleanRooms.length, tempColLabels, true);
        } else if (view === RESOURCE_TYPE.equipmentCapacityView) {
          setNumResourceCols(equipment.length);
          const tempColLabels = generateAndSetColLabels(equipment.length, equipment);
          loadEventsByResource(plannerEvents, equipment.length, tempColLabels, true);
        } else if (view === RESOURCE_TYPE.personCapacityView) {
          setNumResourceCols(people.length);
          const tempColLabels = generateAndSetColLabels(people.length, people);
          loadEventsByResource(plannerEvents, people.length, tempColLabels, true);
        } else if (view === RESOURCE_TYPE.singleCleanRoomCapacityView ||
          view === RESOURCE_TYPE.singleEquipmentCapacityView ||
          view === RESOURCE_TYPE.singlePersonCapacityView) {
          setNumResourceCols(1);
          const tempColLabels = generateAndSetColLabels(1, [viewAdditionalData]);
          loadEventsByResource(plannerEvents, 1, tempColLabels, true);
        } else if (view === RESOURCE_TYPE.singleScheduleCapacityView) {
          const uniqueResourcesForSchedule = {};
          const filteredPlannerEvents = plannerEvents.filter((pEvt) => {
            if (pEvt.scheduleId === viewAdditionalData.scheduleId) {
              pEvt.resources?.forEach((r) => {
                uniqueResourcesForSchedule[r.code] = r;
              });
              return true;
            }
            return false;
          });
  
          const resourcesForSchedule = Object.values(uniqueResourcesForSchedule);
          setNumResourceCols(resourcesForSchedule.length);
          const tempColLabels = generateAndSetColLabels(resourcesForSchedule.length, resourcesForSchedule);
          loadEventsByResource(filteredPlannerEvents, resourcesForSchedule.length, tempColLabels, true);
        }
      } else if (SCHEDULE_VIEWS.indexOf(view) !== -1) { //  && SCHEDULE_VIEWS.indexOf(previousView.current) === -1
        // setNumScheduleCols(10);
        const tempColLabels = generateAndSetColLabels(numScheduleCols);
        loadEventsByScheduleId(plannerEvents, numScheduleCols, tempColLabels, true);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [startMDate, view, viewAdditionalData, plannerEvents]);

  useEffect(() => {
    if ((selectedCustomerFilter || selectedSubjectIdFilter) && plannerEvents.length === 0) {
      setIsSnackbarVisible(true);
      setSnackbarMessage('No events found!');
    }    
  }, [plannerEvents, selectedCustomerFilter, selectedSubjectIdFilter]);

  function handleLoadMore(evt) {
    if (numMonths < 3) {
      setNumMonths(numMonths+1);
    }
  }
 
  const getNextAvailableColumnIdx = (colLabels) => {
    let colIdx = -1; 
    Object.values(colLabels).find((c, idx) => {
      if (c.label == '') {
        colIdx = idx;
        return true;
      }
      return false;
    }); //.indexOf('');
    return colIdx;
  }

  const loadEventsByScheduleId = (events, numScheduleCols, colLabels, isResetCellData = false) => {
    const mObj = startMDate;
    if (mObj) {    
      const startMDate_dayOfYear = mObj.dayOfYear();

      const processName_availableColIdxMap = {};
      const newCellData = {};
      events.forEach((e) => {
        const row = Object.assign({}, e, {
          isHighlighted: e.processCode === PROCESS_CODE_BIOPSY
        });
        const ed = row.start;        
        const ed_dayOfYear = moment(ed).dayOfYear();

        const relative_dayOfYear = (ed_dayOfYear - startMDate_dayOfYear);
        if (relative_dayOfYear >= 0) {
          row.type = RESOURCE_TYPE.event;
          if (processName_availableColIdxMap[row.scheduleId] == undefined) {
            const colIdx = getNextAvailableColumnIdx(colLabels);
            processName_availableColIdxMap[row.scheduleId] = colIdx;
            const processFound = lookupProcessByCode(row.processCode);
            colLabels[colIdx] = processFound?.shortName ? { 
              label: `${row?.meta_subjectId} ${row?.clientCode} ${processFound.shortName}`, 
              additionalData: {
                type: RESOURCE_TYPE.schedule,
                isHighlighted: row.processCode === PROCESS_CODE_BIOPSY,
                scheduleId: row.scheduleId 
              }
            } : { 
              label: `${row?.meta_subjectId} ${row?.clientCode} ${row?.processCode}`,
              isHighlighted: row.processCode === PROCESS_CODE_BIOPSY,
              additionalData: {
                type: RESOURCE_TYPE.schedule,
                scheduleId: row.scheduleId 
              }
            };
          }
          const colIdx = processName_availableColIdxMap[row.scheduleId];
          newCellData[`${(relative_dayOfYear*numScheduleCols)+colIdx}`] = row;
        }
      });

      if (isResetCellData) {
        setCellData(newCellData);
      } else {
        setCellData(Object.assign(newCellData, cellData));
      }
    }
  }

  const loadEventsByResource = (events, numResourceCols, colLabels, isResetCellData = false) => {
    const mObj = startMDate;
    if (mObj) {    
      const startMDate_dayOfYear = mObj.dayOfYear();

      const resourceName_availableColIdxMap = {};
      const newCellData = {};
      events.forEach((e) => {
        const row = Object.assign({}, e);
        const ed = row.start;        
        const ed_dayOfYear = moment(ed).dayOfYear();

        const relative_dayOfYear = (ed_dayOfYear - startMDate_dayOfYear);
        if (relative_dayOfYear >= 0) {
          row.type = RESOURCE_TYPE.process;
          row.resources?.filter((resource) => {
            if (view === RESOURCE_TYPE.singleScheduleCapacityView) {
              return true;
            } else if (view === RESOURCE_TYPE.cleanRoomCapacityView) {
              return (resource.type == RESOURCE_TYPE.cleanRoom);
            } else if (view === RESOURCE_TYPE.equipmentCapacityView) {
              return (resource.type == RESOURCE_TYPE.equipment);
            } else if (view === RESOURCE_TYPE.personCapacityView) {
              return (resource.type == RESOURCE_TYPE.person);
            } else if (view === RESOURCE_TYPE.singleCleanRoomCapacityView ||
              view === RESOURCE_TYPE.singleEquipmentCapacityView ||
              view === RESOURCE_TYPE.singlePersonCapacityView) {
              return (resource.code === viewAdditionalData.code);
            }
            return false;
          }).forEach((resource) => {
            if (resourceName_availableColIdxMap[resource.name] == undefined) {
              const colIdx = Object.values(colLabels).findIndex((cl) => (cl.label === resource.name));
              resourceName_availableColIdxMap[resource.name] = colIdx;
            }

            const colIdx = resourceName_availableColIdxMap[resource.name];
            const cellIdx = (relative_dayOfYear*numResourceCols)+colIdx;
            if (newCellData[cellIdx]) {
              newCellData[cellIdx] += `, ${row.processCode.toUpperCase()}`;
            } else {
              newCellData[cellIdx] = row.processCode.toUpperCase();
            }
          });
        }
      });
      if (isResetCellData) {
        setCellData(newCellData);
      } else {
        setCellData(Object.assign(newCellData, cellData));
      }
    }
  }

  /**
   * Updates the **colLabels** as per the passed arguments.
   * Does not handle append functionality.
   * @param {number} colCount - Number of columns to render
   * @param {object[]} resources - Optional. Array of resources used to label the columns.
   * @returns 
   */
  const generateAndSetColLabels = (colCount, resources = null) => {
    const cl = {};
    for(let i = 0;i < colCount;i++) {  
      cl[i] = resources ? {
        label: resources[i].name, 
        additionalData: resources[i]
      } : { 
        label: `` 
      };
    }
    setColLabels(cl);
    return cl;
  }
 
  function handleScrollTOView() {

    const next = row + navigateTo;

    if (next >= 0 && next <= Object.keys(rowLabels).length) {
      rowLabels[row].scrollIntoView = false;
      setRow(next)
      rowLabels[next].scrollIntoView = true;

    }
    else {
      handleLoadMore()
    }
  }


  /**
   * Updates **rowLabels**. Handles appending row labels of the subsequent months.
   * @param {number} nextMonthNum 
   */
  const generateAndAppendRowLabelsForMonth = (nextMonthNum) => {
    const mObj = moment().date(1).add(nextMonthNum, 'M');
    if (!startMDate) {
      const mObj2 = moment().date(1).add(nextMonthNum, 'M');
      setStartMDate(mObj2);
    }
    const monthName = mObj.format('MMM');
    const numRows = mObj.daysInMonth();
    const newNumDays = numDays + numRows;
    
    const rl = {};
    for(let j = numDays,k=0;j < newNumDays;j++,k++) {
      if (k > 0) { 
        mObj.add(1, 'day');
      }
      if(todayD.format('MMDDYYYY') === mObj.format('MMDDYYYY')) {
        setRow(j);
      }
      const dow = mObj.format('ddd'); // Day of week
      rl[j] = {
        label: k+1,
        subLabel: monthName, 
        tooltip: dow,
        isHighlighted: dow === 'Sat' || dow === 'Sun',
        dateString: mObj.format(),
        dateStringDayjs: dayjs(mObj.format()),
        dateStringMomentObj: mObj,
        scrollIntoView: todayD.format('MMDDYYYY') === mObj.format('MMDDYYYY')
      };
    }
    // console.log('rl: ', rl);
    setNumDays(newNumDays);
    setRowLabels(Object.assign({}, rowLabels, rl));
  };

  const handleTemplateDrop = (template, cellId) => {
    setIsLoading(true);
    const newCellData = {}, events = [];
    const scheduleId = uuidv4();

    const processFound = lookupProcessByCode(template?.data?.process);
    colLabels[cellId%numScheduleCols] = processFound ? { 
      label: processFound.shortName,
      isHighlighted: template?.data?.process === PROCESS_CODE_BIOPSY,
      additionalData: {
        type: RESOURCE_TYPE.schedule,
        scheduleId: scheduleId
      }
    } : { 
      label: template?.data?.process,
      isHighlighted: template?.data?.process === PROCESS_CODE_BIOPSY,
      additionalData: {
        type: RESOURCE_TYPE.schedule,
        scheduleId: scheduleId
      }
    };

    const templateData = template.data.data;
    
    for(let i = 0;i < templateData.length;i++) {
      let cellDataIdx = null;
      if (templateData[0].dayNum != '0') {
        cellDataIdx = +cellId + ((+templateData[i].dayNum-1)*numScheduleCols);
      } else {
        cellDataIdx = (+cellId + (+templateData[i].dayNum*numScheduleCols));        
      }
      if (cellDataIdx) {
        newCellData[cellDataIdx] = templateData[i].dayLabel;
        const rl = rowLabels[Math.floor(cellDataIdx/numScheduleCols)];
        if (rl) {          
          const dateJsObj = dayjs(rl.dateString);
          const resources = [];

          templateData[i]['resource/cleanRoom/multiple']?.forEach((code) => {
            const resourceFound = cleanRooms.find((cr => cr.code === code));
            // TODO: Check for Resource Conflict before assigning
            if (resourceFound) {
              resources.push(resourceFound);
            }
          });

          templateData[i]['resource/equipment/multiple']?.forEach((code) => {
            const resourceFound = equipment.find((cr => cr.code === code));
            // TODO: Check for Resource Conflict before assigning
            if (resourceFound) {
              resources.push(resourceFound);
            }
          });

          templateData[i]['resource/people/multiple']?.forEach((code) => {
            const resourceFound = people.find((cr => cr.code === code));
            // TODO: Check for Resource Conflict before assigning
            if (resourceFound) {
              resources.push(resourceFound);
            }
          });

          const eventPayload = prepareEventPayload(
            SCHEDULE_TYPE.PLANNER, 
            scheduleId, 
            dateJsObj.hour(0).minute(0).second(1), // all day event. Added time so it shows up when checking for conflicts.
            dateJsObj.clone().hour(23).minute(59).second(59), // all day event. Added time so it shows up when checking for conflicts.
            template.data.process, 
            template.data.client, 
            resources.map(r => ({
              type: r.type,
              name: r.name,
              shortName: r.shortName,
              code: r.code,
              subType: r.subType || '',
              resourceId: r.resourceId || ''
            })), 
            {
              dayNum: templateData[i].dayNum,
              allDay: true
            }
          );
          events.push(eventPayload);
        }
      }
    }
    setCellData(Object.assign(newCellData, cellData));

    bulkAdd(events).then(() => {
      return recalculateAndPublishSlotAvailability();
    }).then(() => {
      return detectConflictAndSaveStatusForSchedule(scheduleId);
    }).then((res) => {
      setIsSnackbarVisible(true);
      setSnackbarMessage(`Saved [${events.length}] events created using Template.`);
    });
  }

  const handleResourceDrop = (resource, cellId) => {
    setIsLoading(true);
    // Resource conflict is checked for after saving it to the Event. And the conflict flag is saved on the event.
    const scheduleEvent = cellData[cellId];
    const resourceAlreadyExists = scheduleEvent.resources.find(r => r.code === resource.data.code);

    if (!resourceAlreadyExists) {
      const newCellData = {};
      newCellData[cellId] = scheduleEvent;

      const rData = {
        type: resource.data.type,
        subType: resource.data.subType || '',
        name: resource.data.name,
        shortName: resource.data.shortName,
        code: resource.data.code,
        resourceId: resource.data.resourceId || ''
      };

      if (rData.type === RESOURCE_TYPE.person) {
        const startD = dayjs(scheduleEvent.start);
        const endD = dayjs(scheduleEvent.end);
        rData.allDay = scheduleEvent.allDay;
        rData.start = startD.toDate();
        rData.duration = endD.diff(startD, 'minute');
      }
      newCellData[cellId].resources = [rData].concat(newCellData[cellId].resources);
      newCellData[cellId].resourceCodes = [rData.code].concat(newCellData[cellId].resourceCodes);

      setCellData(Object.assign(newCellData, cellData));

      saveEventResources(cellData[cellId].id, newCellData[cellId].resources).then(() => {
        return recalculateAndPublishSlotAvailability();
      }).then(() => {
        return evaluateConflictStatusForEventsAndSave([newCellData[cellId]]);
      }).then(() => {
        setIsSnackbarVisible(true);
        setSnackbarMessage(`Assigned Resource [${resource.data.name}]!`);
      });
    } else {
      setIsSnackbarVisible(true);
      setSnackbarMessage(`No action taken. Resource already assigned [${resource.data.name}]!`);
    }
  }

  const isCapacityView = useMemo(() => !(view === RESOURCE_TYPE.day || view === RESOURCE_TYPE.cleanRoom || view === RESOURCE_TYPE.equipment || view === RESOURCE_TYPE.person), [view]);
  const isCapacityChartVisible = useMemo(() => (view === RESOURCE_TYPE.singleCleanRoomCapacityView || view === RESOURCE_TYPE.singleEquipmentCapacityView || view === RESOURCE_TYPE.singlePersonCapacityView), [view]);

  const { eventsForSingleResourceCapacityView, resourceTotalAvailability, resourceTotalUtilization } = useMemo(() => {
    let resourceTotalAvailability = 0, resourceTotalUtilization = 0, eventsForSingleResourceCapacityView = [];
    if (isCapacityChartVisible) {
      const resource = viewAdditionalData;
      // console.log('eventsForSingleResourceCapacityView: useMemo: ', resource.code);
      const vals = Object.values(rowLabels);
      
      vals.forEach(val => {
        const resourceMasterRecord = fetchResource(resource.code);        
        const mins = _getResourceAvailabilityInMinutes(val.dateStringDayjs, resourceMasterRecord);
        resourceTotalAvailability += mins;
      });

      eventsForSingleResourceCapacityView = plannerEvents.filter(event => {
        const defaultRequiredPersonDuration = event.processCode === PROCESS_CODE_BIOPSY ? 
          autoAllocationConfig?.peopleAllocation[event.dayNum-1]?.duration : DAY_24HRS_MINUTES;
        const resourceFoundInEvent = event.resources.find(r => r.code === resource.code);
        if (resourceFoundInEvent) {
          if (resourceFoundInEvent?.start && resourceFoundInEvent.duration) {
            resourceTotalUtilization += +resourceFoundInEvent.duration;
          } else {
            resourceTotalUtilization += +defaultRequiredPersonDuration;
          }
          return true;
        }
      });

    }
    return { eventsForSingleResourceCapacityView, resourceTotalAvailability, resourceTotalUtilization };
  }, [isCapacityChartVisible, viewAdditionalData, plannerEvents, rowLabels]);
  
  return (
    <Box className={classes.root}>
      {!isCapacityView && 
        <Box className={classes.floatingSideStripWrapper}>
          <Box className={classes.floatingSideStrip}>
            <Button 
              color="primary" 
              className={`${classes.floatingSideStrip__verticalText} ${sidePanelContentToShow === 'resources' ? classes.floatingSideStrip__verticalText__selected : ''}`} 
              onClick={() => {
                if (sidePanelContentToShow === 'resources') {
                  setShowSidePanel(!showSidePanel);
                  setSidePanelContentToShow('');
                } else {
                  setShowSidePanel(true);
                  setSidePanelContentToShow('resources');
                }
              }}
            >
              Resources &nbsp;
              <DoubleArrowIcon />
            </Button>
            <Button 
              color="primary" 
              className={`${classes.floatingSideStrip__verticalText} ${sidePanelContentToShow === 'templates' ? classes.floatingSideStrip__verticalText__selected : ''}`} 
              onClick={() => {
                if (sidePanelContentToShow === 'templates') {
                  setShowSidePanel(!showSidePanel);
                  setSidePanelContentToShow('');
                } else {
                  setShowSidePanel(true);
                  setSidePanelContentToShow('templates');
                }
              }}
            >
              Templates &nbsp;
              <DoubleArrowIcon />
            </Button>
          </Box>
        </Box>
      }

      <div className={classes.toolbarWrapper}>
        <PlannerGridToolbar 
          cleanRooms={cleanRooms} 
          people={people} 
          equipment={equipment} 
          isNoneSelected={isDeselectAllToolbarOptions}
          onZoomLevelChange={(value) => {
            setZoomLevel(value);
          }}
          onViewChange={(value, resource, additionalFilters = {customer: null, subjectId: null}) => {
            setSelectedCustomerFilter(additionalFilters?.customer);
            setSelectedSubjectIdFilter(additionalFilters?.subjectId);
            setView(value);
            setViewAdditionalData(resource);
            setIsDeselectAllToolbarOptions(false);
            if (SCHEDULE_VIEWS.indexOf(value) === -1) {
              setShowSidePanel(false);
              setSidePanelContentToShow('');
            }
            setIsScheduleDrillDownActivated(false);
          }}
          events={plannerEvents} 
        />
        {isScheduleDrillDownActivated && <Box>
          <PlannerGridBreadcrumb onClick={(item) => {
            if (item.type === RESOURCE_TYPE.schedule) {
              setView(RESOURCE_TYPE.singleScheduleCapacityView);
              setViewAdditionalData(item);
            } else if (item.type === RESOURCE_TYPE.cleanRoom) {
              setView(RESOURCE_TYPE.singleCleanRoomCapacityView);
              setViewAdditionalData(item);
            } else if (item.type === RESOURCE_TYPE.equipment) {
              setView(RESOURCE_TYPE.singleEquipmentCapacityView);
              setViewAdditionalData(item);
            } else if (item.type === RESOURCE_TYPE.person) {
              setView(RESOURCE_TYPE.singlePersonCapacityView);
              setViewAdditionalData(item);
            }
          }} 
          onDelete={(item) => {
            if (item?.type === RESOURCE_TYPE.schedule && item?.scheduleId) {
              deleteEventsByScheduleId(item.scheduleId).then(() => {
                history.push('/schedule/plan');
              });
            }
          }}
          items={[scheduleDrillDownBreadcrumb.schedule, scheduleDrillDownBreadcrumb.resource]} />
        </Box>}
      </div>

      <Box position="relative">
        {isLoading && <CircularProgress size={25} className={classes.loadingIndicator} color="secondary" />}
      </Box>
      
      <Box display="flex">
        <WaGrid 
          zoomLevel={zoomLevel}
          numCols={isCapacityView ? numResourceCols : numScheduleCols}
          numRows={numDays}
          colLabels={colLabels}
          rowLabels={rowLabels}
          cellData={cellData}
          cellDataType={view}
          onLoadMore={(evt) => {
            // console.log('onLoadMore: ', evt);
            handleLoadMore(evt);
          }}
          onCellClick={(cellId) => {
            // console.log('onCellClick: ', cellId, cellData[cellId]);
            if (view == RESOURCE_TYPE.day || view == RESOURCE_TYPE.cleanRoom || view == RESOURCE_TYPE.equipment || view == RESOURCE_TYPE.person) {
              if (cellData[cellId]) {
                setIsResourceAssignmentModalVisible(true);
                setSelectedScheduleEvent(cellData[cellId]);
              } else {
                // TODO: Implement logic to insert a day in the schedule (if applicable)
              }
            }
          }}
          onCellTooltipPreRender={(singleCellData) => {
            if(singleCellData?.type == RESOURCE_TYPE.event) {
              const subId = singleCellData.meta_subjectId ? `Subject : ${singleCellData.meta_subjectId} <br/>` : '';
              const peopleCSV = singleCellData.resources.filter(i => i.type === RESOURCE_TYPE.person).map((r) => r.shortName).join(',');
              const equipmentCSV = singleCellData.resources.filter(i => i.type === RESOURCE_TYPE.equipment).map((r) => r.shortName).join(',');
              const cleanRoomCSV = singleCellData.resources.filter(i => i.type === RESOURCE_TYPE.cleanRoom).map((r) => r.shortName).join(',');
              
              const tooltipText = `Day ${singleCellData.dayNum}<br/> ${subId} Customer: ${singleCellData.clientCode} <br/>
              ${peopleCSV ? `People: ${peopleCSV}<br/>` : ''} 
              ${equipmentCSV ? `Equipment: ${equipmentCSV}<br/>` : ''}
              ${cleanRoomCSV ? `Clean Room: ${cleanRoomCSV}<br/>` : ''}`;
                                   
              return tooltipText;
            } 
            return singleCellData || '';
          }}
          onCellStylesPreRender={(cellId) => {
            if ((view == RESOURCE_TYPE.day || !(view.includes('single'))) && cellData[cellId]) {
              if (cellData[cellId].hasConflict) {
                return {
                  backgroundColor: '#FAA0A0'
                };
              } else if (cellData[cellId].hasResourceDowntime) {
                return {
                  backgroundColor: '#FFA500'
                };
              } else {
                return {
                  backgroundColor: '#C1E1C1'
                };
              }
            }
          }}
          onCellDataPreRender={(singleCellData) => {
            // console.log('onCellDataPreRender: ', singleCellData);
            if (singleCellData.type == RESOURCE_TYPE.event) {
              if (view == RESOURCE_TYPE.day) {
                return singleCellData.title;
              } else if (view == RESOURCE_TYPE.cleanRoom || view == RESOURCE_TYPE.equipment || view == RESOURCE_TYPE.person) {
                return `${singleCellData.resources
                  .filter((r) => r.type === view)
                  .map((r) => r.shortName).join(',')} [${singleCellData.title}]`;
              }
            } 
          }}
          onCellColumnHeadClick={(id, label, additionalData) => {
            // console.log('onCellColumnHeadClick: ', id, label, additionalData);
            if (additionalData.type === RESOURCE_TYPE.schedule) {
              setView(RESOURCE_TYPE.singleScheduleCapacityView);
              setViewAdditionalData(additionalData);
              setIsScheduleDrillDownActivated(true);
              setScheduleDrillDownBreadcrumb({ schedule: {label, ...additionalData}, resource: null });
            } else if (additionalData.type === RESOURCE_TYPE.cleanRoom) {
              setView(RESOURCE_TYPE.singleCleanRoomCapacityView);
              setViewAdditionalData(additionalData);
              if (isScheduleDrillDownActivated) {
                setScheduleDrillDownBreadcrumb({ schedule: scheduleDrillDownBreadcrumb.schedule, resource: {label, ...additionalData} });
              }
            } else if (additionalData.type === RESOURCE_TYPE.equipment) {
              setView(RESOURCE_TYPE.singleEquipmentCapacityView);
              setViewAdditionalData(additionalData);
              if (isScheduleDrillDownActivated) {
                setScheduleDrillDownBreadcrumb({ schedule: scheduleDrillDownBreadcrumb.schedule, resource: {label, ...additionalData} });
              }
            } else if (additionalData.type === RESOURCE_TYPE.person) {
              setView(RESOURCE_TYPE.singlePersonCapacityView);
              setViewAdditionalData(additionalData);
              if (isScheduleDrillDownActivated) {
                setScheduleDrillDownBreadcrumb({ schedule: scheduleDrillDownBreadcrumb.schedule, resource: {label, ...additionalData} });
              }
            }
            setShowSidePanel(false);
            setSidePanelContentToShow('');
            setIsDeselectAllToolbarOptions(true);
          }}
          onResourceDrop={(resource, cellId) => {
            // console.log('planner-grid-view: onResourceDrop: ', resource, cellId, cellData[cellId]);
            // console.log(resource?.data?.scheduleId, colLabels, (cellId%numScheduleCols), colLabels[cellId%numScheduleCols]?.additionalData?.scheduleId);

            if (resource.type == RESOURCE_TYPE.template) {
              if (cellData[cellId]) {
                setIsSnackbarVisible(true);
                setSnackbarMessage('Invalid Template assignment!');
              } else {
                handleTemplateDrop(resource, cellId);
              }
            } else if ([RESOURCE_TYPE.cleanRoom, RESOURCE_TYPE.equipment, RESOURCE_TYPE.person].indexOf(resource.type) !== -1) {
              if (cellData[cellId] && cellData[cellId].type === RESOURCE_TYPE.event) {
                handleResourceDrop(resource, cellId);
              } else {
                setIsSnackbarVisible(true);
                setSnackbarMessage('Invalid Resource assignment!');
              }
            } else if (resource.type === RESOURCE_TYPE.event) {
              if (cellData[cellId]) {
                setIsSnackbarVisible(true);
                setSnackbarMessage('Another Event found. Cannot move to this Date.');
              } else if (resource?.data?.scheduleId === colLabels[cellId%numScheduleCols]?.additionalData?.scheduleId) {
                // Event is allowed to be moved in the same column
                const rl = rowLabels[Math.floor(cellId/numScheduleCols)];        
                const movedToDate = dayjs(rl.dateString);

                setMovedScheduleEvent({ event: resource?.data, newDate: movedToDate });
                setShowEventMoveConfirmation(true);
              } else {
                setIsSnackbarVisible(true);
                setSnackbarMessage('Cannot move Event here.');
              }
            }
          }}
        />
        
        <PlannerGridSidePanel isVisible={showSidePanel} contentType={sidePanelContentToShow} />
        <PlannerGridCapacityCharts 
          isVisible={isCapacityChartVisible} 
          resource={viewAdditionalData}
          dateRangeStart={dateRangeStart}
          dateRangeEnd={dateRangeEnd}
          plannerEvents={eventsForSingleResourceCapacityView}
          totalAvailability={resourceTotalAvailability}
          totalUtilization={resourceTotalUtilization}
          // Resource is available only for single resource capacity view
        />
      </Box>

      <SimpleSnackbar
        isVisible={isSnackbarVisible}
        message={snackbarMessage}
        onClose={() => {
            setIsSnackbarVisible(false);
        }}
      />

      {selectedScheduleEvent && <ResourceAssignmentModal
        isVisible={isResourceAssignmentModalVisible}
        scheduleEvent={selectedScheduleEvent}
        onSave={() => {
          setIsResourceAssignmentModalVisible(false);
          setIsSnackbarVisible(true);
          setSnackbarMessage(`Successfully saved Schedule & re-calculated slots.`);
          setSelectedScheduleEvent(null);
        }}
        onClose={() => {
          setIsResourceAssignmentModalVisible(false);
          setSelectedScheduleEvent(null);
        }}
        onDelete={() => {
          setIsResourceAssignmentModalVisible(false);
          setIsSnackbarVisible(true);
          setSnackbarMessage(`Successfully deleted Schedule & re-calculated slots.`);
          setSelectedScheduleEvent(null);
        }}
        cleanRooms={cleanRooms} 
        processes={processes} 
        equipment={equipment} 
        people={people} 
        clients={clients} 
      />}

      <ConfirmationModal 
        isVisible={showEventMoveConfirmation} 
        isLoadingIndicatorVisible={isSavingMoveScheduleData}
        message="You are moving this Event to a different Date. Are you sure?" 
        onOk={() => {
          const scheduleEvent = movedScheduleEvent.event;
          const movedToDateDayjs = movedScheduleEvent.newDate;
          // console.log('diffDays: ', movedToDateDayjs.diff(dayjs(scheduleEvent.start), 'day', true), );
          // const numDaysDiff = Math.ceil(movedToDateDayjs.diff(dayjs(scheduleEvent.start), 'day', true));
          const numDaysDiff = +movedToDateDayjs.format('YYYYMMDD') - +dayjs(scheduleEvent.start).format('YYYYMMDD');
          // console.log('move ok: movedScheduleEvent: ', numDaysDiff, 'movedToDateDayjs: ', movedToDateDayjs, dayjs(scheduleEvent.start));

          setIsSavingMoveScheduleData(true);
          moveSchedule(scheduleEvent.scheduleId, numDaysDiff).then(() => {
            return recalculateAndPublishSlotAvailability();
          }).then(() => {
            return attempToResolveConflictsInOtherEvents();
          }).then(() => {
            return detectConflictAndSaveStatusForSchedule(scheduleEvent.scheduleId);
          }).then(() => {
            setIsSavingMoveScheduleData(false);
            setMovedScheduleEvent({ event: null, newDate: null });
            setShowEventMoveConfirmation(false);
          });
        }} onCancel={() => {
          setMovedScheduleEvent({ event: null, newDate: null });
          setShowEventMoveConfirmation(false);
        }} 
      />
    </Box>
  )
}

export default PlannerGrid;