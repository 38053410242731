import { useEffect, useState } from "react";
import { FormDialogue } from "formik-generator-materialui";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import useTemplateData from "../../hooks/scheduler/use-template-data";
import useSchedulerProcesses from "../../hooks/scheduler/use-scheduler-processes";
import useSchedulerClients from "../../hooks/scheduler/use-scheduler-clients";
import useResources from "../../hooks/scheduler/use-resources";
import { makeStyles } from "@material-ui/styles";

const formFieldsSchema = [
  {
    "title": "",
    typeField: "group",
    subfields: [
      {
        title: 'Template Name',
        typeField: 'text',
        path: "name",
        required: true,
        col: 4,
      },
      {
        title: 'Process',
        typeField: 'select',
        choices: [],
        path: "process",
        required: true,
        col: 4,
      },
      {
        title: 'Client',
        typeField: 'select',
        choices: [],
        path: "client",
        required: true,
        col: 4,
      }]
  },
  {
    title: '',
    typeField: 'arrayObject',
    path: "data",
    emptyAddText: 'Add Day',
    subfields: [
      {
        title: "Day #",
        name: "dayNum",
        typeField: "text",
        required: true,
        col: 1
      },
      {
        title: "Day Label",
        name: "dayLabel",
        typeField: "text",
        required: true,
        col: 2
      },
      {
        title: "Clean Rooms",
        typeField: 'array',
        name: "resource/cleanRoom/multiple",
        required: true,
        col: 3,
        subfield: {
          typeField: "select",
          choices: [],
        }
      },
      {
        title: "Equipment",
        typeField: 'array',
        name: "resource/equipment/multiple",
        required: true,
        col: 3,
        subfield: {
          typeField: "select",
          choices: [],
        }
      },
      {
        title: "People",
        typeField: 'array',        
        name: "resource/people/multiple",
        subfield: {
          typeField: "select",
          choices: [],
        },
        required: true,
        col: 3
      },
      // {
      //   title: "Start Time",
      //   name: "startTime",
      //   typeField: "date",
      //   required: true,
      //   col: 2
      // },
      // {
      //   title: "Duration",
      //   name: "duration",
      //   typeField: "select",
      //   required: true,
      //   choices: ['5 mins'],
      //   col: 2
      // },
    ]
  },
  {
    title: 'Active',
    typeField: 'switch',
    path: 'active'
  }
];
const useStyles = makeStyles(theme => ({
  additionalFields: {
    margin:'25px',
    fontSize:'15px',
  }
}));

const TemplateFormModal = ({ isVisible, templateId = null, onClose = null, onSave = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const { template, add, update } = useTemplateData(templateId);
  const { processes } = useSchedulerProcesses();
  const { clients } = useSchedulerClients();
  const { cleanRooms, equipment, people } = useResources();

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (((templateId && template) || !templateId) && 
      processes && clients && cleanRooms.length > 0 && equipment && people) {
      setIsLoadingComplete(true);

      formFieldsSchema[0].subfields[1].choices = processes.map((p) => {
        return { value: p.code, title: p.name };
      });

      formFieldsSchema[0].subfields[2].choices = clients.map((c) => {
        return { value: c.code, title: c.name };
      });

      formFieldsSchema[1].subfields[2].subfield.choices = cleanRooms.map((cr) => {
        return { value: cr.code, title: cr.name };
      });
      formFieldsSchema[1].subfields[3].subfield.choices = equipment.map((eq) => {
        return { value: eq.code, title: eq.name };
      });
      formFieldsSchema[1].subfields[4].subfield.choices = people.map((pe) => {
        return { value: pe.code, title: pe.name };
      });

      if (template) {
        setInitialValues(template);
      }
    }
  }, [templateId, template, processes, clients, cleanRooms, equipment, people]);

  const classes = useStyles();

  const showHeadData = (templateData) => {
    return (<>
        Edit Template  <span className={classes.additionalFields}> Name: {templateData.name} </span> 
        <span className={classes.additionalFields}> Process: {templateData.process} </span> 
        <span className={classes.additionalFields}> Client: {templateData.client} </span>
    </>)

  }

  return (
    <>
      {isLoadingComplete && <FormDialogue
        component={
          <Box style={{ marginBottom: '20px'}}>
            <Alert variant="outlined" color="info">
              <Box>All Events are created as <strong>Full Day</strong> events.</Box>
              <Box>All <strong>Resources</strong> assigned to Events are for the <strong>entire day</strong>.</Box>
              <Box>You may <strong>Edit the Start/End Time</strong> of the Events & Resources in the Master Planner.</Box>
            </Alert>
          </Box>
        }
        maxWidth="lg"
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
          onClose & onClose();
        }}
        okText="Save"
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          process: Yup.string().required(),
          client: Yup.string().required()
        })}
        onOk={(values) => {
          console.log('template onSave: ', templateId, values);
          if (templateId) {
            update(values).then(() => {
              onSave?.();
            });
          } else {
            add(values).then(() => {
              onSave?.();
            });
          }
        }}
        title={templateId ? showHeadData(initialValues)  : "New Template"}
        text=""
        initialValues={initialValues}        
        fields={formFieldsSchema}
      />}
    </>
  );
}

export default TemplateFormModal;