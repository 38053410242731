import React, { useMemo, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import dayjs from 'dayjs';
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer
} from 'react-big-calendar';
import EventBusyIcon from "@material-ui/icons/EventBusy";
import PersonIcon from "@material-ui/icons/Person";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Box from "@material-ui/core/Box";
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import * as CalendarDateUtils from '../../utils/big-calendar-date-utils';
import Wrapper from "../../components/Wrapper/Wrapper";
import Breadcrumb from '../../design-system/breadcrumb';
import PageTitle from "../../design-system/page-title";
import SplitButtonWithOptions from '../../design-system/split-button-with-options';
import TwoWeeksView from '../../design-system/calendar/two-weeks-view';
import SchedulerOrerFormV2Modal from '../../components/scheduler/scheduler-order-form-v2-modal';
import SimpleSnackbar from "../../design-system/simple-snackbar";
import useSchedulerEvents from '../../hooks/scheduler/use-scheduler-events';
import useSchedulerBlockedDays from '../../hooks/scheduler/use-scheduler-blocked-days';
import useSchedulerAutoAllocationPublishStatus from '../../hooks/scheduler/use-scheduler-auto-allocation-publish-status';
import useUserSession from '../../hooks/use-user-session';
import { RESOURCE_TYPE, SCHEDULE_TYPE, USER_ROLE_MASTER, SCHEDULE_ORDER_DATE_TYPE } from '../../hooks/constants';
import CalendarEventCard from '../../design-system/calendar/calendar-event-card';
import useResources from '../../hooks/scheduler/use-resources';

const mLocalizer = momentLocalizer(moment);

const useStyles = makeStyles(theme => ({
  root: {
    height: '80vh',
    width: '100%'
  },
  event__color_red: {
    border: '3px solid red !important',
  },
  event__color_orange: {
    backgroundColor: '#FAC898',
    color: '#111',
    '&.rbc-selected': {
      backgroundColor: '#FAC898',
    }
  },
  event__color_blue: {
    backgroundColor: '#A7C7E7',
    color: '#111',
    '&.rbc-selected': {
      backgroundColor: '#A7C7E7',
    }
  },
  event__color_purple: {
    backgroundColor: '#C3B1E1',
    color: '#111',
    '&.rbc-selected': {
      backgroundColor: '#C3B1E1',
    }
  },
  event__color_brown: {
    backgroundColor: '#B1907F',
    color: '#111',
    '&.rbc-selected': {
      backgroundColor: '#B1907F',
    }
  },
  event__color_moonstoneBlue: {
    backgroundColor: '#6BBAB8',
    color: '#111',
    '&.rbc-selected': {
      backgroundColor: '#6BBAB8',
    }
  },
  event__color_turquoise: {
    backgroundColor: '#57f6c0',
    color: '#fff',
    '&.rbc-selected': {
      backgroundColor: '#57f6c0',
    }
  },
  event__color_purple2: {
    backgroundColor: '#480000',
    color: '#fff',
    '&.rbc-selected': {
      backgroundColor: '#480000',
    }
  },
  event__color_blue2: {
    backgroundColor: '#001eef',
    color: '#fff',
    '&.rbc-selected': {
      backgroundColor: '#001eef',
    }
  },
  event__color_blue3: {
    backgroundColor: '#166991',
    color: '#fff',
    '&.rbc-selected': {
      backgroundColor: '#166991',
    }
  },
  event__color_green: {
    backgroundColor: '#199661',
    color: '#fff',
    '&.rbc-selected': {
      backgroundColor: '#199661',
    }
  },
  event__color_green2: {
    backgroundColor: '#A3EBB1',
    color: '#111',
    '&.rbc-selected': {
      backgroundColor: '#A3EBB1',
    }
  },
  event__color_yellow: {
    backgroundColor: '#F7E594',
    color: '#111',
    '&.rbc-selected': {
      backgroundColor: '#F7E594',
    }
  },
  date__color_white: {
    backgroundColor: '#fff !important'
  },
  date__block_highlight: {
    border: '1px dashed #f50057 !important'
  },
  date__block_selected: {
    backgroundColor: 'rgba(245, 0, 87, 0.1) !important'
  },
  date__slot_unavailable: {
    backgroundColor: 'rgba(238, 75, 43, 0.1) !important'
  },
  date__slot_undefined: {
    // backgroundColor: 'rgba(99, 3, 48, 0.1) !important',
    backgroundColor: '#efefef !important'
  },
  date__less_than_14Days: {
    backgroundColor: 'rgba(247, 229, 148, 0.2) !important'
  },
  highlight__today: {
    backgroundColor: 'rgb(144 210 234 / 28%) !important',
    border: '2px solid #434346 !important'
  }
}));

const ColoredTimeCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      // backgroundColor: 'green',
    },
  });

const ColoredDateCellWrapper = ({ children }) => {
  return React.cloneElement(React.Children.only(children), {
    style: {
      // backgroundColor: 'green',
    },
  })
};

// const CALENDAR_VIEWS = {
//   MONTH: 'month',
//   TWO_WEEKS: 'twoWeeks',
//   WEEK: 'week',
//   DAY: 'day'
// };

const ViewSchedulePage = () => {
  const [today, setToday] = useState(dayjs());
  const todayPlus14DaysMDY = today.add(13, 'day').format('MMDDYYYY');
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Successfully created Order!');
  const [scheduleType, setScheduleType] = useState(SCHEDULE_TYPE.SITE);
  const [selectedSchedulerEvent, setSelectedSchedulerEvent] = useState(null);
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(null);
  const [isBlockingCalendar, setIsBlockingCalendar] = useState(false);
  const [numDaysBlocked, setNumDaysBlocked] = useState(0);
  const [selectedBlockedDaysMap, setSelectedBlockedDaysMap] = useState({});
  const [selectedCalendarView, setSelectedCalendarView] = useState('month');
  const [selectedClientFilter, setSelectedClientFilter] = useState(null);
  const { userProfile } = useUserSession();
  const { clients } = useResources(false, RESOURCE_TYPE.client);
  const [searchEvent, setSearchEvent] = useState({});
  const [subjectId, setSubjectId] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const classes = useStyles();

  const monthViewStartDate = useMemo(() => CalendarDateUtils.firstVisibleDay(selectedDate, mLocalizer), [selectedDate]);
  const monthViewEndDate = useMemo(() => CalendarDateUtils.lastVisibleDay(selectedDate, mLocalizer), [selectedDate]);

  // Note: always passing EMPTY Subject ID
  const { events } = useSchedulerEvents(scheduleType, selectedClientFilter?.code || userProfile.clientCode, '', monthViewStartDate, monthViewEndDate); 
  const { blockedDaysMap, saveBlockedDays } = useSchedulerBlockedDays();
  const { dataWithDatesInMDY: slotAvailabilityData } = useSchedulerAutoAllocationPublishStatus();

  // console.log(monthViewStartDate, monthViewEndDate);

  // https://stackoverflow.com/questions/58605394/modifying-the-tooltip-in-react-big-calendar
  // https://github.com/jquense/react-big-calendar/issues/538
  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {        
        timeSlotWrapper: ColoredTimeCellWrapper,
        dateCellWrapper: ColoredDateCellWrapper,
        event: CalendarEventCard
      },
      defaultDate: selectedDate, // new Date(2015, 3, 1),
      max: CalendarDateUtils.add(CalendarDateUtils.endOf(new Date(), 'day'), -1, 'hours'), // CalendarDateUtils.add(CalendarDateUtils.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours'),
      // views: Object.keys(Views).filter((v) => (v === 'MONTH' || v === 'WEEK' || v === 'DAY')).map((k) => Views[k]),
      views: {
        month: true,
        twoWeeks: TwoWeeksView,
        week: true,
        day: true
      }
    }),
    []
  );

  const processColorMap = {
    'pr-01': classes.event__color_orange,
    'pr-02': classes.event__color_moonstoneBlue,
    'pr-03': classes.event__color_blue,
    'pr-04': classes.event__color_purple,
    'pr-05': classes.event__color_brown,
    'pr-5': classes.event__color_brown,
    'pr-06': classes.event__color_turquoise,
    'pr-07': classes.event__color_purple2,
    'pr-08': classes.event__color_blue2,
    'pr-09': classes.event__color_blue3,
    'pr-10': classes.event__color_green,
    'NEPH': classes.event__color_brown,
    'FREN': classes.event__color_moonstoneBlue
  };

  const handleCalendarClick = (evt) => {
    if (isBlockingCalendar && selectedCalendarView === 'month') {
      const dateISOStr = evt.start.toISOString();
      const mdy = dayjs(evt.start).format('MMDDYYYY');
      const tempBlockedDaysMap = {};
      Object.assign(tempBlockedDaysMap, selectedBlockedDaysMap);
      
      if (tempBlockedDaysMap[mdy]) {                  
        delete tempBlockedDaysMap[mdy];
        if (numDaysBlocked > 0) {
          setNumDaysBlocked(numDaysBlocked-1);
        }
        setSelectedBlockedDaysMap(tempBlockedDaysMap);
      }  else {
        setNumDaysBlocked(numDaysBlocked+1);
        setSelectedBlockedDaysMap(Object.assign(tempBlockedDaysMap, {
          [mdy]: true
        }));
      }
    } else {
      if (userProfile.role === USER_ROLE_MASTER.SITE_USER) {
        const startMidnight = new Date(evt.start.getTime());
        startMidnight.setHours(0);
        startMidnight.setMinutes(0);
        // const dateISOStr = startMidnight.toISOString();
        const mdy = dayjs(startMidnight).format('MMDDYYYY');
        if (blockedDaysMap[mdy]) {
          setIsSnackbarVisible(true);
          setSnackbarMessage('Cannot create schedule. Date is blocked!');
        } else if (mdy <= todayPlus14DaysMDY) {
          if(mdy < today.format('MMDDYYYY')) {
            setSnackbarMessage('Cannot create schedule.');
          }
          else {
            setSnackbarMessage('Cannot create schedule. Booking is allowed 14 days from today!');
          }
          setIsSnackbarVisible(true);
          
        } else if(slotAvailabilityData?.slots[mdy] === 0) {
          setIsSnackbarVisible(true);
          setSnackbarMessage('Cannot create schedule. No slots available!');
        } else if (!slotAvailabilityData?.slots[mdy]) {
          setIsSnackbarVisible(true);
          setSnackbarMessage('Cannot create schedule. No slots allocated!');
        } else {
          setSelectedCalendarDate(evt.start);
          setShowOrderForm(true);
        }
      }
    }
  };

  const handleCalendarEventPropGetter = (event, start, end, isSelected) => {

    let cssClasses = [];

    if (event.scheduleType === SCHEDULE_TYPE.PLANNER) {
      cssClasses.push(processColorMap[event.clientCode])
      if (event.meta_subjectId === searchEvent?.meta_subjectId && event.dayNum === searchEvent?.dayNum) {
        cssClasses.push(classes.event__color_red)
      }
    }
    else {
      event.meta_isApproved ? cssClasses.push(classes.event__color_green2) : cssClasses.push(classes.event__color_yellow);
      (event.meta_subjectId === searchEvent?.meta_subjectId) ? cssClasses.push(classes.event__color_red) : '';
    }

    return {
      className: cssClasses
    };
  };

  const handleCalendarSlotPropGetter = (date) => {
    // const dateISOStr = date.toISOString();
    const mdy = dayjs(date).format('MMDDYYYY');
    
    if (isBlockingCalendar) {
      return {
        // className: `${classes.date__block_highlight} ${selectedBlockedDaysMap[dateISOStr] ? classes.date__block_selected : ''}`
        className: `${classes.date__block_highlight} ${selectedBlockedDaysMap[mdy] ? classes.date__block_selected : classes.date__color_white}`
      };
    } else {
      // console.log('bang: ', slotAvailabilityData?.slots[dateISOStr]);
      const attrs = {
        className: ''
      };
      if (blockedDaysMap[mdy]) {
        attrs.className = classes.date__block_selected;
      } else if(mdy <= todayPlus14DaysMDY) {
        attrs.className = classes.date__less_than_14Days;
      } else if (slotAvailabilityData?.slots[mdy] === 0) {
        attrs.className = classes.date__slot_unavailable;
      } else if (!slotAvailabilityData?.slots[mdy]) {
        attrs.className = classes.date__slot_undefined;
      }
      return attrs;
    }
  };

  const handleCalendarDayPropGetter = (date) => {
    // const dateISOStr = date.toISOString();
    const mdy = dayjs(date).format('MMDDYYYY');
    const todayDate =  today.format('MMDDYYYY');

    if (isBlockingCalendar) {
      return {
        // className: `${classes.date__block_highlight} ${selectedBlockedDaysMap[dateISOStr] ? classes.date__block_selected : ''}`
        className: `${classes.date__block_highlight} ${selectedBlockedDaysMap[mdy] ? classes.date__block_selected : classes.date__color_white}`
      };
    } else {
      const attrs = {
        className: classes.date__color_white
      };
      if (blockedDaysMap[mdy]) {
        attrs.className = classes.date__block_selected;
      } else if (mdy == todayDate) {
        attrs.className = classes.highlight__today;
      } else if(mdy <= todayPlus14DaysMDY) {
        attrs.className = classes.date__less_than_14Days;
      } else if (slotAvailabilityData?.slots[mdy] === 0) {
        attrs.className = classes.date__slot_unavailable;
      } else if (!slotAvailabilityData?.slots[mdy]) {
        attrs.className = classes.date__slot_undefined;
      } 
      return attrs;
    }
  }
  
  return (
    <Wrapper>        
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box sx={{ flexGrow: 1 }} > 
          <PageTitle title="View Schedule"  /> 
        </Box>
        <Box sx={{ flexGrow: 2 }} sx={{width:200}}>
          <Autocomplete
            onChange={(event, value) => {
              if (value?.start) {
                setSelectedDate(value.start);
                setSearchEvent(value);
              }
              else {
                setSearchEvent('');
              }
            }}
            freeSolo
            autoHighlight
            options={events.sort((a, b) => {
              return a.dayNum - b.dayNum;
            }).filter((option) => !!option.meta_subjectId)}
            getOptionLabel={(option) => option.meta_subjectId}
            renderOption={(props, option) => {
              return (
                <Box display="flex" alignItems="center">
                  {props.scheduleType === SCHEDULE_TYPE.PLANNER && <span>Day {props.dayNum}:&nbsp;</span>}
                  {props.scheduleType === SCHEDULE_TYPE.SITE && props.meta_eventType === SCHEDULE_ORDER_DATE_TYPE.BIOPSY && <PersonIcon color="primary" />}
                  {props.scheduleType === SCHEDULE_TYPE.SITE && props.meta_eventType === SCHEDULE_ORDER_DATE_TYPE.INJECTION_1 && <DoneIcon color="primary" />}
                  {props.scheduleType === SCHEDULE_TYPE.SITE && props.meta_eventType === SCHEDULE_ORDER_DATE_TYPE.INJECTION_2 && <DoneAllIcon color="primary" />}
                  {props.meta_subjectId}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Search Subject Id" 
              margin="none" size="small" variant="outlined" />
            )}
          />
        </Box>
        {(userProfile?.role === USER_ROLE_MASTER.LAB_USER || userProfile?.role === USER_ROLE_MASTER.ADMIN) && <Box ml={1} display="flex" alignItems="center" height="32">
          <ButtonGroup variant="outlined" size="small">
            {((!isBlockingCalendar && selectedCalendarView === 'month') || isBlockingCalendar) && <Button
              color="secondary"
              size="small"
              onClick={() => {
                setIsBlockingCalendar(true);
                if (blockedDaysMap) {
                  setSelectedBlockedDaysMap(Object.assign({}, blockedDaysMap));
                }
              }}
            >
              <EventBusyIcon />
              {isBlockingCalendar && <span>({numDaysBlocked})&nbsp;</span>}
              Block Dates
            </Button>}
            {isBlockingCalendar && <Button color="secondary" onClick={() => {
              saveBlockedDays(selectedBlockedDaysMap).then(() => {
                setIsBlockingCalendar(false);
                setNumDaysBlocked(0);
                setSelectedBlockedDaysMap({});
              });
            }}><SaveIcon color="secondary" /></Button>}
            {isBlockingCalendar &&<Button color="secondary" onClick={() => {
              setIsBlockingCalendar(false);
              setNumDaysBlocked(0);
              setSelectedBlockedDaysMap({});
            }}><CancelIcon color="secondary" /></Button>}
          </ButtonGroup>&nbsp;&nbsp;
          <SplitButtonWithOptions options={['View Site Events', 'View Planner Events']} onSelect={(val) => {
            if (val === 'View Site Events') {
              setScheduleType(SCHEDULE_TYPE.SITE);
            } else {
              setScheduleType(SCHEDULE_TYPE.PLANNER);
            }
          }} />&nbsp;&nbsp;
          <SplitButtonWithOptions options={['All Customers'].concat(clients.map(c => c.code))} onSelect={(val) => {
            if (val !== 'All Customers') {
              const selectedClient = clients.find(c => c.code === val);
              if (selectedClient) {
                setSelectedClientFilter(selectedClient);
              }
            } else {
              setSelectedClientFilter(null);
            }
          }} />
        </Box>}
      </Box>

      <Box>
        <div className={classes.root}>
          <Calendar
            components={components}
            defaultView="month"
           // defaultDate={selectedDate}
            date={selectedDate}
            events={events}
            eventPropGetter={handleCalendarEventPropGetter}
            slotPropGetter={handleCalendarSlotPropGetter}
            dayPropGetter={handleCalendarDayPropGetter}
            tooltipAccessor={null}
            onView={(selectedView) => {
              setSelectedCalendarView(selectedView);
            }}
            onSelectEvent={(schedulerEventRec, evt) => {
              // console.log('onSelectEvent: ', schedulerEventRec, evt);
              if (schedulerEventRec.scheduleType === SCHEDULE_TYPE.SITE) {
                setSelectedSchedulerEvent(schedulerEventRec);
                setShowOrderForm(true);
              }
            }}
            messages={{
              noEventsInRange: 'There are no special events in this range!',
              twoWeeks: '2 Weeks'
            }}
            localizer={mLocalizer}
            max={max}
            showMultiDayTimes
            step={60}
            views={views}
            selectable
            onSelectSlot={handleCalendarClick}
            onNavigate={(newDate, view, action) => {
              setSelectedDate(newDate);
            }} 
          />
        </div>

        {showOrderForm && <SchedulerOrerFormV2Modal 
          isVisible={showOrderForm}
          scheduleId={selectedSchedulerEvent?.scheduleId}
          orderId={selectedSchedulerEvent?.meta_orderId}
          selectedCalendarDate={selectedCalendarDate}
          onSave={() => {
            setShowOrderForm(false);
            setIsSnackbarVisible(true);
            setSelectedSchedulerEvent(null);
            setSelectedCalendarDate(null);
            setSnackbarMessage('Successfully saved Order!');
          }} 
          onClose={() => {
            setShowOrderForm(false);
            setSelectedSchedulerEvent(null);
            setSelectedCalendarDate(null);
          }}
          onDelete={() => {
            setShowOrderForm(false);
            setSelectedSchedulerEvent(null);
            setSelectedCalendarDate(null);
          }}
        />}

        <SimpleSnackbar
          isVisible={isSnackbarVisible}
          message={snackbarMessage}
          onClose={() => {
            setIsSnackbarVisible(false);
          }}
        />
      </Box>
    </Wrapper>
  );
}

export default ViewSchedulePage;
