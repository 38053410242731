import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';
import Wrapper from "../../components/Wrapper/Wrapper";
import Breadcrumb from '../../design-system/breadcrumb';
import PageTitle from "../../design-system/page-title";
import ResourceDataGrid from '../../components/scheduler-admin/resource-datagrid';
import { RESOURCE_TYPE_NAME_MAP } from '../../hooks/constants';
import ResourceFormModal from '../../components/scheduler-admin/resource-form-modal';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  floatingButton: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(2.5)
  }
}));

const ManageResourcesPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [isCreateResource, setIsCreateResource] = useState(false);
  const { resourceType } = useParams();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Wrapper>        
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      <PageTitle title={`Manage ${RESOURCE_TYPE_NAME_MAP[resourceType]}`} />

      <ResourceDataGrid resourceType={resourceType} />
      <Zoom
          key={'fab-add-zoom'}
          in={!isCreateResource}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${isCreateResource ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
        <Fab color="primary" aria-label="create new resource" className={classes.floatingButton} onClick={() => {
          setIsCreateResource(true);
        }}>
          <AddIcon />
        </Fab>
      </Zoom>

      {isCreateResource && 
        <ResourceFormModal
          resourceType={resourceType}
          onClose={() => {
            setIsCreateResource(false);
          }}
          onSave={() => {
            setIsCreateResource(false);
          }}
      />}
    </Wrapper>
  );
}

export default ManageResourcesPage;