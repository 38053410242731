import { useEffect, useState } from 'react';
import { firestoreInstance } from '../config/firebase-init';

export default function useOrderLotList(orderId) {
  const lotsRef = firestoreInstance.collection('orders').doc(orderId).collection('lots');

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
      const unsubscribeLotsRef = lotsRef.onSnapshot((res) => {
          if (!res.empty) {
              const dataRecords = res.docs.map((doc) => {
                  return {...doc.data(), NO_ID_FIELD: doc.id};
              });
              setData(dataRecords);
          } else {
              setData([]);
          }
          setError(null);
      }, (err) => { setError(err); });
      
      return () => {
        unsubscribeLotsRef();
      };
  }, []);

  return {
    lotList: data,
    createLot: (payload) => {
      return lotsRef
        .add(payload)
        .catch((e) => {
          console.error("Error creating lot: ", e);
        });
    },
    updateLot: (lotId, payload) => {
      return lotsRef
        .doc(lotId)
        .update(payload)
        .catch((e) => {
          console.error("Error updating lot: ", e);
        });
    },
    deleteLot: (lotId) => {
      console.log('deleteLot: ', lotId);
      lotsRef
        .doc(lotId)
        .delete()
        .then(() => {
          console.log("Success deleting lot!");
        })
        .catch((error) => {
          console.error('Error deleting lot! ', error);
        });
    }
  };
}