import { useEffect, useState } from 'react';
import { firestoreInstance } from '../../config/firebase-init';

const useTemplateData = (templateId) => {
  const templatesRef = firestoreInstance.collection('schedulerTemplates');

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (templateId) {
      const unsubscribeTemplatesRef = templatesRef.doc(templateId).onSnapshot((doc) => {
        setData(doc.data());
        setError(null);
      }, (err) => { setError(err); });
      
      return () => {
        unsubscribeTemplatesRef();
      };
    }
  }, [templateId]);

  return {
    template: data,
    add: (values) => {
      return templatesRef.add(values).then(() => {
        return true;
      }).catch((err) => {
        console.error('Error creating template! ', err);
      });
    },
    update: (values) => {
      return templatesRef.doc(templateId).update(values).then(() => {
        return true;
      }).catch((err) => {
        console.error('Error creating template! ', err);
      });
    }
  };
}

export default useTemplateData;