import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import ExploreIcon from "@material-ui/icons/Explore";
// import AddIcon from "@material-ui/icons/Add";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { DragDropContext } from "react-beautiful-dnd";
import WorkflowColumn from "./workflow-column";
import useBatchMasterData from "../../hooks/use-batch-master-data";
import useFormsMasterList from '../../hooks/use-form-master-list';
import WorkflowColumnSettingsModal from './workflow-column-settings-modal';
import WorkflowFormsModal from './workflow-forms-modal';
import WorkflowManualProcessFormModal from './workflow-manual-process-form-modal';

const useStyles = makeStyles(theme => ({
  boardWrapper: {
    // display: 'flex'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0
  },
  board: {
    minHeight: '80vh',
    width: 'auto',
    display: `flex`,
    flexDirection: `row`,
    overflowX: 'auto',
    '& > div': {
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: 0
      }
    }
  }
}));

export default function WokflowBoard({ onSave }) {
  const history = useHistory();
  const { batchMasterRecordId } = useParams();
  const [isCheckpointModalOpen, setIsCheckpointModalOpen] = useState(false);
  const [isWorkflowFormsModalOpen, setIsWorkflowFormsModalOpen] = useState(false);
  const [isWorkflowColumnSettingsModalOpen, setIsWorkflowColumnSettingsModalOpen] = useState(false);
  const [selectedWorkflowStateData, setSelectedWorkflowStateData] = useState();
  const [isParkedFormsVisible, setIsParkedFormsVisible] = useState(true);
  
  const classes = useStyles();
  const { formsMasterList, formsMasterMap, allFormsMasterMap } = useFormsMasterList();
  const { 
    isLoading, 
    workflowConfig, 
    batchAvailableFormSlugs, 
    updateAvailableForms, 
    saveWorkflowStates, 
    addNewSequence,
    updateStateActivationRules
  } = useBatchMasterData(batchMasterRecordId);
  
  const { formCards, workflowStates, workflowStatesSequence } = workflowConfig;

  const handleDragEnd_formCard = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    } else if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    } else if (destination.droppableId === source.droppableId) {
      const workflowState = workflowStates[source.droppableId];
      const newFormCardSlugs = Array.from(workflowState.formSlugs);
      newFormCardSlugs.splice(source.index, 1);
      newFormCardSlugs.splice(destination.index, 0, draggableId);
      
      const newWorkflowState = {
        ...workflowState,
        formSlugs: newFormCardSlugs
      };
      
      const newWorkflowStates = {
        ...workflowStates,
        [workflowState.slug]: newWorkflowState
      };

      saveWorkflowStates(newWorkflowStates).then(() => {
        onSave?.();
      });
    } else {
      const sourceState = workflowStates[source.droppableId];      
      const newSourceFormCardSlugs = Array.from(sourceState.formSlugs);
      newSourceFormCardSlugs.splice(source.index, 1);

      const destinationState = workflowStates[destination.droppableId];
      const newDestinationFormCardSlugs = Array.from(destinationState.formSlugs);
      newDestinationFormCardSlugs.splice(destination.index, 0, draggableId);
      
      const newSourceWorkflowState = {
        ...sourceState,
        formSlugs: newSourceFormCardSlugs
      };

      const newDestinationWorkflowState = {
        ...destinationState,
        formSlugs: newDestinationFormCardSlugs
      };
      
      const newWorkflowStates = {
        ...workflowStates,
        [sourceState.slug]: newSourceWorkflowState,
        [destinationState.slug]: newDestinationWorkflowState
      };

      saveWorkflowStates(newWorkflowStates).then(() => {
        onSave?.();
      });;
    }
  };

  return (
    <Box>
      <Toolbar className={classes.toolbar}>
        <ButtonGroup
          size="small"
          color="primary"
          aria-label="outlined primary button group"
        >
          {/* <Button
            variant={isParkedFormsVisible ? "contained" : ""}
            onClick={() => {
              setIsParkedFormsVisible(!isParkedFormsVisible);
            }}
          >
            <ExploreIcon />
            Parked Forms
          </Button> */}
          <Button
            onClick={() => {
              setIsWorkflowFormsModalOpen(true);
            }}
          >
            <CheckBoxIcon />
            Choose Form
          </Button>
          <Button
            onClick={() => {
              setIsCheckpointModalOpen(true);
            }}
          >
            <SettingsBackupRestoreIcon />
            Manual Process
          </Button>
        </ButtonGroup>
        <ButtonGroup
          size="small"
          color="primary"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={() => {
              onSave?.();
            }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              history.push('/manage/batches');
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Toolbar>

      <Box className={classes.boardWrapper}>
        <Box>
          {!isLoading && <DragDropContext onDragEnd={handleDragEnd_formCard}>
            {isParkedFormsVisible && <WorkflowColumn 
              key="parked-forms" 
              stateData={workflowStates['parked-forms']} 
              formCardsData={workflowStates['parked-forms'].formSlugs.map((formSlug) => formCards[formSlug])}
              variant="row"
              isSettingsIconHidden={true}
            />}

            <Box className={classes.board}>
              {workflowStatesSequence?.map((stateSlug) => {
                const formCardsData = workflowStates[stateSlug].formSlugs.map((formSlug) => allFormsMasterMap[formSlug]);
                
                return (
                  <WorkflowColumn 
                    key={stateSlug} 
                    stateData={workflowStates[stateSlug]} 
                    formCardsData={formCardsData}
                    onSettingsClick={() => {
                      setIsWorkflowColumnSettingsModalOpen(true);
                      setSelectedWorkflowStateData(workflowStates[stateSlug]);
                    }}
                  />
                  );
                })
              }
              <Box>
                <Button
                  onClick={() => {
                    addNewSequence().then(() => {
                      onSave?.();
                    });
                  }}
                >
                  New Sequence
                </Button>
              </Box>
            </Box>
          </DragDropContext>}
        </Box>
      </Box>

      {isWorkflowColumnSettingsModalOpen && <WorkflowColumnSettingsModal
        workflowStateData={selectedWorkflowStateData}
        isVisible={isWorkflowColumnSettingsModalOpen}
        initialValues={{ sequenceActivationSettings: selectedWorkflowStateData.activationRules }}
        onSave={(values) => {
          updateStateActivationRules(selectedWorkflowStateData.slug, values['sequenceActivationSettings']).then(() => {
            setIsWorkflowColumnSettingsModalOpen(false);
          });
        }}
        onClose={() => {
          setIsWorkflowColumnSettingsModalOpen(false);
        }}
      />}

      {isWorkflowFormsModalOpen && <WorkflowFormsModal 
        isVisible={isWorkflowFormsModalOpen}
        formsMaster={formsMasterList}
        batchAvailableFormSlugs={batchAvailableFormSlugs}
        onSave={(availableForms) => {
          updateAvailableForms(availableForms).then(() => {
            setIsWorkflowFormsModalOpen(false);
            onSave?.();
          });
        }}
        onClose={() => {
          setIsWorkflowFormsModalOpen(false);
        }} 
      />}

      {isCheckpointModalOpen && <WorkflowManualProcessFormModal 
        isVisible={isCheckpointModalOpen}
        onSave={(manualProcessDescription, manualProcessFormTemplate) => {
          setIsCheckpointModalOpen(false);
          const newAvailableForms = [{
            name: manualProcessFormTemplate.name,
            slug: manualProcessFormTemplate.slug,
            type: manualProcessFormTemplate.type,
            description: manualProcessDescription
          }].concat(Object.values(formCards));

          updateAvailableForms(newAvailableForms).then(() => {
            setIsCheckpointModalOpen(false);
          });
        }}
        onClose={() => {
          setIsCheckpointModalOpen(false);
        }} 
      />}
    </Box>
  );
}