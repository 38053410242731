import { RESOURCE_TYPE } from '../constants';
import useResources from './use-resources';

const useSchedulerClients = () => {
  const { clients } = useResources(false, RESOURCE_TYPE.client);

  return {
    clients,
    lookupClientByCode: (code) => {
      const clientFound = clients.find((c) => {
        return (c.code === code);
      });
      return clientFound || {};
    }
  };
}

export default useSchedulerClients;