import { useMemo, useEffect, useState } from 'react';
import { PERMISSIONS_MASTER } from "../hooks/constants";
import { firestoreInstance } from '../config/firebase-init';

export default function usePermissions() {
    const [ permissionsMap, setPermissionsMap ] = useState({});
    const permissionsRef = firestoreInstance.collection('permissions').doc('data');

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribePermissionsRef = permissionsRef.onSnapshot((doc) => {
            setData(doc.data());
            setError(null);
        }, (err) => { setError(err); });

        return () => {
            unsubscribePermissionsRef();
        };
    }, []);

    useMemo(() => {
        if (data) {
            const tempMap = {};
            data.values.forEach((permVal) => {
                tempMap[permVal.code] = permVal;
            });
            setPermissionsMap(tempMap);
        }
    }, [data]);

    return {
        permissions: data ? data.values : [],
        permissionsMap,
        loadPermissionsMaster: () => {
            return permissionsRef.set({ values: PERMISSIONS_MASTER }).then(() => {
                return true;
            });
        },
        savePermissions: (values) => {
            return permissionsRef.set({ values }).then(() => {
                return true;
            });
        }
    }
}