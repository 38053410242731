import { makeStyles } from "@material-ui/core/styles";
import { Bar, Bubble } from "react-chartjs-2";
import React, { useState } from "react";
import { iotMockData } from "../../utils/mock/dashboard";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";

import Wrapper from "../../components/Wrapper/Wrapper";
import Breadcrumb from '../../design-system/breadcrumb';
import PageTitle from "../../design-system/page-title";

const useStyles = makeStyles(theme => ({
  legendCard: {
    marginBottom: theme.spacing(2)
  },
  legendCardContent: {
    width: '100%',
    padding: theme.spacing(1)
  },
  legendTitle: {
    fontSize: '0.7rem'
  },
  legendLine2: {
    fontSize: '0.8rem',
    fontWeight: 'bold'
  },
  legendLine3: {
    fontSize: '0.7rem',
  }
}));

const SensorMonitoringPage = () => {
  const classes = useStyles();

  return (
    <Wrapper>        
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      <PageTitle title="Monitoring" />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <Card className={classes.legendCard}>
            <Box display="flex" alignItems="center" >
              <div>
                <img src="/static/images/thermometer-2.png" height="50" />
              </div>
              <Box className={classes.legendCardContent}>
                <Typography className={classes.legendTitle}>TEMP</Typography>
                <Typography className={classes.legendLine2}>25.9 <sup>o</sup>C</Typography>
                <LinearProgress value="20" />
                <Typography className={classes.legendLine3}>min 17.9 / max 25.9 </Typography>
              </Box>
            </Box>
          </Card>

          <Card className={classes.legendCard}>
            <Box display="flex" alignItems="center" >
              <div>
                <img src="/static/images/bulb.png" height="50" />
              </div>
              <Box className={classes.legendCardContent}>
                <Typography className={classes.legendTitle}>LIGHT</Typography>
                <Typography className={classes.legendLine2}>88.8 lux</Typography>
                <LinearProgress value="35" />
                <Typography className={classes.legendLine3}>min 0.5 / max 88.8</Typography>
              </Box>
            </Box>
          </Card>

          <Card className={classes.legendCard}>
            <Box display="flex" alignItems="center" >
              <div>
                <img src="/static/images/black-cloud.png" width="50" />
              </div>
              <Box className={classes.legendCardContent}>
                <Typography className={classes.legendTitle}>CO</Typography>
                <Typography className={classes.legendLine2}>1.2 mg/m<sup>3</sup></Typography>
                <LinearProgress value="12" />
                <Typography className={classes.legendLine3}>min 1.2 / max 369.4</Typography>
              </Box>
            </Box>
          </Card>

          <Card className={classes.legendCard}>
            <Box display="flex" alignItems="center" >
              <div>
                <img src="/static/images/humidity.png" width="50" />
              </div>
              <Box className={classes.legendCardContent}>
                <Typography className={classes.legendTitle}>HUMIDITY</Typography>
                <Typography className={classes.legendLine2}>30.3%</Typography>
                <LinearProgress value="12" />
                <Typography className={classes.legendLine3}>min 29.6 / max 45.6</Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={8} md={9}>
          <Grid container spacing={2}>
            {iotMockData.map((chart, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Card>
                  <CardHeader
                    subheader={chart.title}
                  />
                  <CardContent>
                    {chart.type === "bar" && (
                      <Bar
                        data={chart.data}
                        height={chart.height}
                        options={chart.options}
                      />
                    )}
                    {chart.type === "bubble" && (
                      <Bubble
                        data={chart.data}
                        height={chart.height}
                        options={chart.options}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default SensorMonitoringPage;
