import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Wrapper from "../../components/Wrapper/Wrapper";
import Breadcrumb from '../../design-system/breadcrumb';
import PageTitle from "../../design-system/page-title";
import PlannerGrid from '../../components/scheduler/planner-grid';
import useUserSession from '../../hooks/use-user-session';
import { USER_ROLE_MASTER } from '../../hooks/constants';
import { formatDateToISOYMD } from '../../helpers';
import { KeyboardArrowRight } from '@material-ui/icons';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

// import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  square: {
    display:'inline-flex',
    width:'20px',
    height:'20px',
    backgroundColor:'rgb(255, 165, 0)',
    margin:'4px',
  },
  conflicts:{
    display:'inline-flex',
    width:'20px',
    height:'20px',
    backgroundColor:'rgb(250, 160, 160)',
    margin:'4px',
    marginLeft:'15px',
  },
  conflictsLabel : {
   marginRight:'30px',
  },
  navigationWrapper:{
    '& .MuiButton-root' : {
      minWidth:'45px'
    },
    '& .MuiButton-text': {
      padding:'0px !important'
    }
  }
}));

const PlanSchedulePage = () => {
  const [today, setToday] = useState(dayjs());
  const { userProfile } = useUserSession();
  const classes = useStyles();
  const [navigateTo, setNavigateTo] = useState(null);
  const [navCounter, setNavCounter] = useState(0);

  const handleNavigate = (navigateBy) => {
    setNavigateTo(navigateBy)
    setNavCounter(navCounter+1);
  }

  return (
    <Wrapper>        
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      <Box display="flex" justifyContent="space-between">
        <PageTitle title="Master Planner" />
        <Box display="flex" alignItems="center">
          <Box className={classes.navigationWrapper} mr={4} sx={{ border: "1px solid", alignContent: 'center' }} display="flex">
            <Box>
              <Tooltip placement='top' title="Previous Month">
                <Button onClick={() => { handleNavigate(-30) }}>
                  <KeyboardArrowLeft style={{ marginRight: "-15px" }} />
                  <KeyboardArrowLeft />
                </Button>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip placement='top' title="Previous Week">
                <Button onClick={() => { handleNavigate(-7) }}> <KeyboardArrowLeft />  </Button>
              </Tooltip>
            </Box>
            <Box sx={{ borderLeft: "1px solid" }}>
              <Tooltip placement='top' title="Next Week">
                <Button onClick={() => { handleNavigate(7) }}><KeyboardArrowRight /> </Button>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip placement='top' title="Next Month">
                <Button onClick={() => { handleNavigate(30) }}>
                  <KeyboardArrowRight style={{ marginRight: "-15px" }} />
                  <KeyboardArrowRight />
                </Button>
              </Tooltip>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <div className={classes.square}></div><label>Downtime</label>
            <div className={classes.conflicts}></div><label className={classes.conflictsLabel}>Conflict</label>
          </Box>
          <Box>
            <Chip label={today.format('MM/DD/YYYY')} />
            {userProfile.role === USER_ROLE_MASTER.ADMIN && <Chip color="primary" label={`ISO YMD: ${formatDateToISOYMD(today)}`} />}
          </Box>
        </Box>
      </Box>
      <div className={classes.root}>
        <PlannerGrid navigateTo={navigateTo} navInit={navCounter} />
      </div>
    </Wrapper>
  );
}

export default PlanSchedulePage;
