import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from "@material-ui/icons/Done";
import ChromeReaderModeOutlinedIcon from "@material-ui/icons/ChromeReaderModeOutlined";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Chip from '@material-ui/core/Chip';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { BATCH_FORM_STATUS } from '../../hooks/constants';

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: '0.6rem',
    height: '18px',
    margin: '1px'
  },
  colorOrange: {
    backgroundColor: 'orange',
    color: '#fff'
  },
  colorBrown: {
    backgroundColor: 'brown',
    color: '#fff'
  },
  colorRed: {
    backgroundColor: 'red',
    color: '#fff'
  }
}));

export default function FormStatusIcon({ status, variant }) {
  const classes = useStyles();

  switch(status) {
    case BATCH_FORM_STATUS.COMPLETED:
      return (
        variant === 'chip' ? <Chip color="primary" label="Completed" size="small" className={classes.chip} /> : <DoneIcon />
      )
    case BATCH_FORM_STATUS.REVIEWED:
      return (
        variant === 'chip' ? <Chip color="secondary" label="Reviewed" size="small" className={classes.chip} /> : <ChromeReaderModeOutlinedIcon />
      )
    case BATCH_FORM_STATUS.VERIFIED:
      return (
          variant === 'chip' ? <Chip label="Verified" size="small" className={`${classes.chip} ${classes.colorOrange}`} /> : <DoneAllIcon />
      )
    case BATCH_FORM_STATUS.QA_VERIFIED:
      return (
        variant === 'chip' ? <Chip label="QA Verified" size="small" className={`${classes.chip} ${classes.colorBrown}`} /> : <CheckCircleIcon />
      )
    case BATCH_FORM_STATUS.REOPENED:
      return (
        variant === 'chip' ? <Chip label="Re-opened" size="small" className={`${classes.chip} ${classes.colorRed}`} /> : <ErrorOutlineIcon fontSize="small" color="error" />
      )
    default:
      return <></>
  }
}