import { useEffect, useState } from 'react';
import { firestoreInstance } from '../config/firebase-init';

export default function useBatchFormsQaAccessComments(orderId, lotId, formName) {
    const batchFormQaAccessRef = firestoreInstance
        .collection('orders').doc(orderId)
        .collection('lots').doc(lotId)
        .collection('batchForms').doc(formName)
        .collection('qaAccess').doc('comments');
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribeBatchFormQaAccessRef = batchFormQaAccessRef.onSnapshot((doc) => {
            setData(doc.data());
            setError(null);
        }, (err) => { setError(err); });

        return () => {
            unsubscribeBatchFormQaAccessRef();
        };
    }, []);

    return {
        formData: data ? data.formValues : {},
        save: (values) => {
            const payload = {
                formValues: values,
            };
            return batchFormQaAccessRef.set(payload).then(() => {
                return true;
            }).catch((error) => {
                console.error(`Error saving ${formName}! `, error);
                return Promise.reject(error);
            });
        },
        error,
    }
}