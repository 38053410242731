import { useRef } from 'react';
import dayjs from 'dayjs';
import { FormGenerator } from "formik-generator-materialui";
import Button from '@material-ui/core/Button';
import useBatchFormData from '../../hooks/use-batch-form-data';
import { BATCH_FORM_NAMES as FORM_NAMES } from '../../hooks/constants';

const formFieldsSchema = [
    {
        title: 'Date',
        typeField: 'date',
        simple: true,
        path: 'dailyRecord/date'
    },
    {
        title: 'Feed tray and utensiles wiped down with 70% Isopropanol',
        typeField: 'group',
        subfields: [
            {
                title: 'Verified',
                typeField: 'switch',
                path: 'feedTrayWiped/ok',
                col: 3
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'feedTrayWiped/verifiedBy',
                col: 3
            },
            {
                title: 'Date',
                typeField: 'date',
                path: 'feedTrayWiped/date',
                simple: true,
                col: 3
            }
        ]
    },
    {
        title: 'Line Startup QA Check',
        typeField: 'group',
        subfields: [
            {
                title: 'Verified',
                typeField: 'switch',
                path: 'lineStartup/ok',
                col: 3
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'lineStartup/verifiedBy',
                col: 3
            },
            {
                title: 'Date',
                typeField: 'date',
                path: 'lineStartup/date',
                simple: true,
                col: 3
            }
        ]
    },
    {
        title: 'Line Operators',
        typeField: 'arrayObject',
        path: 'lineOperators',
        emptyAddText: 'Add Name',
        subfields: [
            {
                title: 'Name',
                typeField: 'text',
                name: 'operatorName',
                col: 12
            }
        ]
    },
    {
        title: 'The following table is used only if tablets are not reclaimed from defective blisters. If tablets are reclaimed from defective blisters, leave the table blank. Record the number of damaged blisters that cannot have tablets reclaimed on the line titled “Damaged Blisters”. Enter the number of rejected loose tablets on ‘Rejects’ section.',
        typeField: 'group',
        subfields: [
            {
                title: 'Number of Blisters',
                typeField: 'arrayObject',
                path: 'numberOfBlisters',
                emptyAddText: 'Add Row',
                subfields: [
                    {
                        title: 'Print',
                        hint: 'Illigible, Smeared, missing, Misaligned',
                        path: 'numberOfBlisters/print',
                        typeField: 'text'
                    },
                    {
                        title: 'Tablet Quality',
                        hint: 'Broken, Missing',
                        path: 'numberOfBlisters/tabletQuality',
                        typeField: 'text'
                    },
                    {
                        title: 'Packaging',
                        hint: 'Poor perforations, Deformed blisters',
                        path: 'numberOfBlisters/packaging',
                        typeField: 'text'
                    }
                ]
            }
        ]
    },
    {
        title: '',
        typeField: 'group',
        subfields: [
            {
                title: 'Damageed Blisters',
                typeField: 'text',
                path: 'damagedBlisters',
                col: 3
            },
            {
                title: 'Total Blister Rejects',
                typeField: 'text',
                path: 'totalBlisterRejects',
                col: 3
            },
            {
                title: 'Calculated By',
                typeField: 'text',
                path: 'calculatedBy',
                col: 3
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'verifiedBy',
                col: 3
            }
        ]
    }
];

const prepareDataToEdit = (values) => {
    const data = Object.assign({}, values);
    // console.log(data);
    if (data['feedTrayWiped/date'] && data['feedTrayWiped/date'].seconds) {
        data['feedTrayWiped/date'] = dayjs.unix(data['feedTrayWiped/date'].seconds).toDate();
    }
    if (data['lineStartup/date'] && data['lineStartup/date'].seconds) {
        data['lineStartup/date'] = dayjs.unix(data['lineStartup/date'].seconds).toDate();
    }
    return data;
}

function DailyRecordForm({
    orderId,
    lotId,
    onSave = null,
    onCancel = null,
    isReadOnly = false,
    isValidateOnlyOnSubmit = true
}) {
    const { batchFormData, saveBatchForm, error } = useBatchFormData(orderId, lotId, FORM_NAMES.dailyRecord);
    let initialValues = {};
    if (batchFormData) {
        initialValues = prepareDataToEdit(batchFormData);
    }

    const saveFormData = (formData) => {
        saveBatchForm(formData).then(() => {
            onSave && onSave();
        });
    };

    const formRef = useRef(null);
    return (
        <>
            <FormGenerator
              onSubmit={(values) => {
                saveFormData(values);
              }}
              fields={formFieldsSchema}
              formRef={formRef}
              initialValues={initialValues}
              readOnly={isReadOnly}
              isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
            />
            <Button color="secondary" style={{ marginRight: '2em' }}
                onClick={() => {
                    formRef.current.resetForm();
                    onCancel && onCancel();
                }}>Cancel</Button>
            <Button variant="contained" color="primary"
                onClick={() => formRef.current.submitForm()}>Save</Button>
        </>
    );
}

export default DailyRecordForm;