import { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  autocompleteRoot: {
    position: 'relative',
    '& .MuiAutocomplete-popper li': {
      fontSize: '0.8rem',
      lineHeight: '1rem'
    }
  },
  autocompleteInput: {
    width: '100px',
    position: 'relative',
    '& label': {
      fontSize: '0.8rem'
    },
    '& input': {
      fontSize: '0.8rem'
    }
  },
}));

/**
 * 
 * @param {object} props - {val: number}
 * 
 */
const TimeDurationInput = ({ val, onChange }) => {
  const classes = useStyles();
  const [options,setOptions] = useState([]);

  useEffect(() => {
    const temp = [];
    for(let i = 30;i <= 24*60;i+=30) {
      temp.push(`${i} mins`);
    }
    setOptions(temp);
  }, []);

  const handleChange = (evt) => {
    console.log('handleChange: ', evt);
    // onChange?.();
  };

  // TODO: Implement onChange handler

  return (
    <Box className={classes.autocompleteRoot}>
      <Autocomplete
        id="free-solo-demo"
        className={classes.autocompleteInput}
        disablePortal={true}
        value={`${val}`}
        freeSolo
        options={options}
        renderInput={(params) => <TextField {...params} label="Duration (mins)" />}
      />
      {/* <Button size="small">-</Button>
      <TextField value={val} onChange={handleChange} />
      <Button size="small">+</Button> */}
    </Box>
  );
};

export default TimeDurationInput;
