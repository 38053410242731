import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import { Droppable } from "react-beautiful-dnd";
import FormCard from "./form-card";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5),
    backgroundColor: '#F4F5F7',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    // border: `1px solid #555`,
    minWidth: `225px`,
    // height: `100vh`,
    // width: '100%'
  },
  columnHeading: {
    display: `flex`,
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: '#5E6C84',
    height: theme.spacing(6),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    '& h6': {
      fontSize: '14px'
    },
    '& svg': {
      width: '0.8em',
      cursor: 'pointer'
    }
  },
  droppableArea: {
    minHeight: `200px`
  },
  droppableArea_row: {
    minHeight: `auto`,
    flexWrap: 'wrap',
    display: 'flex',
    '& > div': {
      margin: theme.spacing(0.6)
    }
  }
}));

export default function WorkflowColumn({ variant, stateData, formCardsData, isSettingsIconHidden, onSettingsClick }) {
  const classes = useStyles();

  return (    
    <Box className={classes.root}>
      <div className={classes.columnHeading}>
        <Typography variant="h6">{stateData.name}</Typography>
        {!isSettingsIconHidden && <SettingsIcon onClick={() => {
          onSettingsClick?.();
        }} />}
      </div>
      <Droppable droppableId={stateData.slug}>
        {(provided) => (
          <div 
            className={`${classes.droppableArea} ${variant === 'row' ? classes.droppableArea_row : ''}`}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {formCardsData.map((formCardData, index) => (
              <FormCard key={formCardData.slug} formMetadata={formCardData} index={index} workflowStateSlug={stateData.slug} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
}