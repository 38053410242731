import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FIRESTORE_COLLECTIONS, SCHEDULE_TYPE } from '../constants';
import { firestoreInstance } from '../../config/firebase-init';
import { generateSlotAvailability } from '../../helpers-scheduler';
import useSchedulerBlockedDays from './use-scheduler-blocked-days';
import useSchedulerEvents from './use-scheduler-events';
import useSchedulerAutoAllocationConfig from './use-scheduler-auto-allocation-config';
import useResourcesV2 from './use-resources-v2';

function useSchedulerAutoAllocationPublishStatus() {
  const autoAllocationPublishRef = firestoreInstance.collection(FIRESTORE_COLLECTIONS.schedulerAutoAllocation).doc('publish-status');
  const { data: autoAllocationConfig } = useSchedulerAutoAllocationConfig();
  const { blockedDaysMap } = useSchedulerBlockedDays();
  const { cleanRooms, equipment, people } = useResourcesV2();
  const { fetchEventsByDateRange } = useSchedulerEvents();

  const [data, setData] = useState(null);
  const [dataWithDatesInMDY, setDataWithDatesInMDY] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribeAutoAllocationPublishRef = autoAllocationPublishRef.onSnapshot((doc) => {
      const docData = doc.data();
      const tempMap = {};
      Object.keys(docData?.slots).forEach(dStrISO => {
        tempMap[dayjs(dStrISO).format('MMDDYYYY')] = docData?.slots[dStrISO];
      });
      setData(docData);
      setDataWithDatesInMDY(Object.assign({}, docData, { slots: tempMap }));
      setError(null);
    }, (err) => { setError(err); });
    
    return () => {
      unsubscribeAutoAllocationPublishRef();
    };
  }, []);

  return {
    data,
    getSortedDatesAndSlotCount: () => {
      if (data) {
        const sortedDates = Object.keys(data.slots).sort((a,b) => {
          return dayjs(a).diff(b, 'day');
        }).map(dateISOStr => {
          return {
            dateISOStr,
            count: data.slots[dateISOStr]
          };
        });
        return sortedDates;
      }
      return [];
    },
    dataWithDatesInMDY,
    error,
    publishSlotAvailability: (payload) => {
      payload.publishedOn = (new Date()).toISOString();
      return autoAllocationPublishRef.set(payload);
    },

    recalculateAndPublishSlotAvailability: async (existingAndNewPlannerEvents) => {    
      // console.log('recalculateAndPublishSlotAvailability: ', existingAndNewPlannerEvents);
      const slotAvailabilityData = data;

      const datesArray = [];
      Object.keys(slotAvailabilityData.slots).forEach(dStrISO => {
        datesArray.push(dayjs(dStrISO).toDate());
      });

      let _existingPlannerEvents = existingAndNewPlannerEvents;
      if (!_existingPlannerEvents) {
        _existingPlannerEvents = await fetchEventsByDateRange(
          dayjs(slotAvailabilityData.fromDate).subtract(1, 'day').toDate(), 
          dayjs(slotAvailabilityData.toDate).add(24, 'day').toDate(),
          SCHEDULE_TYPE.PLANNER
        );
        // console.log('fetching events from firestore: ', _existingPlannerEvents);
      }
  
      const newAvailableSlotsList = generateSlotAvailability(
        datesArray,
        autoAllocationConfig, 
        Object.keys(blockedDaysMap),
        [].concat(cleanRooms, equipment, people),
        _existingPlannerEvents
      );
      // console.log('newAvailableSlotsList: ', newAvailableSlotsList);
  
      const payload = {
        fromDate: datesArray[0].toISOString(), 
        toDate: datesArray[datesArray.length-1].toISOString(), 
        slots: {} 
      };
  
      newAvailableSlotsList.map(slot => {
        payload.slots[slot.date] = slot.numSlots;
      });
  
      payload.publishedOn = (new Date()).toISOString();

      return autoAllocationPublishRef.set(payload);
    }
  };
}

export default useSchedulerAutoAllocationPublishStatus;
