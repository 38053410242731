import { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Breadcrumb from '../../design-system/breadcrumb';
import PageTitle from "../../design-system/page-title";
import useFormMasterList from "../../hooks/use-form-master-list";
import useBatchMasterList from "../../hooks/use-batch-master-list";
import ConfigureFormModal from '../../components/batch-forms/configure-form-modal';
import PreviewFormModal from '../../components/batch-forms/preview-form-modal';

const useStyles = makeStyles({
  root: {
    "& thead": {
      backgroundColor: '#E6EFFC',
      "& th": {
        // fontWeight: "bold",
      },
    },
  },
  formNames: {
    display: "flex",
    flexWrap: "wrap",
  },
});

export default function ManageForms(props) {
  const { match } = props;
  const isManageManualProcesses = (match.path === '/manage/forms/manual-processes');

  const classes = useStyles();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const { formsMasterList, manualProcessFormsMasterList, updateFormConfig } = useFormMasterList();
  const { batchesMasterList, batchesMasterAvailableFormSlugs } = useBatchMasterList();

  return (
    <Box>
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      <PageTitle title={`${isManageManualProcesses ? 'Configure Manual Processes' : 'Configure Forms'}`}>
        <Chip label={`Showing ${isManageManualProcesses ? manualProcessFormsMasterList.length : formsMasterList?.length}`} />
      </PageTitle>
      <TableContainer component={Paper} className={classes.root}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Form Name</TableCell>
              <TableCell>Used In</TableCell>
              <TableCell>Action(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(isManageManualProcesses ? manualProcessFormsMasterList : formsMasterList)?.map((form) => (
              <TableRow key={form.slug}>
                <TableCell>{form.name}</TableCell>
                <TableCell>
                  {batchesMasterList?.map((ebrMasterRec) => {
                    const ebrSlug = ebrMasterRec.batchSlug;
                    if (batchesMasterAvailableFormSlugs[ebrSlug].availableFormSlugs.indexOf(form.slug) !== -1) {
                      return (<Chip key={ebrMasterRec.batchSlug} label={ebrMasterRec.batchName} size="small" color="primary" />)
                    }
                  })}
                </TableCell>
                <TableCell>
                  <Button size="small" variant="outlined" color="primary" onClick={() => {
                    setSelectedForm(form);
                    setIsEditModalVisible(true);
                  }}>Edit</Button> &nbsp;
                  <Button size="small" variant="outlined" onClick={() => {
                    console.log('preview');
                    setSelectedForm(form);
                    setIsPreviewModalVisible(true);
                  }}>Preview</Button> &nbsp;
                  {/* <Button size="small" variant="outlined" color="secondary">Disable</Button> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isEditModalVisible && 
        <ConfigureFormModal 
          isVisible={isEditModalVisible}
          formMasterData={selectedForm}
          onClose={() => {
            setIsEditModalVisible(false);
          }} 
          onSave={(sopDate, formStatuses) => {
            updateFormConfig(selectedForm.NO_ID_FIELD, {
              sopDate,
              formStatuses
            }).then(() => {
              setIsEditModalVisible(false);
            });
          }}
        />
      }

      {isPreviewModalVisible && <PreviewFormModal 
        isVisible={isPreviewModalVisible} 
        formSlug={selectedForm.slug} 
        formName={selectedForm.name} 
        onClose={() => {
          setIsPreviewModalVisible(false);
        }}
      />}
    </Box>
  );
}