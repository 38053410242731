import { useEffect, useState } from 'react';
import { BATCH_FORM_STATUS } from './constants';
import { firestoreInstance } from '../config/firebase-init';

export const formStatusInitialState = {
    [BATCH_FORM_STATUS.COMPLETED]: false,
    [BATCH_FORM_STATUS.REVIEWED]: false,
    [BATCH_FORM_STATUS.VERIFIED]: false,
    [BATCH_FORM_STATUS.QA_VERIFIED]: false,
    [BATCH_FORM_STATUS.REOPENED]: false
};

export default function useBatchFormData(orderId, lotId, formName) {
    const batchFormRef = firestoreInstance
        .collection('orders').doc(orderId)
        .collection('lots').doc(lotId)
        .collection('batchForms').doc(formName);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribeBatchFormRef = batchFormRef.onSnapshot((doc) => {
            setData(doc.data());
            setError(null);
        }, (err) => { setError(err); });

        return () => {
            unsubscribeBatchFormRef();
        };
    }, []);

    const saveBatchForm = (payload) => {
        return batchFormRef.set(payload).then(() => {
            return true;
        }).catch((error) => {
            console.error(`Error saving ${formName}! `, error);
            return Promise.reject(error);
        });
    }

    const updateBatchForm = (payload) => {
        return batchFormRef.update(payload).then(() => {
            return true;
        }).catch((error) => {
            console.error(`Error saving ${formName}! `, error);
            return Promise.reject(error);
        });
    }

    return {
        batchFormData: data ? data.formValues : undefined,
        batchFormStatus: data && data.formStatus ? data.formStatus : formStatusInitialState,
        error,
        saveBatchForm: (values) => {
            const payload = {
                formValues: values,
                formStatus: data && data.formStatus ? data.formStatus : formStatusInitialState
            };
            return saveBatchForm(payload);
        },
        saveBatchFormStatus: (status) => {
            // TODO: Trigger Email Notification
            const payload = {
                formStatus: Object.assign({}, data.formStatus, { [status]: true })
            };
            return updateBatchForm(payload);
        }
    }
}