import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { ALL_DAY_DURATION, FIRESTORE_COLLECTIONS, SCHEDULE_TYPE, PROCESS_CODE_BIOPSY, RESOURCE_TYPE } from '../constants';
import { firestoreInstance } from '../../config/firebase-init';
import { formatDateToISOYMD, isEmpty, isString } from '../../helpers';
import { doesResourceHaveConflicts } from '../../helpers-scheduler';
import useResourcesV2 from './use-resources-v2';
import useSchedulerAutoAllocationConfig from './use-scheduler-auto-allocation-config';

/**
 * 
 * @param {*} scheduleType 
 * @param {*} clientCode 
 * @param {*} subjectId 
 * @param {Date} filterByStartDate - JS Date.
 * @param {Date} filterByEndDate - JS Date.
 * @returns 
 */
export default function useSchedulerEvents(scheduleType, clientCode, subjectId, filterByStartDate, filterByEndDate) {
  const eventsRef = firestoreInstance.collection(FIRESTORE_COLLECTIONS.schedulerEvents);
  const { fetchResource: fetchResourceV2 } =  useResourcesV2();
  const { data: autoAllocationConfig } = useSchedulerAutoAllocationConfig();

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (scheduleType) {
      let filteredEventsRef = eventsRef.where('active', '==', true).where('scheduleType', '==', scheduleType);
      // console.log('useSchedulerEvents: scheduleType: ', scheduleType);
      // console.log('useSchedulerEvents: subjectId: ', subjectId);
      // console.log('useSchedulerEvents: clientCode: ', clientCode);
      // console.log('useSchedulerEvents: filterByStartDate: ', filterByStartDate);
      // console.log('useSchedulerEvents: filterByEndDate: ', filterByEndDate);
      if (subjectId) {
        filteredEventsRef = filteredEventsRef.where('meta_subjectId', '==', subjectId);
      }
      // if (filterByStartDate) {
      //   filteredEventsRef = filteredEventsRef.where('start', '>=', filterByStartDate.toISOString());
      // }
      // if (filterByEndDate) {
      //   filteredEventsRef = filteredEventsRef.where('start', '<=', filterByEndDate.toISOString());
      // }
      if(clientCode) {
        filteredEventsRef = filteredEventsRef.where('clientCode', '==', clientCode);
      }
        
      const unsubscribeEventsRef = filteredEventsRef.onSnapshot((res) => {
        if (!res.empty) {
          const dataRecords = res.docs.map((doc) => {
            return {...doc.data(), NO_ID_FIELD: doc.id};
          });
          setData(dataRecords);
        } else {
          setData([]);
        }
        setError(null);
      }, (err) => { setError(err); });
      
      return () => {
        unsubscribeEventsRef();
      };
    }
  }, [scheduleType, clientCode, subjectId, filterByStartDate, filterByEndDate]);

  const fetchEventsByScheduleId = (scheduleId) => {
    return eventsRef
      .where('scheduleId', '==', scheduleId)
      .get()
      .then((querySnapshot) => {
        const records = []; 
        querySnapshot.forEach((doc) => {
          const rec = doc.data();
          rec.id = doc.id;
          records.push(rec);
        });
        return records.sort((a,b) => (a.dayNum - b.dayNum));
      });
  };

  const preProcessEventDates = (eventsList) => {
    return eventsList.map((evt) => {
      const newEvt = evt; // Object.assign({}, evt);
      newEvt.id = newEvt.NO_ID_FIELD;
      newEvt.title = `${newEvt?.meta_subjectId ? newEvt?.meta_subjectId + ' : ' : '' } ${newEvt.clientCode} : ${newEvt?.title?.toUpperCase()}`
      if (newEvt?.start) {
        newEvt.start = dayjs(evt.start).toDate();
      }
      if (newEvt?.end) {
        newEvt.end = dayjs(evt.end).toDate();
      }
      return newEvt;
    })
  };

  const eventsWithPreProcessedDates = useMemo(() => preProcessEventDates(data), [data]);

  /**
   * 
   * @param {Date} fromDate - Javascript Date Object.
   * @param {Date} toDate - Javascript Date Object.
   * @param {string} scheduleType 
   * @param {boolean} onlyWithConflictFlagEnabled - Fetch only events with conflict flag enabled
   * @returns 
   */
  const _fetchEventsByDateRange = (fromDate, toDate, scheduleType, onlyWithConflictFlagEnabled = false) => {
    const fromDateISOString = isString(fromDate) ? fromDate : fromDate.toISOString();
    const toDateISOString = isString(toDate) ? toDate : toDate.toISOString();

    let queryRef = eventsRef
      .where('active', '==', true)
      .where('scheduleType', '==', scheduleType)
      .where('start', '>=', fromDateISOString)
      .where('start', '<=', toDateISOString);    

    if (onlyWithConflictFlagEnabled) {
      queryRef = queryRef.where('hasConflict', '==', true);
    }

    return queryRef
      .get()
      .then((res) => {
        if (!res.empty) {
          const dataRecords = res.docs.map((doc) => {
            return {...doc.data(), NO_ID_FIELD: doc.id, id: doc.id};
          });
          return preProcessEventDates(dataRecords);
        } else {
          return [];
        }
      }, (err) => { console.error(err); return []; }
    );
  };

  /**
   * 
   * @param {*} scheduleId 
   * @param {*} eventId 
   * @param {*} resourceCodes 
   * @param {DayJs / JS Date / String} startDate 
   * @param {DayJS / JS Date / String} endDate 
   * @returns 
   */
  const _fetchConflictingPlannerEvents = (scheduleId, eventId, resourceCodes, startDate = null, endDate = null) => {
    // console.log('fetchConflictingPlannerEvents: ', scheduleId, eventId, resourceCodes, startDate, startDate.toISOString(), endDate, endDate.toISOString());
    if (scheduleId && eventId && resourceCodes?.length > 0) {
      const startDateISOString = isString(startDate) ? startDate : startDate.toISOString();
      const endDateISOString = isString(endDate) ? endDate : endDate.toISOString();
      const promises = [];
      const queryRef = eventsRef
        .where('active', '==', true)
        .where('scheduleType', '==', SCHEDULE_TYPE.PLANNER);
        // .where('scheduleId', '!=', scheduleId);

      for (let i = 0;i <= resourceCodes.length;i += 10) {
        const partResouceCodes = resourceCodes.slice(i,i+9);
        if (partResouceCodes.length > 0) {
          const partQueryRef = queryRef.where('resourceCodes', 'array-contains-any', partResouceCodes); // array-contains-any allows upto 10 items in the array.
          if (startDate && endDate) {
            promises.push(partQueryRef.where('start', '>=', startDateISOString).where('start', '<=', endDateISOString).get());
            promises.push(partQueryRef.where('end', '>=', startDateISOString).where('end', '<=', endDateISOString).get());
            
            // Get MMDDYYYY representation of the ISO Date
            promises.push(partQueryRef.where('allDay', '==', true).where('startYMD', '==', formatDateToISOYMD(startDateISOString)).get());
          } else {
            promises.push(partQueryRef.get());
          }
        }
      }

      return Promise.all(promises).then((querySnapshots) => {
        const records = {}; 
        querySnapshots.forEach((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.id !== eventId) {
              const rec = doc.data();
              rec.id = doc.id;
              rec.NO_ID_FIELD = doc.id;
              records[rec.id] = rec;
            }
          });
        });
        // console.log('fetchConflictingPlannerEvents: First Pass: Identify OVerlapping Events: ', records);
        return Object.values(records);
      });
    } else {
      return Promise.resolve([]);
    }
  }

  /**
   * Fetches all future events (including today) with the ```hasConflict=true```
   * @returns 
   */
  const _fetchFutureEventsWithConflictFlagEnabled = () => {
    return eventsRef
      .where('active', '==', true)
      .where('hasConflict', '==', true)
      .where('scheduleType', '==', SCHEDULE_TYPE.PLANNER)
      .where('start', '>=', dayjs().hour(0).minute(0).second(1).toISOString())
      .get().then((querySnapshot) => {
        const records = []; 
        querySnapshot.forEach((doc) => {
          const rec = doc.data();
          rec.id = doc.id;
          rec.NO_ID_FIELD = doc.id;
          records.push(rec);
        });
        return records;
      });
  };

  /**
   * Updates the conflict status for an event.
   * @param {object} scheduleEvent 
   * @param {boolean} hasConflict 
   * @returns 
   */
  const _updateConflictStatus = (scheduleEvent, hasConflict) => {
    // console.log('_updateConflictStatus: ', scheduleEvent, hasConflict);
    return eventsRef.doc(scheduleEvent.id).update({ hasConflict });
  };

  const _bulkUpdateConflictStatus = (events, hasConflict) => {
    // console.log('_bulkUpdateConflictStatus: ', events, hasConflict);
    const batch = firestoreInstance.batch();
    events.forEach(event => {
      batch.update(eventsRef.doc(event.id), { hasConflict });
    });
    return batch.commit();
  };

  const _evaluateConflictStatusForEventsAndSave = (scheduleEvents) => {
    const additionalPromises = [];
    scheduleEvents.forEach((scheduleEvent) => {
      // console.log('scheduleEvent: ', scheduleEvent);
      return _fetchConflictingPlannerEvents(
        scheduleEvent.scheduleId, 
        scheduleEvent.id, 
        scheduleEvent.resourceCodes,
        scheduleEvent.start,
        scheduleEvent.end
      ).then(existingEventsConflictingThisScheduleEvent => {
        // console.log('existingEventsConflictingThisScheduleEvent: ', existingEventsConflictingThisScheduleEvent);
        if (existingEventsConflictingThisScheduleEvent.length > 0) {
          const defaultDurationForEvent = (scheduleEvent.processCode === PROCESS_CODE_BIOPSY ? 
            autoAllocationConfig.eventDuration[scheduleEvent.dayNum]?.duration : ALL_DAY_DURATION) || ALL_DAY_DURATION;
          const defaultDurationForPerson = (scheduleEvent.processCode === PROCESS_CODE_BIOPSY ? 
            autoAllocationConfig.peopleAllocation[scheduleEvent.dayNum]?.duration : ALL_DAY_DURATION) || ALL_DAY_DURATION;

          let hasConflict = false;
          scheduleEvent.resourceCodes.map(rCode => fetchResourceV2(rCode)).forEach((masterResource) => {
            const {hasConflicts, eventsHavingResourceConflict} = doesResourceHaveConflicts(
              dayjs(scheduleEvent.start),
              masterResource, 
              existingEventsConflictingThisScheduleEvent, 
              scheduleEvent.processCode === PROCESS_CODE_BIOPSY ? 
                (masterResource.type === RESOURCE_TYPE.person ? defaultDurationForPerson : defaultDurationForEvent) : ALL_DAY_DURATION
            );
            if (!hasConflicts) {
              // No conflict              
            } else {
              // Yes Conflict
              hasConflict = true;
              additionalPromises.push(_bulkUpdateConflictStatus([scheduleEvent].concat(eventsHavingResourceConflict), true));
            }
          });

          if (!hasConflict) {
            additionalPromises.push(_updateConflictStatus(scheduleEvent, false));
          }
        } else {
          // No conflict
          additionalPromises.push(_updateConflictStatus(scheduleEvent, false));
        }
      }).catch(err => {
        console.error('evaluateConflictStatusForEventsAndSave: error: ', err);
      });
    });
    return Promise.all(additionalPromises);
  };

  const _fetchPlannerEventsByResourceForADay = async (resourceCode, startYMD) => {
    const data = await eventsRef
      .where('active', '==', true)
      .where('scheduleType', '==', SCHEDULE_TYPE.PLANNER)
      .where('resourceCodes', 'array-contains-any', [resourceCode])
      .where('startYMD', '==', startYMD)
      .get().then((querySnapshot) => {
        const records = [];
        if (!querySnapshot.empty) {          
          querySnapshot.forEach((doc) => {
            const rec = doc.data();
            rec.id = doc.id;
            rec.NO_ID_FIELD = doc.id;
            records.push(rec);
          });
        }
        return records;
      });
    return data;
  };

  return {
    events: eventsWithPreProcessedDates,
    eventsRaw: data,

    /**
     * 
     * @param {*} scheduleType 
     * @param {*} scheduleId 
     * @param {dayjs} startDateObject - dayjs
     * @param {dayjs} endDateObject
     * @param {*} processCode 
     * @param {*} clientCode 
     * @param {*} resources 
     * @param {*} additionalData 
     */
    // add duration in resource object
    prepareEventPayload: (scheduleType, scheduleId, startDateObject, endDateObject, processCode, clientCode, resources, additionalData) => {
      const eventPayload = {
        start: startDateObject.toISOString(),
        startYMD: formatDateToISOYMD(startDateObject),
        end: endDateObject.toISOString(),
        endYMD: formatDateToISOYMD(endDateObject),
        allDay: additionalData.allDay || false,
        // custom fields
        scheduleId: scheduleId,
        scheduleType: scheduleType,
        processCode,
        clientCode,
        active: true,
        dayNum: +additionalData.dayNum,
        resources: resources || [],
        resourceCodes: resources?.map(r => r.code) || []
      };
      if (scheduleType === SCHEDULE_TYPE.PLANNER) {
        eventPayload.title = `Day ${additionalData.dayNum}`;
        // eventPayload.dayNum = +additionalData.dayNum;
        eventPayload.meta_orderId = additionalData.orderId || ''; // This value isn't available when dropping a template on the Planner Grid.
        eventPayload.meta_subjectId = additionalData.subjectId || ''; // This value isn't available when dropping a template on the Planner Grid.
        eventPayload.meta_eventType = additionalData.eventType || ''; // This value isn't available when dropping a template on the Planner Grid.
      } else if (scheduleType === SCHEDULE_TYPE.SITE) {
        eventPayload.title = `Subject: ${additionalData.subjectId}`;
        // eventPayload.dayNum = +additionalData.dayNum;
        eventPayload.meta_orderId = additionalData.orderId;
        eventPayload.meta_eventType = additionalData.eventType;
        eventPayload.meta_isApproved = additionalData.isApproved;
        eventPayload.meta_subjectId = additionalData.subjectId;
        eventPayload.meta_biopsyDate = additionalData.biopsyDate?.toISOString() || '';
        eventPayload.meta_injection1Date = additionalData.injection1Date?.toISOString() || '';
        eventPayload.meta_injection2Date = additionalData.injection2Date?.toISOString() || '';
      }
      return eventPayload;
    },

    /**
     * Fetches all planner events
     * @returns 
     */
    fetchPlannerEvents: () => {
      return eventsRef
        .where('active', '==', true)
        .where('scheduleType', '==', SCHEDULE_TYPE.PLANNER)
        .get().then((querySnapshot) => {
          const records = []; 
          querySnapshot.forEach((doc) => {
            const rec = doc.data();
            rec.id = doc.id;
            rec.NO_ID_FIELD = doc.id;
            records.push(rec);
          });
          return records;
        });
    },

    testISODateQuery: (startISOString, endISOString, resourceCodes = []) => {
      const queryRef = eventsRef
        .where('active', '==', true)
        .where('scheduleType', '==', SCHEDULE_TYPE.PLANNER)
        .where('resourceCodes', 'array-contains-any', resourceCodes);
      
      const promises = [];
      promises.push(queryRef.where('start', '>=', startISOString).where('start', '<=', endISOString).get());
      promises.push(queryRef.where('end', '>=', startISOString).where('end', '<=', endISOString).get());
      promises.push(queryRef.where('allDay', '==', true).where('startYMD', '==', formatDateToISOYMD(startISOString)).get());

      return Promise.all(promises).then((querySnapshots) => {
        const records = {}; 
        querySnapshots.forEach((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const rec = doc.data();
            rec.id = doc.id;
            rec.NO_ID_FIELD = doc.id;
            records[rec.id] = rec;
          });
        });
        console.log('testISODateQuery: records: ', records);
        return Object.values(records);
      });
    },

    /**
     * Fetches planner events by resource codes.
     * @param {string} scheduleId - Required. Schedule ID to omit from the check.
     * @param {string} eventId - Required. Event ID to omit from the check.
     * @param {string[]} resourceCodes - Required
     * @param {Date} startDate - JS Date. Optional
     * @param {Date} endDate - JS Date. Optional
     * @returns 
     */
    fetchConflictingPlannerEvents: _fetchConflictingPlannerEvents,

    /**
     * Fetches events by Schedule ID (Planner or Site events)
     */
    fetchEventsByScheduleId,

    /**
     * Fetches events by date range.
     */
    fetchEventsByDateRange: _fetchEventsByDateRange,

    saveEventResources: (eventId, resources, eventHasConflict = false) => {
      if (eventId) {
        return eventsRef.doc(eventId).update({
          hasConflict: eventHasConflict,
          resources: resources.map(r => {
            if (isEmpty(r.start)) {
              return r;
            } else {
              return Object.assign({}, r, {
                start: isString(r.start) ? r.start : r.start.toISOString()
              });
            }
          }),
          resourceCodes: resources.map(r => r.code)
        });
      }
    },

    /**
     * Attempts to resolve conflict because:
     * - **referenceScheduleEvent** might have changed. Check for all events on the same date with ```hasConflict=true```
     * - Re-evaluating conflicts 
     * @param {object} referenceScheduleEvent 
     */
    attempToResolveConflictsInOtherEvents: (referenceScheduleEvent) => {
      // console.log('attempToResolveConflictsInOtherEvents: referenceScheduleEvent: ', referenceScheduleEvent);
      // See if you can narrow the conflicting events to attempt resolving. // Fetch events within a date range with hasConflict=true
      const promise = referenceScheduleEvent ? 
        _fetchEventsByDateRange(referenceScheduleEvent.start, referenceScheduleEvent.end, SCHEDULE_TYPE.PLANNER, true) : 
        _fetchFutureEventsWithConflictFlagEnabled();

      promise.then(records => {
        return _evaluateConflictStatusForEventsAndSave(records);
      });
    },

    /**
     * 
     * @param {string} eventId 
     * @param {string} start - Date ISO String
     * @param {string} end - Date ISO String
     * @returns 
     */
    saveEventStartAndEndDate: (eventId, start, end) => {
      if (eventId && start && end) {
        return eventsRef.doc(eventId).update({ 
          start,
          startYMD: formatDateToISOYMD(start),
          end,
          endYMD: formatDateToISOYMD(end)
        });
      }
    },

    /**
     * 
     * @param {schedulerEvent[]} recordsPayload 
     * @returns 
     */
    bulkAdd: (recordsPayload) => {
      const batch = firestoreInstance.batch();
      recordsPayload.forEach((recPayload) => {
        batch.set(eventsRef.doc(), recPayload);
      });
      return batch.commit();
    },

    /**
     * Updates the conflict status for an event.
     * @param {object} scheduleEvent 
     * @param {boolean} hasConflict 
     * @returns 
     */
    updateConflictStatus: _updateConflictStatus,

    bulkUpdateConflictStatus: _bulkUpdateConflictStatus,

    /**
     * 
     * @param {string} scheduleId 
     * @returns 
     */
    deleteEventsByScheduleId: (scheduleId) => {
      return fetchEventsByScheduleId(scheduleId).then((data) => {
        const batch = firestoreInstance.batch();
        for (let i = 0;i < data.length;i++) {
          batch.delete(eventsRef.doc(data[i].id));
        }
        return batch.commit();
      });
    },

    /**
     * 
     * @param {string} scheduleId 
     * @param {number} numDays - +ve or -ve. Num days by which to move schedule.
     */
    moveSchedule: (scheduleId, numDays) => {
      return fetchEventsByScheduleId(scheduleId).then((data) => {
        const batch = firestoreInstance.batch();
        data.forEach((rec) => {
          const startD = dayjs(rec.start);
          const endD = dayjs(rec.end);
          rec.start = startD.add(numDays, 'day').toISOString();
          rec.startYMD = formatDateToISOYMD(rec.start);
          rec.end = endD.add(numDays, 'day').toISOString();
          rec.endYMD = formatDateToISOYMD(rec.end);
          batch.set(eventsRef.doc(rec.id), rec);
        });
        return batch.commit();
      });
    },

    evaluateConflictStatusForEventsAndSave: _evaluateConflictStatusForEventsAndSave,

    /**
     * Checks the conflicts for all events in a Schedule. This is invoked when moving a schedule.
     * It could also be invoked when a User action causes all events in schedule to be updated.
     * @param {string} scheduleId 
     * @returns 
     */
    detectConflictAndSaveStatusForSchedule: (scheduleId) => {
      return fetchEventsByScheduleId(scheduleId).then(records => {
        return _evaluateConflictStatusForEventsAndSave(records);
      });
    },

    fetchPlannerEventsByResourceForADay: _fetchPlannerEventsByResourceForADay,

    /**
     * Checks the conflicts for all events to which the resource is assigned.
     * @param {object} masterResource 
     */
    detectConflictsAndSaveStatusForResourceDowtime: (resourceCode, resourceDowntimeArray) => {
      const resourceDowntimeYMD = resourceDowntimeArray.map(dateISOString => {
        return formatDateToISOYMD(dateISOString);
      });

      if (resourceDowntimeYMD.length > 0) {
        return eventsRef
        .where('active', '==', true)
        .where('scheduleType', '==', SCHEDULE_TYPE.PLANNER)
        .where('resourceCodes', 'array-contains-any', [resourceCode])
        .where('start', '>=', dayjs().hour(0).minute(0).second(1).toISOString())
        .get().then((querySnapshot) => {
          if (!querySnapshot.empty) {              
            const batch = firestoreInstance.batch();
            querySnapshot.forEach((doc) => {
              const rec = doc.data();
              rec.id = doc.id;
              rec.NO_ID_FIELD = doc.id;
  
              if (resourceDowntimeYMD.indexOf(rec.startYMD) !== -1) {
                batch.update(eventsRef.doc(rec.id), { 
                  hasResourceDowntime: {
                    flag: true,
                    resourceCodes: rec.hasResourceDowntime?.resourceCodes ? 
                      [resourceCode].concat(rec.hasResourceDowntime.resourceCodes) : 
                      [resourceCode]
                  } 
                });
              } else if (
                rec.hasResourceDowntime?.resourceCodes?.indexOf(resourceCode) !== -1 && 
                rec.hasResourceDowntime?.resourceCodes?.length === 1
              ) {
                batch.update(eventsRef.doc(rec.id), { 
                  hasResourceDowntime: null
                });
              }
            });
            return batch.commit();
          } else {
            return Promise.resolve('No action taken!');
          }
        });
      } else {
        return Promise.resolve('No action taken!');
      }
    }
  };
}