import { useEffect, useState } from 'react';
import { BATCH_FORM_NAMES } from './constants';
import { firestoreInstance } from '../config/firebase-init';

const productionSetupFields = [
    'lineClearanceChecklist/verified',
    'lineClearanceChecklist/verifiedBy',
    'lineClearanceChecklist/verifiedOn',
    'roomIdentifierSign/verified',
    'roomIdentifierSign/verifiedBy',
    'roomIdentifierSign/verifiedOn',
    'currentRun/verified',
    'currentRun/verifiedBy',
    'currentRun/verifiedOn'
];

const packagingFields = [
    'type',
    'supplierName',
    'lotNum',
    'width',
    'amount',
    'checked',
    'verified'
];

const lineSetupFields = [
    'roomNum',
    'toolingNum/feedTrayNum',
    'toolingNum/perfBladeNum',
    'toolingNum/toolingNum',
    'tempAndSpeedRecordedVerified'
];

export default function useBatchFormSchema(formName) {
    const batchFormSchemaRef = firestoreInstance.collection('batchFormSchema');
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribeBatchFormSchemaRef = batchFormSchemaRef.onSnapshot((res) => {
            if (!res.empty) {
                const dataRecords = res.docs.map((doc) => {
                    return doc.data()
                });
                setData(dataRecords);
            } else {
                setData([]);
            }
            setError(null);
        }, (err) => { setError(err); });
        
        return () => {
            unsubscribeBatchFormSchemaRef();
        };
    }, []);

    return {
        fieldsSchema: [],
        searchFields: () => {
            let fields = [];
            switch (formName) {
                case BATCH_FORM_NAMES.productionSetup:
                    fields = productionSetupFields;
                    break;
                case BATCH_FORM_NAMES.packaging:
                    fields = packagingFields;
                    break;
                case BATCH_FORM_NAMES.lineSetupFields:
                    fields = lineSetupFields;
                    break;
            }

            return fields.map((fieldName) => {
                return {
                    id: fieldName,
                    value: fieldName
                };
            });
        }
    }
}