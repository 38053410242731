import { useRef, useState } from 'react';
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
// import Slide from "@material-ui/core/Slide";
// import IconButton from '@material-ui/core/IconButton';
// import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@material-ui/icons/Remove";
import BuildIcon from "@material-ui/icons/Build";
// import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PersonIcon from '@material-ui/icons/Person';
// import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import { RESOURCE_TYPE } from '../../hooks/constants';
import { Popover, Tooltip } from '@material-ui/core';
import { PROCESS_CODE_BIOPSY } from '../../hooks/constants';
import CapacityForecastModal from './capacity-forecast-modal';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  columnHeadRoot: {
    minWidth: '96px',
    maxWidth: '96px',
    border: '1px solid transparent'
  },
  chip: {
    width: '100%'
  },
  popOverForcasting : {
    '& .MuiButton-outlinedSizeSmall' : {
    padding: "3px",
    fontSize: "12px",
    minWidth: "140px",
    margin: "1px 2px",
    }
  }
}));

const zoomStyles = {
  '-5': {
    fontSize: '0.6rem'
  },
  '-4': {
    fontSize: '0.6rem'
  },
  '-3': {
    fontSize: '0.6rem'
  },
  '-3': {
    fontSize: '0.6rem'
  },
  '-2': {
    fontSize: '0.7rem'
  },
  '-1': {
    fontSize: '0.8rem'
  },
  0: {},
  1: {
    fontSize: '0.9rem'
  },
  2: {
    fontSize: '0.9rem'
  },
  3: {
    fontSize: '0.9rem'
  },
  4: {
    fontSize: '0.9rem'
  },
  5: {
    fontSize: '1rem'
  }
};

const WaGridCellColumnHead = ({ domId, id, label, onClick, additionalData = {}, icon, zoomLevel = 0, isHighlighted }) => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const [showForcast, setShowForcast] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const el = open ? 'simple-popover' : undefined;
  
  let iconComponent = (<AccountTreeIcon fontSize="small" />);
  if (icon === RESOURCE_TYPE.cleanRoomCapacityView || icon === RESOURCE_TYPE.singleCleanRoomCapacityView || additionalData?.type === RESOURCE_TYPE.cleanRoom) {
    iconComponent = (<MeetingRoomIcon fontSize="small" />);
  } else if (icon === RESOURCE_TYPE.equipmentCapacityView || icon === RESOURCE_TYPE.singleEquipmentCapacityView || additionalData?.type === RESOURCE_TYPE.equipment) {
    iconComponent = (<BuildIcon fontSize="small" />);
  } else if (icon === RESOURCE_TYPE.personCapacityView || icon === RESOURCE_TYPE.singlePersonCapacityView || additionalData?.type === RESOURCE_TYPE.person) {
    iconComponent = (<PersonIcon />);
  } 
  // else if (view === RESOURCE_TYPE.singleScheduleCapacityView) {
  //   iconComponent = <></>;
  // }
  // console.log('WaGridCellColumnHead: ', label, additionalData, isHighlighted);

  return (
    <Box 
      className={classes.columnHeadRoot}
      id={domId}
      ref={containerRef}
    >
      <Tooltip title={label} placement="top-start">
      <Chip 
        label={label} 
        variant="default"
        aria-describedby={el}
        color={label.toUpperCase().includes(PROCESS_CODE_BIOPSY.toUpperCase()) ? 'primary' : 'default'}
        icon={label ? iconComponent : null} 
        className={classes.chip}
        style={zoomStyles[zoomLevel]}
        onClick={handleClick}
      />
      </Tooltip>
      <Popover className={classes.popOverForcasting}
        id={el}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Button variant="outlined" size="small"
         onClick={() => {
          onClick?.(id, label, additionalData);
        }}>
        View Detail
        </Button>
        <br/>
        <Button variant="outlined" size="small"
         onClick={() => {
          setShowForcast(true);
        }}>
        Check forecasting
        </Button>
      </Popover>
      {/* <Slide direction="up" in={isActionButtonsVisible} container={containerRef.current}>
        <Box>
          <IconButton size="small">
            <AddIcon />
          </IconButton>
          <IconButton size="small">
            <RemoveIcon />
          </IconButton>
          <IconButton size="small">
            <ViewAgendaIcon />
          </IconButton>
        </Box>
      </Slide> */}
      <CapacityForecastModal isVisible={showForcast} initData={label} />
    </Box>    
  );
}

export default WaGridCellColumnHead;