import { useState } from "react";
import Box from '@material-ui/core/Box';
import BatchFormWizard from '../../components/batch-form-wizard';
import SimpleSnackbar from "../../design-system/simple-snackbar";
import PageTitle from "../../design-system/page-title";
import Breadcrumb from '../../design-system/breadcrumb';

function OrderLotBatchPage(props) {
    const { match } = props;
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    
    return (
        <Box>
            <Breadcrumb items={[{ text: 'Home', path: '/' }, { text: 'Manage Orders', path: '/orders' }]} />
            <PageTitle title={`Edit Batch Record`} />
            <BatchFormWizard
                orderId={match.params.orderId}
                lotId={match.params.lotId}
                noteId={match.params.noteId}
                formName={match.params.formName}
                onSave={() => {
                    setIsSnackbarVisible(true);
                    setSnackbarMessage('Saved form!');
                }}
                onNoteAdded={() => {
                    setIsSnackbarVisible(true);
                    setSnackbarMessage('Saved note!');
                }}
            />
            <SimpleSnackbar
                isVisible={isSnackbarVisible}
                message={snackbarMessage}
                onClose={() => {
                    setIsSnackbarVisible(false);
                }}
            />
        </Box>
    );
}

export default OrderLotBatchPage;