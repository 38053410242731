import { useEffect, useState } from 'react';
import useUserSession from './use-user-session';
import { NoteStatus } from './constants';
import { firestoreInstance } from '../config/firebase-init';

export default function useBatchFormNotes(orderId, lotId, formName) {
    const session = useUserSession();
    const batchFormNotesRef = firestoreInstance
        .collection('orders').doc(orderId)
        .collection('lots').doc(lotId)
        .collection('batchForms').doc(formName)
        .collection('notes');
    const streamNotesRef = firestoreInstance.collection('streamNotes');

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribeBatchFormNotesRef = batchFormNotesRef.orderBy('createdOn', 'desc').onSnapshot((res) => {
            if (!res.empty) {
                const dataRecords = res.docs.map((doc) => {
                    return doc.data()
                });
                setData(dataRecords);
            } else {
                setData([]);
            }
            setError(null);
        }, (err) => { setError(err); });
        
        return () => {
            unsubscribeBatchFormNotesRef();
        };
    }, []);

    return {
        batchFormNotes: data,
        saveNote: (messageHtml, messageTokens, noteStatus) => {
            const tagsPeople = [];
            const tagsPeopleEmails = [];
            const tagsFormFields = [];

            messageTokens.forEach((token) => {
                if (token.insert.mention) {
                    const tag = {
                        id: token.insert.mention.id,
                        value: token.insert.mention.value
                    };
                    if (token.insert.mention.denotationChar == '#') {
                        tagsFormFields.push(tag);
                    } else {
                        tagsPeople.push(tag);
                        tagsPeopleEmails.push(tag.id);
                    }
                }
            });
            const payload = {
                html: messageHtml,
                tagsPeople: tagsPeople,
                tagsPeopleEmails,
                tagsFormFields: tagsFormFields,
                email: session.user.email,
                status: noteStatus || NoteStatus.pending,
                orderId,
                lotId,
                formName,
                createdOn: new Date()
            };
            return batchFormNotesRef.add(payload).then((docRef) => {
                return docRef.id;
            }).then((docId) => {
                return streamNotesRef.add(Object.assign({}, payload, {
                    originalNoteId: docId,
                    status: noteStatus || NoteStatus.pending,
                    orderId,
                    lotId,
                    formName
                }));
            }).catch((error) => {
                console.error(`Error saving ${formName} notes! `, error);
                return Promise.reject(error);
            });
        },
        error,
    }
}