import { useState } from 'react';
// import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import useTemplates from "../../hooks/scheduler/use-templates";
import TemplateFormModal from './template-form-modal';
import useSchedulerProcesses from '../../hooks/scheduler/use-scheduler-processes';
import useSchedulerClients from '../../hooks/scheduler/use-scheduler-clients';

const useStyles = makeStyles({
  root: {
    "&.dataRow": {
      "&:hover": {
        backgroundColor: "#efefefef",
      },
    },
    "&.dataRow--last": {
      "& > *": {
        borderBottom: "unset",
      },
    },
    "&.selected": {
      "& > *": {
        backgroundColor: "rgb(63,81,181,0.1)",
      },
    },
  }
});

const TemplateDataGrid = () => {
  const classes = useStyles();
  const { templates } = useTemplates(true);
  const [isEditTemplate, setIsEditTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { lookupProcessByCode } = useSchedulerProcesses();
  const { lookupClientByCode } = useSchedulerClients();

  return (
    <>
      <TableContainer component={Paper} className={classes.root}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Process</TableCell>
              <TableCell align="right">Client</TableCell>
              {/* <TableCell align="right">Date Created</TableCell> */}
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates?.map((row, rowIdx) => (
              <TableRow
                key={`template-row-${rowIdx}`}
                className={`${
                  rowIdx === templates.length - 1
                    ? "dataRow dataRow--last"
                    : `dataRow dataRow--${rowIdx}`
                }`}
              >
                <TableCell scope="row">{row.name}</TableCell>
                <TableCell align="right">{lookupProcessByCode(row['process'])?.name || row['process']}</TableCell>
                <TableCell align="right">{lookupClientByCode(row['client'])?.name || row['client']}</TableCell>
                {/* <TableCell align="right">
                  {row?.createdOn?.seconds &&
                    dayjs.unix(row?.createdOn?.seconds).format("MMM DD, YYYY")}
                </TableCell> */}
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setIsEditTemplate(true);
                      setSelectedTemplate(row);
                    }}
                  >
                    <BorderColorIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEditTemplate && 
        <TemplateFormModal 
          isVisible={isEditTemplate}
          templateId={selectedTemplate.NO_ID_FIELD}          
          onClose={() => {
            setIsEditTemplate(false);
          }}
          onSave={(values) => {
            // console.log('onSave: ', values);
          }}
      />}
    </>
  );
}

export default TemplateDataGrid;