// import { useFirestore } from "reactfire";
import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import usePermissions from "../../hooks/use-permissions";
import { USER_ROLE } from "../../hooks/constants";
import SimpleSnackbar from "../../design-system/simple-snackbar";
import { firestoreInstance } from "../../config/firebase-init";

const userRoleKeys = Object.keys(USER_ROLE);

const useStyles = makeStyles({
  tableContainer: {
    "& table": {
      "& thead": {
        "& th": {
          textTransform: "capitalize",
        },
      },
    },
  },
});

function ManagePermissions() {
  const classes = useStyles();
  const permissionsRef = firestoreInstance.collection("permissions").doc("data");
  const { savePermissions, loadPermissionsMaster } = usePermissions();
    const [permissionsFormValues, setPermissionsFormValues] = useState([]);
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("Saved Permissions!");

  const fetchPermissions = () => {
    permissionsRef.get().then((res) => {
      const data = res.data();
      if (data && data.values) {
        setPermissionsFormValues(data.values);
      }
    });
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  const handleSave = () => {
      savePermissions(permissionsFormValues).then(() => {
          setIsSnackbarVisible(true);
    });
  };

  return (
    <Box p={2}>
      {permissionsFormValues && permissionsFormValues.length === 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            loadPermissionsMaster().then(() => {
              fetchPermissions();
            });
          }}
        >
          Load Permissions Master
        </Button>
      )}
      {permissionsFormValues && permissionsFormValues.length > 0 && (
        <>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Privilege</TableCell>
                  {userRoleKeys.map((roleKey) => {
                    return (
                      <TableCell
                        key={`permission--tableHeader-${roleKey}`}
                        align="center"
                      >
                        {USER_ROLE[roleKey]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {permissionsFormValues.map((perm, permIdx) => (
                  <TableRow key={`permission-${perm.code}`}>
                    <TableCell>{perm.title}</TableCell>
                    {userRoleKeys.map((roleKey) => {
                      return (
                        <TableCell
                          key={`permission--tableRow-${roleKey}`}
                          align="center"
                        >
                          <Checkbox
                            color="primary"
                            checked={!!perm[USER_ROLE[roleKey]]}
                            onChange={() => {
                              setPermissionsFormValues(
                                permissionsFormValues.map(
                                  (newPerm, newPermIdx) => {
                                    if (permIdx === newPermIdx) {
                                      newPerm[USER_ROLE[roleKey]] = !perm[
                                        USER_ROLE[roleKey]
                                      ];
                                    }
                                    return newPerm;
                                  }
                                )
                              );
                            }}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2} textAlign="right">
            <Button
              color="primary"
              onClick={() => {
                loadPermissionsMaster().then(() => {
                  fetchPermissions();
                });
              }}
            >
              Reset
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </>
          )}
          <SimpleSnackbar
                isVisible={isSnackbarVisible}
                message={snackbarMessage}
                onClose={() => {
                    setIsSnackbarVisible(false);
                }}
            />
    </Box>
  );
}

export default ManagePermissions;
