import { useState, useEffect } from "react";
import { FormDialogue } from "formik-generator-materialui";
// import * as Yup from "yup";
import useFormMasterList from "../../hooks/use-form-master-list";
import { BATCH_FORM_STATUS } from "../../hooks/constants";

const formFieldsSchema = [
  {
    title: '',
    typeField: 'arrayObject',
    path: "sequenceActivationSettings",
    emptyAddText: 'Add Rule',
    subfields: [
      {
        title: "Form",
        name: "formSlug",
        typeField: "select",
        choices: [],
        required: true,
        col: 6
      },
      {
        title: "Form Status",
        name: "formStatus",
        typeField: "select",
        choices: [],
        required: true,
        col: 6
      }
    ]
}
];

export default function WorkflowColumnSettingsModal({
  isVisible,
  initialValues = {},
  workflowStateData = {},
  onClose = null,
  onSave = null,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { formsMasterList } = useFormMasterList();

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (formsMasterList) {
      const formSlugs = formsMasterList?.map((form) => form.slug);
      formFieldsSchema[0].subfields[0].choices = formSlugs;
      formFieldsSchema[0].subfields[1].choices = Object.values(BATCH_FORM_STATUS);
    }
  }, [formsMasterList]);

  return (
    <>
      {formFieldsSchema[0].subfields[0].choices.length > 0 && <FormDialogue
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
          onClose & onClose();
        }}
        okText="Save"
        onOk={(values) => {
          // console.log('onSave: ', values);
          onSave?.(values);
        }}
        title="Sequence Activation Settings"
        text={workflowStateData.name}
        initialValues={initialValues}        
        fields={formFieldsSchema}
      />}
    </>
  );
}