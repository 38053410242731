import { useEffect, useMemo, useState } from 'react';
import { RESOURCE_TYPE } from "../constants";
import { firestoreInstance } from '../../config/firebase-init';

const useResources = (activeAndInactive, resourceType) => {
  let resourcesRef = null;
  if (activeAndInactive) {
    if (resourceType) {
      resourcesRef = firestoreInstance.collection('schedulerResources')
        .where('type', '==', resourceType);
    } else {
      resourcesRef = firestoreInstance.collection('schedulerResources')
    }
  } else {
    if (resourceType) {
      resourcesRef = firestoreInstance.collection('schedulerResources')
        .where('type', '==', resourceType).where('active', '==', true);
    } else {
      resourcesRef = firestoreInstance.collection('schedulerResources')
        .where('active', '==', true);
    }
  }
  
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const { cleanRooms, equipment, people, processes, clients, resourcesByCodeMap } = useMemo(() => {
    const cleanRooms = [], equipment = [], people = [], processes = [], clients = [], resourcesByCodeMap = {};
    data?.map((rec) => {
      resourcesByCodeMap[rec.code] = rec;
      if (rec.type === RESOURCE_TYPE.cleanRoom) {
        cleanRooms.push(rec);
      } else if (rec.type === RESOURCE_TYPE.equipment) {
        equipment.push(rec);
      } else if (rec.type === RESOURCE_TYPE.person) {
        people.push(rec);
      } else if (rec.type === RESOURCE_TYPE.process) {
        processes.push(rec);
      } else if (rec.type === RESOURCE_TYPE.client) {
        clients.push(rec);
      }
    });
    return {cleanRooms, equipment, people, processes, clients, resourcesByCodeMap};
  }, [data]);
 
  useEffect(() => {
    const unsubscribeResourcesRef = resourcesRef.onSnapshot((res) => {
      const dataRecords = parseResponse(res);
      setData(dataRecords);
      setError(null);
    }, (err) => { setError(err); });

    return () => {
      unsubscribeResourcesRef();
    };
  }, [resourceType]);

  const parseResponse = (res) => {
    let dataRecords = [];
    if (!res.empty) {
      dataRecords = res.docs.map((doc) => {
        return {...doc.data(), NO_ID_FIELD: doc.id};
      });       
    }
    return dataRecords;
  }

  return {
    cleanRooms,
    equipment,
    people,
    processes,
    clients,
    filter: (searchString) => {
      if (searchString) {
        // snapshots for all filter Queries        
        const filterByNamesPromise = resourcesRef.orderBy('name')
          .startAt(searchString).endAt(searchString + '~').get().then(parseResponse);
        const filterByShortNamesPromise = resourcesRef.orderBy('shortName')
          .startAt(searchString).endAt(searchString + '~').get().then(parseResponse);
        const filterByCodesPromise = resourcesRef.orderBy('code')
          .startAt(searchString).endAt(searchString + '~').get().then(parseResponse);
        // To combine all promises/filters data

        return Promise.all([filterByNamesPromise, filterByShortNamesPromise, filterByCodesPromise]).then((dataSets) => {
          const mergedDataSets = [].concat(...dataSets);
          const finalDedupedData = {};
          mergedDataSets.forEach((rec) => {
            if (!finalDedupedData[rec.NO_ID_FIELD]) {
              finalDedupedData[rec.NO_ID_FIELD] = rec;
            }
          });
          setData(Object.values(finalDedupedData));
          setError(null);
        });
      } else {
        return resourcesRef.get().then((res) => {
          const dataRecords = parseResponse(res);
          setData(dataRecords);
          setError(null);
        });
      }
    },
    fetchResource: (resourceCode) => {
      return resourcesByCodeMap[resourceCode];
    }
  };
}

export default useResources;