import { useState } from "react";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
// import CommentIcon from '@material-ui/icons/Comment';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useHistory } from "react-router";
import SplitButtonWithOptions from '../../design-system/split-button-with-options';
import LotFormModal from "../Forms/lot-form-modal";
import useStore from "../../hooks/use-store";
import useOrderLotList from "../../hooks/use-order-lot-list";
import QrCodeModal from '../batch-form-wizard/qr-code-modal';

const useMainGridStyles = makeStyles({
  root: {
    "& > .MuiTable-root": {
      "& th": {
        textTransform: "uppercase",
      },
    },
  },
});

const useRowStyles = makeStyles({
  root: {
    "&.orderRow": {
      "&:hover": {
        backgroundColor: "#efefefef",
      },
    },
    "&.orderRow--last": {
      "& > *": {
        borderBottom: "unset",
      },
    },
    "&.expanded": {
      "& > *": {
        borderBottom: "unset",
      },
    },
    "&.selected": {
      "& > *": {
        backgroundColor: "rgb(63,81,181,0.1)",
      },
    },
  },
});

const useLotRowStyles = makeStyles({
  root: {
    "& tr.lotRow": {
      "& > *": {
        borderBottom: "unset",
      },
      "&:hover": {
        backgroundColor: "#efefefef",
      },
    },
    "& .MuiBox-root": {
      marginLeft: "64px",
    },
  },
});

function Row({
  row,
  rowClasses = "",
  onEdit = null,
  onNewLotForm = null,
  onLotClick = null,
  onPrint = null,
  onPrintQrCode = null
}) {
  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const { lotList, deleteLot } = useOrderLotList(row.NO_ID_FIELD);
  const classes = useRowStyles();
  const classesLotRow = useLotRowStyles();

  const handleDeleteLot = (lotRow) => {
    deleteLot(lotRow.NO_ID_FIELD);
  };

  const editLot = (lotRow) => {
    const lotValues = Object.assign({}, lotRow, {
      expDate: dayjs.unix(lotRow.expDate.seconds).format(),
    });
    onNewLotForm && onNewLotForm(row, lotValues);
  };

  return (
    <>
      <TableRow
        className={`${classes.root} ${rowClasses} ${
          isRowExpanded ? "expanded" : "collapsed"
        }`}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            disabled={lotList && lotList.length === 0}
            onClick={() => setIsRowExpanded(!isRowExpanded)}
          >
            {isRowExpanded ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell scope="row">{row.product}</TableCell>
        <TableCell align="right">{row.part}</TableCell>
        <TableCell align="right">{row.customer}</TableCell>
        <TableCell align="right">
          {row.orderDate &&
            dayjs.unix(row.orderDate.seconds).format("MMM DD, YYYY")}
        </TableCell>
        <TableCell align="right">
          <IconButton
            color="primary"
            onClick={() => {
              onEdit && onEdit(row);
            }}
          >
            <BorderColorIcon />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              onNewLotForm && onNewLotForm(row);
            }}
          >
            New Lot
          </Button>
        </TableCell>
      </TableRow>
      {lotList && lotList.length > 0 && (
        <TableRow className={classesLotRow.root}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
              <Box>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Lot #</TableCell>
                      <TableCell>Batch #</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lotList.map((lotRow, lotRowIdx) => (
                      <TableRow
                        key={lotRow.NO_ID_FIELD}
                        className={
                          lotRowIdx === lotList.length - 1
                            ? "lotRow lotRow--last"
                            : `lotRow lotRow-${lotRowIdx}`
                        }
                      >
                        <TableCell component="th" scope="row">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              onLotClick?.(lotRow);
                            }}
                          >
                            {lotRow.lotNum}
                          </Button>
                        </TableCell>
                        <TableCell>{lotRow.batchNum}</TableCell>
                        <TableCell>
                          <div style={{ display: 'flex-inline', justifyContent: 'flex-start' }}>
                            <ButtonGroup size="small">
                              <Button
                                onClick={() => {
                                  editLot(lotRow);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  handleDeleteLot(lotRow);
                                }}
                              >
                                Delete
                              </Button>
                            </ButtonGroup> &nbsp;&nbsp;
                            <SplitButtonWithOptions options={['Approve', 'Complete', 'Re-open']} /> &nbsp;&nbsp;
                            <SplitButtonWithOptions options={['QA Access', 'Access Logs', 'Notes']} /> &nbsp;&nbsp;
                            <ButtonGroup size="small" >
                              <Button onClick={(evt) => {
                                onPrint?.(evt, lotRow);
                              }}>
                                &nbsp; <PrintIcon />
                              </Button>
                              <Button onClick={(evt) => {
                                onPrintQrCode?.(lotRow);
                              }}>
                                <img src="/static/images/qr-code.svg" />
                              </Button>
                            </ButtonGroup>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function OrdersDataGrid({ data, onEdit, onLotSave }) {
  const classes = useMainGridStyles();
  const [isLotFormOpen, setIsLotFormOpen] = useState(false);
  const [isPrintQrModalOpen, setIsPrintQrModalOpen] = useState(false);
  const [selectedOrderData, setSelectedOrderData] = useState({});
  const [selectedLotData, setSelectedLotData] = useState({});
  const {
    uiState: { selectedOrderRowIdx },
    setSelectedOrderRowIdx,
  } = useStore();
  const history = useHistory();

  return (
    <>
      <TableContainer component={Paper} className={classes.root}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Product</TableCell>
              <TableCell align="right">Part #</TableCell>
              <TableCell align="right">Customer</TableCell>
              <TableCell align="right">Date Created</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIdx) => (
              <Row
                rowClasses={`
                  ${
                    rowIdx === data.length - 1
                      ? "orderRow orderRow--last"
                      : `orderRow orderRow--${rowIdx}`
                  }
                  ${rowIdx === selectedOrderRowIdx ? "selected" : ""}
                `}
                key={row.NO_ID_FIELD}
                row={row}
                onPrint={(evt, lotRowData) => {
                  // setSelectedOrderRowIdx(rowIdx);
                  // console.log('order: ', data[rowIdx], lotRowData);
                  setSelectedOrderData(data[rowIdx]);
                  setSelectedLotData(lotRowData);
                  history.push(`/orders/${row.NO_ID_FIELD}/${lotRowData.NO_ID_FIELD}/print-batch`);
                }}
                onPrintQrCode={(lotRowData) => {
                  setSelectedOrderData(data[rowIdx]);
                  setSelectedLotData(lotRowData);
                  setIsPrintQrModalOpen(true);
                }}
                onEdit={(row) => {
                  setSelectedOrderRowIdx(rowIdx);
                  onEdit && onEdit(row);
                }}
                onLotClick={(lotRowData) => {
                  setSelectedOrderRowIdx(rowIdx);
                  history.push(
                    `/orders/${row.NO_ID_FIELD}/${lotRowData.NO_ID_FIELD}/batch`
                  );
                }}
                onNewLotForm={(orderData, lotData) => {
                  setSelectedOrderRowIdx(rowIdx);
                  setSelectedOrderData(orderData);
                  if (lotData) {
                    // When editing a Lot
                    setSelectedLotData(lotData);
                  } else {
                    setSelectedLotData({});
                  }
                  setIsLotFormOpen(true);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isLotFormOpen && <LotFormModal
        orderData={selectedOrderData}
        isVisible={isLotFormOpen}
        initialValues={selectedLotData}
        onSave={() => {
          setIsLotFormOpen(false);
          onLotSave && onLotSave();
        }}
        onClose={() => {
          setIsLotFormOpen(false);
        }}
      />}

      {isPrintQrModalOpen && 
        <QrCodeModal 
          isVisible={isPrintQrModalOpen}
          customerName={selectedOrderData.customer}
          productName={selectedOrderData.product}
          lotNum={selectedLotData.lotNum}
          batchNum={selectedLotData.batchNum}
          onClose={() => {
            setIsPrintQrModalOpen(false);
          }} 
      />}
    </>
  );
}

export default OrdersDataGrid;
