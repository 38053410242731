import dayjs from 'dayjs';
import { FIRESTORE_COLLECTIONS } from '../constants';
import { firestoreInstance } from '../../config/firebase-init';

export default function useSchedulerOrders() {
  const ordersRef = firestoreInstance.collection(FIRESTORE_COLLECTIONS.schedulerOrders);
  
  return {
    fetchOrders: () => {
      return ordersRef.where('active', '==', true).get().then((querySnapshot) => {
        const records = []; 
        querySnapshot.forEach((doc) => {
          const rec = doc.data();
          rec.id = doc.id;
          records.push(rec);
        });
        return records;
      });
    },
    fetchOrder: (orderId) => {
      return ordersRef.doc(orderId).get().then((querySnapshot) => {
        if (querySnapshot.exists) {
          return querySnapshot.data();
        }
        return null;
      });
    },
    addOrder: (orderId, payload) => {
      return ordersRef.doc(orderId).set(payload);
    },
    deleteOrder: (orderId) => {
      return ordersRef.doc(orderId).delete();
    },
    saveOrder: (docId, payload) => {
      return ordersRef.doc(docId).update(payload);
    },
    submitOrderToCliniTrack: (payload) => {
      // https://app-api-praglife-demo.azurewebsites.net/api/Order/createorder
      return fetch('https://app-api-praglife-demo.azurewebsites.net/api/Order/createorder', {
        method: 'POST',
        cache: 'no-cache',
        // mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then((res) => {
        console.log('response: ', res);
        return res;
      });
    },
    fetchOrderBySubjectId: (subjectId) => {
      return ordersRef.where('PatientId', '==', subjectId).get().then((querySnapshot) => {
        const records = []; 
        querySnapshot.forEach((doc) => {
          const rec = doc.data();
          rec.id = doc.id;
          records.push(rec);
        });
        return records.length > 0 ? records[0] : null;
      });
    },
    /**
     * All 3 Dates are optional. However, if one or more dates are enterd then the following validation ruels should be applied:
     * - Dates should be in chronological order **Biopsy < Injection 1 < Injection 2**
     * - Injection 1 should be greater thatn Biopsy by 14 days
     * - Injection 2 should be greater than Injection 1 by 14 days
     * @param {object} biopsyDate - Native Javascript Date object
     * @param {object} injection1Date - Native Javascript Date object
     * @param {object} injection2Date - Native Javascript Date object
     * @returns 
     */
    isValid_OrderDates: (biopsyDate, injection1Date, injection2Date, isOrderEditMode) => {
      if (!biopsyDate) {
        return {isValid: false, error: 'Biopsy Date is required'};
      } else if (biopsyDate || injection1Date || injection2Date) {
        const today = new Date();
        if (biopsyDate) {
          const b_dayjs = dayjs(biopsyDate);
          // Biopsy Date should be greater than today. Disabled in edit mode.
          if (!isOrderEditMode && b_dayjs.diff(today, 'day') < 0) return {isValid: false, error: 'Biopsy Date should be greater than today'};

          if (injection1Date) {
            const i1_dayjs = dayjs(injection1Date);
            // Injection 1 Date should be greater than Biopsy Date by 35 days
            if (i1_dayjs.diff(biopsyDate, 'day') < 35) return {isValid: false, error: 'Injection 1 Date should be scheduled 35 days after Biopsy'};

            if (injection2Date) {
              const i2_dayjs = dayjs(injection2Date);
              // Injection 2 Date should be greater than Injection 1 Date by 35 days
              if (i2_dayjs.diff(injection1Date, 'day') < 35) return {isValid: false, error: 'Injection 2 Date should be scheduled 35 days after Injection 1'};
            }
          } else if (injection2Date) {
            return {isValid: false, error: 'Injection 1 must be entered before entering Injection 2'};
          }
        } else if (injection1Date) {
          const i1_dayjs = dayjs(injection1Date);
          // Injection 1 Date should be greater than today. Disabled in Edit mode.
          if (!isOrderEditMode && i1_dayjs.diff(today, 'day') < 0) return {isValid: false, error: 'Injection 1 Date should be greater than today'};
          
          if (injection2Date) {
            const i2_dayjs = dayjs(injection2Date);
            // Injection 2 Date should be greater than Injection 1 Date by 35 days
            if (i2_dayjs.diff(injection1Date, 'day') < 35) return {isValid: false, error: 'Injection 2 Date should be scheduled 35 days after Injection 1'};
          }
        } else if (injection2Date) {
          if (!injection1Date) {
            return {isValid: false, error: 'Injection 1 must be entered before entering Injection 2'};
          }

          const i2_dayjs = dayjs(injection2Date);
          // Injection 2 Date should be greater than today. Disabled in Edit mode.
          if (!isOrderEditMode && i2_dayjs.diff(today, 'day') < 0) return {isValid: false, error: 'Injection 2 Date should be greater than today'};
        }
      } else {
        return {isValid: false, error: 'One of Biopsy, Injection 1 or Injection 2 Dates is required'};
      }
      return {isValid: true};
    }
  };
}