import { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const ConfirmationModal = ({ isVisible, message, onOk, onCancel, isLoadingIndicatorVisible }) => {
  const classes = useStyles();
  const componentRef = useRef();

  return (
    <>
      <Dialog
        open={isVisible}
        className={classes.root}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          onCancel?.();
        }}
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description">
        <DialogTitle id="confirmation-modal-title">Confirm</DialogTitle>
        <DialogContent ref={componentRef}>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { onCancel?.() }}>
            Cancel
          </Button>
          <Button disabled={isLoadingIndicatorVisible} variant="outlined" onClick={() => { onOk?.() }} color="primary">
            Ok
          </Button>
          {isLoadingIndicatorVisible && <CircularProgress size={30} color="secondary" />}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmationModal;