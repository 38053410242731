import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import OrdersDataGrid from "../../components/Datagrids/orders-datagrid";
import OrderForm from "../../components/Forms/order-form";
import SimpleSnackbar from "../../design-system/simple-snackbar";
import TabPanel from '../../design-system/tab-content-panel';
import PageTitle from "../../design-system/page-title";
import Breadcrumb from '../../design-system/breadcrumb';
import { firestoreInstance } from '../../config/firebase-init';

const useStyles = makeStyles(theme => ({
  floatingButton: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(2.5)
  }
}));

function Orders() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [ordersToEdit, setOrdersToEdit] = useState([]);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const ordersRef = firestoreInstance.collection("orders");

  const [ordersData, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
      const unsubscribeOrdersRef = ordersRef.onSnapshot((res) => {
          if (!res.empty) {
              const dataRecords = res.docs.map((doc) => {
                  return {...doc.data(), NO_ID_FIELD: doc.id};
              });
              setData(dataRecords);
          } else {
              setData([]);
          }
          setError(null);
      }, (err) => { setError(err); });
      
      return () => {
        unsubscribeOrdersRef();
      };
  }, []);

  const removeUpdatedOrderTab = (orderData) => {
    if (orderData.NO_ID_FIELD) {
      const newOrdersToEdit = ordersToEdit.filter((order) => {
        return order.NO_ID_FIELD !== orderData.NO_ID_FIELD;
      });
      setOrdersToEdit(newOrdersToEdit);
    }
  };

  return (
    <Box>
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      <PageTitle title="Manage Orders" />
      <Tabs value={tabIndex} onChange={handleChange}>
        <Tab label="Orders" />
        <Tab label="New Order" />
        {ordersToEdit.map((order) => (
          <Tab
            key={order.id || order.NO_ID_FIELD}
            label={`Edit ${order.product || order.id || order.NO_ID_FIELD}`}
          />
        ))}
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        {ordersData && (
          <OrdersDataGrid
            data={ordersData}
            onEdit={(order) => {
              setOrdersToEdit(ordersToEdit.concat(order));
            }}
            onLotSave={() => {
              setSnackbarMessage("Successfully saved Lot");
              setIsSnackbarVisible(true);
            }}
          />
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <OrderForm
          onSave={() => {
            setTabIndex(0);
            setSnackbarMessage("Successfully saved Order");
            setIsSnackbarVisible(true);
          }}
          onCancel={() => {
            setTabIndex(0);
          }}
        />
      </TabPanel>
      {ordersToEdit.map((order, idx) => (
        <TabPanel
          key={`tabPanel-${order.NO_ID_FIELD}`}
          value={tabIndex}
          index={2 + idx}
        >
          <OrderForm
            initialValues={order}
            onSave={(initialValues) => {
              setTabIndex(0);
              removeUpdatedOrderTab(initialValues);
              setSnackbarMessage("Successfully saved Order");
              setIsSnackbarVisible(true);
            }}
            onCancel={(initialValues) => {
              setTabIndex(0);
              removeUpdatedOrderTab(initialValues);
            }}
          />
        </TabPanel>
      ))}
      <SimpleSnackbar
        isVisible={isSnackbarVisible}
        message={snackbarMessage}
        onClose={() => {
          setIsSnackbarVisible(false);
        }}
      />
      <Fab color="primary" aria-label="create new order" className={classes.floatingButton} onClick={() => {
        setTabIndex(1);
      }}>
        <AddIcon />
      </Fab>
    </Box>
  );
}

export default Orders;
