import Avatar from '@material-ui/core/Avatar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import InfoIcon from '@material-ui/icons/Info';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NotificationsPausedIcon from '@material-ui/icons/NotificationsPaused';
import NoteIcon from '@material-ui/icons/Note';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import React from 'react';
import indigo from '@material-ui/core/colors/indigo';
import moment from 'moment';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';

const styles = {
  indigoText: {
    color: indigo[500],
    backgroundColor: 'transparent'
  },
  redText: {
    color: red[500],
    backgroundColor: 'transparent'
  },
  yellowText: {
    color: yellow[500],
    backgroundColor: 'transparent'
  },
  defaultText: {
    backgroundColor: 'transparent',
    color: 'inherit'
  }
};

const date = Date.now();

export const mockNotifications = [
  {
    avatar: (
      <Avatar style={{ ...styles.defaultText }}>
        <InfoIcon />
      </Avatar>
    ),
    title: 'Viewed form',
    subtitle: moment(date).format('MMMM Do YYYY, h:mm:ss a'),
    preTitle: 'Doctor Smith',
  },
  {
    avatar: (
      <Avatar style={{ ...styles.defaultText }}>
        <DeleteSweepIcon />
      </Avatar>
    ),
    title: 'Removed 6 records from Rejects form',
    subtitle: moment(date).format('MMMM Do YYYY, h:mm:ss a'),
    preTitle: 'Christian Pfeffer',
  },
  {
    avatar: (
      <Avatar style={{ ...styles.defaultText }}>
        <DoneIcon />
      </Avatar>
    ),
    title: 'Updated form status to Verified',
    subtitle: moment(new Date(date - 1000 * 60 * 60)).format(
      'MMMM Do YYYY, h:mm:ss a'
    ),
    preTitle: 'Jerald Breitenberg',
  },
  {
    avatar: (
      <Avatar style={{ ...styles.defaultText }}>
        <NotificationsPausedIcon />
      </Avatar>
    ),
    title: 'Setup reminder on a Note from Supe User',
    subtitle: moment(new Date(date - 1000 * 60 * 60 * 2)).format(
      'MMMM Do YYYY, h:mm:ss a'
    ),
    preTitle: 'Nurse Stella',
  },
  {
    avatar: (
      <Avatar style={{ ...styles.defaultText }}>
        <NoteIcon />
      </Avatar>
    ),
    title: 'Sent note to Super User',
    subtitle: moment(new Date(date - 1000 * 60 * 60 * 3)).format(
      'MMMM Do YYYY, h:mm:ss a'
    ),
    preTitle: 'Doctor Smith',
  },
  {
    avatar: (
      <Avatar style={{ ...styles.defaultText }}>
        <DoneAllIcon />
      </Avatar>
    ),
    title: 'Update form status to Completed',
    subtitle: moment(new Date(date - 1000 * 60 * 60 * 6)).format(
      'MMMM Do YYYY, h:mm:ss a'
    ),
    preTitle: 'Jonathan Balistreri',
  },
  {
    avatar: (
      <Avatar style={{ ...styles.defaultText }}>
        <NotificationsPausedIcon />
      </Avatar>
    ),
    title: 'Setup reminder on a Note from Supe User',
    subtitle: moment(new Date(date - 1000 * 60 * 60 * 2)).format(
      'MMMM Do YYYY, h:mm:ss a'
    ),
    preTitle: 'Nurse Stella',
  },
  {
    avatar: (
      <Avatar style={{ ...styles.defaultText }}>
        <NoteIcon />
      </Avatar>
    ),
    title: 'Sent note to Super User',
    subtitle: moment(new Date(date - 1000 * 60 * 60 * 3)).format(
      'MMMM Do YYYY, h:mm:ss a'
    ),
    preTitle: 'Doctor Smith',
  },
  {
    avatar: (
      <Avatar style={{ ...styles.defaultText }}>
        <DoneAllIcon />
      </Avatar>
    ),
    title: 'Update form status to Completed',
    subtitle: moment(new Date(date - 1000 * 60 * 60 * 6)).format(
      'MMMM Do YYYY, h:mm:ss a'
    ),
    preTitle: 'Jonathan Balistreri',
  },  
];

export const mockTodo = [
  {
    title: 'Learn React',
    subtitle: moment(new Date(date - 1000 * 60 * 60 * 3)).format(
      'MMMM Do YYYY, h:mm:ss a'
    )
  },
  {
    title: 'Learn React Native',
    subtitle: moment(new Date(date - 1000 * 60 * 60 * 6)).format(
      'MMMM Do YYYY, h:mm:ss a'
    )
  },
  {
    title: 'Write Documentation',
    subtitle: moment(new Date(date - 1000 * 60 * 60 * 10)).format(
      'MMMM Do YYYY, h:mm:ss a'
    )
  }
];

export const mockStats = [
  {
    title: 'Local storage',
    value: 30
  },
  {
    title: 'Cloud storage ',
    value: 80
  },
  {
    title: 'Local storage',
    value: 20
  }
];
