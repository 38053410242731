import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormSlugComponentMapper from '../batch-form-wizard/form-slug-component-mapper';

export default function PreviewFormModal({ isVisible, formSlug, formName, onClose }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  const FormComponent = FormSlugComponentMapper(formSlug);

  return (
    <Dialog
      fullWidth={true}
      open={isOpen}
      onClose={() => {
        onClose?.();
      }}
      aria-labelledby="preview-form-modal-title"
      aria-describedby="preview-form-modal-description">
      <DialogTitle id="preview-form-modal-title">{`Preview Form: ${formName}`}</DialogTitle>
      <DialogContent>        

        {/* <DialogContentText id="configure-forms-modal-description">
          Choose statuses for this form.
        </DialogContentText> */}
        {<FormComponent isPreview={true} />}
        
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { onClose?.() }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )  
}