import { useRef } from 'react';
import dayjs from 'dayjs';
import { FormGenerator } from "formik-generator-materialui";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import useBatchFormData from '../../hooks/use-batch-form-data';
import { BATCH_FORM_NAMES as FORM_NAMES } from '../../hooks/constants';

const useStyles = makeStyles((theme) => ({
    paragraph: {
        marginBottom: theme.spacing(2),
        fontSize: '0.8rem'
    },
  }));

const formFieldsSchema = [
    {
        title: '',
        typeField: 'group',
        path: '',
        subfields: [
            {
                title: 'Verified',
                typeField: 'switch',
                path: 'verified',
                col: 2
            },
            {
                title: 'Verified By',
                typeField: 'text',
                path: 'verifiedBy',
                col: 3
            },
            {
                title: 'Verified On',
                typeField: 'date',
                path: 'verifiedOn',
                col: 3
            },
        ]
    }
];

const prepareDataToEdit = (values) => {
    const data = Object.assign({}, values);
    if (data['verifiedOn'] && data['verifiedOn'].seconds) {
        data['verifiedOn'] = dayjs.unix(data['verifiedOn'].seconds).toDate();
    }
    return data;
}

function DailyOperationsForm({
    orderId,
    lotId,
    onSave = null,
    onCancel = null,
    isReadOnly = false,
    isValidateOnlyOnSubmit = true
}) {
    const classes = useStyles();
    const { batchFormData, saveBatchForm, error } = useBatchFormData(orderId, lotId, FORM_NAMES.dailyOperations);
    let initialValues = {};
    if (batchFormData) {
        initialValues = prepareDataToEdit(batchFormData);
    }

    const saveFormData = (formData) => {
        saveBatchForm(formData).then(() => {
            onSave && onSave();
        });
    };

    const formRef = useRef(null);
    return (
        <>
            <Typography className={classes.paragraph}>
                At the start of each day’s production, assure the line is free of foreign production/components/labeling and the line & equipment are clean. Verify the vision system is operating properly, if applicable.
            </Typography>
            <Typography className={classes.paragraph}>
                Filled blisters are visually inspected by the packers for proper lot and expiration/best by date, print alignment and clarity, proper forming, empty blisters, broken/miscolored tablets, proper seal, perforation, and product/package quality. Blister strips failing to conform are segregated and are rejected or reclaimed. Quality inspectors or the supervisor are notified immediately if any foreign tablets, objects, or other items are found. The number of rejected blister strips is documented.
            </Typography>
            <Typography className={classes.paragraph}>
                Samples for inspection/testing are taken by Quality at regular intervals. The intervals are hourly for supplements and every half hour for OTC/Rx drugs. Customers, at their discretion, may request shorter or longer sampling intervals. Results of this inspection are recorded on the “QA Test & Inspection” page. If blisters are defective for print quality, improper seal, malfunctioning opening feature, or perforation, the segment of the blistering run impacted is identified, segregated and 100% inspected to remove all poor quality blisters. Details of the additional inspections are recorded in the QA inspection comments section. Sampling intervals that are 15 minutes or longer than the specified intervals are explained on the QA inspection comments section. Additional samples are taken for retention and for Beginning, Middle, and End (BME) inspection samples. Three strips are taken for retention and for BME final inspection, one each from beginning, middle, and end of production. A customer may request more samples be taken for the final inspection. The BME sample strips are visually inspected prior to release for legible/correct lot number/expiration date, artwork code (if applicable) and conformance to Product description. Results are records on the master blister filling record.
            </Typography>
            <Typography className={classes.paragraph}>
                Machine operating parameters and room conditions are recorded at each sample interval, i.e. forming temperature, seal temperature, and relative humidity (if requested). Cycle speed is determined and recorded at daily start-up and after breaks and lunches.
            </Typography>
            <FormGenerator
              onSubmit={(values) => {
                saveFormData(values);
              }}
              fields={formFieldsSchema}
              formRef={formRef}
              initialValues={initialValues}
              readOnly={isReadOnly}
              isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
            />
            <Button color="secondary" style={{ marginRight: '2em' }}
                onClick={() => {
                    formRef.current.resetForm();
                    onCancel && onCancel();
                }}>Cancel</Button>
            <Button variant="contained" color="primary"
                onClick={() => formRef.current.submitForm()}>Save</Button>
        </>
    );
}

export default DailyOperationsForm;