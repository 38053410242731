import { RESOURCE_TYPE } from '../constants';
import useResources from './use-resources';

const useSchedulerProcesses = () => {
  const { processes } = useResources(false, RESOURCE_TYPE.process);

  return {
    processes,
    lookupProcessByCode: (code) => {
      if (code) {
        const processFound = processes.find((p) => {
          return (p.code === code);
        });
        return processFound;
      }
    }
  }
}

export default useSchedulerProcesses;