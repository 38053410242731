import dayjs from "dayjs";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Drawer from "@material-ui/core/Drawer";
import ReportIcon from "@material-ui/icons/Report";
import DoneIcon from "@material-ui/icons/Done";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { Message } from "../../components";
import useStreamNotes from "../../hooks/use-stream-notes";
import { NoteStatus } from "../../hooks/constants";
import PageTitle from "../../design-system/page-title";
import Breadcrumb from '../../design-system/breadcrumb';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    // padding: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 100,
  },
  toolBar: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
  },
  drawerPaper: {
    position: "relative",
    width: 160,
    maxWidth: 160,
    zIndex: theme.zIndex.drawer + 3,
    [theme.breakpoints.up("md")]: {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      top: "0",
      height: "calc(100vh - 56px)",
    },
    border: 0,
    height: "auto",
  },
  listItemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(0.5)
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#E6EFFC', // theme.palette.background.default,
    // padding: theme.spacing(1),
    minWidth: 0,
    minHeight: '400px'
  },
  modal: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
    zIndex: "1000",
  },
  backdrop: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
  },
}));


const alertMessages = {
  [NoteStatus.pending]: 'Horray! No pending messages.',
  [NoteStatus.done]: 'Get ready for something exciting!'
};

const Messages = () => {
  const classes = useStyles();
  const [selectedNoteStatus, setSelectedNoteStatus] = useState(NoteStatus.pending);
  const { notes } = useStreamNotes(selectedNoteStatus);

  const notesTransformed = (notes || []).map((n) => {
    return {
      from: n.email,
      date: dayjs.unix(n.createdOn.seconds).format("MMM DD, YYYY"),
      subject: `Regarding "${n.formName}" `,
      avatar: (
        <Avatar style={{ backgroundColor: '#E6EFFC', color: '#303f9f', textTransform: "uppercase" }}>{n.email[0]}{n.email[1]}</Avatar>
      ),
      body: n.html,
      orderId: n.orderId,
      lotId: n.lotId,
      formName: n.formName,
      originalNoteId: n.originalNoteId,
    };
  });

  return (
    <Box>
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      <PageTitle title="Messages Inbox" />
      <div className={classes.root}>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            ModalProps={{
              keepMounted: false,
              className: classes.modal,
              BackdropProps: {
                className: classes.backdrop,
              },
              // onBackdropClick: handleDrawerToggle
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <List>
              <ListItem button
                selected={selectedNoteStatus === NoteStatus.pending}
                onClick={() => {
                  setSelectedNoteStatus(NoteStatus.pending)
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <ReportIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Pending" />
              </ListItem>
              <ListItem button
                selected={selectedNoteStatus === NoteStatus.done}
                onClick={() => {
                  setSelectedNoteStatus(NoteStatus.done)
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <DoneIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Done" />
              </ListItem>
              <ListItem button
                selected={selectedNoteStatus === NoteStatus.signaturePending}
                onClick={() => {
                  setSelectedNoteStatus(NoteStatus.signaturePending)
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <EditTwoToneIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Signatures" />
              </ListItem>
            </List>
          </Drawer>
        </Hidden>

        <Box className={classes.content}>
          <Hidden mdUp>
            <Box m={2}>
              <ButtonGroup>
                <Button
                  color={selectedNoteStatus === NoteStatus.pending ? 'primary' : ''}
                  onClick={() => {
                    setSelectedNoteStatus(NoteStatus.pending)
                  }}
                >
                  <ReportIcon /> Pending
                </Button>
                <Button
                  color={selectedNoteStatus === NoteStatus.done ? 'primary' : ''}
                  onClick={() => {
                    setSelectedNoteStatus(NoteStatus.done)
                  }}
                >
                  <DoneIcon />
                  Done
                </Button>
                <Button
                  color={selectedNoteStatus === NoteStatus.signaturePending ? 'primary' : ''}
                  onClick={() => {
                    setSelectedNoteStatus(NoteStatus.signaturePending)
                  }}
                >
                  <EditTwoToneIcon />
                  Signatures
                </Button>
              </ButtonGroup>
            </Box>
          </Hidden>
          {
            notesTransformed && notesTransformed.length == 0 &&
            <Alert>{alertMessages[selectedNoteStatus]}</Alert>
          }
          <Message messages={notesTransformed} />
        </Box>
      </div>
    </Box>
  );
};

export default Messages;
