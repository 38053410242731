import { useEffect, useState } from 'react';
import { firestoreInstance } from '../config/firebase-init';

export default function useLotData(orderId, lotId) {
    const lotRef = firestoreInstance.collection('orders').doc(orderId).collection('lots').doc(lotId);

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribeLotRef = lotRef.onSnapshot((doc) => {
            setData(doc.data());
            setError(null);
        }, (err) => { setError(err); });

        return () => {
            unsubscribeLotRef();
        };
    }, []);

    return {
        lotData: data
    }
}