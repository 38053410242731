import { useMemo, useEffect, useState } from 'react';
import { FORM_TYPES } from './constants';
import { firestoreInstance } from '../config/firebase-init';

export default function useFormsMaster() {
  const [allFormsMasterMap, setAllFormsMasterMap] = useState({});
  const [formsMasterMap, setFormsMasterMap] = useState({});
  const [manualProcessFormsMasterMap, setManualProcessFormsMasterMap] = useState({});
  const formsMasterRef = firestoreInstance.collection('formsMaster');

  const [formsMasterListData, setData] = useState([]);
  const [formsMasterListError, setError] = useState(null);

  useEffect(() => {
      const unsubscribeFormsMasterRef = formsMasterRef.onSnapshot((res) => {
          if (!res.empty) {
              const dataRecords = res.docs.map((doc) => {
                  return {...doc.data(), NO_ID_FIELD: doc.id};
              });
              setData(dataRecords);
          } else {
              setData([]);
          }
          setError(null);
      }, (err) => { setError(err); });
      
      return () => {
        unsubscribeFormsMasterRef();
      };
  }, []);

  useMemo(() => {
    if (formsMasterListData) {
      const fMap = {}, mpfMap = {}, allFormsMap = {};
      formsMasterListData.forEach((form) => {
        allFormsMap[form.slug] = form;
        if (form.type !== FORM_TYPES.manualProcess) {
          fMap[form.slug] = form;
        } else {
          mpfMap[form.slug] = form;
        }
      });
      setFormsMasterMap(fMap);
      setManualProcessFormsMasterMap(mpfMap);
      setAllFormsMasterMap(allFormsMap);
    }
  }, [formsMasterListData]);

  return {
    allFormsMasterList: formsMasterListData,
    allFormsMasterMap,
    formsMasterList: formsMasterListData?.filter((form) => form.type != FORM_TYPES.manualProcess),
    formsMasterMap,
    manualProcessFormsMasterList: formsMasterListData?.filter((form) => form.type == FORM_TYPES.manualProcess),
    manualProcessFormsMasterMap,
    updateFormConfig: (formId, payload) => {
      return formsMasterRef.doc(formId).update(payload).catch((err) => {
        console.error('Error updating form master data: ', err);
      })
    }
  };
}