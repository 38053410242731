import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CopyrightInfo from '../../design-system/copyright-info';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  pageContentLayoutGrid: {
    minHeight: 'calc(100vh - 82px)',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr auto',
    gridTemplateAreas: `
      'contentLayoutMain'
      'contentLayoutFooter'
    `,
    alignItems: 'start'
  },
  pageContentLayoutMain: {
    gridArea: 'contentLayoutMain'
  },
  pageContentLayoutFooter: {
    gridArea: 'contentLayoutFooter',
    textAlign: 'center',
    alignSelf: 'end'
  },
}));

const Wrapper = ({ children, padding }) => {
  const classes = useStyles();
  return (
    <div className={classes.pageContentLayoutGrid}>
      <div className={`${padding ? classes.root : null} ${classes.pageContentLayoutMain}`}>{children}</div>
      <div className={classes.pageContentLayoutFooter}>
        <CopyrightInfo />
      </div>
    </div>
  );
};

Wrapper.prototypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.bool
};

Wrapper.defaultProps = {
  padding: true
};

export default Wrapper;
