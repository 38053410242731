import { v4 as uuidv4 } from 'uuid';
import dayjs from'dayjs';
import * as Yup from "yup";
import { useState, useEffect, useRef } from "react";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
// import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { FormGenerator } from "formik-generator-materialui";
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import useSchedulerEvents from "../../hooks/scheduler/use-scheduler-events";
import { SCHEDULE_TYPE, USER_ROLE_MASTER, SCHEDULE_ORDER_DATE_TYPE, PROCESS_CODE_BIOPSY } from '../../hooks/constants';
import useSchedulerOrders from '../../hooks/scheduler/use-scheduler-orders';
import useUserSession from '../../hooks/use-user-session';
import useSchedulerAutoAllocationConfig from '../../hooks/scheduler/use-scheduler-auto-allocation-config';
import useResourcesV2 from '../../hooks/scheduler/use-resources-v2';
import {
  pickResourcesForSlotAssignment 
} from '../../helpers-scheduler';
import useSchedulerAutoAllocationPublishStatus from '../../hooks/scheduler/use-scheduler-auto-allocation-publish-status';

const formFieldsSchema = [
  {
    title: "Customer",
    path: "customerGuid",
    typeField: "hidden",
    required: true,
    // typeField: "select",
    // choices: [
    //   { value: '5e863f6f-b069-1692-6c0f-a19579905b22', title: 'Frenova' },
    //   { value: 'e274b60f-2b0b-05b5-ff71-6ef0766b18ab', title: 'Nephrology & Hypertension' }
    // ] 
  },
  {
    title: "Subject ID",
    path: "subjectId",
    typeField: "text",
    required: true,
  },
  {
    title: "Informed Consent Date",
    path: "consentDate",
    typeField: "date",
    required: false,
    simple: true,
  },
  {
    title: 'Kidney Weight (gms)',
    typeField: 'group',
    path: '',
    subfields: [
      {
        title: 'Right Kidney',
        path: 'rightKidneyWeight',
        typeField: 'text',
        required: false,
        col: 6
      },
      {
        title: 'Left Kidney',
        path: 'leftKidneyWeight',
        typeField: 'text',
        required: false,
        col: 6
      },
    ]
  }
];

const formFieldsValidationSchema = Yup.object().shape({
  customerGuid: Yup.string(), // .required('Customer is a required field'),
  subjectId: Yup.string() // .required('Subject ID is a required field')
});

const useStyles = makeStyles((theme) => ({
  title: {
    "& h2": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  content: {
    minHeight: "400px",
    minWidth: '300px'
  },
  label: {
    fontWeight: '500'
  }
}));

function SchedulerOrerFormV2Modal({
  isVisible,
  orderId = null,
  scheduleId = null,
  selectedCalendarDate = null, // Javascript Date Object. Available when creating a new schedule. 
  onClose = null,
  onDelete = null,
  onSave = null,
}) {
  // const today = new Date();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [initialValues, setInitialValues] = useState({
    customerGuid: '',
    subjectId: '',
    consentDate: '', //selectedCalendarDate || new Date(),
    rightKidneyWeight: 137,
    leftKidneyWeight: 127
  });
  const formRef = useRef(null);
  const [biopsyDate, handleBiopsyDateChange] = useState(null);
  const [injection1Date, handleInjection1DateChange] = useState(null);
  const [injection2Date, handleInjection2DateChange] = useState(null);
  const [isOrderDataFetching, setIsOrderDataFetching] = useState(true);
  const [isOrderDatesValid, setIsOrderDatesValid] = useState(true);
  const [formErrors, setFormErrors] = useState([]);
  const [isSavingOrder, setIsSavingOrder] = useState(false);

  const { 
    dataWithDatesInMDY,
    recalculateAndPublishSlotAvailability 
  } = useSchedulerAutoAllocationPublishStatus();
  const { 
    bulkAdd, 
    deleteEventsByScheduleId, 
    prepareEventPayload, 
    fetchEventsByDateRange, 
    detectConflictAndSaveStatusForSchedule 
  } = useSchedulerEvents(SCHEDULE_TYPE.PLANNER);
  const { addOrder, deleteOrder, fetchOrder, fetchOrderBySubjectId, isValid_OrderDates, saveOrder, submitOrderToCliniTrack } = useSchedulerOrders();
  const { data: autoAllocationConfig } = useSchedulerAutoAllocationConfig();
  const { userProfile } = useUserSession();

  const {
    resources: allActiveResources,
    clients
  } = useResourcesV2();

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  useEffect(() => {    
    if (orderId) {
      fetchOrder(orderId).then((data) => {
        if (data) {
          setOrderData(data);
          setInitialValues({
            customerGuid: data.CustomerGuid,
            subjectId: data.PatientId,
            consentDate: data.DateOfInformedConsent ? new Date(data.DateOfInformedConsent) : '',
            rightKidneyWeight: data.RightKidneyWeight,
            leftKidneyWeight: data.LeftKidneyWeight
          });
          if (data.BiopsyDate) {
            handleBiopsyDateChange(new Date(data.BiopsyDate));
          }
          if (data.InjectionDate1) {
            handleInjection1DateChange(new Date(data.InjectionDate1));
          }
          if (data.InjectionDate2) {
            handleInjection2DateChange(new Date(data.InjectionDate2));
          }
          setIsOrderDataFetching(false);
        }
      });
    } else {
      setIsOrderDataFetching(false);
      handleBiopsyDateChange(selectedCalendarDate);
    }    
  }, [orderId]);

  useEffect(() => {
    if (!orderId) {
      if (userProfile && clients.length > 0) {
        // Client Code is assigned only to Site Users
        const currentUser_clientRecord = clients.find(cl => cl.code === userProfile.clientCode);
        if (!initialValues.customerGuid && currentUser_clientRecord) {
          setInitialValues(Object.assign({}, initialValues, {
            customerGuid: currentUser_clientRecord.integrations.cliniTrackId
          }));
        }
      }
    }
  }, [orderId, userProfile, clients]);

  const _generateEventPayload = (datePlainObjOrDatepickerObj, title, orderId, scheduleId, eventType, subjectId, isApproved) => {
    const startD = datePlainObjOrDatepickerObj.$d ? datePlainObjOrDatepickerObj.$d : datePlainObjOrDatepickerObj;
    const endD = dayjs(startD).add(1,'h');
    return {
      start: startD.toISOString(),
      end: endD.toISOString(),
      allDay: false,
      title: title,
      //custom fields
      scheduleId: scheduleId,
      scheduleType: SCHEDULE_TYPE.SITE,
      meta_orderId: orderId,
      meta_eventType: eventType,
      meta_isApproved: isApproved,
      meta_subjectId: subjectId,
      meta_biopsyDate: biopsyDate?.toISOString() || '',
      meta_injection1Date: injection1Date?.toISOString() || '',
      meta_injection2Date: injection2Date?.toISOString() || '',
      dayNum: 0,
      processCode: PROCESS_CODE_BIOPSY,
      clientCode: userProfile.clientCode,
      active: true,
      resources: [],
      resourceCodes: []
    }
  };

  const handleSave = async (data) => {
    const areDatesValid = isValid_OrderDates(
      biopsyDate?.$d || biopsyDate,
      injection1Date?.$d || injection1Date,
      injection2Date?.$d || injection2Date,
      !!orderId
    );
    
    const errorMsgs = [];
    if (!areDatesValid.isValid) {
      errorMsgs.push(areDatesValid.error);
    }
    if (!data.customerGuid) {
      errorMsgs.push('Customer is required');
    }
    if (!data.subjectId) {
      errorMsgs.push('Subject ID is required');
    }
    const orderDataLookupBySubjectId = data.subjectId?.trim() ? await fetchOrderBySubjectId(data.subjectId.trim()) : null;
    if ((!orderId && orderDataLookupBySubjectId) || (orderId && orderDataLookupBySubjectId?.id !== orderId)) {
      errorMsgs.push('An Order with the Subject ID already exists');
    }
    if (errorMsgs.length > 0) {
      setIsOrderDatesValid(false);
      setFormErrors(errorMsgs);
      return;
    }
    setIsOrderDatesValid(true);
    setFormErrors(errorMsgs);

    const plannerEventsAlreadySaved = orderId ? [] : await fetchEventsByDateRange(
      selectedCalendarDate, 
      dayjs(selectedCalendarDate).add(22, 'day').toDate(),
      SCHEDULE_TYPE.PLANNER
    );
    // console.log('plannerEventsAlreadySaved: ', plannerEventsAlreadySaved);

    const cliniTrackPayload = {
      CustomerGuid: data.customerGuid,
      CustomerId: '', // not used currently
      PatientId: data.subjectId,
      DateOfInformedConsent: data.consentDate ? data.consentDate.toISOString() : '',
      RightKidneyWeight: data.rightKidneyWeight,
      LeftKidneyWeight: data.leftKidneyWeight,
      TissueEstablishmentCode: '', // no longer required
      BiopsyDate: biopsyDate?.toISOString() || '',
      InjectionDate1: injection1Date?.toISOString() || '',
      InjectionDate2: injection2Date?.toISOString() || ''
    };
    
    const tempOrderId = orderId || uuidv4();
    const tempScheduleId = scheduleId || uuidv4();
    const orderPayload = Object.assign({}, cliniTrackPayload, {
      id: tempOrderId,
      scheduleId: tempScheduleId,
      isBiopsyApproved: true, // orderData?.isBiopsyApproved || false,
      isInjection1Approved: !!injection1Date,
      isInjection2Approved: !!injection2Date
    });

    setIsSavingOrder(true);
    (orderId ? saveOrder(tempOrderId, orderPayload) : addOrder(tempOrderId, orderPayload)).then(() => {
      if (!orderId) {
        const events = [];
        if (biopsyDate) {
          events.push(_generateEventPayload(biopsyDate, `Subject: ${data.subjectId} [B]`, tempOrderId, tempScheduleId, 
          SCHEDULE_ORDER_DATE_TYPE.BIOPSY, data.subjectId, true));
        }
        if (injection1Date) {
          events.push(_generateEventPayload(injection1Date, `Subject: ${data.subjectId} [I1]`, tempOrderId, tempScheduleId, 
          SCHEDULE_ORDER_DATE_TYPE.INJECTION_1, data.subjectId, true));
        }
        if (injection2Date) {
          events.push(_generateEventPayload(injection2Date, `Subject: ${data.subjectId} [I2]`, tempOrderId, tempScheduleId, 
          SCHEDULE_ORDER_DATE_TYPE.INJECTION_2, data.subjectId, true));
        }
        
        const tempDate = biopsyDate.$d ? biopsyDate : dayjs(biopsyDate);
        // Note: Generating 21 Planner Events for the 21-day schedule
        const plannerEvents = [];
        const processCode = PROCESS_CODE_BIOPSY, 
        clientCode = userProfile.clientCode,
        additionalData = {
          dayNum: 0,
          allDay: false,
          orderId: tempOrderId,
          subjectId: data.subjectId,
          eventType: SCHEDULE_ORDER_DATE_TYPE.BIOPSY
        };
        for(let i = 0;i < 21;i++) {
          const duration = +autoAllocationConfig.eventDuration[i].duration;
          const additionalDataCopy = Object.assign({}, additionalData, {
            dayNum: i+1,
            allDay: (duration === 0)
          });
          const autoConfigStartTime = additionalDataCopy.allDay ? null : dayjs(autoAllocationConfig.eventStartTime[i].time);
          const currentDateObject = additionalDataCopy.allDay ? tempDate.clone().hour(0).minute(0).second(1).add(i, 'day') : 
            tempDate.clone().add(i, 'day').hour(autoConfigStartTime.hour()).minute(autoConfigStartTime.minute()).second(autoConfigStartTime.second());
          const assignedResources = pickResourcesForSlotAssignment(
            i, 
            currentDateObject,
            allActiveResources, 
            autoAllocationConfig, 
            plannerEventsAlreadySaved,
            additionalDataCopy.allDay
          );
          plannerEvents.push(
            prepareEventPayload(
              SCHEDULE_TYPE.PLANNER, 
              tempScheduleId, 
              currentDateObject,
              additionalDataCopy.allDay ? currentDateObject.clone().hour(23).minute(59).second(59) : currentDateObject.clone().add(duration, 'minute'),
              processCode, 
              clientCode, 
              assignedResources, 
              additionalDataCopy
            )
          );
        }
        
        // console.log('plannerEvents: ', plannerEvents);

        return bulkAdd([].concat(events, plannerEvents)).then(() => {
          const existingAndNewPlannerEvents = [].concat(plannerEvents, plannerEventsAlreadySaved);
          return recalculateAndPublishSlotAvailability(existingAndNewPlannerEvents);
        }).then(() => {
          return detectConflictAndSaveStatusForSchedule(tempScheduleId);
        });
      } else {
        // TODO: Re-create all planner events
        return deleteEventsByScheduleId(scheduleId).then(() => {
          const events = [];
          if (biopsyDate) {
            events.push(
              _generateEventPayload(
                biopsyDate, 
                `Subject: ${data.subjectId} [B]`, tempOrderId, tempScheduleId, 
                SCHEDULE_ORDER_DATE_TYPE.BIOPSY, data.subjectId, true));
          }
          if (injection1Date) {
            events.push(
              _generateEventPayload(
                injection1Date, 
                `Subject: ${data.subjectId} [I1]`, tempOrderId, tempScheduleId, 
                SCHEDULE_ORDER_DATE_TYPE.INJECTION_1, data.subjectId, true));
          }
          if (injection2Date) {
            events.push(
              _generateEventPayload(
                injection2Date, 
                `Subject: ${data.subjectId} [I2]`, tempOrderId, tempScheduleId, 
                SCHEDULE_ORDER_DATE_TYPE.INJECTION_2, data.subjectId, true));
          }
          return bulkAdd(events);
        });
      }
    }).then((bulkAddResponse) => {
      if (!orderId) {
        // Submit the order to CliniTrack only once.
        // TODO: Uncomment this after ensuring the submission to CliniTrack works
        // console.log('cliniTrackPayload: ', cliniTrackPayload);
        // Injection1 & INjection 2 dates are mandatory
        return submitOrderToCliniTrack(cliniTrackPayload);
      }
    }).finally(() => {
      formRef.current.resetForm();
      setIsSavingOrder(false);
      onSave?.();
    });
  };

  const handleClose = () => { 
    formRef.current.resetForm();
    onClose?.();
  };

  const handleDelete = () => {
    if (orderId || scheduleId) {
      setIsSavingOrder(true);
      let promise;
      if (orderData?.scheduleId) {
        promise = deleteEventsByScheduleId(orderData.scheduleId).then(() => {
          return deleteOrder(orderId);
        }).then(() => {
          formRef.current.resetForm();
          onDelete?.();
        });
      } else if (scheduleId) {
        promise = deleteEventsByScheduleId(scheduleId).then(() => {
          // Note: No need to reset form because form wasn't rendered.
          onDelete?.();
        });
      }
      promise.then(async () => {
        return recalculateAndPublishSlotAvailability();
      }).then(() => {
        setIsSavingOrder(false);
      });
    }
  };

  // const handleApproveDate = (orderId, payload, type) => {
  //   saveOrder(orderId, payload).then(() => {
  //     deleteEventsByScheduleId(scheduleId).then(() => {
  //       const events = [];
  //       if (biopsyDate) {
  //         events.push(
  //           _generateEventPayload(
  //             biopsyDate, 
  //             `Subject: ${orderData.PatientId} [B]`, 
  //             orderId, scheduleId, SCHEDULE_ORDER_DATE_TYPE.BIOPSY, 
  //             orderData.PatientId, 
  //             type === SCHEDULE_ORDER_DATE_TYPE.BIOPSY ? payload.isBiopsyApproved : orderData?.isBiopsyApproved));
  //       }
  //       if (injection1Date) {
  //         events.push(
  //           _generateEventPayload(
  //             injection1Date, 
  //             `Subject: ${orderData.PatientId} [I1]`, orderId, scheduleId, SCHEDULE_ORDER_DATE_TYPE.INJECTION_1, orderData.PatientId, 
  //             type === SCHEDULE_ORDER_DATE_TYPE.INJECTION_1 ? payload.isInjection1Approved : orderData?.isInjection1Approved));
  //       }
  //       if (injection2Date) {
  //         events.push(
  //           _generateEventPayload(
  //             injection2Date, 
  //             `Subject: ${orderData.PatientId} [I2]`, orderId, scheduleId, SCHEDULE_ORDER_DATE_TYPE.INJECTION_2, orderData.PatientId, 
  //             type === SCHEDULE_ORDER_DATE_TYPE.INJECTION_2 ? payload.isInjection2Approved : orderData?.isInjection2Approved));
  //       }
  //       return bulkAdd(events);
  //     }).then(() => {
  //       setOrderData(Object.assign({}, orderData, payload));
  //       // onSave?.();
  //     });
  //   });
  // }

  // Order & Schedule Events can be delete as long as it is not approved by the Lab User
  const canDeleteOrder = (userProfile.role === USER_ROLE_MASTER.SITE_USER && 
    (orderId || scheduleId) && !(orderData?.isBiopsyApproved || orderData?.isInjection1Approved || orderData?.isInjection2Approved)) || 
    userProfile.role === USER_ROLE_MASTER.ADMIN;
  const isFormReadOnly = userProfile.role !== USER_ROLE_MASTER.SITE_USER || (
    userProfile.role === USER_ROLE_MASTER.SITE_USER && orderId && (
      orderData?.isBiopsyApproved || orderData?.isInjection1Approved || orderData?.isInjection2Approved
    )
  );
  const isSiteUser = userProfile.role === USER_ROLE_MASTER.SITE_USER; 
  const isLabUser = userProfile.role === USER_ROLE_MASTER.LAB_USER;

  const disableBookedBiopsyDates = (date) => {
    return !(dataWithDatesInMDY.slots[dayjs(date).format('MMDDYYYY')] > 0);
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.title}>
        {orderId ? 'Edit' : 'New'} Patient Schedule
      </DialogTitle>
      <DialogContent className={classes.content}>
        {orderId && !orderData && !isOrderDataFetching && <div>Oops! Order Data not found.</div>}
        {isOrderDataFetching && <CircularProgress color="secondary" />}
        <Box mb={1}>
          {!isOrderDatesValid && 
            <Alert variant="outlined" color="error">
              {formErrors.map((error, idx) => (<div key={`error-msg-${idx}`}>{error}</div>))}
            </Alert>
          }
        </Box>

        {((orderId && orderData) || !orderId) && <>
          <FormGenerator
            onSubmit={(values) => {
              handleSave(values);
            }}
            fields={formFieldsSchema}
            formRef={formRef}
            initialValues={initialValues}
            validationSchema={formFieldsValidationSchema}
            readOnly={isFormReadOnly}
            isValidateOnlyOnSubmit={true}
          />

          <Box mt={1} display="flex" justifyContent="space-between">
            <KeyboardDateTimePicker 
              label="Biopsy Date *" value={biopsyDate} onChange={handleBiopsyDateChange}
              minDate={orderId ? orderData?.biopsyDate : selectedCalendarDate}
              format="MM/DD/YYYY HH:mm A" 
              disabled={!isSiteUser || orderData?.isBiopsyApproved}
              shouldDisableDate={disableBookedBiopsyDates}
            />
              
            {/* {orderId && isLabUser && <Typography>
              <label>
                <Switch checked={orderData.isBiopsyApproved} onChange={(evt) => {
                  handleApproveDate(orderId, {isBiopsyApproved: evt.target.checked}, SCHEDULE_ORDER_DATE_TYPE.BIOPSY);
                }} /> Approve
              </label>
            </Typography>}
            {orderId && orderData.isBiopsyApproved && !isLabUser && <Typography>Approved</Typography>} */}
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <KeyboardDateTimePicker 
              label="Injection 1 Date" value={injection1Date} onChange={handleInjection1DateChange} 
              minDate={biopsyDate || selectedCalendarDate}
              format="MM/DD/YYYY HH:mm A" 
              disabled={!isSiteUser || orderData?.isInjection1Approved} />
            {/* {orderId && isLabUser && <Typography>
              <label>
                <Switch checked={orderData.isInjection1Approved} onChange={(evt) => {
                  handleApproveDate(orderId, {isInjection1Approved: evt.target.checked}, SCHEDULE_ORDER_DATE_TYPE.INJECTION_1);
                }} /> Approve
              </label>
            </Typography>}
            {orderId && orderData.isInjection1Approved && !isLabUser && <Typography>Approved</Typography>} */}
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <KeyboardDateTimePicker 
              label="Injection 2 Date" value={injection2Date} onChange={handleInjection2DateChange} 
              minDate={injection1Date || selectedCalendarDate}
              format="MM/DD/YYYY HH:mm A" 
              disabled={!isSiteUser || orderData?.isInjection2Approved} />
            {/* {orderId && isLabUser && <Typography>
              <label>
                <Switch checked={orderData.isInjection2Approved} onChange={(evt) => {
                  handleApproveDate(orderId, {isInjection2Approved: evt.target.checked}, SCHEDULE_ORDER_DATE_TYPE.INJECTION_2);
                }} /> Approve
              </label>
            </Typography>}
            {orderId && orderData.isInjection2Approved && !isLabUser && <Typography>Approved</Typography>} */}
          </Box>
        </>}
      </DialogContent>
      <DialogActions disableSpacing>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            {canDeleteOrder && <Button onClick={handleDelete} variant="outlined" color="secondary" disabled={isSavingOrder}>
              Delete Schedule
            </Button>}
          </Box>
          <Box display="flex" alignItems="center">
            <Button onClick={handleClose}>
              Close
            </Button>
            <Button
              disabled={(isSiteUser && orderData?.isBiopsyApproved && orderData?.isInjection1Approved && orderData?.isInjection2Approved) || isLabUser || isSavingOrder}
              onClick={() => {
                formRef.current.submitForm();
              }}
              variant="contained"
              color="primary"
            >
              {isSavingOrder ? 'Saving' : 'Save'}
            </Button>
            {isSavingOrder && <CircularProgress size={30} color="secondary" />}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default SchedulerOrerFormV2Modal;
