import {
  data,
  dataBubble,
  dataMixed,
  gridOptions,
  height,
  options,
  sharedOptions,
  stackedGridOptions,
  weeklyCapacityData,
  weeklyCapacityDataForAMonth,
  weeklyUnavailabilityDataForAMonth
} from './chart';

export default [
  {
    type: 'bar',
    title: 'Orders This Week',
    subtitle: '+458,90',
    data: data,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...stackedGridOptions
    }
  },
  {
    type: 'bar',
    title: '# of Rejects',
    subtitle: '-46,68',
    data: dataMixed,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...options
    }
  },
  {
    type: 'bubble',
    title: 'Packaging Ratio',
    subtitle: '+2,50%',
    data: dataBubble,
    height: height,
    options: {
      ...sharedOptions
    }
  }
];

export const iotMockData = [
  {
    type: 'bar',
    title: 'TEMPERATURE',
    subtitle: '+458,90',
    data: data,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...stackedGridOptions
    }
  },
  {
    type: 'bar',
    title: 'LIGHT',
    subtitle: '-46,68',
    data: dataMixed,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...options
    }
  },
  {
    type: 'bubble',
    title: 'CO',
    subtitle: '+2,50%',
    data: dataBubble,
    height: height,
    options: {
      ...sharedOptions
    }
  },
  {
    type: 'bar',
    title: 'TEMPERATURE',
    subtitle: '+458,90',
    data: data,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...stackedGridOptions
    }
  },
];

export const resourceCapacityMockData = [
  {
    type: 'bar',
    title: 'THIS WEEK - CAPACITY',
    subtitle: '+458,90',
    data: weeklyCapacityData,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...stackedGridOptions
    }
  },
  {
    type: 'bar',
    title: 'THIS MONTH - CAPACITY',
    subtitle: '+458,90',
    data: weeklyCapacityDataForAMonth,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...stackedGridOptions
    }
  },
  {
    type: 'bar',
    title: 'THIS WEEK - UNAVAILABILITY',
    subtitle: '+458,90',
    data: weeklyCapacityData,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...stackedGridOptions
    }
  },
  {
    type: 'bar',
    title: 'THIS MONTH - UNAVAILABILITY',
    subtitle: '+458,90',
    data: weeklyUnavailabilityDataForAMonth,
    height: height,
    options: {
      ...sharedOptions,
      ...gridOptions,
      ...stackedGridOptions
    }
  },
];
