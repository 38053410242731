import { useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from 'dayjs';
import ReactQuill from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import ActivityStream from "../../../design-system/ActivityStream";
import useBatchFormNotes from "../../../hooks/use-batch-form-notes";
import useUsersSearch from '../../../hooks/use-users-search';
import useBatchFormSchema from '../../../hooks/use-batch-form-schema';
import './styles.css';

const useStyles = makeStyles(theme => ({
  messageInput: {
    border: '1px solid #efefef'
  }
}));

function BatchFormNotes({ orderId, lotId, formName, onNoteAdded }) {
  const { searchFields } = useBatchFormSchema(formName);
  const { searchUsers } = useUsersSearch();
  
  const editorRef = useRef(null);
  const classes = useStyles();
  const [message] = useState("");
  const { batchFormNotes, saveNote } = useBatchFormNotes(
    orderId,
    lotId,
    formName
  );

  const transformedFormNotes = (batchFormNotes || []).map((row) => {
    return {
      title: row.email,
      subtitle: dayjs.unix(row.createdOn.seconds).format('MMM DD, YYYY'),
      avatar: <Avatar style={{ backgroundColor: '#3f51b5' }}>{row.email[0]}</Avatar>,
      body: row.html,
    };      
  });
  
  return (
    <>
      <Box className={classes.messageInput} p={1}>
        <ReactQuill
          value={message}
          placeholder={"Type your note.."}
          ref={editorRef}
          modules={{
            mention: {
              allowedChars: /^[A-Za-z\s]*$/,
              mentionDenotationChars: ["@", "#"],
              source: async (searchTerm, renderList, mentionChar) => {
                let values;
                let results = [];

                if (mentionChar === "@") {
                  results = await searchUsers(searchTerm);
                } else {
                  results = searchFields();
                }

                if (searchTerm.length === 0) {
                  renderList(values, searchTerm);
                } else {
                  renderList(results, searchTerm);
                }
              }
            }
          }}
        />
        <Box textAlign="right">
          <Button>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => {
            const editor = editorRef.current.getEditor();

            const messageHtml = editorRef.current.getEditorContents();
            const messageTokens = editor.getContents()['ops'];
            saveNote(messageHtml, messageTokens).then(() => {
              onNoteAdded && onNoteAdded();
            });
          }}>
            Save
          </Button>
        </Box>
      </Box>

      <ActivityStream title="" stream={transformedFormNotes} />
    </>
  );
}

export default BatchFormNotes;
