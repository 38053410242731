
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PauseIcon from "@material-ui/icons/Pause";
import StarIcon from "@material-ui/icons/StarBorderOutlined";
import SaveIcon from "@material-ui/icons/Save";
import WokflowBoard from "../../components/batch-workflow-builder/workflow-board";
import PageTitle from '../../design-system/page-title';
import Breadcrumb from '../../design-system/breadcrumb';
import SimpleSnackbar from "../../design-system/simple-snackbar";

const useStyles = makeStyles(theme => ({
  customIconButton: {
    minWidth: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

export default function BatchWorkflowConfigurationPage() {
  const classes = useStyles();

  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  return (
    <Box>
      <Breadcrumb items={[
        { text: 'Home', path: '/' },
        { text: 'Configure Batches', path: '/manage/batches' }
      ]} />
      <PageTitle title="Workflow Configurator">
        <Button size="small" className={classes.customIconButton}>
          <PauseIcon />
        </Button>
        <Button size="small" className={classes.customIconButton}>
          <StarIcon />
        </Button>
        <Button size="small" className={classes.customIconButton}>
          <SaveIcon /> <span style={{ fontSize: '0.6rem' }}>Auto saved (2 mins)</span>
        </Button>
      </PageTitle>
      <WokflowBoard 
        onSave={() => {
          setIsSnackbarVisible(true);
          setSnackbarMessage('Saved workflow configuration!');
        }} 
      />
      <SimpleSnackbar
        isVisible={isSnackbarVisible}
        message={snackbarMessage}
        onClose={() => {
            setIsSnackbarVisible(false);
        }}
      />
    </Box>
  );
}