import { useRef } from "react";
import dayjs from 'dayjs';
import { FormGenerator } from "formik-generator-materialui";
import Button from "@material-ui/core/Button";
import useBatchFormsQaAccessComments from "../../../hooks/use-batch-form-qa-access-comments";

const formFieldsSchema = [
  {
    title: "",
    typeField: "arrayObject",
    path: "inspectionComments",
    emptyAddText: "Add Comment",
    subfields: [
      {
        title: "Date",
        typeField: "date",
        name: "date",
        simple: true,
        col: 3,
      },
      {
        title: "Detail",
        typeField: "text",
        name: "detail",
        col: 9,
      },
    ],
  },
];

const prepareDataToEdit = (values) => {
  const data = Object.assign({}, values);
  if (data['inspectionComments']) {
    const data_inspectionComments = [].concat(data['inspectionComments']);
    data_inspectionComments.forEach((row) => {
      if (row.date && row.date.seconds && row.date !== 'Invalid date') {
        row['date'] = dayjs.unix(row['date'].seconds).toDate();
      }
    });
  }
  return data;
};

function QAAccessForm({
  orderId,
  lotId,
  formName,
  onSave = null,
  onCancel = null,
  isReadOnly = false,
  isValidateOnlyOnSubmit = true,
}) {
  const { formData, save, error } = useBatchFormsQaAccessComments(
    orderId,
    lotId,
    formName
  );
  let initialValues = {
    inspectionComments: [
      {
        date: "",
        text: "",
      },
    ],
  };
  if (formData) {
    initialValues = prepareDataToEdit(formData);
  }

  const saveFormData = (modifiedFormData) => {
    save(modifiedFormData).then(() => {
      onSave && onSave();
    });
  };

  const formRef = useRef(null);
  return (
    <>
      <FormGenerator
        onSubmit={(values) => {
          saveFormData(values);
        }}
        fields={formFieldsSchema}
        formRef={formRef}
        initialValues={initialValues}
        readOnly={isReadOnly}
        isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
      />
      <Button
        color="secondary"
        style={{ marginRight: "2em" }}
        onClick={() => {
          formRef.current.resetForm();
          onCancel && onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => formRef.current.submitForm()}
      >
        Save
      </Button>
    </>
  );
}

export default QAAccessForm;
