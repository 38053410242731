import { useEffect, useState } from 'react';
import { NoteStatus } from './constants';
import useUserSession from './use-user-session';
import { firestoreInstance } from '../config/firebase-init';

export default function useStreamNotes(status = NoteStatus.pending) {
    const session = useUserSession();
    const streamNotesRef = firestoreInstance
        .collection('streamNotes')
        // .where('status', '==', status)
        .where('tagsPeopleEmails', 'array-contains', session.user.email);

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribeStreamNotesRef = streamNotesRef.orderBy('createdOn', 'desc').onSnapshot((res) => {
            if (!res.empty) {
                const dataRecords = res.docs.map((doc) => {
                    return doc.data()
                });
                setData(dataRecords);
            } else {
                setData([]);
            }
            setError(null);
        }, (err) => { setError(err); });
        
        return () => {
            unsubscribeStreamNotesRef();
        };
    }, []);

    return {
        notes: data ? data.filter((row) => row.status === status) : [],
        error,
    }
}