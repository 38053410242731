import { useRef } from "react";
import dayjs from 'dayjs';
import { FormGenerator } from "formik-generator-materialui";
import Button from "@material-ui/core/Button";
import useBatchFormData from "../../hooks/use-batch-form-data";
import { BATCH_FORM_NAMES as FORM_NAMES } from "../../hooks/constants";

const formFieldsSchema = [
  {
    title:
      "Total number of Blisters rejected from page(s) 6. If tablets are reclaimed, list only loose tablets and damaged blisters that are unable to be reclaimed.",
    typeField: "arrayObject",
    path: "rejectCalculation",
    emptyAddText: "Add Row",
    subfields: [
      {
        title: "Date",
        typeField: "date",
        name: "date",
        simple: true,
        col: 3,
      },
      {
        title: "Blisters Rejected",
        typeField: "text",
        name: "blistersRejected",
        col: 4,
      },
    ],
  },
];

const prepareDataToEdit = (values) => {
  const data = Object.assign({}, values);
  // console.log(data);
  // if (data.rejectCalculation) {
  //   data.rejectCalculation.forEach((row) => {
  //     if (row.date) {
  //       row.date = dayjs.unix(row.date.seconds).toDate();
  //     }
  //   })
  // }
  return data;
};

function RejectsForm({
  orderId,
  lotId,
  onSave = null,
  onCancel = null,
  isReadOnly = false,
  isValidateOnlyOnSubmit = true,
}) {
  const { batchFormData, saveBatchForm, error } = useBatchFormData(
    orderId,
    lotId,
    FORM_NAMES.rejects
  );
  let initialValues = {
    rejectCalculation: [
      {
        date: "",
        blistersRejected: "",
      },
    ],
  };
  if (batchFormData) {
    initialValues = prepareDataToEdit(batchFormData);
  }

  const saveFormData = (formData) => {
    saveBatchForm(formData).then(() => {
      onSave && onSave();
    });
  };

  const formRef = useRef(null);
  return (
    <>
      <FormGenerator
        onSubmit={(values) => {
          saveFormData(values);
        }}
        fields={formFieldsSchema}
        formRef={formRef}
        initialValues={initialValues}
        readOnly={isReadOnly}
        isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
      />
      <Button
        color="secondary"
        style={{ marginRight: "2em" }}
        onClick={() => {
          formRef.current.resetForm();
          onCancel && onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => formRef.current.submitForm()}
      >
        Save
      </Button>
    </>
  );
}

export default RejectsForm;
