import { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { firestoreInstance } from '../../config/firebase-init';
import { RESOURCE_TYPE } from '../constants';

const useResourcesV2 = (activeAndInactive, resourceType) => {
  let resourcesRef = null;
  if (activeAndInactive) {
    resourcesRef = firestoreInstance.collection('schedulerResources');
  } else {
    resourcesRef = firestoreInstance.collection('schedulerResources').where('active', '==', true);
  }
  
  // const [resourcesByCodeMap, setResourcesByCodeMap] = useState({});
  const [initialState, setInitialState] = useImmer({
    resourcesByCodeMap: {},
    resources: [],
    cleanRooms: [],
    equipment: [],
    people: [],
    processes: [],
    clients: []
  });
  const [error, setError] = useState(null);
 
  useEffect(() => {
    const unsubscribeResourcesRef = resourcesRef.onSnapshot((res) => {
      const tempMap = {}, records = [], cleanRooms = [], equipment = [], people = [], processes = [], clients = [];
      if (!res.empty) {
        res.docs.forEach((doc) => {
          const rec = {
            NO_ID_FIELD: doc.id,
            ...doc.data(),
          };
          tempMap[rec.code] = rec;
          records.push(rec);
          if (rec.type === RESOURCE_TYPE.cleanRoom) {
            cleanRooms.push(rec);
          } else if (rec.type === RESOURCE_TYPE.equipment) {
            equipment.push(rec);
          } else if (rec.type === RESOURCE_TYPE.person) {
            people.push(rec);
          } else if (rec.type === RESOURCE_TYPE.process) {
            processes.push(rec);
          } else if (rec.type === RESOURCE_TYPE.client) {
            clients.push(rec);
          }
        });       
      }
      // setResourcesByCodeMap(tempMap);
      setInitialState(draft => {
        draft.resourcesByCodeMap = tempMap;
        draft.resources = records;
        draft.cleanRooms = cleanRooms;
        draft.equipment = equipment;
        draft.people = people;
        draft.processes = processes;
        draft.clients = clients;
      });
      setError(null);
    }, (err) => { setError(err); });

    return () => {
      unsubscribeResourcesRef();
    };
  }, [resourceType]);

  return {
    resources: initialState.resources,
    cleanRooms: initialState.cleanRooms,
    equipment: initialState.equipment,
    people: initialState.people,
    processes: initialState.processes,
    clients: initialState.clients,
    fetchResource: (resourceCode) => {
      return initialState.resourcesByCodeMap[resourceCode];
    }
  };
}

export default useResourcesV2;