import { useRef } from 'react';
import dayjs from 'dayjs';
import { FormGenerator } from "formik-generator-materialui";
import Button from '@material-ui/core/Button';
import useBatchFormData from '../../hooks/use-batch-form-data';
import { BATCH_FORM_NAMES as FORM_NAMES } from '../../hooks/constants';

const formFieldsSchema = [
    {
        title: '',
        typeField: 'arrayObject',
        path: "inspectionComments",
        emptyAddText: 'Add Comment',
        subfields: [
            {
                title: 'Date',
                typeField: 'date',
                name: 'date',
                simple: true,
                col: 3
            },
            {
                title: 'Detail',
                typeField: 'text',
                name: 'detail',
                col: 9
            }
        ]
    }
];

const prepareDataToEdit = (values) => {
    const data = Object.assign({}, values);
    if (data['inspectionComments']) {
        const data_inspectionComments = [].concat(data['inspectionComments']);
        data_inspectionComments.forEach((row) => {
            // console.log('date: ', row.date);
            if (row.date && row.date.seconds && row.date !== 'Invalid Date') {
                row.date = dayjs.unix(row.date.seconds).toDate();
            }
        });
        data['inspectionComments'] = data_inspectionComments;
    }
    return data;
}

function InspectionCommentsForm({
    orderId,
    lotId,
    onSave = null,
    onCancel = null,
    isReadOnly = false,
    isValidateOnlyOnSubmit = true,
    isPreview = false
}) {
    const { batchFormData, saveBatchForm, error } = isPreview ? { batchFormData: null, saveBatchForm: null, error: null } : useBatchFormData(orderId, lotId, FORM_NAMES.inspectionComments);
    let initialValues = {};
    if (batchFormData) {
        initialValues = prepareDataToEdit(batchFormData);
    }

    const saveFormData = (formData) => {
        saveBatchForm(formData).then(() => {
            onSave && onSave();
        });
    };

    const formRef = useRef(null);
    return (
        <>
            <FormGenerator
              onSubmit={(values) => {
                saveFormData(values);
              }}
              fields={formFieldsSchema}
              formRef={formRef}
              initialValues={initialValues}
              readOnly={isReadOnly}
              isValidateOnlyOnSubmit={isValidateOnlyOnSubmit}
            />
            {!isPreview && <>
                <Button color="secondary" style={{ marginRight: '2em' }}
                    onClick={() => {
                        formRef.current.resetForm();
                        onCancel && onCancel();
                    }}>Cancel</Button>
                <Button variant="contained" color="primary"
                    onClick={() => formRef.current.submitForm()}>Save</Button>
            </>}
        </>
    );
}

export default InspectionCommentsForm;