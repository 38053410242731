import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(0.2),
    fontSize: '12px',
    textTransform: 'uppercase',
    '& p': {
      fontSize: '12px',
      textTransform: 'uppercase',
    }
  }
}));

export default function Breadcrumb({ items }) {
  const classes = useStyles();

  return (
    <Breadcrumbs className={classes.root}>
      {items && items.map((item) => (
          <Typography key={`breadcrumb-${item.path}`}>
            <Link to={item.path}>{item.text}</Link>
          </Typography>
      ))}
    </Breadcrumbs>
  )
}