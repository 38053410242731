import { firestoreInstance } from '../config/firebase-init';

export default function useUsersSearch(searchTerm) {
    const usersRef = firestoreInstance.collection('users');

    return {
        searchUsers: (searchTerm) => {
            if (searchTerm) {
                return usersRef.where('email', '>=', searchTerm).get().then((res) => {
                    if (res.docs.length > 0) {
                        return res.docs.map((row) => {
                            const rowData = row.data();
                            return {
                                email: rowData.email,
                                name: rowData.name,
                                id: rowData.email,
                                value: `${rowData.name} [${rowData.email}]`
                            };
                        });
                    }
                    return [];
                }).catch(error => {
                    console.error(error);
                    return [];
                });
            }
            return Promise.resolve([]);
        },
        searchUsersByRole: (roles) => {
            if (roles) {
                return usersRef.where('role', 'in', roles).get().then((res) => {
                    if (res.docs.length > 0) {
                        return res.docs.map((row) => {
                            const rowData = row.data();
                            return {
                                email: rowData.email,
                                name: rowData.name,
                                id: row.id,
                            };
                        });
                    }
                    return [];
                }).catch(error => {
                    console.error(error);
                    return [];
                });
            }
            return Promise.resolve([]);
        }
    }
}