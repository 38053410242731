import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import PageTitle from "../../design-system/page-title";
import useBatchMasterList from '../../hooks/use-batch-master-list';
import useFormMasterList from "../../hooks/use-form-master-list";
import Breadcrumb from '../../design-system/breadcrumb';
import WorkflowFormsModal from '../../components/batch-workflow-builder/workflow-forms-modal';
import Wrapper from "../../components/Wrapper/Wrapper";

const useStyles = makeStyles(theme => ({
  root: {
    "& thead": {
      backgroundColor: '#E6EFFC',
      "& th": {
        // fontWeight: "bold",
      },
    },
  },
  formNames: {
    display: "flex",
    flexWrap: "wrap",
    '& > div': {
      width: '33%',
      fontSize: '0.9em',
    }
  },
  floatingButton: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(2.5)
  }
}));

function ManageBatches() {
  const history = useHistory();
  const classes = useStyles();
  const [isWorkflowFormsModalOpen, setIsWorkflowFormsModalOpen] = useState(false);

  const { formsMasterList } = useFormMasterList();
  const { batchesMasterList, batchesMasterAvailableFormSlugs, createNewRecord } = useBatchMasterList();

  return (
    <Wrapper>
      <Breadcrumb items={[{ text: 'Home', path: '/' }]} />
      <PageTitle title="Configure Batches" />
      <TableContainer component={Paper} className={classes.root}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Forms</TableCell>
              <TableCell>Action(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {batchesMasterList && batchesMasterList.map((batchMasterRec) => (
              <TableRow key={batchMasterRec.batchSlug}>
                <TableCell>{batchMasterRec.batchName}</TableCell>
                <TableCell>
                  <div className={classes.formNames}>

                    {formsMasterList && formsMasterList.map((form) => (
                      <div key={`${batchMasterRec.batchSlug}--${form.slug}`}>
                        <Checkbox 
                          disabled
                          checked={batchesMasterAvailableFormSlugs[batchMasterRec.batchSlug]?.availableFormSlugs.indexOf(form.slug) !== -1} 
                        />
                        {form.name}
                      </div>
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      history.push(`/manage/batch-workflow/${batchMasterRec.NO_ID_FIELD}`)
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <Fab color="primary" aria-label="configure new batch" className={classes.floatingButton} onClick={() => {
        setIsWorkflowFormsModalOpen(true);
      }}>
        <AddIcon />
      </Fab>

      {isWorkflowFormsModalOpen && <WorkflowFormsModal 
        modalTitle="New Batch Record"
        isCaptureBatchName={true}
        isVisible={isWorkflowFormsModalOpen}
        formsMaster={formsMasterList}
        batchWorkflowFormSlugs={[]}
        onSave={(selectedForms, batchName, batchSlug) => {
          createNewRecord(batchName, batchSlug, selectedForms).then((docId) => {
            setIsWorkflowFormsModalOpen(false);
            history.push(`/manage/batch-workflow/${docId}`)
          });
        }}
        onClose={() => {
          setIsWorkflowFormsModalOpen(false);
        }} 
      />}
    </Wrapper>
  );
}

export default ManageBatches;
