import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

const PlannerGridBreadcrumb = ({ items, onClick, onDelete }) => {
  return (
    <Box mt={1} mb={1} display="flex" justifyContent="space-between" alignItems="center">
      <Chip label={items[0].scheduleId} />
      <ButtonGroup size="small" variant="contained" color="secondary">
        {items.map((item, idx) => {
          if (item) {
            return (
              <Button key={`breadcrumb-${item.code}`} onClick={() => {
                onClick?.(item);
              }}>{item.label} {idx === 0 ? '- Resources ' : ''}</Button>
            );
          }
          return '';
        })}
      </ButtonGroup>

      {/* <ButtonGroup size="small" variant="outlined" color="secondary">
        {items.filter((i) => (i?.type === RESOURCE_TYPE.schedule)).map((item) => {
          if (item) {
            return (
              <Button key={`delete-${item.code}`} onClick={() => {
                onDelete?.(item);
              }}>Delete {item.label} Schedule</Button>
            );
          }
          return '';
        })}
      </ButtonGroup> */}
    </Box>
  );
}

export default PlannerGridBreadcrumb;