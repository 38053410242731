import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

let _auth = null, _analytics = null, _firestore = null;
if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // if (typeof navigator !== `undefined`) {
  //   _analytics = firebase.analytics();
  // }
  _auth = firebase.auth();
  _firestore = firebase.firestore();
  // firebase.firestore.setLogLevel('debug');
  // _firestore.settings({ experimentalForceLongPolling: true });
  // _authGoogle = new firebase.auth.GoogleAuthProvider();

  // _auth.onIdTokenChanged(async (user) => {
  //   if (user) {
  //     // TODO: (Optional) Update User object stored in browser.
  //     // User is signed in or token was refreshed.
  //     const token = await user.getIdToken();
  //     saveToken(token);
  //   }
  // });
}

export const firebaseInstance = firebase;
export const firebaseAuth = _auth;
export const firebaseAnalytics = _analytics;
export const firestoreInstance = _firestore;
