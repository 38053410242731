import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { DatePicker } from '@material-ui/pickers';
import { Badge } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& .MuiBadge-anchorOriginTopRightRectangle':{
      top:'7px',
      right:'11px',
    }
  },
  statsBox: {},
}));

const dayWithDowntimeStyles = {
  backgroundColor: 'rgba(245, 0, 87, 0.3)'
};

function ResourceDowntimeForm({ initialValues, onChange, click, resourseSchedule=null }) {
  const classes = useStyles();
  const [selectedDowntime, setSelectedDowntime] = useState([]);
  const [selectedDowntimeMDY, setSelectedDowntimeMDY] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (initialValues) {
      setSelectedDowntime(initialValues);
      setSelectedDowntimeMDY(initialValues.map(v => dayjs(v).format('MMDDYYYY')));
    }
  }, [initialValues]);

  // (day: DateIOType, selectedDate: DateIOType, dayInCurrentMonth: boolean, dayComponent: Element) => Element
  function DayRendererWithDowntimeIndicator(day, selectedDate, dayInCurrentMonth, dayComponent) {
    return (
      <Badge color="secondary" variant="dot" invisible={resourseSchedule.indexOf(day.format('YYYYMMDD')) == -1}>
      <Box style={selectedDowntimeMDY.indexOf(day.format('MMDDYYYY')) !== -1 ? dayWithDowntimeStyles : {}}>
        {dayComponent}
      </Box>
     </Badge>
    );
  }

  const onConfirm = (dObj) => {
    dObj.hour(0).minute(0).second(0)
    const dStr = dObj.toISOString();
    const dStrMDY = dObj.format('MMDDYYYY');
    const deselectDowntimeIndex = selectedDowntimeMDY.indexOf(dStrMDY);
    if (deselectDowntimeIndex === -1) {
      const newDowntime = [].concat(selectedDowntime, [dStr]);
      setSelectedDowntime(newDowntime);

      const newDowntimeMDY = [].concat(selectedDowntimeMDY, [dStrMDY]);
      setSelectedDowntimeMDY(newDowntimeMDY);

      onChange?.(newDowntime);
    } else {
      // Remove the previously selected downtime
      const newDowntime = selectedDowntime.filter((dt, idx) => (idx !== deselectDowntimeIndex));
      setSelectedDowntime(newDowntime);

      const newDowntimeMDY = selectedDowntimeMDY.filter((dtMDY, idx) => (idx !== deselectDowntimeIndex));
      setSelectedDowntimeMDY(newDowntimeMDY);

      onChange?.(newDowntime);
    }
    handleClose()
  }

  const handleChange = (dObj) => {
    setSelectedDate(dObj);
    if(resourseSchedule.indexOf(dObj.format('YYYYMMDD')) !== -1) {
      setOpen(true);
    }
    else {
      onConfirm(dObj)
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    onConfirm(selectedDate)
  };

  function ConfirmationDialog () {
   // console.log(dObj)

    return (
      <div>
        <Dialog
          open={open}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This resource has an event scheduled on this day. Do you still want to keep resource on downtime?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleOk} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <Box className={classes.root}>
      <DatePicker variant="static" initialFocusedDate={selectedMonth} onChange={handleChange} onMonthChange={(date) => {
        setSelectedMonth(date);
      }} renderDay={DayRendererWithDowntimeIndicator} />
     {open && <ConfirmationDialog dObj={selectedDate} ></ConfirmationDialog>}
      <Box ml={2}>
        <Alert variant="outlined" color="info">
          <Box>Click the dates in the Calendar to select/un-select Downtime.</Box>
        </Alert> <br/>
        <Grid container spacing={1}>
          <Grid item>
            <Card variant="outlined" className={classes.statsBox}>
              <CardHeader title="Total Days" subheader="Downtime"></CardHeader>
              <CardContent>
                <Chip onClick={()=> {
                  click(selectedDowntime)
                }} color="secondary" label={`${selectedDowntime.length} DAYS`} />
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item>
            <Card variant="outlined" className={classes.statsBox}>
              <CardHeader title="This Month" subheader="Downtime"></CardHeader>
              <CardContent>
                <Chip color="secondary" label="12 DAYS" />
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card variant="outlined" className={classes.statsBox}>
              <CardHeader title="This Year" subheader="Downtime"></CardHeader>
              <CardContent>
                <Chip color="secondary" label="22 DAYS" />
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
}

export default ResourceDowntimeForm;
