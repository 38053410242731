import { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import useUsersSearch from "../../../hooks/use-users-search";
import useBatchFormSchema from "../../../hooks/use-batch-form-schema";
import "./styles.css";

function NoteEditor({ formName, triggerValueChange, onValueChange }) {
  const { searchFields } = useBatchFormSchema(formName);
  const { searchUsers } = useUsersSearch();
  const [message] = useState("");
  const editorRef = useRef(null);
  let messageHtml = '', messageTokens = [];

  useEffect(() => {
    if (triggerValueChange) {
      onValueChange && onValueChange(messageHtml, messageTokens);
    }
  }, [triggerValueChange]);

  const onChange = () => {
    const editor = editorRef.current.getEditor();
    messageHtml = editorRef.current.getEditorContents();
    messageTokens = editor.getContents()["ops"];
  }

  return (
    <>
      <ReactQuill
        defaultValue={message}
        placeholder={"Type your note.."}
        ref={editorRef}
        onChange={onChange}
        onBlur={onChange}
        modules={{
          mention: {
            allowedChars: /^[A-Za-z\s]*$/,
            mentionDenotationChars: ["@", "#"],
            source: async (searchTerm, renderList, mentionChar) => {
              let values;
              let results = [];

              if (mentionChar === "@") {
                results = await searchUsers(searchTerm);
              } else {
                results = searchFields();
              }

              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                renderList(results, searchTerm);
              }
            },
          },
        }}
      />
    </>
  );
}

export default NoteEditor;
