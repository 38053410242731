import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { render } from "react-dom";
import {
  BackendError,
  Lockscreen,
  NotFound,
  PasswordReset,
  Signin,
  Signup
} from "./pages";
import useUserSession from './hooks/use-user-session';
import AppProvider from "./components/AppProvider/AppProvider";
import DashboardPage from "./containers/Dashboard";
import registerServiceWorker from "./registerServiceWorker";

function App() {
  const session = useUserSession();

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/500" component={BackendError} />
        <Route exact path="/Lockscreen" component={Lockscreen} />
        <Route exact path="/forgot" component={PasswordReset} />
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/signup" component={Signup} />
        <Route path="/" component={session.user ? DashboardPage : Signin} />
      </Switch>
    </BrowserRouter>
  )
}

render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById("root")
);

registerServiceWorker();
